var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useContext } from "react";
import { BaseSelectorContext, useDialogContext, useFormContextLite, } from "components-care";
import { IconButton } from "@mui/material";
import { useButtonEndAdornmentStyles } from "../../../components/Dialogs/PickDeviceModelButton";
import SearchAndMultiSelect from "../../../components/SearchAndBrowse/Instances/SearchAndMultiSelect";
import StaffSearch from "../../../components/SearchAndBrowse/Instances/StaffSearch";
import { Search as SearchIcon } from "@mui/icons-material";
import reactNodeToElement from "../../../../../utils/reactNodeToElement";
const BriefingPickEmployeesButton = (props) => {
    const { onSelect, disabled, getInitialSelection, getBriefingRequirements, title, } = props;
    const { getFieldValue } = useFormContextLite();
    const { classes } = useButtonEndAdornmentStyles();
    const selectorContext = useContext(BaseSelectorContext);
    const [pushDialog] = useDialogContext();
    const handleSelect = useCallback((selected) => {
        onSelect(selected);
        if (selectorContext)
            selectorContext.addToLru(...selected);
    }, [onSelect, selectorContext]);
    const openDialog = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        return pushDialog(_jsx(SearchAndMultiSelect, { instance: StaffSearch, initialSelection: getInitialSelection(), onSelected: handleSelect, props: {
                briefingRequirements: getBriefingRequirements &&
                    (yield getBriefingRequirements(getFieldValue)),
            }, title: title }));
    }), [
        pushDialog,
        getInitialSelection,
        getFieldValue,
        handleSelect,
        getBriefingRequirements,
        title,
    ]);
    return reactNodeToElement(!disabled && (_jsx(IconButton, { onClick: openDialog, className: classes.button, disabled: disabled, size: "large", color: "primary", children: _jsx(SearchIcon, {}) })));
};
export default React.memo(BriefingPickEmployeesButton);

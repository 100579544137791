import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useState } from "react";
import { FullFormDialog, showInfoDialog, useDialogContext, } from "components-care";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import DialogButton from "../../Dialogs/Buttons/DialogButton";
import DialogButtons from "../../Dialogs/Buttons/DialogButtons";
const DEFAULT_PROHIBIT_UNSELECT = [[], ""];
const useStyles = makeStyles()({
    root: {
        height: "100%",
    },
});
const SearchAndMultiSelect = (props) => {
    const { instance: Instance, props: instanceProps, onSelected, initialSelection, title, prohibitUnselect, } = props;
    const [prohibitIds, prohibitMsg] = prohibitUnselect !== null && prohibitUnselect !== void 0 ? prohibitUnselect : DEFAULT_PROHIBIT_UNSELECT;
    const { classes } = useStyles();
    const [selected, setSelected] = useState(initialSelection);
    const [pushDialog, popDialog] = useDialogContext();
    const { t } = useTranslation("global-device-catalog");
    const handleSelect = useCallback((newSelected) => {
        const unselected = selected.filter((id) => !newSelected.includes(id));
        if (unselected.find((id) => prohibitIds.includes(id))) {
            void showInfoDialog(pushDialog, {
                title: t("unselect-prevention-title"),
                message: prohibitMsg,
            });
        }
        else {
            setSelected(newSelected);
        }
    }, [prohibitIds, prohibitMsg, pushDialog, selected, t]);
    const handleConfirm = useCallback(() => {
        popDialog();
        onSelected(selected);
    }, [onSelected, popDialog, selected]);
    return (_jsx(FullFormDialog, { useCustomClasses: true, maxWidth: "md", dialogTitle: title, children: _jsxs(Grid, { container: true, direction: "column", wrap: "nowrap", className: classes.root, children: [_jsx(Grid, { item: true, xs: true, children: React.createElement(Instance, Object.assign(Object.assign({}, instanceProps), { selected, onSelected: handleSelect, multiSelect: true })) }), _jsx(Grid, { item: true, children: _jsx(DialogButtons, { children: _jsx(DialogButton, { onClick: handleConfirm, styleType: "action", children: t("actions.confirm") }) }) })] }) }));
};
export default React.memo(SearchAndMultiSelect);

import { jsx as _jsx } from "react/jsx-runtime";
import React, { useContext } from "react";
import SamedisCrud from "../../../../../components-care/SamedisCrud";
import { useIncidentModel } from "../../../../../components-care/models/IncidentModel";
import IncidentsForm from "./IncidentsFormPage";
import { useSyncConfigOpt } from "../../../../../utils/useSyncConfig";
import EnterpriseContext from "../../../../Enterprise/components/EnterpriseContext";
import IncidentFilterBar from "../IncidentFilterBar";
import { useTheme } from "@mui/material";
export const IncidentsContext = React.createContext(null);
export const useIncidentsContext = () => {
    const ctx = useContext(IncidentsContext);
    if (!ctx)
        throw new Error("IncidentsContext not set");
    return ctx;
};
const IncidentsCRUD = (props) => {
    var _a;
    const theme = useTheme();
    const ctx = useIncidentsContext();
    const enterprise = useContext(EnterpriseContext);
    const model = useIncidentModel({
        role: ctx.type,
        enterpriseClients: enterprise === null || enterprise === void 0 ? void 0 : enterprise.clients,
    });
    const isSyncReadOnly = ((_a = useSyncConfigOpt()) === null || _a === void 0 ? void 0 : _a.incidents) === "to_samedis";
    return (_jsx(SamedisCrud, Object.assign({ model: model, isSyncReadOnly: isSyncReadOnly, deletePermission: "incidents.dispatcher", readPermission: null, editPermission: null, 
        // only dispatcher controller supports create, otherwise use landing page
        newPermission: ctx.type === "dispatcher" ? "incidents.reporter" : false, exportPermission: "incidents.xlsx", gridProps: {
            filterBar: IncidentFilterBar,
            enableFilterDialogMediaQuery: theme.breakpoints.down("lg"),
        } }, props, { children: IncidentsForm })));
};
export default React.memo(IncidentsCRUD);

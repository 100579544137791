import { green, grey } from "@mui/material/colors";
export const customColors = {
    white: "rgb(255, 255, 255)",
    grey: "rgb(127, 127, 127)",
    lighterBlue: "rgb(218, 236, 248)",
    lightBlue: "rgb(182, 216, 241)",
    turquoise: "#00AFC3",
};
const customPalette = {
    primary: {
        main: "rgb(8, 105, 179)",
        light: "rgb(57, 135, 194)",
    },
    secondary: {
        main: "rgb(185, 215, 240)",
    },
    action: {
        hover: "rgba(0,0,0, .2)",
    },
    error: {
        main: "rgb(255, 99, 71)",
    },
    warning: {
        main: "#ffcd00",
    },
    success: {
        main: green[500],
    },
    background: {
        default: "#f7f7f7",
    },
    divider: "#E5EAF2",
};
const filterActiveColor = customPalette.warning.main;
const BREAKPOINTS = {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
};
const fontSize = {
    base: 13,
};
const typography = {
    h1: {
        fontSize: "2rem",
        fontWeight: 700,
        letterSpacing: 0,
        fontFamily: '"Roboto Condensed", sans-serif',
    },
    h2: {
        fontSize: "1rem",
        fontWeight: 700,
        letterSpacing: 0,
        fontFamily: '"Roboto Condensed", sans-serif',
    },
    h3: {
        fontSize: "0.8125rem",
        fontWeight: 700,
        letterSpacing: 0,
        fontFamily: '"Roboto Condensed", sans-serif',
    },
    h4: {
        fontSize: "0.75rem",
        fontWeight: 700,
        letterSpacing: 0,
        fontFamily: '"Roboto Condensed", sans-serif',
    },
    h5: {
        fontSize: "0.5625rem",
        fontWeight: 700,
        letterSpacing: 0,
        fontFamily: '"Roboto Condensed", sans-serif',
    },
    h6: {
        fontSize: "0.4375rem",
        fontWeight: 700,
        letterSpacing: 0,
        fontFamily: '"Roboto Condensed", sans-serif',
    },
    subtitle1: {
        fontWeight: 500,
        fontSize: "1rem",
        letterSpacing: -0.21,
        fontFamily: '"Roboto", sans-serif',
        lineHeight: 1.43,
    },
    subtitle2: {
        fontWeight: 500,
        fontSize: "0.8125rem",
        letterSpacing: -0.17,
        fontFamily: '"Roboto", sans-serif',
        lineHeight: 1.43,
    },
    body1: {
        fontWeight: 400,
        fontSize: "0.8125rem",
        letterSpacing: 0.16,
        fontFamily: '"Roboto", sans-serif',
    },
    body2: {
        fontWeight: 400,
        fontSize: "0.5625rem",
        letterSpacing: 0.11,
        fontFamily: '"Roboto", sans-serif',
    },
    caption: {
        fontWeight: 400,
        fontSize: "1rem", // this is used for labels and displayed at 75% scale most of the time, so 0.75 rem
        letterSpacing: 0.14,
        fontFamily: '"Roboto", sans-serif',
    },
    caption2: {
        fontWeight: 400,
        fontSize: "0.75rem",
        letterSpacing: 0.105,
        fontFamily: '"Roboto", sans-serif',
    },
    button: {
        textTransform: "unset",
        fontWeight: 400,
        fontSize: "0.8125rem",
        letterSpacing: 0.16,
        fontFamily: '"Roboto", sans-serif',
    },
    overline: {
        textTransform: "unset",
        fontWeight: 400,
        fontSize: "0.8125rem",
        letterSpacing: 0.16,
        fontFamily: '"Roboto", sans-serif',
    },
};
const BORDER_RADIUS = 5;
const ccInputStyles = {
    root: {
        "& MuiInput-root": {
            paddingRight: 0,
        },
        "& MuiInput-input": {
            paddingRight: 16,
            "&::placeholder": {
                fontSize: "0.75rem",
                fontStyle: "italic",
            },
        },
        "& MuiInput-multiline": {
            paddingRight: "4px !important",
        },
    },
};
export const getTheme = () => ({
    typography: Object.assign({ fontSize: fontSize.base }, typography),
    breakpoints: {
        values: BREAKPOINTS,
    },
    shape: {
        borderRadius: BORDER_RADIUS,
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    fontSize: "0.8125rem",
                    lineHeight: 1.43,
                    letterSpacing: "0.01071em",
                },
            },
        },
        MuiTypography: {
            defaultProps: {
            /*variantMapping: {
              //caption2: // not defined, use default
            }*/
            },
            styleOverrides: {
                root: {
                    ".MuiTooltip-tooltip &": {
                        color: "#FFFFFF",
                    },
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    borderRadius: BORDER_RADIUS,
                },
            },
        },
        MuiTextField: {
            defaultProps: {
                variant: "standard",
            },
        },
        MuiSelect: {
            defaultProps: {
                variant: "standard",
            },
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    margin: "8px 8px 8px 5px !important",
                    ".components-care-data-grid-filter-bar &": {
                        margin: "2px 8px 2px 5px !important",
                    },
                    fontSize: fontSize.base,
                    "&.Mui-disabled::placeholder": {
                        opacity: 0,
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                input: {
                    padding: "2.5px 4px",
                },
                inputMultiline: {
                    margin: "0 !important",
                },
                notchedOutline: {
                    "& > legend": Object.assign({}, typography.caption2),
                },
            },
        },
        MuiStepper: {
            styleOverrides: {
                root: {
                    backgroundColor: "unset",
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: Object.assign({ ["@media (min-width: " + BREAKPOINTS.sm + "px)"]: {
                        minWidth: 160,
                    } }, typography.subtitle1),
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    ["@media (max-width: " + BREAKPOINTS.sm + "px)"]: {
                        margin: 8,
                        width: "calc(100% - 16px)",
                        maxHeight: "calc(100% - 16px)",
                    },
                },
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: Object.assign({}, typography.h2),
            },
        },
        MuiStepLabel: {
            styleOverrides: {
                label: Object.assign({}, typography.body1),
            },
        },
        MuiStepIcon: {
            styleOverrides: {
                text: Object.assign({}, typography.body1),
            },
        },
        MuiPopover: {
            defaultProps: {
                PaperProps: {
                    elevation: 0,
                    style: { border: "1px solid " + customPalette.divider },
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: Object.assign({}, typography.caption),
            },
        },
        MuiDatePicker: {
            defaultProps: {
                views: ["year", "month", "day"],
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                inputRoot: {
                    '&[class*="MuiOutlinedInput-root"]': {
                        paddingLeft: 8,
                        paddingTop: 0,
                        paddingBottom: 0,
                        "& .MuiAutocomplete-input": {
                            padding: "2.5px 4px",
                        },
                    },
                    '&[class*="MuiAutocomplete-inputRoot"]': {
                        paddingRight: "10px !important",
                        flexWrap: "nowrap",
                    },
                },
                paper: {
                    margin: 0,
                },
                noOptions: {
                    padding: "2.5px 16px",
                },
                loading: {
                    padding: "2.5px 16px",
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: Object.assign(Object.assign({}, typography.body1), { padding: 8 }),
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: Object.assign({}, typography.body2),
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    ".MuiTooltip-tooltip &": {
                        color: "#FFFFFF",
                    },
                },
            },
        },
        // CC START
        CcSignalPortlet: {
            // keep in sync with useMyTypographyStyles
            // https://github.com/Samedis-care/samedis-care-issues/issues/1293
            styleOverrides: {
                divider: {
                    display: "none",
                },
                title: ({ theme }) => ({
                    fontFamily: "Roboto",
                    fontSize: "1.75rem",
                    fontWeight: 700,
                    color: "#BDBDBC",
                    textTransform: "uppercase",
                    paddingLeft: theme.spacing(2),
                    textAlign: "left",
                }),
                paper: {
                    boxShadow: "none",
                },
            },
        },
        CcSignalPortletItem: {
            styleOverrides: {
                itemColorInactive: {
                    color: "#FFF",
                    backgroundColor: grey[600],
                },
                listText: {
                    "& .MuiTypography-root": {
                        // keep in sync with useMyTypographyStyles
                        // https://github.com/Samedis-care/samedis-care-issues/issues/1293
                        fontSize: "1rem",
                        fontWeight: 700,
                        color: "rgba(0, 0, 0, 0.6)",
                        textTransform: "uppercase",
                    },
                },
            },
        },
        CcActionButton: {
            styleOverrides: {
                button: {
                    padding: "7px 25px",
                    borderRadius: 25,
                    fontSize: "0.75rem",
                    border: "none",
                    "&.Mui-disabled": {
                        backgroundColor: "#bcbdbf",
                        border: "none",
                        boxShadow: "rgba(255, 255, 255, 0.25) 0px 0px 2px 0px",
                    },
                    "&.MuiButton-contained": {
                        borderRadius: 25,
                    },
                },
            },
        },
        CcFormButtons: {
            styleOverrides: {
                buttonWrapper: {
                    margin: "0 25px 0 0",
                },
                root: {
                    width: "auto",
                    border: "none",
                    borderRadius: "32px",
                    padding: "10px 20px",
                    backgroundColor: "rgba(185, 215, 240, 0.5)",
                },
            },
        },
        CcGroupBox: {
            styleOverrides: {
                legend: Object.assign(Object.assign({}, typography.caption), { fontSize: "calc(" + typography.caption.fontSize + " * 0.75)", lineHeight: "calc(" + typography.caption.lineHeight + " * 0.75)", letterSpacing: "calc(" +
                        typography.caption.letterSpacing.toString() +
                        "px * 0.75)", color: "rgba(0, 0, 0, 0.6)" }),
            },
        },
        CcFormPageLayout: {
            styleOverrides: {
                body: {
                    paddingBottom: 90,
                },
            },
        },
        CcSubActionButton: {
            styleOverrides: {
                button: {
                    backgroundColor: "transparent",
                },
            },
        },
        CcBaseSelector: {
            styleOverrides: {
                listItem: {
                    padding: "4px 16px !important",
                },
                smallLabel: Object.assign({}, typography.caption2),
            },
        },
        CcMultiSelectEntry: {
            styleOverrides: {
                root: {
                    paddingTop: 0,
                    paddingBottom: 0,
                },
                selected: {
                    paddingTop: 4,
                    paddingBottom: 4,
                },
            },
        },
        CcMultiSelect: {
            defaultProps: {
                confirmDelete: true,
            },
        },
        CcFileUpload: {
            defaultProps: {
                variant: "modern",
            },
            styleOverrides: {
                modernUploadLabel: {
                    "&.CcFileUpload-modernUploadLabel-empty": Object.assign({}, typography.caption2),
                },
                formatTextModern: Object.assign({}, typography.caption2),
            },
        },
        CcFile: {
            styleOverrides: {
                listLabel: Object.assign({}, typography.body1),
            },
        },
        CcMultiImage: {
            styleOverrides: {
                editLabel: Object.assign({}, typography.body1),
            },
        },
        CcImageSelector: {
            defaultProps: {
                variant: "modern",
            },
            styleOverrides: {
                modernUploadControlUpload: {
                    color: customPalette.primary.main,
                    fill: customPalette.primary.main,
                },
            },
        },
        CcLocalizedKeyboardDatePicker: {
            defaultProps: {
                hideDisabledIcon: true,
            },
        },
        CcUiKitInput: {
            styleOverrides: ccInputStyles,
        },
        CcUiKitInputOutlined: {
            styleOverrides: ccInputStyles,
        },
        CcUiKitTextField: {
            styleOverrides: ccInputStyles,
        },
        CcDialogTitle: {
            styleOverrides: {
                text: Object.assign({}, typography.h2),
            },
        },
        CcNotifications: {
            styleOverrides: {
                header: Object.assign({}, typography.h2),
            },
        },
        CcDataGrid: {
            styleOverrides: {
                root: {
                    border: "none",
                },
                header: {
                    borderWidth: "0 0 0 0",
                    padding: "0 0 15px 0",
                    marginTop: 2,
                },
                cell: {
                    borderWidth: "0 0 1px 0",
                    "&.CcDataGrid-dataCell": {
                        borderColor: customColors.white,
                        backgroundColor: customColors.lighterBlue,
                    },
                    "&.CcDataGrid-dataCellSelected": {
                        backgroundColor: customColors.lightBlue,
                    },
                    "&.CcDataGrid-headerCell": {
                        borderWidth: 0,
                    },
                },
                footer: {
                    padding: "15px 0 0 0",
                },
                columnHeaderContentWrapper: {
                    fontSize: "0.75rem",
                    fontWeight: 500,
                },
                columnHeaderResizer: {
                    borderWidth: "0 1px 0 0",
                    top: "2px",
                    height: "calc(100% - 13px)",
                },
                customFilterContainerHeader: Object.assign({}, typography.subtitle1),
                columnHeaderFilterButton: {
                    "&.CcDataGrid-columnHeaderFilterButtonActive": {
                        color: filterActiveColor,
                    },
                },
                quickFilterIcon: {
                    "&.CcDataGrid-quickFilterActiveIcon": {
                        color: filterActiveColor,
                    },
                },
                customFilterIcon: {
                    "&.CcDataGrid-customFilterActiveIcon": {
                        color: filterActiveColor,
                    },
                },
                customFilterSingle: {
                    "& .MuiAutocomplete-root.Mui-active": {
                        borderColor: filterActiveColor,
                        "& > fieldset": {
                            borderColor: filterActiveColor,
                        },
                        "& .MuiAutocomplete-inputRoot": {
                            borderColor: filterActiveColor,
                            "& > fieldset": {
                                borderColor: filterActiveColor,
                            },
                        },
                    },
                },
                customFilterMulti: {
                    "& .MuiAutocomplete-root.Mui-active": {
                        borderColor: filterActiveColor,
                        "& > fieldset": {
                            borderColor: filterActiveColor,
                        },
                        "& .MuiAutocomplete-inputRoot": {
                            borderColor: filterActiveColor,
                            "& > fieldset": {
                                borderColor: filterActiveColor,
                            },
                        },
                    },
                },
                centeredStickyTypography: {
                    "& .MuiTypography-h4": Object.assign({}, typography.subtitle1),
                    "& .MuiTypography-h5": Object.assign({}, typography.subtitle2),
                },
            },
        },
        CcComponentWithLabel: {
            styleOverrides: {
                label: {
                    "&.MuiTypography-caption": {
                        transform: "scale(0.75)",
                    },
                },
            },
        },
        CcDefaultFormPageButtons: {
            styleOverrides: {
                backButton: {
                    backgroundColor: customPalette.primary.main,
                },
            },
        },
        CcMultiLanguageInput: {
            styleOverrides: {
                languageLabelInputAdornment: Object.assign({}, typography.caption2),
            },
        },
    },
    palette: Object.assign({}, customPalette),
});

import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback } from "react";
import { Grid } from "@mui/material";
import { selectorLocalLoadHandler, SingleSelect, } from "components-care";
import { makeStyles } from "tss-react/mui";
const useStyles = makeStyles()((theme) => ({
    notchedOutline: {
        borderColor: theme.palette.divider,
    },
}));
const FilterEntrySwitch = (props) => {
    var _a;
    const { name, selected, setSelected, options, title } = props;
    const { classes } = useStyles();
    const handleSelectionChange = useCallback((selected) => {
        setSelected(name, selected ? selected.value : "");
    }, [setSelected, name]);
    return (_jsx(Grid, { item: true, children: _jsx(SingleSelect, { label: title, onLoad: selectorLocalLoadHandler(options), selected: (_a = options.find((opt) => opt.value === selected)) !== null && _a !== void 0 ? _a : null, onSelect: handleSelectionChange, disableClearable: true, disableSearch: true, textFieldInputClasses: classes }) }));
};
export default React.memo(FilterEntrySwitch);

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { MdmContext } from "../../MdmTypes";
import { BaseSelectorContext, showErrorDialog, useDialogContext, useFormFieldContext, } from "components-care";
import DeviceTypePickerDialog from "../DeviceType/DeviceTypePickerDialog";
import { IconButton } from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import { useButtonEndAdornmentStyles } from "../../../components/Dialogs/PickDeviceModelButton";
import { useTenantId } from "../../../../components/TenantContext";
import DeviceTypeModel from "../../../../../components-care/models/DeviceTypeModel";
import i18n from "../../../../../i18n";
import { useAuthProviderContext } from "../../../../components/AuthProvider";
import reactNodeToElement from "../../../../../utils/reactNodeToElement";
const DeviceModelPickDeviceTypeButton = () => {
    const tenantId = useTenantId();
    const authInfo = useAuthProviderContext();
    const target = useContext(MdmContext);
    const [dialogLoading, setDialogLoading] = useState(false);
    const { visibility, field, handleChange, value } = useFormFieldContext();
    const selectorContext = useContext(BaseSelectorContext);
    const [pushDialog, popDialog] = useDialogContext();
    const { classes } = useButtonEndAdornmentStyles();
    const handleSelect = useCallback((selected) => {
        if (selected) {
            handleChange(field, selected);
            if (selectorContext)
                selectorContext.addToLru(selected);
            popDialog();
        }
    }, [handleChange, field, selectorContext, popDialog]);
    const openDialog = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const currentId = value;
        let catalogId = null;
        setDialogLoading(true);
        try {
            if (currentId) {
                // load root node
                try {
                    const [record] = yield DeviceTypeModel({
                        tenantId,
                        target,
                        t: i18n.t.bind(i18n),
                    }).getCached(currentId);
                    catalogId = (_a = record.parent_ids[0]) !== null && _a !== void 0 ? _a : currentId;
                }
                catch (e) {
                    if (!(e instanceof Error) ||
                        e.name !== "BackendError" ||
                        e.code !== "record_not_found_error") {
                        throw e;
                    }
                }
            }
            else {
                const tenant = authInfo.current_user.tenants.find((tenant) => tenant.id === tenantId);
                catalogId = tenant.type_catalog_id;
            }
        }
        catch (e) {
            showErrorDialog(pushDialog, e);
        }
        finally {
            setDialogLoading(false);
        }
        pushDialog(_jsx(DeviceTypePickerDialog, { mode: target, initialCatalogId: catalogId, initialSelectedId: currentId, onConfirm: handleSelect }));
    }), [
        authInfo.current_user.tenants,
        handleSelect,
        pushDialog,
        target,
        tenantId,
        value,
    ]);
    return useMemo(() => reactNodeToElement(!(visibility.readOnly || dialogLoading) && (_jsx(IconButton, { onClick: openDialog, className: classes.button, disabled: visibility.readOnly || dialogLoading, size: "large", color: "primary", children: _jsx(SearchIcon, {}) }))), [classes.button, dialogLoading, openDialog, visibility.readOnly]);
};
export default React.memo(DeviceModelPickDeviceTypeButton);

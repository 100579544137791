import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import NotFound from "../NotFound";
import { useAuthProviderContext } from "./AuthProvider";
import TenantContextSetter from "./TenantContextSetter";
import TenantSelection from "../TenantSelection";
import Forbidden from "../Forbidden";
import { RawTenantRoutes, } from "./TenantRoutesProvider";
import { hasPermission, Route as SentryRoute, Routes as SentryRoutes, useParams, } from "components-care";
import { normalizeCanDos } from "../../utils";
import TenantCreate from "../TenantSelection/components/FacilityCreate";
import ErrorBoundary from "./ErrorBoundary";
import { useIsGlobalMode } from "./SamedisPortal";
const convRouteDef = (permissions, entry) => {
    var _a, _b;
    const children = (_b = (_a = entry.children) === null || _a === void 0 ? void 0 : _a.map((entry) => convRouteDef(permissions, entry)).flat()) !== null && _b !== void 0 ? _b : [];
    return entry.route
        ? [
            _jsx(SentryRoute, { path: `${entry.route}/*`, element: React.createElement(entry.pageComponent
                    ? hasPermission(normalizeCanDos(permissions), entry.cando)
                        ? entry.pageComponent
                        : Forbidden
                    : NotFound) }, entry.route),
            ...children,
        ]
        : children;
};
const TenantRoutes = () => {
    const authInfo = useAuthProviderContext();
    const params = useParams();
    const tenant = authInfo.current_user.tenants.find((tenant) => tenant.id === params.tenantId);
    if (!tenant) {
        return (_jsx(TenantContextSetter, { tenantId: null, children: _jsx(NotFound, {}) }));
    }
    return (_jsx(TenantContextSetter, { tenantId: tenant.id, children: _jsx(SentryRoutes, { children: [
                ...RawTenantRoutes.map((entry) => convRouteDef(tenant.candos, entry))
                    .flat()
                    .reverse(),
                _jsx(SentryRoute, { path: `*`, element: _jsx(NotFound, {}) }, "not-found"),
            ] }) }));
};
const GlobalRoutes = () => {
    const authInfo = useAuthProviderContext();
    return (_jsx(TenantContextSetter, { tenantId: null, children: _jsx(SentryRoutes, { children: [
                ...RawTenantRoutes.map((entry) => convRouteDef(authInfo.candos, entry))
                    .flat()
                    .reverse(),
                _jsx(SentryRoute, { path: `*`, element: _jsx(NotFound, {}) }, "not-found"),
            ] }) }));
};
const Routes = () => {
    const isGlobalMode = useIsGlobalMode();
    if (isGlobalMode) {
        return (_jsx(ErrorBoundary, { children: _jsx(GlobalRoutes, {}) }));
    }
    return (_jsx(ErrorBoundary, { children: _jsxs(SentryRoutes, { children: [_jsx(SentryRoute, { path: `/tenant/:tenantId/*`, element: _jsx(TenantRoutes, {}) }), _jsx(SentryRoute, { path: "/new-tenant/*", element: _jsx(TenantContextSetter, { tenantId: null, children: _jsx(TenantCreate, {}) }) }), _jsx(SentryRoute, { path: "/", element: _jsx(TenantContextSetter, { tenantId: null, children: _jsx(TenantSelection, {}) }) }), _jsx(SentryRoute, { path: "*", element: _jsx(TenantContextSetter, { tenantId: null, children: _jsx(NotFound, {}) }) })] }) }));
};
export default React.memo(Routes);

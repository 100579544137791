var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { createElement as _createElement } from "react";
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useEffect, useState } from "react";
import { Loader, MultiSelect, useFormContext, } from "components-care";
import InventoryModel from "../components-care/models/InventoryModel";
import { useTenantId } from "../pages/components/TenantContext";
import { SERVICE_INTERVAL_CATEGORIES, } from "./MaintenanceTypesInput";
import i18n from "../i18n";
import { useTranslation } from "react-i18next";
import { intervalToString } from "./Interval";
import { validateMaintenanceTypeEntry } from "../components-care/types/TypeMaintenanceTypes";
const entryToData = (t, value) => {
    var _a;
    const category = SERVICE_INTERVAL_CATEGORIES(t).find((entry) => entry.value === value.category);
    if (value.label == null)
        throw new Error("Label not found");
    return {
        icon: category === null || category === void 0 ? void 0 : category.icon,
        label: t("event-issue:maintenance-types.entry", {
            LABEL: value.label,
            CATEGORY: (_a = category === null || category === void 0 ? void 0 : category.label) !== null && _a !== void 0 ? _a : value.category,
            INTERVAL: intervalToString(value, t),
        }),
        value: value.label,
    };
};
const MaintenanceTypesSelector = (props) => {
    const { inventoryId, selected, onSelect } = props, otherProps = __rest(props, ["inventoryId", "selected", "onSelect"]);
    const { t } = useTranslation("event-issue");
    const { parentFormContext } = useFormContext();
    const [refreshToken, setRefreshToken] = useState(() => new Date().toISOString());
    const [maintenanceTypes, setMaintenanceTypes] = useState([]);
    const tenantId = useTenantId();
    useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            let mTypes;
            if (parentFormContext &&
                parentFormContext.model.modelId === "inventory-model") {
                mTypes = parentFormContext.values
                    .with_service_intervals.filter((entry) => validateMaintenanceTypeEntry(entry) == null);
            }
            else {
                mTypes = (yield InventoryModel({ t: i18n.t, tenantId }).getCached(inventoryId))[0].with_service_intervals;
            }
            setMaintenanceTypes(mTypes);
            setRefreshToken(new Date().toISOString());
        }))();
    }, [inventoryId, parentFormContext, tenantId]);
    if (!inventoryId)
        throw new Error("Should not be rendered without Inventory ID");
    const handleLoad = useCallback((query) => {
        if (!maintenanceTypes)
            return [];
        return maintenanceTypes
            .filter((entry) => entry.label.toLowerCase().includes(query.toLowerCase()))
            .map((entry) => entryToData(t, entry));
    }, [maintenanceTypes, t]);
    const handleSelect = useCallback((newSelected) => {
        if (!onSelect)
            return;
        onSelect(newSelected.map((entry) => entry.value));
    }, [onSelect]);
    if (!maintenanceTypes)
        return _jsx(Loader, {});
    return (_createElement(MultiSelect, Object.assign({}, otherProps, { key: refreshToken, selected: selected.map((value) => {
            var _a;
            return entryToData(t, (_a = maintenanceTypes.find((entry) => entry.label === value)) !== null && _a !== void 0 ? _a : {
                label: value,
                value: null,
                unit: "month",
                category: "maintenance",
            });
        }), onLoad: handleLoad, onSelect: handleSelect, enableIcons: true })));
};
export default React.memo(MaintenanceTypesSelector);

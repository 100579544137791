import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback } from "react";
import { useFormFieldContext } from "components-care";
import BriefingPickEmployeesButton from "../../Training/components/Briefing/BriefingPickEmployeesButton";
const PickEmployeesButton = (props) => {
    const { visibility, value, field, handleChange } = useFormFieldContext();
    const handleSelect = useCallback((selected) => {
        handleChange(field, selected);
    }, [handleChange, field]);
    return (_jsx(BriefingPickEmployeesButton, Object.assign({}, props, { disabled: visibility.readOnly, onSelect: handleSelect, getInitialSelection: () => value })));
};
export default React.memo(PickEmployeesButton);

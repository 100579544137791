var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { DepartmentIcon } from "../../../../../components/icons";
import { useTranslation } from "react-i18next";
import DenseLayout from "./DenseLayout";
const DepartmentRenderer = (props) => {
    const { data } = props, otherProps = __rest(props, ["data"]);
    const { t } = useTranslation("department");
    return (_jsx(DenseLayout, Object.assign({ id: data.id, icon: _jsx(DepartmentIcon, {}), title: data.title, info: [
            data.notes,
            data.cost_center_number &&
                t("cost-center-number.field") + ": " + data.cost_center_number,
        ].filter((e) => !!e) }, otherProps)));
};
export default React.memo(DepartmentRenderer);

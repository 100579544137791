var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import TrustLevelManufacturerIcon from "./TrustLevelManufacturerIcon";
import TrustLevelCommunityIcon from "./TrustLevelCommunityIcon";
import TrustLevelTenantIcon from "./TrustLevelTenantIcon";
import TrustLevelSupplierIcon from "./TrustLevelSupplierIcon";
const TrustLevelIcon = React.forwardRef((props, ref) => {
    const { level } = props, other = __rest(props, ["level"]);
    switch (level) {
        case "tenant":
            return _jsx(TrustLevelTenantIcon, Object.assign({ ref: ref }, other));
        case "supplier":
            return _jsx(TrustLevelSupplierIcon, Object.assign({ ref: ref }, other));
        case "community":
            return _jsx(TrustLevelCommunityIcon, Object.assign({ ref: ref }, other));
        case "manufacturer":
            return _jsx(TrustLevelManufacturerIcon, Object.assign({ ref: ref }, other));
    }
    throw new Error("Unsupported Trust Level: " + level);
});
export default React.memo(TrustLevelIcon);

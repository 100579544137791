import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback } from "react";
import { Grid, Popover } from "@mui/material";
import { ActionButton, useDialogContext } from "components-care";
import { Menu as MenuIcon } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { IssueType } from "../../../../../components-care/models/EventIssueModel";
import { FullFormDialog as FormDialog } from "components-care/dist/backend-components/Form";
import { hasPermission, usePermissionContext } from "components-care/dist";
import { useActionButtonStyles } from "../../../../../components/ActionButton";
import { useFormEnterpriseClientIdOpt } from "../../../../Enterprise/utils/useFormEnterpriseClientId";
const EventIssueCRUD = React.lazy(() => import("../../Issues"));
const IncidentActionsButton = (props) => {
    const { incidentId, inventoryId } = props;
    const { t } = useTranslation("incident");
    const [pushDialog] = useDialogContext();
    const [perms] = usePermissionContext();
    const { classes } = useActionButtonStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const enterpriseClientId = useFormEnterpriseClientIdOpt();
    const handleClick = useCallback((event) => {
        setAnchorEl(event.currentTarget);
    }, []);
    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, []);
    const handleNewRepairIssue = useCallback(() => {
        handleClose();
        pushDialog(_jsx(FormDialog, { dialogTitle: t("actions.new-repair-issue"), useCustomClasses: true, children: _jsx(EventIssueCRUD, { disableRouting: true, initialView: "new", inventoryId: inventoryId, incidentId: incidentId, issueType: IssueType.Malfunction, enterpriseClientId: enterpriseClientId, disableBackgroundGrid: true }) }));
    }, [handleClose, pushDialog, t, inventoryId, incidentId, enterpriseClientId]);
    const handleRetireDevice = useCallback(() => {
        handleClose();
        pushDialog(_jsx(FormDialog, { dialogTitle: t("actions.retire-device"), useCustomClasses: true, children: _jsx(EventIssueCRUD, { disableRouting: true, initialView: "new", inventoryId: inventoryId, incidentId: incidentId, issueType: IssueType.DeviceRetired, enterpriseClientId: enterpriseClientId, deviceRetired: true, disableBackgroundGrid: true }) }));
    }, [handleClose, pushDialog, t, inventoryId, incidentId, enterpriseClientId]);
    return (_jsxs(_Fragment, { children: [_jsx(ActionButton, { onClick: handleClick, icon: _jsx(MenuIcon, {}), children: t("actions.button") }), _jsx(Popover, { open: !!anchorEl, anchorEl: anchorEl, onClose: handleClose, anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right",
                }, transformOrigin: {
                    vertical: "top",
                    horizontal: "right",
                }, className: classes.popover, children: _jsx("div", { className: classes.content, children: _jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(ActionButton, { onClick: handleNewRepairIssue, disabled: !hasPermission(perms, "issues.writer"), children: t("actions.new-repair-issue") }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(ActionButton, { onClick: handleRetireDevice, disabled: !hasPermission(perms, "issues.writer"), children: t("actions.retire-device") }) })] }) }) })] }));
};
export default React.memo(IncidentActionsButton);

import { makeStyles } from "tss-react/mui";
import { combineColors, SubActionButton } from "components-care";
import { styled } from "@mui/material";
export const useActionButtonStyles = makeStyles()((theme) => ({
    rightAligned: {
        float: "right",
    },
    content: {
        width: "23vw",
        padding: 10,
        [theme.breakpoints.between("sm", "lg")]: {
            width: "37vw",
        },
        [theme.breakpoints.only("xs")]: {
            width: "90vw",
        },
    },
    popover: {
        marginTop: 10,
    },
    danger: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText,
        "&:hover": {
            backgroundColor: `rgba(${combineColors(theme.palette.error.main, theme.palette.action.hover)})`,
        },
    },
    subDanger: {},
}));
export const DangerSubActionButton = styled(SubActionButton)(({ theme }) => ({
    color: theme.palette.error.main,
    "&.MuiButton-outlined svg": {
        fill: theme.palette.error.main,
    },
    "&:hover": {
        color: `rgba(${combineColors(theme.palette.error.main, theme.palette.action.hover)})`,
    },
}));

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { GroupBox, Loader, useDialogContext, useModelGet, } from "components-care";
import { useTenantIdWithContext } from "../../../../components/TenantContext";
import { useInventoryModelLite } from "../../../../../components-care/models/InventoryModel";
import SamedisCrudMultiImage from "../../../../../components-care/SamedisCrudMultiImage";
import OperationStatus from "../Inventory/OperationStatus";
import DeviceModelViewer from "../../../components/Dialogs/DeviceModelViewer/DeviceModelViewer";
import getLocalizedString from "../../../../../utils/getLocalizedString";
const useClasses = makeStyles()((theme) => ({
    button: {
        marginLeft: 15,
        color: theme.palette.primary.main,
        cursor: "pointer",
        textAlign: "right",
        textDecoration: "underline",
    },
    groupBox: {
        paddingLeft: "1.5rem",
    },
    operationStatus: {
        left: "50%",
        position: "relative",
        transform: "translateX(-50%)",
        display: "inline-block",
    },
}));
const InventoryDetails = (props) => {
    const { inventoryId, noImage, enterpriseClientId } = props;
    const tenantId = useTenantIdWithContext(props.tenantId);
    const { t, i18n } = useTranslation([
        "common",
        "inventory",
        "device-models",
        "event-issue",
    ]);
    const { classes } = useClasses();
    const inventoryModel = useInventoryModelLite({
        tenantId: props.tenantId,
        enterpriseClientId,
    });
    const { isLoading, error, data } = useModelGet(inventoryModel, inventoryId);
    const [fields] = data !== null && data !== void 0 ? data : [];
    const deviceId = fields === null || fields === void 0 ? void 0 : fields.catalog_id;
    const device_number = fields === null || fields === void 0 ? void 0 : fields.device_number;
    const serial_number = fields === null || fields === void 0 ? void 0 : fields.serial_number;
    const device_types = fields
        ? getLocalizedString(i18n.language, fields.device_type_title_labels)
        : "";
    const deviceModelTitle = fields === null || fields === void 0 ? void 0 : fields.device_model_title;
    const deviceManufacturerTypePlate = fields === null || fields === void 0 ? void 0 : fields.device_model_manufacturer_according_to_type_plate;
    const inventory_device_location = fields === null || fields === void 0 ? void 0 : fields.device_location_title;
    const has_warranty = fields === null || fields === void 0 ? void 0 : fields.has_warranty;
    const do_maintenance = fields === null || fields === void 0 ? void 0 : fields.has_warranty;
    const linked_image_id = fields === null || fields === void 0 ? void 0 : fields.linked_image_id;
    const operationStatus = fields === null || fields === void 0 ? void 0 : fields.operation_status;
    const [pushDialog] = useDialogContext();
    const openInfo = useCallback(() => pushDialog(_jsx(DeviceModelViewer, { deviceId: fields.catalog_id, inventoryId: inventoryId, enterpriseClientId: enterpriseClientId, target: "tenant" })), [fields, inventoryId, pushDialog, enterpriseClientId]);
    const displayLI = useCallback((field, value) => {
        return (value && (_jsxs("li", { children: [_jsxs("b", { children: [field, ": "] }), value] })));
    }, []);
    return (_jsx(Grid, { children: error ? (error.message) : isLoading ? (_jsx(Loader, {})) : (_jsxs(Grid, { container: true, spacing: 3, children: [!noImage && (_jsx(Grid, { item: true, xs: 12, children: _jsx(SamedisCrudMultiImage, { field: "images", getEndpoint: () => enterpriseClientId
                            ? `v4/enterprise/tenants/${tenantId}/clients/${enterpriseClientId}/inventories/${inventoryId}/images`
                            : `v4/tenants/${tenantId}/inventories/${inventoryId}/images`, initialId: inventoryId, label: t("inventory:inventory-image"), placeholderImage: "/img/placeholders/device.png", additionalImageSource: deviceId
                            ? enterpriseClientId
                                ? `v4/enterprise/tenants/${tenantId}/clients/${enterpriseClientId}/device_models/${deviceId}/images`
                                : `v4/tenants/${tenantId}/device_models/${deviceId}/images`
                            : undefined, primaryId: linked_image_id, readOnly: true }) })), _jsx(Grid, { item: true, xs: 12, children: _jsxs(GroupBox, { label: t("inventory:device-info"), children: [_jsx("div", { className: classes.operationStatus, children: _jsx(OperationStatus, { status: operationStatus, small: true }) }), _jsxs("ul", { className: classes.groupBox, children: [displayLI(t("device-models:device-type.field"), device_types), displayLI(t("device-models:device-form-title.field"), deviceModelTitle), displayLI(t("device-models:manufacturer-according-to-type-plate.field"), deviceManufacturerTypePlate), displayLI(t("inventory:device-number.field"), device_number), displayLI(t("inventory:serial-number.field"), serial_number), displayLI(t("inventory:device-location-id.field"), inventory_device_location), displayLI(t("inventory:has_warranty.info"), has_warranty
                                        ? t("common:boolean.yes")
                                        : t("common:boolean.no")), displayLI(t("inventory:do_maintenance.info"), do_maintenance
                                        ? t("common:boolean.yes")
                                        : t("common:boolean.no"))] }), _jsx(Grid, { className: classes.button, onClick: openInfo, children: t("common:links.more-info") })] }) })] })) }));
};
export default React.memo(InventoryDetails);

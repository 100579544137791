import { ModelDataTypeEnumSelectRenderer, ModelDataTypeStringRendererCC, } from "components-care";
class RendererEnumSelectCustomReadonly extends ModelDataTypeEnumSelectRenderer {
    constructor() {
        super(...arguments);
        this.altRenderer = new ModelDataTypeStringRendererCC();
    }
    render(params) {
        var _a, _b;
        if (!params.visibility.grid &&
            params.visibility.editable &&
            params.visibility.readOnly) {
            return this.altRenderer.render(Object.assign(Object.assign({}, params), { value: (_b = (_a = this.values.find((e) => e.value === params.value)) === null || _a === void 0 ? void 0 : _a.getLabel()) !== null && _b !== void 0 ? _b : "", handleChange: () => {
                    /* no op */
                } }));
        }
        return super.render(params);
    }
}
export default RendererEnumSelectCustomReadonly;

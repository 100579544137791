import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext, useEffect, useMemo } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { DefaultFormPage, FormField, } from "components-care/dist";
import { useTranslation } from "react-i18next";
import { IsInFormDialogContext } from "components-care/dist/backend-components/Form/FormDialog";
import BasicPageLayout from "../../../../../components/BasicPageLayout";
import BoxGrid from "../../../../../components/BoxGrid";
import { useFormContext } from "components-care";
import ContactActionsButton from "./ContactActionsButton";
import { useContactsCrudContext } from "../../Contacts";
import { ContactHasAccountStatusFromForm } from "./HasAccountStatus";
const ContactFormContent = () => {
    const { t } = useTranslation("contact");
    const { id, values, setCustomReadOnly, removeCustomReadOnly } = useFormContext();
    const contactType = values.contact_type;
    const status = values.status;
    const context = useContactsCrudContext();
    const isMdm = context.mode !== "tenant";
    useEffect(() => {
        if (isMdm)
            return;
        if (!id)
            return;
        if (status === "tenant")
            return;
        setCustomReadOnly("public-contact");
        return () => {
            removeCustomReadOnly("public-contact");
        };
    }, [id, isMdm, removeCustomReadOnly, setCustomReadOnly, status]);
    return useMemo(() => (_jsxs(_Fragment, { children: [_jsxs(Grid, { item: true, xs: 12, sm: 6, md: 8, container: true, spacing: 4, children: [_jsxs(BoxGrid, { item: true, xs: 12, container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "contact_type" }) }), contactType === "contact" && (_jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "salutation" }) })), _jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "last_name", overrides: (prev) => {
                                        const props = prev.type
                                            .props;
                                        const suffix = contactType === "company" ? "-alt" : "";
                                        if (props) {
                                            props.placeholder = t("last_name.placeholder" + suffix);
                                        }
                                        prev.getLabel = () => t("last_name.field" + suffix);
                                        return prev;
                                    } }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "first_name", overrides: (prev) => {
                                        const props = prev.type
                                            .props;
                                        const suffix = contactType === "company" ? "-alt" : "";
                                        if (props) {
                                            props.placeholder = t("first_name.placeholder" + suffix);
                                        }
                                        prev.getLabel = () => t("first_name.field" + suffix);
                                        return prev;
                                    } }) }), contactType === "contact" && (_jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "company_id" }) }))] }), _jsxs(BoxGrid, { item: true, xs: 12, md: 6, container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(Typography, { variant: "h2", children: t("communication") }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "phone" }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "mobile" }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "fax" }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "email" }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "url" }) })] }), _jsxs(BoxGrid, { item: true, xs: 12, md: 6, container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(Typography, { variant: "h2", children: t("address") }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "street" }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "zip" }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "town" }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "country" }) })] })] }), _jsxs(BoxGrid, { item: true, xs: 12, sm: 6, md: 4, container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "avatar", overrides: {
                                getLabel: () => contactType === "company"
                                    ? t("avatar.logo")
                                    : t("avatar.field"),
                            } }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "categories" }) })] })] })), [contactType, t]);
};
const ContactForm = (formProps) => {
    const { t } = useTranslation("contact");
    const isInDialog = useContext(IsInFormDialogContext);
    return (_jsx(DefaultFormPage, Object.assign({}, formProps, { children: _jsx(BasicPageLayout, { title: !isInDialog && (_jsxs(_Fragment, { children: [t("pagetitle"), _jsx(Box, { pl: 2, component: "span", display: "inline-block", children: _jsx(ContactHasAccountStatusFromForm, {}) })] })), actions: _jsx(ContactActionsButton, { formProps: formProps }), children: _jsx(ContactFormContent, {}) }) })));
};
export default ContactForm;

import { useTenantInfo } from "../pages/components/TenantContext";
import { useTranslation } from "react-i18next";
import localeRelevance from "components-care/dist/assets/data/locale-relevance.json";
import { useMemo } from "react";
const getLocaleForLanguage = (language) => {
    // get all locales of language
    const locales = Object.entries(localeRelevance).filter(([locale]) => locale.startsWith(language + "-"));
    if (locales.length === 0)
        return null;
    return locales
        .sort((a, b) => b[1] - a[1])[0][0] // sort by relevance, get most relevant, get locale identifier
        .split("-")[1]; // get country of locale
};
export const useRegulatoryDomain = () => {
    const tenantInfo = useTenantInfo();
    const { i18n } = useTranslation();
    const tenantCountry = tenantInfo === null || tenantInfo === void 0 ? void 0 : tenantInfo.country;
    return useMemo(() => {
        if (tenantCountry)
            return tenantCountry;
        const localeSplit = i18n.language.split("-");
        const language = localeSplit[0];
        const country = localeSplit[1] || getLocaleForLanguage(language);
        if (country)
            return country;
        return "GB";
    }, [i18n.language, tenantCountry]);
};

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { FormField } from "components-care";
import EnterpriseContext from "./EnterpriseContext";
import { useFormEnterpriseClientIdOpt } from "../utils/useFormEnterpriseClientId";
import BasicPageLayout from "../../../components/BasicPageLayout";
import EnterpriseRecordCreateTenantSelectFormPage from "./EnterpriseRecordCreateTenantSelectFormPage";
import BoxGrid from "../../../components/BoxGrid";
import { Grid } from "@mui/material";
const EnterpriseRecordCreateTenantSelect = (props) => {
    const { title, children: ChildForm } = props, other = __rest(props, ["title", "children"]);
    const ctx = useContext(EnterpriseContext);
    const clientId = useFormEnterpriseClientIdOpt();
    const [present, setPresent] = useState(() => !!clientId);
    useEffect(() => {
        if (clientId)
            return;
        setPresent(false);
    }, [clientId]);
    const handleNext = useCallback(() => {
        setPresent(true);
    }, []);
    if (!ctx || (present && clientId))
        return _jsx(ChildForm, Object.assign({}, other));
    return (_jsx(EnterpriseRecordCreateTenantSelectFormPage, Object.assign({}, other, { handleNext: handleNext, children: _jsx(BasicPageLayout, { title: title, children: _jsx(BoxGrid, { item: true, xs: 12, container: true, spacing: 2, children: _jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "tenant_id" }) }) }) }) })));
};
export default React.memo(EnterpriseRecordCreateTenantSelect);

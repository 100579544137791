import { jsx as _jsx } from "react/jsx-runtime";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { CollapsibleMenu, Loader, PortalLayout } from "components-care";
import React, { Suspense, useMemo } from "react";
import { GLOBAL_MODE_APPS } from "../../constants";
import getCurrentApp from "../../utils/getCurrentApp";
import PortalHeader from "./PortalHeader";
import Routes from "./Routes";
import SamedisMenu from "./SamedisMenu";
export const useSamedisPortalStyles = makeStyles()((theme) => ({
    appBar: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.getContrastText(theme.palette.secondary.main),
        zIndex: 10,
    },
    toolbar: {
        minHeight: "48px !important",
    },
    menu: {
        backgroundColor: theme.palette.primary.main,
    },
    collapseMenu: {
        color: theme.palette.getContrastText(theme.palette.primary.main),
        paddingTop: 16,
    },
    fullHeight1: {
        minHeight: "100%",
        display: "flex",
        flexDirection: "column",
    },
    hidden: { display: "none" },
}));
/**
 * Global mode = no tenant selector, thus no tenant context
 */
export const useIsGlobalMode = () => useMemo(() => GLOBAL_MODE_APPS.includes(getCurrentApp()), []);
const SamedisPortal = () => {
    var _a;
    const { classes } = useSamedisPortalStyles();
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.only("xs"));
    const slimMode = ((_a = sessionStorage.getItem("slim")) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === "true";
    const renderContent = () => (_jsx(Suspense, { fallback: _jsx(Loader, {}), children: _jsx(Box, { p: isXs ? 2 : 4, className: classes.fullHeight1, children: _jsx(Routes, {}) }) }));
    // slim mode, enabled in auth provider. render without header and menu
    // if (sessionStorage.getItem("slim")?.toLowerCase() === "true") {
    //   return renderContent();
    // }
    return (_jsx(PortalLayout, { variant: "no-top-left", headerContent: _jsx(PortalHeader, {}), customClasses: {
            header: {
                appBar: {
                    root: slimMode ? classes.hidden : classes.appBar,
                },
                toolbar: {
                    regular: classes.toolbar,
                },
            },
        }, menuContent: _jsx(CollapsibleMenu, { classes: {
                root: slimMode ? classes.hidden : classes.menu,
                button: classes.collapseMenu,
            }, width: 300, children: _jsx(SamedisMenu, {}) }), collapseBreakpoint: "lg", content: renderContent(), mainId: "main-content" }));
};
export default React.memo(SamedisPortal);

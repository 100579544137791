var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect } from "react";
import { TeamModelSelectorSort, TeamModelToSelectorData, useTeamModel, } from "../../../../../../components-care/models/TeamModel";
import { BackendSingleSelect, FormField, uniqueArray, useFormContext, } from "components-care";
import { useTenantId } from "../../../../../components/TenantContext";
import { useTranslation } from "react-i18next";
import { useLruConfig } from "../../../../../../utils";
import { Grid } from "@mui/material";
import BriefingPickTeamButton from "../BriefingPickTeamButton";
import InventoryModel, { InventoryModelSelectorSort, InventoryModelToSelectorData, useInventoryModelLite, } from "../../../../../../components-care/models/InventoryModel";
import BriefingPickInventoryButton from "../BriefingPickInventoryButton";
import getLocalizedString from "../../../../../../utils/getLocalizedString";
export const getCatalogNameFromRecord = (data) => {
    return [
        getLocalizedString(null, data.device_type_title_labels),
        data.title,
        data.associated_version_ids.length > 0 && data.version,
        data.current_responsible_manufacturer,
    ]
        .filter((entry) => entry)
        .join(", ");
};
const teamModelParams = {
    extraParams: { "filter[type]": "device" },
};
const inventoryModelParams = {
    filterDeviceSystem: true,
};
const DeviceGroupSelector = (props) => {
    const { onChange, initialDevices } = props;
    const { values, readOnly, setFieldValue, getFieldValue } = useFormContext();
    const tenantId = useTenantId();
    const teamModel = useTeamModel(teamModelParams);
    const deviceSystemModel = useInventoryModelLite(inventoryModelParams);
    const { t, i18n } = useTranslation("overview-briefing");
    const lruGroup = useLruConfig("device-group-selector.group");
    const lruDeviceSystem = useLruConfig("device-group-selector.device-system");
    const addDevices = useCallback((ids) => __awaiter(void 0, void 0, void 0, function* () {
        setFieldValue("device_model_ids", uniqueArray([
            ...getFieldValue("device_model_ids"),
            ...ids,
        ]));
    }), [setFieldValue, getFieldValue]);
    useEffect(() => {
        if (initialDevices) {
            void addDevices(initialDevices);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const deviceModelIds = values.device_model_ids;
    useEffect(() => {
        if (!onChange)
            return;
        onChange(deviceModelIds);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deviceModelIds]);
    const handleSelectTeam = useCallback((selected) => __awaiter(void 0, void 0, void 0, function* () {
        const [team] = yield teamModel.getCached(selected);
        return addDevices(team.catalog_ids);
    }), [teamModel, addDevices]);
    const handleSelectDeviceSystem = useCallback((selected) => __awaiter(void 0, void 0, void 0, function* () {
        if (!selected)
            return;
        // fetch all devices of system
        const [allInventories] = yield InventoryModel({
            t,
            tenantId,
            extraParams: { "filter[device_system_inventory_id]": selected },
        }).fetchAllCached();
        const deviceModelIds = uniqueArray(allInventories.map((inventory) => inventory.catalog_id));
        return addDevices(deviceModelIds);
    }), [tenantId, t, addDevices]);
    return (_jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(BackendSingleSelect, { model: teamModel, modelToSelectorData: TeamModelToSelectorData, selected: null, onSelect: handleSelectTeam, label: t("form.step1.selectors.device.group"), placeholder: t("form.step1.selectors.device.group-placeholder"), sort: TeamModelSelectorSort, lru: lruGroup, disabled: readOnly, endAdornment: _jsx(BriefingPickTeamButton, { type: "device", onSelect: (selected) => handleSelectTeam(selected), disabled: readOnly, title: t("form.step1.selectors.device.group-dialog") }) }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(BackendSingleSelect, { model: deviceSystemModel, modelToSelectorData: InventoryModelToSelectorData, selected: null, onSelect: handleSelectDeviceSystem, label: t("form.step1.selectors.device.system"), placeholder: t("form.step1.selectors.device.system-placeholder"), sort: InventoryModelSelectorSort(i18n.language), lru: lruDeviceSystem, disabled: readOnly, endAdornment: _jsx(BriefingPickInventoryButton, { searchForDeviceSystem: true, onSelect: (selected) => handleSelectDeviceSystem(selected), disabled: readOnly, title: t("form.step1.selectors.device.system-dialog") }) }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "device_model_ids" }) })] }));
};
export default React.memo(DeviceGroupSelector);

import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback } from "react";
import { BackendSingleSelect } from "components-care";
import { AccessProfilesModelSort, AccessProfilesModelToSelectorData, useAccessProfilesModel, } from "../../../components-care/models/AccessProfilesModel";
import handleBackendMultiSelectLoadError from "../../../utils/handleBackendMultiSelectLoadError";
import { useTranslation } from "react-i18next";
import PickAccessProfileNoForm from "./PickAccessProfileNoForm";
const AccessProfileSelector = (props) => {
    const { onSelect, disabled } = props;
    const model = useAccessProfilesModel();
    const { t } = useTranslation("profiles");
    const handlePick = useCallback((id) => {
        if (!onSelect)
            return;
        onSelect(id);
    }, [onSelect]);
    return (_jsx(BackendSingleSelect, Object.assign({ model: model, modelToSelectorData: AccessProfilesModelToSelectorData, sort: AccessProfilesModelSort, onLoadError: handleBackendMultiSelectLoadError, label: t("selector.title"), placeholder: t("selector.placeholder"), endAdornment: _jsx(PickAccessProfileNoForm, { onSelect: handlePick, disabled: disabled, title: t("selector.dialog-title") }) }, props)));
};
export default React.memo(AccessProfileSelector);

import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useActiveLanguageContext } from "../../../../../components/ActiveLanguageContext";
import { useCCLanguagesTranslations } from "components-care/dist/utils/useCCTranslations";
import { selectorLocalLoadHandler, SingleSelect, } from "components-care";
const DeviceModelViewerLanguageSelector = (props) => {
    var _a;
    const { languages: languageCodes } = props;
    const { t: ccT } = useCCLanguagesTranslations();
    const { t } = useTranslation("device-models");
    const [activeLanguageCode, setActiveLanguage] = useActiveLanguageContext();
    const handleSelect = useCallback((data) => {
        if (!data)
            return;
        setActiveLanguage(data.value);
    }, [setActiveLanguage]);
    const languages = useMemo(() => languageCodes.map((code) => ({
        value: code,
        label: ccT(code),
    })), [languageCodes, ccT]);
    const activeLanguage = (_a = languages.find((lng) => lng.value === activeLanguageCode)) !== null && _a !== void 0 ? _a : null;
    return (_jsx(SingleSelect, { onLoad: selectorLocalLoadHandler(languages), placeholder: t("active_language.placeholder"), selected: activeLanguage, onSelect: handleSelect, disableClearable: true }));
};
export default React.memo(DeviceModelViewerLanguageSelector);

import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useContext } from "react";
import { useTheme } from "@mui/material";
import { useContactModel, } from "../../../components-care/models/ContactModel";
import SamedisCrud from "../../../components-care/SamedisCrud";
import ContactsFilterBar from "./components/ContactsFilterBar";
import ContactForm from "./components/Contact/Form";
import { hasPermission, useDialogContext, usePermissionContext, } from "components-care";
import ContactInviteDialog from "./components/Contact/ContactInviteDialog";
import { MailOutline as InviteIcon } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
const ContactsCrudContext = React.createContext(undefined);
export const useContactsCrudContext = () => {
    const ctx = useContext(ContactsCrudContext);
    if (!ctx)
        throw new Error("missing ctx");
    return ctx;
};
const ContactsCRUD = (props) => {
    const theme = useTheme();
    const model = useContactModel({
        target: props.mode,
        forceFields: props.forceFields,
        enterpriseClientId: props.enterpriseClientId,
    });
    const { t } = useTranslation("contact");
    const [pushDialog] = useDialogContext();
    const [perms] = usePermissionContext();
    const checkDisabledInvite = useCallback((selectedRows) => selectedRows === 0 || !hasPermission(perms, "contacts.writer"), [perms]);
    const openInviteDialog = useCallback((_invert, ids) => {
        if (_invert)
            throw new Error("_invert not supported");
        pushDialog(_jsx(ContactInviteDialog, { contactIds: ids }));
    }, [pushDialog]);
    return (_jsx(ContactsCrudContext.Provider, { value: {
            servicePartnerView: props.servicePartnerView,
            mode: props.mode,
        }, children: _jsx(SamedisCrud, Object.assign({ model: model, gridProps: {
                filterBar: props.mode === "tenant" ? ContactsFilterBar : undefined,
                defaultCustomData: { "filter[scope]": "tenant" },
                enableFilterDialogMediaQuery: theme.breakpoints.down("lg"),
                defaultSort: [
                    {
                        field: "name",
                        direction: 1,
                    },
                ],
                customDataActionButtons: [
                    {
                        icon: _jsx(InviteIcon, {}),
                        label: t("buttons.invite"),
                        isDisabled: checkDisabledInvite,
                        onClick: openInviteDialog,
                    },
                ],
            }, deletePermission: "contacts.deleter", readPermission: "contacts.reader", editPermission: "contacts.writer", newPermission: "contacts.writer", exportPermission: "contacts.xlsx" }, props, { formProps: Object.assign(Object.assign({}, props.formProps), { readOnlyReasons: Object.assign({}, (props.servicePartnerView && { servicePartnerView: null })) }), children: ContactForm })) }));
};
export default React.memo(ContactsCRUD);

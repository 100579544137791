var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Notifications, timestampToAge, useModelMutation, } from "components-care";
import { useUserNotificationModel } from "../../../components-care/models/UserNotificationModel";
import * as Sentry from "@sentry/react";
import { Divider, Grid, Tooltip, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { marked } from "marked";
import DOMPurify from "dompurify";
import { JournalRenderer } from "../../../components/MarkedRenderer";
import { MARKDOWN_PURIFY_CONFIG } from "../../../constants";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles()((theme) => ({
    displayBlock: {
        display: "inline-block",
    },
    notificationSender: {
        display: "inline-block",
        paddingLeft: 4,
    },
    notificationRoot: {
        paddingTop: theme.spacing(1),
    },
    root: {
        width: theme.breakpoints.values.sm,
    },
}));
export const SamedisNotificationRenderer = (notification) => {
    const { i18n } = useTranslation();
    const { classes } = useStyles();
    return (_jsxs(Grid, { container: true, spacing: 0, className: classes.notificationRoot, children: [_jsxs(Grid, { item: true, xs: 12, children: [_jsx("div", { className: classes.displayBlock, children: _jsx(Tooltip, { title: notification.created.toLocaleString(i18n.language), children: _jsx(Typography, { variant: "caption2", color: "textSecondary", children: timestampToAge(notification.created) }) }) }), notification.origin && (_jsx("div", { className: classes.notificationSender, children: _jsxs(Typography, { variant: "caption2", color: "textSecondary", children: ["- ", notification.origin] }) }))] }), _jsx(Grid, { item: true, xs: 12, children: notification.message }), _jsx(Grid, { item: true, xs: 12, children: _jsx(Divider, {}) })] }));
};
export const convertNotification = (data) => __awaiter(void 0, void 0, void 0, function* () {
    return ({
        id: data.id,
        message: (_jsxs("div", { children: [_jsx(Typography, { variant: "h2", children: data.title }), data.content && (_jsx("div", { dangerouslySetInnerHTML: {
                        __html: DOMPurify.isSupported // if not supported the library falls back to returning exactly what you pass into it, so we block this
                            ? DOMPurify.sanitize(yield marked(data.content, {
                                renderer: new JournalRenderer(),
                            }), MARKDOWN_PURIFY_CONFIG)
                            : "Your browser is out-of-date",
                    } }))] })),
        origin: data.sender,
        created: data.publish_at,
        read: data.read_at != null,
    });
});
const PAGE_SIZE = 10;
const SamedisNotifications = () => {
    const { classes } = useStyles();
    const model = useUserNotificationModel();
    const { mutateAsync: updateNotification } = useModelMutation(model);
    const [notifications, setNotifications] = useState([]);
    const [loadingRead, setLoadingRead] = useState(false);
    const [loadingUnread, setLoadingUnread] = useState(false);
    const [canLoadMoreRead, setCanLoadMoreRead] = useState(true);
    const [unreadCount, setUnreadCount] = useState(0);
    const loadLatest = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const [data, , userData] = yield model.index({
                page: 1,
                rows: PAGE_SIZE,
                fieldFilter: {
                    read_at: {
                        type: "empty",
                        value1: "empty",
                        value2: "",
                    },
                },
                sort: [{ field: "publish_at", direction: 1 }],
            });
            const dataCast = data.reverse();
            const dataConverted = yield Promise.all(dataCast.map(convertNotification));
            setUnreadCount(userData
                .unread_notification_count);
            setNotifications((prev) => {
                const prevIds = prev.map((prev) => prev.id);
                const prepend = [];
                for (const record of dataConverted) {
                    if (prevIds.includes(record.id))
                        break;
                    prepend.push(record);
                }
                return [...prepend, ...prev];
            });
        }
        catch (e) {
            console.error(e);
            Sentry.captureException(e);
        }
    }), [model]);
    const loadUnread = useCallback(() => {
        // if we can't load more don't even try
        if (unreadCount === 0)
            return;
        setLoadingUnread(true);
    }, [unreadCount]);
    const loadRead = useCallback(() => {
        // if we can't load more don't even try
        if (!canLoadMoreRead)
            return;
        setLoadingRead(true);
    }, [canLoadMoreRead]);
    const markAsRead = useCallback((id) => {
        updateNotification({
            id,
            read: true,
        }).catch((err) => {
            console.error(err);
            Sentry.captureException(err);
        });
    }, [updateNotification]);
    useEffect(() => {
        if (!loadingRead)
            return;
        (() => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const filter = {
                    read_at: {
                        type: "notEmpty",
                        value1: "notEmpty",
                        value2: "",
                    },
                };
                if (notifications.length > 0) {
                    filter.publish_at = {
                        type: "lessThan",
                        value1: notifications[notifications.length - 1].created.toISOString(),
                        value2: "",
                    };
                }
                const [data, , userData] = yield model.index({
                    page: 1,
                    rows: PAGE_SIZE,
                    fieldFilter: filter,
                    sort: [{ field: "publish_at", direction: -1 }],
                });
                const dataCast = data;
                const dataConverted = yield Promise.all(dataCast.map(convertNotification));
                setCanLoadMoreRead(data.length === PAGE_SIZE);
                setUnreadCount(userData
                    .unread_notification_count);
                setNotifications((prev) => [...prev, ...dataConverted]);
            }
            catch (e) {
                console.error(e);
                Sentry.captureException(e);
            }
            finally {
                setLoadingRead(false);
            }
        }))();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingRead]);
    useEffect(() => {
        if (!loadingUnread)
            return;
        (() => __awaiter(void 0, void 0, void 0, function* () {
            var _a;
            try {
                const [data, meta] = yield model.index({
                    page: 1,
                    rows: PAGE_SIZE,
                    fieldFilter: {
                        read_at: {
                            type: "empty",
                            value1: "empty",
                            value2: "",
                        },
                        publish_at: {
                            type: "greaterThan",
                            value1: notifications[0].created.toISOString(),
                            value2: "",
                        },
                    },
                    sort: [{ field: "publish_at", direction: 1 }],
                });
                const dataCast = data.reverse();
                if (dataCast.length > 0)
                    markAsRead(dataCast[0].id);
                const dataConverted = yield Promise.all(dataCast.map(convertNotification));
                setUnreadCount(((_a = meta.filteredRows) !== null && _a !== void 0 ? _a : meta.totalRows) - dataCast.length);
                setNotifications((prev) => [...dataConverted, ...prev]);
            }
            catch (e) {
                console.error(e);
                Sentry.captureException(e);
            }
            finally {
                setLoadingUnread(false);
            }
        }))();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingUnread]);
    const handleOpen = useCallback(() => {
        if (notifications.length === 0)
            return;
        if (notifications[0].read)
            return;
        markAsRead(notifications[0].id);
        loadUnread();
    }, [notifications, markAsRead, loadUnread]);
    const popoverProps = useMemo(() => ({
        classes: { root: "popover-auto-closable" },
        PaperProps: { className: classes.root },
    }), [classes.root]);
    return (_jsx(Notifications, { notificationRenderer: SamedisNotificationRenderer, notifications: notifications, unreadCount: unreadCount, loadLatest: loadLatest, loadUnread: unreadCount === 0 ? undefined : loadUnread, loadRead: loadRead, onOpen: handleOpen, PopoverProps: popoverProps }));
};
export default React.memo(SamedisNotifications);

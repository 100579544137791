import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { FileInput, } from "components-care/dist/standalone/FileUpload/Generic";
import { Grid } from "@mui/material";
import FilePreview from "components-care/dist/standalone/FileUpload/Generic/File";
const ChatAttachmentUploadRenderer = (props) => {
    const { files, previewImages, previewSize, readOnly, removeFile, accept, maxFiles, getRemainingFileCount, handleFileChange, classes, inputRef, } = props;
    return (_jsxs(_Fragment, { children: [_jsx(FileInput, { type: "file", accept: accept || undefined, multiple: maxFiles ? getRemainingFileCount() > 1 : true, onChange: handleFileChange, className: classes === null || classes === void 0 ? void 0 : classes.fileInput, ref: inputRef }), _jsx(Grid, { container: true, spacing: 2, children: files.map((data, index) => (_jsx(FilePreview, { name: data.file.name, downloadLink: "downloadLink" in data.file ? data.file.downloadLink : undefined, size: previewSize, preview: previewImages ? data.preview : undefined, disabled: data.delete || false, onRemove: readOnly || data.preventDelete
                        ? undefined
                        : () => removeFile(data), variant: "icon-only" }, `${index}-${data.file.name}`))) })] }));
};
export default React.memo(ChatAttachmentUploadRenderer);

import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useContext } from "react";
import { Link as MuiLink, Typography } from "@mui/material";
import { MdmContext } from "../../../Admin/MdmTypes";
import DeviceModelViewer from "./DeviceModelViewer";
import { useDialogContext } from "components-care";
const DeviceModelViewerDeviceLink = (props) => {
    const { clickable, id, version, version_number } = props;
    const [pushDialog, popDialog] = useDialogContext();
    const target = useContext(MdmContext);
    const openModel = useCallback(() => {
        if (!id)
            return;
        popDialog();
        pushDialog(_jsx(DeviceModelViewer, { deviceId: id, target: target }));
    }, [id, target, pushDialog, popDialog]);
    return clickable ? (_jsxs(_Fragment, { children: [_jsx(MuiLink, { onClick: openModel, href: "#", children: version || version_number }), " "] })) : (_jsxs(Typography, { variant: "inherit", component: "span", children: [version || version_number, " "] }));
};
export default React.memo(DeviceModelViewerDeviceLink);

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useState } from "react";
import { Dialog, DialogContent, Grid, useMediaQuery, useTheme, } from "@mui/material";
import FilterContainer from "./FilterContainer";
import BrowseContainer from "./BrowseContainer";
import { makeStyles } from "tss-react/mui";
import { DialogTitle, Loader, throwError, useAsyncCache, } from "components-care";
import { useTranslation } from "react-i18next";
import { useTenantIdOpt } from "../../../components/TenantContext";
import { StorageManager } from "components-care/dist/framework/Storage";
const useStyles = makeStyles()({
    root: {
        height: "100%",
    },
    unsize: {
        position: "relative",
        height: "100%",
    },
    unsize2: {
        position: "absolute",
        height: "100%",
        width: "100%",
        overflowY: "auto",
    },
});
export const STORAGE_KEY_SEARCH_AND_BROWSER_FILTER_DEVICE = "SearchAndBrowseFilterDevice";
export const STORAGE_KEY_SEARCH_AND_BROWSER_FILTER_USER = "SearchAndBrowseFilterUser";
const filterConfigToKey = (controlName, filter, moduleName, tenantId) => {
    var _a;
    return [
        controlName,
        ...[...((_a = filter.persistKeys) !== null && _a !== void 0 ? _a : [])]
            .sort()
            .map((k) => k === "module"
            ? moduleName !== null && moduleName !== void 0 ? moduleName : throwError("module name required")
            : k === "tenant"
                ? tenantId
                    ? tenantId
                    : throwError("tried using tenant persist mode without tenant")
                : throwError("invalid persist key")),
    ].join(",");
};
const SearchAndBrowse = (props) => {
    const { filters, moduleName, controlName, filterUserData, resetFilterUserData, filterDeviceData, resetFilterDeviceData } = props, browseContainerProps = __rest(props, ["filters", "moduleName", "controlName", "filterUserData", "resetFilterUserData", "filterDeviceData", "resetFilterDeviceData"]);
    const { t } = useTranslation("global-device-catalog");
    const { classes } = useStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
    const [filterDialogOpen, setFilterDialogOpen] = useState(false);
    // filter persistence
    const tenantId = useTenantIdOpt();
    const [selectedFilters, setSelectedFilters] = useState(() => Object.fromEntries(Object.keys(filters).map((name) => {
        var _a;
        const filter = filters[name];
        const persistType = (_a = filter.persistType) !== null && _a !== void 0 ? _a : "none";
        const filterKey = filterConfigToKey(controlName, filter, moduleName, tenantId);
        const persistDataSet = persistType === "none"
            ? null
            : persistType === "user"
                ? filterUserData[filterKey]
                : persistType === "device"
                    ? filterDeviceData[filterKey]
                    : throwError("unsupported persist type");
        const persistValue = persistDataSet &&
            JSON.parse(persistDataSet)[name];
        const defaultValue = filter.variant === "multi-select"
            ? []
            : filter.variant === "select"
                ? ""
                : false;
        return [name, persistValue !== null && persistValue !== void 0 ? persistValue : defaultValue];
    })));
    const handleSelectedChange = (name, cb) => {
        setSelectedFilters((prev) => {
            var _a;
            const newValue = typeof cb === "function" ? cb(prev[name]) : cb;
            // update persistent data
            const filter = filters[name];
            const filterKey = filterConfigToKey(controlName, filter, moduleName, tenantId);
            if (((_a = filter.persistType) !== null && _a !== void 0 ? _a : "none") !== "none") {
                const data = {};
                for (const otherFilterName in filters) {
                    const otherFilter = filters[otherFilterName];
                    if (otherFilter.persistType !== filter.persistType)
                        continue;
                    if (filterConfigToKey(controlName, otherFilter, moduleName, tenantId) !== filterKey)
                        continue;
                    data[otherFilterName] = prev[otherFilterName];
                }
                data[name] = newValue;
                (() => __awaiter(void 0, void 0, void 0, function* () {
                    // update key and clear suspend-react cache
                    yield StorageManager.setItem(filter.persistType === "user"
                        ? STORAGE_KEY_SEARCH_AND_BROWSER_FILTER_USER
                        : filter.persistType === "device"
                            ? STORAGE_KEY_SEARCH_AND_BROWSER_FILTER_DEVICE
                            : throwError("unsupported persist type"), { key: filterKey }, JSON.stringify(data));
                    resetFilterUserData();
                    resetFilterDeviceData();
                }))();
            }
            return Object.assign(Object.assign({}, prev), { [name]: newValue });
        });
    };
    const hasFilters = Object.values(filters).filter((filter) => !filter.hidden)
        .length > 0;
    const renderFilters = () => (_jsx(FilterContainer, { filters: Object.fromEntries(Object.entries(filters).map(([name, options]) => [
            name,
            Object.assign(Object.assign({}, options), { selected: selectedFilters[name], setSelected: handleSelectedChange }),
        ])) }));
    const handleOpenFilters = useCallback(() => {
        setFilterDialogOpen((prev) => !prev);
    }, []);
    const closeFilterDialog = useCallback(() => {
        setFilterDialogOpen(false);
    }, []);
    useEffect(() => {
        if (isSmallScreen)
            return;
        setFilterDialogOpen(false);
    }, [isSmallScreen]);
    return (_jsxs(Grid, { container: true, spacing: 2, alignItems: "stretch", className: classes.root, children: [isSmallScreen && filterDialogOpen && (_jsxs(Dialog, { open: true, fullScreen: true, onClose: closeFilterDialog, children: [_jsx(DialogTitle, { onClose: closeFilterDialog, children: t("filters.dialog-title") }), _jsx(DialogContent, { children: renderFilters() })] })), !isSmallScreen && hasFilters && (_jsx(Grid, { item: true, xs: 4, children: _jsx("div", { className: classes.unsize, children: _jsx("div", { className: classes.unsize2, children: renderFilters() }) }) })), _jsx(Grid, { item: true, xs: !isSmallScreen && hasFilters ? 8 : 12, container: true, direction: "column", children: _jsx(BrowseContainer, Object.assign({ filters: selectedFilters }, browseContainerProps, { onOpenFilters: isSmallScreen && hasFilters ? handleOpenFilters : undefined })) })] }));
};
const SearchAndBrowseOuter = (props) => {
    var _a;
    const { filters, moduleName, controlName } = props;
    // filter persistence
    const tenantId = useTenantIdOpt();
    // build a list of requested scopes
    const filterPersistKeysUserSet = new Set();
    const filterPersistKeysDeviceSet = new Set();
    for (const filterKey in filters) {
        const filter = filters[filterKey];
        if (((_a = filter.persistType) !== null && _a !== void 0 ? _a : "none") === "none")
            continue;
        if (filter.persistType === "user") {
            filterPersistKeysUserSet.add(filterConfigToKey(controlName, filter, moduleName, tenantId));
        }
        else if (filter.persistType === "device") {
            filterPersistKeysDeviceSet.add(filterConfigToKey(controlName, filter, moduleName, tenantId));
        }
        else {
            throw new Error("unsupported persist type");
        }
    }
    const filterPersistKeysUser = Array.from(filterPersistKeysUserSet).sort();
    const filterPersistKeysDevice = Array.from(filterPersistKeysDeviceSet).sort();
    // fetch data
    const { value: filterUserData, reset: resetFilterUserData } = useAsyncCache(() => __awaiter(void 0, void 0, void 0, function* () {
        return Object.fromEntries(yield Promise.all(filterPersistKeysUser.map((key) => __awaiter(void 0, void 0, void 0, function* () {
            return [
                key,
                yield StorageManager.getItem(STORAGE_KEY_SEARCH_AND_BROWSER_FILTER_USER, {
                    key,
                }),
            ];
        }))));
    }), filterPersistKeysUser, true);
    const { value: filterDeviceData, reset: resetFilterDeviceData } = useAsyncCache(() => __awaiter(void 0, void 0, void 0, function* () {
        return Object.fromEntries(yield Promise.all(filterPersistKeysDevice.map((key) => __awaiter(void 0, void 0, void 0, function* () {
            return [
                key,
                yield StorageManager.getItem(STORAGE_KEY_SEARCH_AND_BROWSER_FILTER_DEVICE, {
                    key,
                }),
            ];
        }))));
    }), filterPersistKeysDevice, true);
    if (!filterUserData || !filterDeviceData)
        return _jsx(Loader, {});
    return (_jsx(SearchAndBrowse, Object.assign({}, props, { filterUserData: filterUserData, resetFilterUserData: resetFilterUserData, filterDeviceData: filterDeviceData, resetFilterDeviceData: resetFilterDeviceData })));
};
export default React.memo(SearchAndBrowseOuter);

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback } from "react";
import { getMediaFileExt, makeMediaCapture, useIsMediaCaptureSupported, useMediaRecordErrorMessage, useMediaRecordTimerFormatted, } from "./MediaCapture";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, } from "@mui/material";
import { useDialogContext } from "components-care";
import { useTranslation } from "react-i18next";
import DialogButton from "../../../../components/Dialogs/Buttons/DialogButton";
import DialogButtons from "../../../../components/Dialogs/Buttons/DialogButtons";
export const useIsAudioCaptureSupported = () => {
    return useIsMediaCaptureSupported("audioinput");
};
const audioConstraints = {
    audio: true,
};
const useAudioMediaCapture = makeMediaCapture(audioConstraints);
const AudioCapture = (props) => {
    const { t } = useTranslation("common");
    const { onDone } = props;
    const [, popDialog] = useDialogContext();
    const _a = useAudioMediaCapture(), { isRecording, isPaused, startRecording, stopRecording, pauseRecording, resumeRecording } = _a, otherState = __rest(_a, ["isRecording", "isPaused", "startRecording", "stopRecording", "pauseRecording", "resumeRecording"]);
    const timer = useMediaRecordTimerFormatted(otherState);
    const error = useMediaRecordErrorMessage("audio", otherState);
    const handleClose = useCallback(() => {
        popDialog();
        onDone(null, null);
    }, [popDialog, onDone]);
    const handleStop = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        const data = yield stopRecording();
        popDialog();
        // YYYY-MM-DD-hh-mm-ss
        const timestamp = new Date()
            .toISOString()
            .replace(/:/g, "-")
            .replace("T", "-")
            .split(".")[0];
        onDone(new File([data], `recoding-${timestamp}.${getMediaFileExt(data)}`, {
            type: data.type,
        }), null);
    }), [onDone, popDialog, stopRecording]);
    return (_jsxs(Dialog, { open: true, onClose: handleClose, children: [_jsx(DialogTitle, { children: t("media_recorder.title.audio") }), _jsx(DialogContent, { children: isRecording
                    ? timer
                    : error
                        ? error
                        : t("media_recorder.message.audio") }), _jsx(DialogActions, { children: _jsx(Box, { px: 2, children: _jsxs(DialogButtons, { children: [isRecording && (_jsx(DialogButton, { onClick: isPaused ? resumeRecording : pauseRecording, styleType: "action", children: isPaused
                                    ? t("media_recorder.action.resume")
                                    : t("media_recorder.action.pause") })), _jsx(DialogButton, { onClick: isRecording ? handleStop : startRecording, styleType: "action", children: isRecording
                                    ? t("media_recorder.action.stop")
                                    : error
                                        ? t("media_recorder.action.try-again")
                                        : t("media_recorder.action.start") }), _jsx(DialogButton, { onClick: handleClose, styleType: "navigation", children: t("media_recorder.action.close") })] }) }) })] }));
};
export default React.memo(AudioCapture);

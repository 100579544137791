import { jsx as _jsx } from "react/jsx-runtime";
import React, { useContext, useEffect, useState } from "react";
import { addGetParams, hasPermission, usePermissionContext, } from "components-care";
import { BriefingIcon, DeviceIcon, HomeIcon, PersonIcon, ReportIcon, RoomIcon, SanaLogoIcon, } from "../../components/icons";
import { AddComment as ServicesIcon, Apps as AppsIcon, BarChart, Dashboard as DashboardIcon, Domain as TenantIcon, Code as DevModeIcon, } from "@mui/icons-material";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { BI_APP_DOMAIN_PREFIX, ENTERPRISE_APP_DOMAIN_PREFIX, GLOBAL_MODE_APPS, MDM_APP_DOMAIN_PREFIX, MY_APP_DOMAIN_PREFIX, StripePublishableKey, } from "../../constants";
import { getSubdomain, isSubdomain, normalizeCanDos } from "../../utils";
import * as Sentry from "@sentry/react";
import { getSession, getSessionExpire, getSessionRefreshToken, useAuthProviderContext, } from "./AuthProvider";
import getCurrentApp from "../../utils/getCurrentApp";
import { useIsGlobalMode } from "./SamedisPortal";
// Pages (main app)
const Dashboard = React.lazy(() => import("../TenantSpecific/Home"));
const InventoriesCRUD = React.lazy(() => import("../TenantSpecific/Devices/Inventories"));
const EventIssuesCRUD = React.lazy(() => import("../TenantSpecific/Devices/Issues"));
const IncidentsCRUD = React.lazy(() => import("../TenantSpecific/Devices/Incidents"));
const BriefedEmployeeDevices = React.lazy(() => import("../TenantSpecific/Training/BriefedEmployeeDevices"));
const TenantEdit = React.lazy(() => import("../TenantSpecific/Facility/FacilityData"));
const SubscriptionPlan = React.lazy(() => import("../TenantSpecific/Billing/SubscriptionPlan"));
const ContactsTenant = React.lazy(() => import("../TenantSpecific/ContactsAndEmployee/ContactsTenant"));
const TeamsCRUD = React.lazy(() => import("../TenantSpecific/Training/Teams"));
const DepartmentsCRUD = React.lazy(() => import("../TenantSpecific/Facility/Departments"));
const DeviceLocationsCRUD = React.lazy(() => import("../TenantSpecific/Facility/DeviceLocations"));
const PositionCRUD = React.lazy(() => import("../TenantSpecific/Facility/Position"));
const StaffCRUD = React.lazy(() => import("../TenantSpecific/ContactsAndEmployee/Staff"));
const DirectoryCRUD = React.lazy(() => import("../TenantSpecific/ContactsAndEmployee/Directory"));
const TrainingCRUD = React.lazy(() => import("../TenantSpecific/Training/OverviewBriefing"));
const BriefingDevicesGrid = React.lazy(() => import("../TenantSpecific/Training/BriefingDevices"));
const BriefingMatrix = React.lazy(() => import("../TenantSpecific/Training/BriefingMatrix"));
const TrainingImportApproval = React.lazy(() => import("../TenantSpecific/Training/TrainingImportApproval"));
const AccessManagement = React.lazy(() => import("../TenantSpecific/Facility/AccessManagement"));
const AccessProfilesCRUD = React.lazy(() => import("../TenantSpecific/Facility/AccessManagementProfiles"));
const GlobalDeviceCatalog = React.lazy(() => import("../TenantSpecific/Devices/GlobalDeviceCatalog"));
const FacilityContentCRUD = React.lazy(() => import("../TenantSpecific/Facility/FacilityContent"));
const TenantDeviceModelsCRUD = React.lazy(() => import("../TenantSpecific/Facility/TenantDeviceModels"));
const TenantDeviceTypesCRUD = React.lazy(() => import("../TenantSpecific/Facility/TenantDeviceTypes"));
const ImaUploads = React.lazy(() => import("../TenantSpecific/IMA/ImaUploads"));
const ImaReport = React.lazy(() => import("../TenantSpecific/IMA/ImaReport"));
// Pages (bi.samedis.care)
const HomeDashboard = React.lazy(() => import("../TenantSpecific/BusinessIntelligence/HomeDashboard"));
const RegulatoryInventoryReport = React.lazy(() => import("../TenantSpecific/BusinessIntelligence/DeviceReports/RegulatoryInventoryReport"));
const DeviceAuditReport = React.lazy(() => import("../TenantSpecific/BusinessIntelligence/DeviceReports/AuditReport"));
const InventoryDashboard = React.lazy(() => import("../TenantSpecific/BusinessIntelligence/InventoryDashboard"));
const IssuesDashboard = React.lazy(() => import("../TenantSpecific/BusinessIntelligence/IssuesDashboard"));
const MtszCustomerReport = React.lazy(() => import("../TenantSpecific/BusinessIntelligence/MtszReports/CustomerReport"));
const MedworxCustomerReport = React.lazy(() => import("../TenantSpecific/BusinessIntelligence/MedworxReports/CustomerReport"));
// Pages (mdm.samedis.care)
const MdmDashboard = React.lazy(() => import("../TenantSpecific/Admin/MdmDashboard"));
const TenantOverview = React.lazy(() => import("../TenantSpecific/Admin/TenantOverview"));
const DeviceModelsMdm = React.lazy(() => import("../TenantSpecific/Admin/DeviceModelsMdm"));
const DeviceTypeMdm = React.lazy(() => import("../TenantSpecific/Admin/DeviceTypeMdm"));
const InventoryDeviceModelReview = React.lazy(() => import("../TenantSpecific/Admin/InventoryDeviceModelReview"));
const DeviceTags = React.lazy(() => import("../TenantSpecific/Admin/DeviceTags"));
const FacilityContentMdm = React.lazy(() => import("../TenantSpecific/Admin/FacilityContentsMdm"));
const SecurityMessageMdm = React.lazy(() => import("../TenantSpecific/Admin/SecurityMessageMdm"));
const ContactsMdm = React.lazy(() => import("../TenantSpecific/Admin/ContactsMdm"));
const NotificationBroadcast = React.lazy(() => import("../TenantSpecific/Admin/NotificationBroadcast"));
// Pages (my.samedis.care)
const MyDashboard = React.lazy(() => import("../MySamedis/MyDashboard"));
const MyUsedDevices = React.lazy(() => import("../MySamedis/Devices/Devices"));
const MyIncidents = React.lazy(() => import("../MySamedis/Devices/Incidents"));
const MyFacilities = React.lazy(() => import("../MySamedis/Devices/Facilities"));
const MyScheduledTrainings = React.lazy(() => import("../MySamedis/Trainings/Scheduled"));
const MyPendingRoutingSlips = React.lazy(() => import("../MySamedis/Trainings/RoutingSlips"));
const MyTrainingDocumentation = React.lazy(() => import("../MySamedis/Trainings/Document"));
const MyTrainedDevices = React.lazy(() => import("../MySamedis/Trainings/Devices"));
// Pages (enterprise.samedis.care)
const EnterpriseDashboard = React.lazy(() => import("../Enterprise/EnterpriseDashboard"));
const EnterpriseDevices = React.lazy(() => import("../Enterprise/Client/Devices"));
const EnterpriseTasks = React.lazy(() => import("../Enterprise/Client/Tasks"));
const EnterpriseRequests = React.lazy(() => import("../Enterprise/Client/Requests"));
const EnterpriseFacilities = React.lazy(() => import("../Enterprise/Tenant/Facilities"));
const EnterpriseContacts = React.lazy(() => import("../Enterprise/Tenant/Contacts"));
// Dev Mode Pages
const DevOfflineMode = React.lazy(() => import("../DevMode/OfflineMode"));
const DevPlayground = React.lazy(() => import("../DevMode/Playground"));
const DEV_MODE = localStorage.getItem("SAMEDIS_DEV_MODE") === "true";
const MdmTenantRoutes = [
    {
        icon: HomeIcon,
        title: "home",
        route: "/",
        cando: null,
        pageComponent: MdmDashboard,
        ignoreAppMenu: true,
    },
    {
        icon: TenantIcon,
        title: "mdm.tenant.root",
        cando: null,
        pageComponent: null,
        children: [
            {
                title: "mdm.tenant.list",
                route: "/tenant/overview",
                cando: "tenant-admin-overview.reader",
                pageComponent: TenantOverview,
            },
        ],
    },
    {
        icon: ServicesIcon,
        title: "mdm.services.root",
        cando: null,
        pageComponent: null,
        children: [
            {
                title: "mdm.master-data.inventory-review",
                route: "/services/inventory-review",
                cando: ["inventories.mdm-reviewer"],
                pageComponent: InventoryDeviceModelReview,
            },
            {
                title: "mdm.services.security-messages",
                route: "/services/security-messages",
                cando: ["security-messages.mdm-admin", "security-messages.mdm-user"],
                pageComponent: SecurityMessageMdm,
            },
            {
                title: "mdm.services.contacts",
                route: "/devices/contacts",
                cando: ["contacts.mdm-admin", "contacts.mdm-user"],
                pageComponent: ContactsMdm,
            },
            {
                title: "mdm.services.notification-broadcast",
                route: "/services/notification-broadcast",
                cando: "notifications.reader",
                pageComponent: NotificationBroadcast,
            },
        ],
    },
    {
        icon: DashboardIcon,
        title: "mdm.master-data.root",
        cando: null,
        pageComponent: null,
        children: [
            {
                title: "mdm.master-data.device-tags",
                route: "/master-data/device-tags",
                cando: ["type-catalogs.mdm-admin"],
                pageComponent: DeviceTags,
            },
            {
                title: "mdm.master-data.device-types",
                route: "/master-data/device-types",
                cando: ["type-catalogs.mdm-admin", "type-catalogs.mdm-user"],
                pageComponent: DeviceTypeMdm,
            },
            {
                title: "mdm.master-data.device-models",
                route: "/master-data/device-models",
                cando: ["catalogs.mdm-admin", "catalogs.mdm-user"],
                pageComponent: DeviceModelsMdm,
            },
            {
                title: "mdm.master-data.facility-content",
                route: "/master-data/facility-contents",
                cando: DEV_MODE ? ["catalogs.mdm-admin", "catalogs.mdm-user"] : false,
                pageComponent: FacilityContentMdm,
            },
        ],
    },
];
const BiTenantRoutes = [
    {
        icon: HomeIcon,
        title: "home",
        route: "/",
        cando: "bi.consumer",
        pageComponent: HomeDashboard,
    },
    {
        icon: DeviceIcon,
        title: "bi.device_reports.root",
        cando: null,
        pageComponent: null,
        children: [
            {
                title: "bi.device_reports.audit",
                route: "/device-reports/audit",
                cando: "bi.consumer+issues.reader",
                pageComponent: DeviceAuditReport,
            },
            {
                title: "bi.device_reports.regulatory_inventory",
                route: "/device-reports/regulatory-inventory",
                cando: "bi.consumer+inventories.reader",
                pageComponent: RegulatoryInventoryReport,
            },
        ],
    },
    {
        icon: BarChart,
        title: "bi.basic_statistics.root",
        cando: null,
        pageComponent: null,
        children: [
            {
                title: "bi.basic_statistics.inventories",
                route: "/basic/inventories",
                cando: "bi.consumer",
                pageComponent: InventoryDashboard,
            },
            {
                title: "bi.basic_statistics.issues",
                route: "/basic/issues",
                cando: "bi.consumer",
                pageComponent: IssuesDashboard,
            },
        ],
    },
    {
        icon: SanaLogoIcon,
        title: "bi.mtsz_reports.root",
        cando: null,
        pageComponent: null,
        children: [
            {
                title: "bi.mtsz_reports.customer_report",
                route: "/mtsz-report/customers",
                cando: "bi.consumer+bi-mtszreports.consumer",
                pageComponent: MtszCustomerReport,
            },
        ],
    },
    {
        icon: SanaLogoIcon,
        title: "bi.medworx_reports.root",
        cando: null,
        pageComponent: null,
        children: [
            {
                title: "bi.medworx_reports.customer_report",
                route: "/medworx-report/customers",
                cando: "bi.consumer+bi-medworxreports.consumer",
                pageComponent: MedworxCustomerReport,
            },
        ],
    },
];
// routes do not include tenant ID despite name
const MySamedisRoutes = [
    {
        icon: HomeIcon,
        title: "home",
        route: "/",
        cando: null,
        pageComponent: MyDashboard,
    },
    {
        icon: DeviceIcon,
        title: "my.devices.root",
        cando: null,
        pageComponent: null,
        children: [
            {
                title: "my.devices.used_devices",
                route: "/devices/used",
                cando: null,
                pageComponent: MyUsedDevices,
            },
            {
                title: "my.devices.incidents",
                route: "/devices/incidents",
                cando: null,
                pageComponent: MyIncidents,
            },
            {
                title: "my.devices.facilities",
                route: "/devices/facilities",
                cando: null,
                pageComponent: MyFacilities,
            },
            {
                title: "my.devices.global-device-catalog",
                route: "/devices/global-device-catalog",
                cando: null,
                pageComponent: GlobalDeviceCatalog,
            },
        ],
    },
    {
        icon: BriefingIcon,
        title: "my.trainings.root",
        cando: null,
        pageComponent: null,
        children: [
            {
                title: "my.trainings.scheduled_trainings",
                route: "/trainings/scheduled",
                cando: null,
                pageComponent: MyScheduledTrainings,
            },
            {
                title: "my.trainings.pending_routing_slips",
                route: "/trainings/pending-routing-slips",
                cando: null,
                pageComponent: MyPendingRoutingSlips,
            },
            {
                title: "my.trainings.document_training",
                route: "/trainings/document",
                cando: null,
                pageComponent: MyTrainingDocumentation,
            },
            {
                title: "my.trainings.device_trainings",
                route: "/trainings/overview",
                cando: null,
                pageComponent: MyTrainedDevices,
            },
        ],
    },
];
const EnterpriseTenantRoutes = [
    {
        icon: HomeIcon,
        title: "enterprise.home",
        route: "/",
        cando: "enterprise.subscriber+dashboard.reader",
        pageComponent: EnterpriseDashboard,
    },
    {
        icon: DeviceIcon,
        title: "enterprise.client.root",
        cando: null,
        pageComponent: null,
        children: [
            {
                title: "enterprise.client.devices",
                route: "/client/devices",
                cando: "enterprise.subscriber+inventories.reader",
                pageComponent: EnterpriseDevices,
            },
            {
                title: "enterprise.client.tasks",
                route: "/client/tasks",
                cando: "enterprise.subscriber+issues.reader",
                pageComponent: EnterpriseTasks,
            },
            {
                title: "enterprise.client.requests",
                route: "/client/requests",
                cando: [
                    "enterprise.subscriber+incidents.dispatcher",
                    "enterprise.subscriber+incidents.supporter",
                ],
                pageComponent: EnterpriseRequests,
            },
        ],
    },
    {
        icon: TenantIcon,
        title: "enterprise.tenant.root",
        cando: null,
        pageComponent: null,
        children: [
            {
                title: "enterprise.tenant.facilities",
                route: "/tenant/facilities",
                cando: "enterprise.subscriber",
                pageComponent: EnterpriseFacilities,
            },
            {
                title: "enterprise.tenant.contacts",
                route: "/tenant/contacts",
                cando: "enterprise.subscriber+contacts.reader",
                pageComponent: EnterpriseContacts,
            },
            {
                title: "enterprise.tenant.access-management",
                route: "/tenant/access-management",
                cando: "enterprise.subscriber+access-control.reader",
                pageComponent: AccessManagement,
            },
        ],
    },
];
const AppTenantRoutes = [
    {
        icon: HomeIcon,
        title: "home",
        route: "/",
        cando: null,
        pageComponent: Dashboard,
        ignoreAppMenu: true,
    },
    {
        icon: DeviceIcon,
        title: "devices.root",
        cando: null,
        pageComponent: null,
        children: [
            {
                title: "devices.inventories",
                route: "/devices/inventory",
                cando: "inventories.reader",
                pageComponent: InventoriesCRUD,
            },
            {
                title: "devices.event-issue",
                route: "/devices/event-issues",
                cando: "issues.reader",
                pageComponent: EventIssuesCRUD,
            },
            {
                title: "devices.incidents",
                route: "/devices/incidents",
                cando: ["incidents.dispatcher", "incidents.supporter"],
                pageComponent: IncidentsCRUD,
            },
            {
                title: "devices.global-device-catalog",
                route: "/devices/global-device-catalog",
                cando: "global-device-catalog.reader",
                pageComponent: GlobalDeviceCatalog,
            },
        ],
    },
    {
        icon: BriefingIcon,
        title: "briefing.root",
        cando: null,
        pageComponent: null,
        children: [
            {
                title: "briefing.overviews",
                route: "/briefing/overviews",
                cando: "briefings.reader",
                pageComponent: TrainingCRUD,
            },
            {
                title: "briefing.import-approval",
                route: "/briefing/import-approval",
                cando: "briefings.approve",
                pageComponent: TrainingImportApproval,
            },
            {
                title: "briefing.teams",
                route: "/briefing/teams",
                cando: "teams.reader",
                pageComponent: TeamsCRUD,
            },
            {
                title: "briefing.devices",
                route: "/briefing/devices",
                cando: "report-briefings.consumer",
                pageComponent: BriefingDevicesGrid,
            },
            {
                title: "briefing.briefed-employee-devices",
                route: "/briefing/briefed-employee-devices",
                cando: "report-briefed-employee-devices.consumer",
                pageComponent: BriefedEmployeeDevices,
            },
            {
                title: "briefing.matrix",
                route: "/briefing/matrix",
                cando: "report-briefed-employee-devices.consumer",
                pageComponent: BriefingMatrix,
            },
        ],
    },
    {
        icon: PersonIcon,
        title: "contacts.root",
        cando: null,
        pageComponent: null,
        children: [
            {
                title: "contacts.staff",
                route: "/employee/staff",
                cando: "staff.reader",
                pageComponent: StaffCRUD,
            },
            {
                title: "contacts.directory",
                route: "/employee/directory",
                cando: "directories.reader",
                pageComponent: DirectoryCRUD,
            },
            {
                title: "contacts.contacts",
                route: "/devices/contacts",
                cando: "contacts.reader",
                pageComponent: ContactsTenant,
            },
        ],
    },
    {
        icon: RoomIcon,
        title: "tenants.root",
        cando: null,
        pageComponent: null,
        children: [
            {
                title: "tenants.device-locations",
                route: "/tenants/device-locations",
                cando: "device-locations.reader",
                pageComponent: DeviceLocationsCRUD,
            },
            {
                title: "tenants.departments",
                route: "/tenants/departments",
                cando: "departments.reader",
                pageComponent: DepartmentsCRUD,
            },
            {
                title: "tenants.position",
                route: "/tenants/positions",
                cando: "positions.reader",
                pageComponent: PositionCRUD,
            },
            {
                title: "tenants.access-management",
                route: "/tenants/access-management",
                cando: "access-control.reader",
                pageComponent: AccessManagement,
            },
            {
                title: "tenants.access-management-profiles",
                route: "/tenants/access-profiles",
                cando: "profiles.reader",
                pageComponent: AccessProfilesCRUD,
            },
            {
                title: "tenants.content",
                route: "/tenants/content",
                cando: ["facility-contents.reader", "facility-contents.writer"],
                pageComponent: FacilityContentCRUD,
            },
            {
                title: "tenants.tenant-device-types",
                route: "/tenants/tenant-device-types",
                cando: ["type-catalogs.reader", "type-catalogs.tenant-reader"],
                pageComponent: TenantDeviceTypesCRUD,
            },
            {
                title: "tenants.tenant-device-models",
                route: "/tenants/tenant-device-models",
                cando: ["catalogs.reader", "catalogs.tenant-reader"],
                pageComponent: TenantDeviceModelsCRUD,
            },
            {
                title: "tenants.edit",
                route: "/edit",
                cando: "tenant.admin",
                pageComponent: TenantEdit,
            },
            {
                title: "tenants.subscription-plan",
                route: "/subscription-plan",
                cando: StripePublishableKey ? "billing.admin" : "billing.disabled",
                pageComponent: SubscriptionPlan,
            },
        ],
    },
    {
        icon: ReportIcon,
        title: "ima.root",
        cando: null,
        pageComponent: null,
        children: [
            {
                title: "ima.uploads",
                route: "/ima/uploads",
                cando: "ima.reader",
                pageComponent: ImaUploads,
            },
            {
                title: "ima.report",
                route: "/ima/report",
                cando: "ima.reader",
                pageComponent: ImaReport,
            },
        ],
    },
];
const openApp = (evt, aux, app) => {
    var _a, _b;
    const openInNewTab = aux || evt.ctrlKey || evt.metaKey || evt.shiftKey;
    // parse tenant from URL, should never be null
    let tenant = GLOBAL_MODE_APPS.includes(app)
        ? null
        : window.location.pathname.startsWith("/tenant/")
            ? window.location.pathname.split("/")[2]
            : null;
    if (!(GLOBAL_MODE_APPS.includes(getCurrentApp()) ||
        GLOBAL_MODE_APPS.includes(app)) &&
        (!tenant || !/[0-9a-fA-F]{16}/.test(tenant))) {
        // invalid tenant and not on my.samedis.care?
        Sentry.captureException(new Error(`Invalid tenant parsed from URL ${window.location.pathname} => ${tenant !== null && tenant !== void 0 ? tenant : "null"}`));
        tenant = null;
    }
    const targetDomain = getSubdomain(app === "main" ? null : app);
    const targetUrl = addGetParams(`${window.location.protocol}//${targetDomain}:${window.location.port}/authenticated`, {
        token: getSession(),
        token_expire: (_a = getSessionExpire()) === null || _a === void 0 ? void 0 : _a.toISOString(),
        refresh_token: getSessionRefreshToken(),
        slim: (_b = sessionStorage.getItem("slim")) !== null && _b !== void 0 ? _b : "false",
        lang: i18n.language,
        redirect_path: tenant ? `/tenant/${tenant}` : undefined,
    }).replace("?", "#");
    if (openInNewTab)
        window.open(targetUrl, "_blank");
    else
        window.location.href = targetUrl;
};
/**
 * Gets all candos used in the given routes
 * @param routeDef The routes
 */
const getCandosUsed = (routeDef) => {
    const candos = [];
    for (const route of routeDef) {
        if (!route.ignoreAppMenu) {
            if (Array.isArray(route.cando)) {
                candos.push(...route.cando);
            }
            else if (typeof route.cando === "string") {
                candos.push(route.cando);
            }
            else if (route.cando == null && route.pageComponent) {
                return null;
            }
        }
        if (route.children) {
            const candosUsed = getCandosUsed(route.children);
            if (candosUsed == null)
                return null;
            if (typeof candosUsed === "string") {
                candos.push(candosUsed);
            }
            else if (Array.isArray(candosUsed)) {
                candos.push(...candosUsed);
            }
        }
    }
    return candos;
};
const appsMenuEntry = {
    icon: AppsIcon,
    title: "apps.root",
    cando: null,
    pageComponent: null,
    children: [
        {
            title: "apps.my",
            onClick: (evt) => openApp(evt, false, "my"),
            onAuxClick: (evt) => openApp(evt, true, "my"),
            cando: getCurrentApp() === "my"
                ? "app-disabled-current"
                : getCandosUsed(MySamedisRoutes),
            pageComponent: null,
        },
        {
            title: "apps.bi",
            onClick: (evt) => openApp(evt, false, "bi"),
            onAuxClick: (evt) => openApp(evt, true, "bi"),
            cando: getCurrentApp() === "bi"
                ? "app-disabled-current"
                : getCandosUsed(BiTenantRoutes),
            pageComponent: null,
        },
        {
            title: "apps.mdm",
            onClick: (evt) => openApp(evt, false, "mdm"),
            onAuxClick: (evt) => openApp(evt, true, "mdm"),
            cando: getCurrentApp() === "mdm"
                ? "app-disabled-current"
                : getCandosUsed(MdmTenantRoutes),
            pageComponent: null,
        },
        {
            title: "apps.enterprise",
            onClick: (evt) => openApp(evt, false, "enterprise"),
            onAuxClick: (evt) => openApp(evt, true, "enterprise"),
            cando: getCurrentApp() === "enterprise"
                ? "app-disabled-current"
                : getCandosUsed(EnterpriseTenantRoutes),
            pageComponent: null,
        },
        {
            title: "apps.main",
            onClick: (evt) => openApp(evt, false, "main"),
            onAuxClick: (evt) => openApp(evt, true, "main"),
            cando: getCurrentApp() === "app"
                ? "app-disabled-current"
                : getCandosUsed(AppTenantRoutes),
            pageComponent: null,
        },
    ],
};
const devModeEntry = {
    title: "dev.root",
    icon: DevModeIcon,
    cando: DEV_MODE ? null : false,
    pageComponent: null,
    children: [
        {
            title: "dev.offline-mode",
            cando: null,
            route: "/dev/offline-mode",
            pageComponent: DevOfflineMode,
        },
        {
            title: "dev.playground",
            cando: null,
            route: "/dev/playground",
            pageComponent: DevPlayground,
        },
    ],
};
export const RawTenantRoutes = [
    ...(isSubdomain(MDM_APP_DOMAIN_PREFIX)
        ? MdmTenantRoutes
        : isSubdomain(BI_APP_DOMAIN_PREFIX)
            ? BiTenantRoutes
            : isSubdomain(MY_APP_DOMAIN_PREFIX)
                ? MySamedisRoutes
                : isSubdomain(ENTERPRISE_APP_DOMAIN_PREFIX)
                    ? EnterpriseTenantRoutes
                    : AppTenantRoutes),
    appsMenuEntry,
    devModeEntry,
];
const TenantRoutesContext = React.createContext(undefined);
export const useTenantRoutesContext = () => {
    const ctx = useContext(TenantRoutesContext);
    if (!ctx)
        throw new Error("Tenant routes context not set");
    return ctx;
};
const convRawRoute = (t, permissions, route) => {
    var _a;
    const children = (_a = route.children) === null || _a === void 0 ? void 0 : _a.map((entry) => convRawRoute(t, permissions, entry));
    return Object.assign(Object.assign({}, route), { title: t(route.title), shouldRender: hasPermission(permissions, route.cando) &&
            (!children ||
                children.filter((child) => child.shouldRender).length > 0) &&
            !route.hideFromMenu, children });
};
/**
 * Permission handler for cross-tenant routes
 * @param props
 * @constructor
 */
const TenantRoutesProvider = (props) => {
    const [routes, setRoutes] = useState([]);
    const [t] = useTranslation("menu");
    const [userPermissions] = usePermissionContext();
    const authCtx = useAuthProviderContext();
    const isGlobal = useIsGlobalMode();
    useEffect(() => {
        const allTenantPermissions = () => [
            ...userPermissions,
            ...authCtx.current_user.tenants
                .map((tenant) => normalizeCanDos(tenant.candos))
                .flat(),
        ];
        const permissions = isGlobal ? allTenantPermissions() : userPermissions;
        setRoutes(RawTenantRoutes.map((entry) => convRawRoute(t, permissions, entry)));
    }, [userPermissions, authCtx.current_user.tenants, t, isGlobal]);
    return (_jsx(TenantRoutesContext.Provider, { value: routes, children: props.children }));
};
export default React.memo(TenantRoutesProvider);

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from "react";
import { FullFormDialog, Loader, useModelGet } from "components-care";
import { useDeviceModelsModelOptionalTenant, } from "../../../../../components-care/models/DeviceModelsModel";
import { Box, Typography } from "@mui/material";
import DeviceModelViewerContent from "./DeviceModelViewerContent";
import { MdmContext } from "../../../Admin/MdmTypes";
import { useInventoryModelLite } from "../../../../../components-care/models/InventoryModel";
import { useTenantIdOpt } from "../../../../components/TenantContext";
const DeviceModelViewer = (props) => {
    const { deviceId, inventoryId, enterpriseClientId, target } = props;
    const deviceModelsModel = useDeviceModelsModelOptionalTenant(useMemo(() => ({
        target,
        enterpriseClientId,
    }), [target, enterpriseClientId]));
    const tenantId = useTenantIdOpt();
    if (!tenantId && inventoryId)
        throw new Error("No inventory access in my.samedis.care");
    const inventoryModel = useInventoryModelLite(useMemo(() => ({ enterpriseClientId, tenantId: tenantId !== null && tenantId !== void 0 ? tenantId : "my-samedis" }), [enterpriseClientId, tenantId]));
    const { isLoading: loadDevice, data: deviceData, error: errorDevice, } = useModelGet(deviceModelsModel, deviceId);
    const { isLoading: loadInventory, data: inventoryData, error: errorInventory, } = useModelGet(inventoryModel, inventoryId !== null && inventoryId !== void 0 ? inventoryId : null, {
        enabled: !!inventoryId,
    });
    return (_jsxs(FullFormDialog, { useCustomClasses: true, maxWidth: "lg", children: [(loadDevice || loadInventory) && _jsx(Loader, {}), errorDevice && (_jsx(Typography, { color: "error", children: errorDevice.message })), errorInventory && (_jsx(Typography, { color: "error", children: errorInventory.message })), deviceData && (!inventoryId || inventoryData) && (_jsx(Box, { mx: -3, height: "100%", children: _jsx(MdmContext.Provider, { value: target, children: _jsx(DeviceModelViewerContent, { enterpriseClientId: enterpriseClientId, device: deviceData[0], inventory: inventoryId ? inventoryData[0] : null }) }) }))] }));
};
export default React.memo(DeviceModelViewer);

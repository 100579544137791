import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Grid, Hidden, useMediaQuery, useTheme } from "@mui/material";
import SamedisLogoApp from "../../assets/img/Samedis_RGB.svg";
import SamedisLogoBi from "../../assets/img/bi.samedis.care.svg";
import SamedisLogoMdm from "../../assets/img/mdm.samedis.care.svg";
import SamedisLogoMy from "../../assets/img/my.samedis.care.svg";
import SamedisLogoEnterprise from "../../assets/img/enterprise.samedis.care.svg";
import { makeStyles } from "tss-react/mui";
import TenantSelector from "./header/TenantSelector";
import AccountActions from "./header/AccountActions";
import MaintenanceIcon, { useIsMaintenanceIconVisible, } from "./header/MaintenanceIcon";
import SamedisNotifications from "./header/Notifications";
import { useIsGlobalMode } from "./SamedisPortal";
import getCurrentApp from "../../utils/getCurrentApp";
import SupportPopover from "../../components/Support/SupportPopover";
const useStyles = makeStyles()((theme) => ({
    logo: {
        width: "auto",
        height: 32,
    },
    spacer: {
        [theme.breakpoints.up("lg")]: {
            width: 338,
        },
    },
}));
const logo = {
    app: SamedisLogoApp,
    enterprise: SamedisLogoEnterprise,
    bi: SamedisLogoBi,
    mdm: SamedisLogoMdm,
    my: SamedisLogoMy,
}[getCurrentApp()];
const PortalHeader = () => {
    const { classes } = useStyles();
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.only("xs"));
    const globalMode = useIsGlobalMode();
    const maintenance = useIsMaintenanceIconVisible();
    return (_jsxs(Grid, { container: true, justifyContent: "flex-start", alignItems: "center", spacing: isXs ? 1 : 2, wrap: "nowrap", children: [_jsx(Hidden, { implementation: "js", smDown: true, children: _jsx(Grid, { item: true, className: classes.spacer, children: _jsx("img", { src: logo, className: classes.logo, alt: "Samedis.care" }) }) }), _jsx(Grid, { item: true, xs: true, children: !globalMode && _jsx(TenantSelector, {}) }), _jsx(Grid, { item: true, children: _jsx(SamedisNotifications, {}) }), _jsx(Grid, { item: true, children: _jsx(SupportPopover, {}) }), maintenance && (_jsx(Grid, { item: true, children: _jsx(MaintenanceIcon, {}) })), _jsx(Grid, { item: true, children: _jsx(AccountActions, {}) })] }));
};
export default React.memo(PortalHeader);

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo, useRef, useState, } from "react";
import { useCCCountryTranslations } from "components-care/dist/utils/useCCTranslations";
import { Box, Divider, FormControlLabel, Grid, Link as MuiLink, Typography, } from "@mui/material";
import territoryContainment from "cldr-data/supplemental/territoryContainment.json";
import countryList from "components-care/dist/assets/data/countries.json";
import { ActionButton, BackActionButton, Checkbox, FormButtons, FullFormDialog, isUniqueArray, uniqueArray, useDialogContext, } from "components-care";
import * as Sentry from "@sentry/react";
import { Trans, useTranslation } from "react-i18next";
import { makeStyles } from "tss-react/mui";
const territoryGrouping = territoryContainment.supplemental
    .territoryContainment;
export const getCountries = (region) => {
    const countries = [];
    for (const subRegion of territoryGrouping[region]._contains) {
        if (subRegion in territoryGrouping) {
            countries.push(...getCountries(subRegion));
        }
        else if (countryList.includes(subRegion)) {
            countries.push(subRegion);
        }
    }
    return countries;
};
var SelectionType;
(function (SelectionType) {
    SelectionType[SelectionType["All"] = 0] = "All";
    SelectionType[SelectionType["Partial"] = 1] = "Partial";
    SelectionType[SelectionType["None"] = 2] = "None";
})(SelectionType || (SelectionType = {}));
const GroupRender = (props) => {
    const { selected, readOnly, onChange } = props;
    const { t } = useCCCountryTranslations();
    const selectionRef = useRef(selected);
    useEffect(() => {
        selectionRef.current = selected;
    }, [selected]);
    const subRegions = useMemo(() => {
        return getCountries(props.region);
    }, [props.region]);
    const getRelevantSelection = useCallback(() => selected
        .filter((entry) => subRegions.includes(entry))
        .sort((a, b) => a.localeCompare(b)), [selected, subRegions]);
    const [relevantSelection, setRelevantSelection] = useState(getRelevantSelection);
    useEffect(() => {
        const newArray = getRelevantSelection();
        setRelevantSelection((prev) => {
            if (newArray.length === prev.length &&
                prev.find((entry, index) => entry !== newArray[index]) === undefined) {
                return prev;
            }
            return newArray;
        });
    }, [getRelevantSelection]);
    const regionData = useMemo(() => {
        return subRegions
            .map((region) => ({
            region,
            label: t(region),
        }))
            .sort((a, b) => a.label.localeCompare(b.label));
    }, [t, subRegions]);
    const selectionState = useMemo(() => {
        return relevantSelection.length === subRegions.length
            ? SelectionType.All
            : relevantSelection.length === 0
                ? SelectionType.None
                : SelectionType.Partial;
    }, [relevantSelection, subRegions]);
    const handleGroupToggle = useCallback((_evt, checked) => {
        if (checked) {
            onChange(uniqueArray([...selectionRef.current, ...subRegions]));
        }
        else {
            onChange(selectionRef.current.filter((selection) => !subRegions.includes(selection)));
        }
    }, [onChange, subRegions]);
    const handleRegionToggle = useCallback((event, checked) => {
        const region = event.target.name.substring("region_".length);
        if (checked) {
            onChange(uniqueArray([...selectionRef.current, region]));
        }
        else {
            onChange(selectionRef.current.filter((entry) => entry !== region));
        }
    }, [onChange]);
    return useMemo(() => (_jsx(Grid, { container: true, spacing: 2, children: _jsxs(Grid, { item: true, xs: 12, children: [_jsx(FormControlLabel, { control: _jsx(Checkbox, { indeterminate: selectionState === SelectionType.Partial, checked: selectionState === SelectionType.All }), label: _jsxs(Typography, { fontWeight: "bolder", children: [t(props.region), " (", subRegions.length, ")"] }), onChange: handleGroupToggle, disabled: readOnly }), _jsx(Box, { pt: 2, pl: 3, children: _jsx(Grid, { container: true, spacing: 0, children: regionData.map((reg) => (_jsx(Grid, { item: true, xs: 12, md: 6, lg: 4, children: _jsx(FormControlLabel, { control: _jsx(Checkbox, { onChange: handleRegionToggle, name: "region_" + reg.region }), checked: relevantSelection.includes(reg.region), label: reg.label, disabled: readOnly }) }, reg.region))) }) })] }) })), [
        handleGroupToggle,
        handleRegionToggle,
        props.region,
        readOnly,
        regionData,
        relevantSelection,
        selectionState,
        subRegions.length,
        t,
    ]);
};
const ALL_REGIONS = uniqueArray(getCountries("001"));
const useStyles = makeStyles()({
    root: {
        height: "100%",
    },
    scrollContainerWrapper: {
        position: "relative",
    },
    scrollContainer: {
        height: "100%",
        overflow: "auto",
        position: "absolute",
    },
    btnContainer: {
        width: "fit-content",
    },
});
const RegionSelectorDialog = (props) => {
    const { label, onChange, name, readOnly } = props;
    const { classes } = useStyles();
    const [, popDialog] = useDialogContext();
    const { t } = useTranslation("common");
    const { t: countriesT } = useCCCountryTranslations();
    const [selection, setSelection] = useState(props.selected);
    const handleSelectWorld = useCallback((_event, checked) => {
        setSelection(checked ? ALL_REGIONS : []);
    }, []);
    const handleDone = useCallback(() => {
        onChange(name, selection);
        popDialog();
    }, [onChange, name, selection, popDialog]);
    const handleCancel = useCallback(() => {
        popDialog();
    }, [popDialog]);
    return (_jsx(FullFormDialog, { useCustomClasses: true, dialogTitle: label, children: _jsxs(Grid, { container: true, spacing: 2, direction: "column", className: classes.root, children: [_jsx(Grid, { item: true, children: _jsx(Divider, {}) }), _jsx(Grid, { item: true, xs: true, className: classes.scrollContainerWrapper, children: _jsxs(Grid, { container: true, spacing: 2, className: classes.scrollContainer, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(FormControlLabel, { control: _jsx(Checkbox, { indeterminate: selection.length > 0 &&
                                            selection.length !== ALL_REGIONS.length, checked: selection.length === ALL_REGIONS.length }), label: _jsxs(Typography, { fontWeight: "bolder", children: [countriesT("001" /* world */), " (", ALL_REGIONS.length, ")"] }), onChange: handleSelectWorld, disabled: readOnly }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(Box, { pl: 3, children: _jsx(Grid, { container: true, spacing: 2, children: territoryGrouping["001" /* world */]._contains.map((region) => (_jsx(Grid, { item: true, xs: 12, children: _jsx(GroupRender, { region: region, selected: selection, onChange: setSelection, readOnly: readOnly }) }, region))) }) }) })] }) }), _jsx(Grid, { item: true, children: _jsx(Divider, {}) }), _jsx(Grid, { item: true, children: _jsxs(FormButtons, { className: classes.btnContainer, children: [_jsx(ActionButton, { onClick: handleDone, children: t("buttons.done") }), _jsx(BackActionButton, { onClick: handleCancel, children: t("buttons.cancel") })] }) })] }) }));
};
const RegionSelector = (props) => {
    const { selected, onChange, name, label } = props;
    const { t } = useTranslation("common");
    const [pushDialog] = useDialogContext();
    const handleChange = useCallback((newValues) => {
        onChange(name, newValues);
    }, [onChange, name]);
    useEffect(() => {
        if (!isUniqueArray(selected)) {
            // invalid data, should never happen
            Sentry.captureMessage("Duplicates in region selector selection");
            console.error("RegionSelector: Duplicate selection detected");
            handleChange(uniqueArray(selected));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected]);
    const openDialog = useCallback(() => {
        pushDialog(_jsx(RegionSelectorDialog, Object.assign({}, props)));
    }, [pushDialog, props]);
    return (_jsxs(Grid, { container: true, spacing: 1, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(Typography, { children: label }) }), _jsx(Grid, { item: true, children: _jsx(Typography, { variant: "caption2", children: _jsx(Trans, { t: t, i18nKey: "region_selector.status", components: {
                            b: (_jsx(Typography, { component: "span", variant: "inherit", fontWeight: "bold" })),
                        }, values: {
                            N: selected.length,
                            TOTAL: ALL_REGIONS.length,
                        } }) }) }), _jsx(Grid, { item: true, children: _jsx(Typography, { variant: "caption2", children: _jsx(MuiLink, { href: "#", onClick: openDialog, children: t("region_selector.edit") }) }) })] }));
};
export default React.memo(RegionSelector);

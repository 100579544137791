import { jsx as _jsx } from "react/jsx-runtime";
import { createElement as _createElement } from "react";
import React, { useCallback, useState } from "react";
import { Grid } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import FilterEntryMultiSelect from "./FilterEntryMultiSelect";
import FilterEntrySwitch from "./FilterEntrySwitch";
import FilterEntrySelect from "./FilterEntrySelect";
const useStyles = makeStyles()((theme) => ({
    root: {
        height: `calc(100% + ${theme.spacing(2)})`,
    },
}));
const FilterContainer = (props) => {
    const { filters } = props;
    const { classes } = useStyles();
    const [expanded, setExpanded] = useState(() => {
        // noinspection LoopStatementThatDoesntLoopJS,UnnecessaryLocalVariableJS
        for (const key in filters)
            if (!["switch", "checkbox"].includes(filters[key].variant))
                return key;
        return null;
    });
    const handleExpandedChange = useCallback((name, exp) => {
        setExpanded((prev) => (exp ? name : prev === name ? null : prev));
    }, []);
    return (_jsx(Grid, { container: true, spacing: 2, direction: "column", className: classes.root, wrap: "nowrap", children: Object.entries(filters)
            .filter(([, options]) => !options.hidden)
            .map(([name, options]) => options.variant === "multi-select" ? (_createElement(FilterEntryMultiSelect, Object.assign({}, options, { key: name, name: name, expanded: expanded === name, setExpanded: handleExpandedChange }))) : options.variant === "select" ? (_createElement(FilterEntrySelect, Object.assign({}, options, { key: name, name: name }))) : (_createElement(FilterEntrySwitch, Object.assign({}, options, { key: name, name: name })))) }));
};
export default React.memo(FilterContainer);

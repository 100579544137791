var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Grid, Paper } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { combineClassNames } from "components-care";
const useStyles = makeStyles({ name: "BoxGrid" })((theme) => ({
    paper: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(2),
        width: "100%",
        height: "100%",
    },
}));
const BoxGrid = (props) => {
    const { classNamePaper, styleInner, classNameInner } = props, gridProps = __rest(props, ["classNamePaper", "styleInner", "classNameInner"]);
    const { classes } = useStyles();
    const paper = (_jsx(Paper, { className: combineClassNames([classes.paper, classNamePaper]), elevation: 0, children: props.container ? (_jsx(Grid, Object.assign({}, gridProps, { item: false, xs: undefined, sm: undefined, md: undefined, lg: undefined, xl: undefined, className: classNameInner, style: styleInner, children: props.children }))) : (props.children) }));
    return props.item ? (_jsx(Grid, Object.assign({}, gridProps, { container: false, spacing: undefined, alignContent: undefined, alignItems: undefined, direction: undefined, justifyContent: undefined, wrap: undefined, children: paper }))) : (paper);
};
export default BoxGrid;

import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useState } from "react";
import { DefaultFormPage, DialogContextProvider, EditOnlyFormPage, FormField, RoutedTabPanelWrapper, RoutedTabs, useRoutedTabPanel, } from "components-care";
import { Grid, Tab } from "@mui/material";
import { useTranslation } from "react-i18next";
import FacilityBasicInformationTab from "./Facility/FacilityBasicInformationTab";
import BasicPageLayout from "../../../../components/BasicPageLayout";
import TenantOverviewContext from "../../Admin/TenantOverviewContext";
import TenantActionsButton from "./Facility/FacilityActionsButton";
import BoxGrid from "../../../../components/BoxGrid";
import FacilityAdminStatistics from "./Facility/FacilityAdminStatistics";
import { StripePublishableKey } from "../../../../constants";
import TenantAdminSubscriptionManagement from "./Facility/FacilityAdminSubscriptionManagement";
import TenantInventorySettings from "./Facility/FacilitySettingsTab";
import TenantSyncSettings from "./Facility/FacilitySyncSettings";
import { styled } from "@mui/material";
import FacilityContactInformationTab from "./Facility/FacilityContactInformationTab";
import FacilityDataTitle from "./Facility/FacilityDataTitle";
import FacilityCreateFormPage from "./Facility/FacilityCreateFormPage";
import FacilityCreateStep1 from "./Facility/FacilityCreateStep1";
import FacilityCreateStep2 from "./Facility/FacilityCreateStep2";
import FacilityCreateStep3 from "./Facility/FacilityCreateStep3";
const StyledTabs = styled(RoutedTabs)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
    borderBottom: `1px solid ${theme.palette.divider}`,
}));
const FacilityDataForm = (props) => {
    var _a;
    const { t } = useTranslation("tenant");
    const isAdmin = useContext(TenantOverviewContext);
    const { id } = props;
    const isCreate = !id;
    const [createStep, setCreateStep] = useState(0);
    const tab = useRoutedTabPanel();
    const content = (_jsx(BasicPageLayout, { title: _jsx(FacilityDataTitle, {}), actions: isCreate ? undefined : (_jsx(DialogContextProvider, { children: _jsx(TenantActionsButton, { tenantId: id, goBack: (_a = props.customProps) === null || _a === void 0 ? void 0 : _a.goBack, isAdmin: isAdmin }) })), children: isCreate ? (_jsxs(_Fragment, { children: [createStep === 0 && _jsx(FacilityCreateStep1, {}), createStep === 1 && _jsx(FacilityCreateStep2, {}), createStep === 2 && _jsx(FacilityCreateStep3, {})] })) : (_jsxs(_Fragment, { children: [_jsx(Grid, { item: true, xs: 12, sm: 8, lg: 9, children: _jsxs(Grid, { container: true, children: [_jsx(Grid, { item: true, xs: 12, children: _jsxs(StyledTabs, { variant: "fullWidth", children: [_jsx(Tab, { value: "", label: t("tabs.facility") }), _jsx(Tab, { value: "contacts", label: t("tabs.contacts") }), _jsx(Tab, { value: "settings", label: t("tabs.settings") }), isAdmin && _jsx(Tab, { value: "sync", label: t("tabs.sync") }), isAdmin && StripePublishableKey && (_jsx(Tab, { value: "subscription", label: t("tabs.subscription") }))] }) }), _jsx(BoxGrid, { item: true, xs: 12, children: _jsxs(RoutedTabPanelWrapper, { children: [tab("contacts", _jsx(FacilityContactInformationTab, {})), tab("settings", _jsx(TenantInventorySettings, {})), tab("sync", _jsx(TenantSyncSettings, {})), tab("subscription", _jsx(TenantAdminSubscriptionManagement, {})), tab("", _jsx(FacilityBasicInformationTab, {}))] }) })] }) }), _jsxs(BoxGrid, { item: true, xs: 12, sm: 4, lg: 3, container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "image" }) }), isAdmin && (_jsx(Grid, { item: true, xs: 12, children: _jsx(FacilityAdminStatistics, {}) }))] })] })) }));
    const customProps = props.customProps;
    if (isCreate)
        return (_jsx(FacilityCreateFormPage, Object.assign({}, props, { customProps: customProps, btnProps: { step: createStep, setStep: setCreateStep }, children: content })));
    else if (customProps)
        return (_jsx(DefaultFormPage, Object.assign({}, props, { customProps: customProps, children: content })));
    else
        return (_jsx(EditOnlyFormPage, Object.assign({}, props, { customProps: customProps, children: content })));
};
export default FacilityDataForm;

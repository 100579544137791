var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback } from "react";
import { Add as AddIcon } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useFormContext } from "components-care";
import SamedisApiClient from "../../../../../components-care/connectors/SamedisApiClient";
import { useTenantId } from "../../../../components/TenantContext";
import { useFormEnterpriseClientIdOpt } from "../../../../Enterprise/utils/useFormEnterpriseClientId";
const NextDeviceNumberButton = () => {
    const tenantId = useTenantId();
    const { values, setFieldValue } = useFormContext();
    const enterpriseClientId = useFormEnterpriseClientIdOpt();
    const handleClick = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield SamedisApiClient.get(enterpriseClientId
            ? `/api/v4/enterprise/tenants/${tenantId}/clients/${enterpriseClientId}/inventories/new`
            : `/api/v4/tenants/${tenantId}/inventories/new`, null);
        setFieldValue("device_number", response.data.attributes.device_number);
    }), [enterpriseClientId, tenantId, setFieldValue]);
    return (_jsx(_Fragment, { children: values.status === "draft" && (_jsx(IconButton, { onClick: handleClick, disabled: !!values.device_number, size: "large", children: _jsx(AddIcon, {}) })) }));
};
export default React.memo(NextDeviceNumberButton);

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import useAsyncMemo from "components-care/dist/utils/useAsyncMemo";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDateHMS } from "components-care";
import { useTranslation } from "react-i18next";
import * as Sentry from "@sentry/react";
export const useIsMediaCaptureSupported = (type) => {
    const result = useAsyncMemo(() => navigator.mediaDevices && navigator.mediaDevices.enumerateDevices(), []);
    return result === null || result === void 0 ? void 0 : result.find((device) => device.kind === type);
};
const MediaCaptureInitialState = {
    error: null,
    isRecording: false,
    isPaused: false,
    recordingStart: null,
    recordingMillis: 0,
};
export const makeMediaCapture = (constraints) => function useMediaCapture() {
    const stream = useRef(null);
    const recorder = useRef(null);
    const chunks = useRef([]);
    const stopRecPromise = useRef(null);
    const [state, setState] = useState(MediaCaptureInitialState);
    const initRecording = useCallback(() => __awaiter(this, void 0, void 0, function* () {
        try {
            const mediaStream = (stream.current =
                yield navigator.mediaDevices.getUserMedia(constraints));
            const mediaRecorder = (recorder.current = new MediaRecorder(mediaStream));
            mediaRecorder.addEventListener("start", () => {
                Sentry.addBreadcrumb({
                    level: "info",
                    category: "media-capture",
                    message: "start",
                });
                setState((prev) => (Object.assign(Object.assign({}, prev), { isRecording: true, recordingMillis: 0, recordingStart: Date.now() })));
            });
            mediaRecorder.addEventListener("stop", () => {
                Sentry.addBreadcrumb({
                    level: "info",
                    category: "media-capture",
                    message: "stop",
                    data: {
                        "promise-present": !!stopRecPromise.current,
                    },
                });
                setState((prev) => (Object.assign(Object.assign({}, prev), { isRecording: false, recordingMillis: prev.recordingMillis + (Date.now() - prev.recordingStart), recordingStart: null })));
                const stopRec = stopRecPromise.current;
                if (stopRec) {
                    if (chunks.current.length === 0) {
                        stopRec.reject(new Error("No data chunks available"));
                    }
                    else {
                        stopRec.resolve(new Blob(chunks.current, { type: chunks.current[0].type }));
                    }
                    chunks.current = [];
                    stopRecPromise.current = null;
                }
            });
            mediaRecorder.addEventListener("pause", () => {
                Sentry.addBreadcrumb({
                    level: "info",
                    category: "media-capture",
                    message: "pause",
                });
                setState((prev) => (Object.assign(Object.assign({}, prev), { isPaused: true, recordingMillis: prev.recordingMillis + (Date.now() - prev.recordingStart), recordingStart: null })));
            });
            mediaRecorder.addEventListener("resume", () => {
                Sentry.addBreadcrumb({
                    level: "info",
                    category: "media-capture",
                    message: "resume",
                });
                setState((prev) => (Object.assign(Object.assign({}, prev), { isPaused: false, recordingStart: Date.now() })));
            });
            mediaRecorder.addEventListener("error", (evt) => {
                Sentry.addBreadcrumb({
                    level: "error",
                    category: "media-capture",
                    message: "error",
                    data: evt,
                });
                setState((prev) => (Object.assign(Object.assign({}, prev), { isRecording: false, isPaused: false, error: evt.error })));
            });
            mediaRecorder.addEventListener("dataavailable", (evt) => {
                Sentry.addBreadcrumb({
                    level: "debug",
                    category: "media-capture",
                    message: "data-available",
                    data: evt,
                });
                chunks.current.push(evt.data);
            });
            return mediaRecorder;
        }
        catch (e) {
            console.error(e);
            setState((prev) => (Object.assign(Object.assign({}, prev), { error: e })));
            throw e;
        }
    }), []);
    useEffect(() => {
        // error reporting
        const error = state.error;
        if (error == null)
            return;
        // ignore these errors
        if (error instanceof DOMException &&
            ["NotAllowedError", "NotFoundError"].includes(error.name))
            return;
        // report everything else
        Sentry.captureException(error);
    }, [state.error]);
    const cleanup = useCallback(() => {
        Sentry.addBreadcrumb({
            level: "info",
            category: "media-capture",
            message: "cleanup",
            data: {
                recorder: !!recorder.current,
                stream: !!stream.current,
            },
        });
        const rec = recorder.current;
        if (rec) {
            rec.stop();
            recorder.current = null;
        }
        const media = stream.current;
        if (media) {
            media.getTracks().forEach((track) => track.stop());
            stream.current = null;
        }
    }, []);
    useEffect(() => {
        // auto cleanup/stop
        return cleanup;
    }, [cleanup]);
    const startRecording = useCallback(() => __awaiter(this, void 0, void 0, function* () {
        // error handling loop
        for (let i = 0; i < 2; ++i) {
            let rec = recorder.current;
            if (!rec) {
                rec = yield initRecording();
            }
            try {
                rec.start();
                break;
            }
            catch (e) {
                if (e instanceof DOMException && e.name === "NotSupportedError") {
                    // media stream is not active
                    cleanup();
                    continue;
                }
                throw e;
            }
        }
    }), [cleanup, initRecording]);
    const pauseRecording = useCallback(() => {
        const rec = recorder.current;
        if (!rec)
            throw new Error("Recorder not set");
        rec.pause();
    }, []);
    const resumeRecording = useCallback(() => {
        const rec = recorder.current;
        if (!rec)
            throw new Error("Recorder not set");
        rec.resume();
    }, []);
    const stopRecording = useCallback(() => {
        const rec = recorder.current;
        if (!rec)
            throw new Error("Recorder not set");
        rec.stop();
        return new Promise((resolve, reject) => {
            stopRecPromise.current = { resolve, reject };
        });
    }, []);
    return Object.assign(Object.assign({}, state), { startRecording,
        pauseRecording,
        resumeRecording,
        stopRecording });
};
export const useMediaRecordTimer = (state) => {
    const now = useDateHMS();
    const totalMS = state.recordingMillis +
        Math.max(state.recordingStart == null ? 0 : now.getTime() - state.recordingStart, 0);
    const totalS = totalMS / 1000;
    return [(totalS / 60) | 0, totalS % 60 | 0];
};
export const useMediaRecordTimerFormatted = (state) => {
    const [min, sec] = useMediaRecordTimer(state);
    const fmt = (n) => n.toString().padStart(2, "0");
    return `${fmt(min)}:${fmt(sec)}`;
};
export const useMediaRecordErrorMessage = (type, state) => {
    const { t } = useTranslation("common");
    if (state.error == null)
        return null;
    if (state.error instanceof DOMException) {
        const errorMap = {
            NotAllowedError: "media_recorder.errors.not-allowed",
            NotFoundError: "media_recorder.errors.not-found",
        };
        if (state.error.name in errorMap) {
            const deviceType = t("media_recorder.type." + type);
            return t(errorMap[state.error.name], { DEVICE_TYPE: deviceType });
        }
    }
    return state.error.message;
};
export const getMediaFileExt = (blob) => {
    let mime = blob.type;
    if (mime.includes(";"))
        mime = mime.split(";")[0];
    const end = mime.split("/")[1];
    if (end === "x-matroska")
        return "mkv";
    return end;
};

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { useTranslation } from "react-i18next";
import DenseLayout from "./DenseLayout";
const StaffRenderer = (props) => {
    const { data, context } = props, otherProps = __rest(props, ["data", "context"]);
    const { t } = useTranslation("staff");
    const fullName = [data.first_name, data.last_name]
        .filter((x) => !!x)
        .join(" ");
    const hasInstruction = (dataSource) => context.briefingRequirements &&
        context.briefingRequirements.length > 0 &&
        !context.briefingRequirements.find((catalog) => !data[dataSource].includes(catalog));
    const hasVendorInstruction = hasInstruction("manufacturer_catalog_ids");
    const hasUserInstruction = hasInstruction("catalog_ids");
    let briefingLabel = context.briefingRequirements &&
        [
            hasVendorInstruction
                ? t("briefing-status.has_vendor_training")
                : hasUserInstruction
                    ? t("briefing-status.has_user_training")
                    : false,
            data.administered_briefings_count > 0 &&
                t("briefing-status.trainer_count", {
                    N: data.administered_briefings_count,
                }),
        ]
            .filter((e) => !!e)
            .join(". ");
    if (briefingLabel)
        briefingLabel = "⭐ " + briefingLabel;
    return (_jsx(DenseLayout, Object.assign({ id: data.id, icon: _jsx("img", { src: data.avatar, alt: fullName }), title: fullName, info: [data.employee_no, briefingLabel, data.notes].filter((e) => !!e) }, otherProps)));
};
export default React.memo(StaffRenderer);

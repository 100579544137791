var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useContext, useMemo } from "react";
import { BaseSelectorContext, useDialogContext, useFormContextLite, useFormFieldContext, } from "components-care";
import { IconButton } from "@mui/material";
import SearchAndSingleSelect from "../SearchAndBrowse/Instances/SearchAndSingleSelect";
import { Search as SearchIcon } from "@mui/icons-material";
import { useButtonEndAdornmentStyles } from "./PickDeviceModelButton";
import ContactSearch from "../SearchAndBrowse/Instances/ContactSearch";
import { useFormEnterpriseClientIdOpt } from "../../../Enterprise/utils/useFormEnterpriseClientId";
import reactNodeToElement from "../../../../utils/reactNodeToElement";
const PickContactButton = (props) => {
    const { target, incident, issue, extraParams, title } = props;
    const { getFieldValue } = useFormContextLite();
    const { visibility, field, handleChange } = useFormFieldContext();
    const { classes } = useButtonEndAdornmentStyles();
    const selectorContext = useContext(BaseSelectorContext);
    const [pushDialog, popDialog] = useDialogContext();
    const enterpriseClientId = useFormEnterpriseClientIdOpt();
    const handleSelect = useCallback((selected) => {
        if (selected) {
            handleChange(field, selected);
            if (selectorContext)
                selectorContext.addToLru(selected);
            popDialog();
        }
    }, [handleChange, field, selectorContext, popDialog]);
    const openDialog = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        return pushDialog(_jsx(SearchAndSingleSelect, { instance: ContactSearch, onSelected: handleSelect, props: {
                target,
                incident: incident
                    ? { inventoryId: getFieldValue("inventory_id") }
                    : undefined,
                issue: issue
                    ? { inventoryId: getFieldValue("inventory_id") }
                    : undefined,
                enterpriseClientId,
                extraParams,
            }, title: title }));
    }), [
        target,
        pushDialog,
        handleSelect,
        incident,
        issue,
        enterpriseClientId,
        getFieldValue,
        title,
        extraParams,
    ]);
    return useMemo(() => reactNodeToElement(!visibility.readOnly && (_jsx(IconButton, { onClick: openDialog, className: classes.button, disabled: visibility.readOnly, size: "large", color: "primary", children: _jsx(SearchIcon, {}) }))), [classes.button, openDialog, visibility.readOnly]);
};
export default React.memo(PickContactButton);

import { useMemo } from "react";
import { Model, ModelDataTypeBackendSingleSelectRenderer, ModelDataTypeBooleanCheckboxRendererCC, ModelDataTypeDateNullableRendererCC, ModelDataTypeIntegerRendererCC, ModelDataTypeStringRendererCC, ModelVisibilityDisabled, ModelVisibilityDisabledReadOnly, ModelVisibilityEditRequired, ModelVisibilityHidden, validatePresence, } from "components-care";
import BackendConnector from "../connectors/BackendConnector";
import { useTranslation } from "react-i18next";
import { useTenantId } from "../../pages/components/TenantContext";
import { handleBackendMultiSelectLoadError } from "../../utils";
import PlanModel from "./PlanModel";
import PaymentMethodModel, { convertPaymentMethodToBaseSelectorData, useAddPaymentMethodDialog, } from "./PaymentMethodModel";
import { DeviceModelsModelSelectorSort } from "./DeviceModelsModel";
import { usePlans } from "../../api/samedis/models/SubscriptionPlan";
import { ID_FIELD_DEF } from "../../constants";
const SubscriptionModel = (params) => {
    var _a;
    return new Model("subscription-model", {
        plan_id: {
            type: new ModelDataTypeBackendSingleSelectRenderer({
                modelToSelectorData: (data) => ({
                    value: data.id,
                    label: data.plan_type === "custom"
                        ? data.name
                        : params.t(data.allow_additional_devices
                            ? "subscription:plan.field-with-additional"
                            : "subscription:plan.field", {
                            PLAN_NAME: data.name,
                            DEVICES_ALLOWED: data.base_additional_devices ||
                                data.included_device_count,
                            PRICE: ((data.allow_additional_devices
                                ? data.price /
                                    data.base_additional_devices
                                : data.price) / 100).toLocaleString(params.locale, {
                                style: "currency",
                                currency: "EUR",
                            }),
                        }),
                    isDisabled: data.status === "inactive" || data.plan_type !== "stripe",
                    hidden: data.status === "inactive" || data.plan_type !== "stripe",
                }),
                onLoadError: handleBackendMultiSelectLoadError,
                disableRequestBatching: true,
            }),
            onChange: (value, _model, setFieldValue) => {
                var _a;
                setFieldValue("additional_devices", params.deviceCountRequired[value]
                    ? (_a = params.deviceCountMap[value]) !== null && _a !== void 0 ? _a : 0
                    : null);
                return value;
            },
            getRelationModel: () => PlanModel({
                tenantId: params.tenantId,
                extraParams: { "filter[with_custom]": true },
            }),
            getLabel: () => params.t("subscription:select-plan.field"),
            visibility: {
                overview: ModelVisibilityDisabled,
                create: ModelVisibilityEditRequired,
                edit: ModelVisibilityEditRequired,
            },
            customData: null,
            validate: validatePresence,
        },
        plan_included_device_count: {
            type: new ModelDataTypeIntegerRendererCC(),
            getLabel: () => "",
            visibility: {
                overview: ModelVisibilityDisabled,
                create: ModelVisibilityDisabledReadOnly,
                edit: ModelVisibilityDisabledReadOnly,
            },
            customData: null,
        },
        additional_devices: {
            type: new ModelDataTypeIntegerRendererCC({
                placeholder: params.t("subscription:additional_devices.placeholder"),
            }),
            getLabel: () => params.t("subscription:additional_devices.field"),
            visibility: {
                overview: ModelVisibilityDisabled,
                create: ModelVisibilityDisabledReadOnly,
                edit: ModelVisibilityDisabledReadOnly,
            },
            customData: null,
            validate: (value, values, _field) => {
                var _a, _b;
                const planId = values["plan_id"];
                const minDevices = (_a = params.deviceCountMap[planId]) !== null && _a !== void 0 ? _a : 0;
                const required = (_b = params.deviceCountRequired[planId]) !== null && _b !== void 0 ? _b : false;
                if (!required)
                    return null;
                if (!value)
                    return validatePresence(value, values, _field);
                if (value < minDevices) {
                    return params.t("subscription:additional_devices.validation-error-min", {
                        MIN: minDevices,
                    });
                }
                if (value % 100 !== 0) {
                    return params.t("subscription:additional_devices.validation-error-step");
                }
                return null;
            },
        },
        payment_method_id: {
            type: new ModelDataTypeBackendSingleSelectRenderer({
                modelToSelectorData: (record) => convertPaymentMethodToBaseSelectorData(params.t, record),
                placeholder: params.t("subscription:payment_method_id.placeholder"),
                onAddNew: (_a = params.addPaymentMethod) !== null && _a !== void 0 ? _a : undefined,
                onLoadError: handleBackendMultiSelectLoadError,
                // lru: getLruConfig(params.tenantId, "subscription-payment-method"),
                sort: DeviceModelsModelSelectorSort(null),
                additionalOptions: [
                    {
                        value: "invoice",
                        label: params.t("subscription:payment_method_id.invoice"),
                    },
                ],
            }),
            getRelationModel: () => PaymentMethodModel({
                t: params.t,
                tenantId: params.tenantId,
            }),
            getLabel: () => params.t("subscription:payment_method_id.field"),
            visibility: {
                overview: ModelVisibilityDisabled,
                create: ModelVisibilityEditRequired,
                edit: ModelVisibilityEditRequired,
            },
            customData: null,
            validate: validatePresence,
        },
        created_by_user: {
            type: new ModelDataTypeStringRendererCC(),
            getLabel: () => "",
            visibility: {
                overview: ModelVisibilityDisabledReadOnly,
                create: ModelVisibilityDisabled,
                edit: ModelVisibilityDisabled,
            },
            customData: null,
        },
        start_date: {
            type: new ModelDataTypeStringRendererCC(),
            getLabel: () => "",
            visibility: {
                overview: ModelVisibilityDisabledReadOnly,
                create: ModelVisibilityDisabled,
                edit: ModelVisibilityDisabled,
            },
            customData: null,
        },
        trial_end: {
            type: new ModelDataTypeDateNullableRendererCC(),
            getLabel: () => "",
            visibility: {
                overview: ModelVisibilityDisabled,
                create: ModelVisibilityDisabledReadOnly,
                edit: ModelVisibilityDisabledReadOnly,
            },
            customData: null,
        },
        current_period_start: {
            type: new ModelDataTypeDateNullableRendererCC(),
            getLabel: () => "",
            visibility: {
                overview: ModelVisibilityDisabled,
                create: ModelVisibilityDisabledReadOnly,
                edit: ModelVisibilityDisabledReadOnly,
            },
            customData: null,
        },
        current_period_end: {
            type: new ModelDataTypeDateNullableRendererCC(),
            getLabel: () => "",
            visibility: {
                overview: ModelVisibilityDisabled,
                create: ModelVisibilityDisabledReadOnly,
                edit: ModelVisibilityDisabledReadOnly,
            },
            customData: null,
        },
        plan_name: {
            type: new ModelDataTypeStringRendererCC(),
            getLabel: () => "",
            visibility: {
                overview: ModelVisibilityDisabledReadOnly,
                create: ModelVisibilityDisabled,
                edit: ModelVisibilityDisabled,
            },
            customData: null,
        },
        status: {
            type: new ModelDataTypeStringRendererCC(),
            getLabel: () => "",
            visibility: {
                overview: ModelVisibilityDisabledReadOnly,
                create: ModelVisibilityDisabledReadOnly,
                edit: ModelVisibilityDisabledReadOnly,
            },
            customData: null,
        },
        cancel_at_period_end: {
            type: new ModelDataTypeBooleanCheckboxRendererCC(),
            getLabel: () => "",
            visibility: {
                overview: ModelVisibilityDisabledReadOnly,
                create: ModelVisibilityDisabledReadOnly,
                edit: ModelVisibilityDisabledReadOnly,
            },
            customData: null,
        },
        charge_date: {
            type: new ModelDataTypeDateNullableRendererCC(),
            getLabel: () => "",
            visibility: {
                overview: ModelVisibilityDisabled,
                create: ModelVisibilityDisabledReadOnly,
                edit: ModelVisibilityDisabledReadOnly,
            },
            customData: null,
        },
        revoke_subscription_cancellation: {
            type: new ModelDataTypeBooleanCheckboxRendererCC(),
            getLabel: () => "",
            getDefaultValue: () => false,
            visibility: {
                overview: ModelVisibilityDisabled,
                create: ModelVisibilityDisabled,
                edit: ModelVisibilityHidden,
            },
            customData: null,
        },
        id: Object.assign(Object.assign({}, ID_FIELD_DEF(params.t)), { customData: null }),
    }, new BackendConnector(`v4/tenants/${params.tenantId}/subscription_plans`, "data"));
};
export const useSubscriptionModel = () => {
    const { t, i18n } = useTranslation("subscription");
    const tenantId = useTenantId();
    const { data: plansData } = usePlans(true);
    const addPaymentMethod = useAddPaymentMethodDialog();
    return useMemo(() => SubscriptionModel({
        t,
        locale: i18n.language,
        tenantId,
        addPaymentMethod,
        deviceCountMap: plansData
            ? Object.fromEntries(plansData.map((plan) => [plan.id, plan.included_device_count]))
            : {},
        deviceCountRequired: plansData
            ? Object.fromEntries(plansData.map((plan) => [plan.id, plan.allow_additional_devices]))
            : {},
    }), [t, i18n.language, tenantId, addPaymentMethod, plansData]);
};
export default SubscriptionModel;

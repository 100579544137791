var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Model, ModelDataTypeBackendMultiSelectRenderer, ModelDataTypeBackendSingleSelectRenderer, ModelDataTypeBooleanCheckboxRendererCC, ModelDataTypeDateNullableRendererCC, ModelDataTypeIntegerRendererCC, ModelDataTypeStringRendererCC, ModelVisibilityDisabled, ModelVisibilityDisabledReadOnly, ModelVisibilityEdit, ModelVisibilityEditRequired, ModelVisibilityGridView, ModelVisibilityGridViewHidden, ModelVisibilityHidden, useDialogContext, useFormContextLite, validateEmail, validatePresence, } from "components-care";
import BackendConnector from "../connectors/BackendConnector";
import IdentAccountModel, { IdentAccountModelSelectorSort, } from "./IdentAccountModel";
import TeamModel from "./TeamModel";
import { handleBackendMultiSelectLoadError } from "../../utils";
import { useTranslation } from "react-i18next";
import { useTenantIdWithContextOpt } from "../../pages/components/TenantContext";
import React, { useCallback, useMemo } from "react";
import PickTeamsButton from "../../pages/TenantSpecific/components/Dialogs/PickTeamsButton";
import { FullFormDialog as FormDialog } from "components-care/dist/backend-components/Form";
import RendererImageWithStatus from "../types/renderers/RendererImageWithStatus";
import { getStringLabel } from "components-care/dist";
import { normalizeDate } from "components-care/dist/backend-integration/Model/Types/Utils/DateUtils";
import moment from "moment";
import RendererBackendObjectIdArray from "../types/renderers/RendererBackendObjectIdArray";
import { ID_FIELD_DEF } from "../../constants";
import PositionModel, { PositionModelSelectorSort, PositionModelToSelectorData, } from "./PositionModel";
import PickPositionsButton from "../../pages/TenantSpecific/components/Dialogs/PickPositionsButton";
import { showImageEditDialog } from "../../components/ImageEdit/ImageEditDialog";
import RendererObjectId from "../types/renderers/RendererObjectId";
const StaffCRUD = React.lazy(() => import("../../pages/TenantSpecific/ContactsAndEmployee/Staff"));
const BackendFieldVisibility = {
    overview: ModelVisibilityDisabled,
    create: ModelVisibilityDisabled,
    edit: ModelVisibilityHidden,
};
const StaffModel = (params) => new Model("staff-model", {
    tenant_id: {
        type: new RendererObjectId(),
        visibility: BackendFieldVisibility,
        getLabel: () => "",
        customData: null,
    },
    avatar: {
        type: new RendererImageWithStatus(["ident_user_id"], {
            uploadLabel: params.t("common:uploads.choose-picture"),
            postEditCallback: params.postImageEdit,
        }, (values) => values.ident_user_id
            ? "has_account"
            : "no_account"),
        getLabel: () => params.t("staff:avatar"),
        getColumnLabel: () => "",
        visibility: {
            overview: ModelVisibilityGridView,
            create: ModelVisibilityEdit,
            edit: ModelVisibilityEdit,
        },
        customData: null,
        columnWidth: [60, 60, 60],
    },
    last_name: {
        type: new ModelDataTypeStringRendererCC({
            placeholder: params.t("staff:last-name.placeholder"),
        }),
        getLabel: () => params.t("staff:last-name.field"),
        visibility: {
            overview: ModelVisibilityGridView,
            create: ModelVisibilityEditRequired,
            edit: ModelVisibilityEditRequired,
        },
        customData: null,
        filterable: true,
        sortable: true,
        columnWidth: [80, 640, 240],
        validate: validatePresence,
    },
    first_name: {
        type: new ModelDataTypeStringRendererCC({
            placeholder: params.t("staff:first-name.placeholder"),
        }),
        getLabel: () => params.t("staff:first-name.field"),
        visibility: {
            overview: ModelVisibilityGridView,
            create: ModelVisibilityEditRequired,
            edit: ModelVisibilityEditRequired,
        },
        customData: null,
        filterable: true,
        sortable: true,
        columnWidth: [80, 640, 240],
        validate: validatePresence,
    },
    employee_no: {
        type: new ModelDataTypeStringRendererCC({
            placeholder: params.t("staff:employee-number.placeholder"),
        }),
        getLabel: () => params.t("staff:employee-number.field"),
        getColumnLabel: () => params.t("staff:employee-number.column-field"),
        visibility: {
            overview: ModelVisibilityGridView,
            create: ModelVisibilityEdit,
            edit: ModelVisibilityEdit,
        },
        customData: null,
        filterable: true,
        sortable: true,
        columnWidth: [80, 120, 120],
    },
    ident_user_id: params.tenantId
        ? {
            type: new ModelDataTypeBackendSingleSelectRenderer({
                modelToSelectorData: (data) => ({
                    value: data.id,
                    label: `${data.first_name} ${data.last_name} (${data.email})`,
                }),
                placeholder: params.t("staff:select-account.placeholder"),
                sort: IdentAccountModelSelectorSort,
                onLoadError: handleBackendMultiSelectLoadError,
            }),
            getRelationModel: () => IdentAccountModel(params.tenantId),
            getLabel: () => params.t("staff:select-account.field"),
            visibility: {
                overview: ModelVisibilityDisabledReadOnly,
                create: ModelVisibilityEdit,
                edit: ModelVisibilityEdit,
            },
            customData: null,
        }
        : {
            type: new ModelDataTypeStringRendererCC(),
            visibility: BackendFieldVisibility,
            getLabel: () => "",
            customData: null,
        },
    joined: {
        type: new ModelDataTypeDateNullableRendererCC(),
        getLabel: () => params.t("staff:joined-date"),
        visibility: {
            overview: ModelVisibilityGridViewHidden,
            create: ModelVisibilityEditRequired,
            edit: ModelVisibilityEditRequired,
        },
        customData: null,
        filterable: true,
        sortable: true,
        columnWidth: [160, 160, 160],
        validate: validatePresence,
        validateHint: (value) => {
            if (!value)
                return null;
            if (value > moment(normalizeDate(new Date())).add(3, "months").toDate())
                return params.t("staff:joined.validations.future");
            return null;
        },
    },
    left: {
        type: new ModelDataTypeDateNullableRendererCC(),
        getLabel: () => params.t("staff:left-date"),
        visibility: {
            overview: ModelVisibilityGridViewHidden,
            create: ModelVisibilityEdit,
            edit: ModelVisibilityEdit,
        },
        customData: null,
        filterable: true,
        sortable: true,
        columnWidth: [160, 160, 160],
        validateHint: (value) => {
            if (!value)
                return null;
            if (value <
                moment(normalizeDate(new Date())).subtract(2, "months").toDate())
                return params.t("staff:left.validations.past");
            return null;
        },
    },
    email: {
        type: new ModelDataTypeStringRendererCC({
            placeholder: params.t("staff:email.placeholder"),
        }),
        getLabel: () => params.t("staff:email.field"),
        visibility: {
            overview: ModelVisibilityGridViewHidden,
            create: ModelVisibilityEdit,
            edit: ModelVisibilityEdit,
        },
        customData: null,
        filterable: true,
        sortable: true,
        columnWidth: [80, 640, 240],
        validate: (value, values, field) => {
            if (!value)
                return null; // optional
            const validEmail = validateEmail(value, values, field);
            if (validEmail != null)
                return validEmail;
            return null;
        },
    },
    title: {
        type: new ModelDataTypeStringRendererCC({
            placeholder: params.t("staff:title.placeholder"),
        }),
        getLabel: () => params.t("staff:title.field"),
        visibility: {
            overview: ModelVisibilityGridViewHidden,
            create: ModelVisibilityEdit,
            edit: ModelVisibilityEdit,
        },
        customData: null,
        filterable: true,
        sortable: true,
        columnWidth: [80, 120, 120],
    },
    notes: {
        type: new ModelDataTypeStringRendererCC({
            multiline: true,
            rows: 5,
            placeholder: params.t("staff:notes.placeholder"),
        }),
        getLabel: () => params.t("staff:notes.field"),
        visibility: {
            overview: ModelVisibilityGridViewHidden,
            create: ModelVisibilityEdit,
            edit: ModelVisibilityEdit,
        },
        customData: null,
        filterable: true,
        sortable: true,
        columnWidth: [80, 640, 320],
    },
    mobile_number: {
        type: new ModelDataTypeStringRendererCC({
            placeholder: params.t("staff:mobile-number.placeholder"),
        }),
        getLabel: () => params.t("staff:mobile-number.field"),
        visibility: {
            overview: ModelVisibilityGridViewHidden,
            create: ModelVisibilityEdit,
            edit: ModelVisibilityEdit,
        },
        customData: null,
        filterable: true,
        sortable: true,
        columnWidth: [80, 640, 240],
    },
    login_allowed: {
        type: new ModelDataTypeBooleanCheckboxRendererCC(),
        getLabel: () => "",
        visibility: {
            overview: ModelVisibilityDisabledReadOnly,
            edit: ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityDisabledReadOnly,
        },
        customData: null,
    },
    administered_briefings_count: {
        type: new ModelDataTypeIntegerRendererCC(),
        getLabel: () => "",
        visibility: {
            overview: ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityDisabledReadOnly,
            edit: ModelVisibilityDisabledReadOnly,
        },
        customData: null,
    },
    // catalogs the user is trained in
    catalog_ids: {
        type: new RendererBackendObjectIdArray(),
        getLabel: () => "",
        visibility: {
            overview: ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityDisabledReadOnly,
            edit: ModelVisibilityDisabledReadOnly,
        },
        customData: null,
    },
    // catalogs the user has received an vendor instruction for
    manufacturer_catalog_ids: {
        type: new RendererBackendObjectIdArray(),
        getLabel: () => "",
        visibility: {
            overview: ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityDisabledReadOnly,
            edit: ModelVisibilityDisabledReadOnly,
        },
        customData: null,
    },
    team_ids: {
        type: new ModelDataTypeBackendMultiSelectRenderer({
            modelToSelectorData: (data) => ({
                value: data.id,
                label: data.name,
            }),
            placeholder: params.t("staff:select-groups.placeholder"),
            searchResultLimit: 50,
            onLoadError: handleBackendMultiSelectLoadError,
            sort: [{ field: "name", direction: 1 }],
            endAdornment: (_jsx(PickTeamsButton, { type: "staff", title: params.t("staff:select-groups.dialog-title") })),
        }),
        getRelationModel: () => TeamModel({
            t: params.t,
            tenantId: params.tenantId,
            extraParams: { "filter[type]": "staff" },
        }),
        getLabel: () => params.t("staff:select-groups.field"),
        visibility: {
            overview: ModelVisibilityDisabled,
            create: ModelVisibilityEdit,
            edit: ModelVisibilityEdit,
        },
        customData: null,
    },
    position_titles: {
        type: (() => {
            const model = new ModelDataTypeStringRendererCC();
            // @ts-expect-error not exposed in CC
            model.deserialize = (data) => Array.isArray(data) ? data.join(", ") : data;
            return model;
        })(),
        getLabel: () => params.t("staff:position_titles.field"),
        visibility: {
            overview: ModelVisibilityGridViewHidden,
            create: ModelVisibilityDisabled,
            edit: ModelVisibilityDisabled,
        },
        customData: null,
        sortable: false,
        filterable: false,
    },
    position_ids: {
        type: new ModelDataTypeBackendMultiSelectRenderer({
            modelToSelectorData: PositionModelToSelectorData,
            placeholder: params.t("staff:select-positions.placeholder"),
            searchResultLimit: 50,
            onLoadError: handleBackendMultiSelectLoadError,
            sort: PositionModelSelectorSort,
            endAdornment: (_jsx(PickPositionsButton, { title: params.t("staff:select-positions.dialog-title") })),
        }),
        getRelationModel: () => PositionModel({ t: params.t, tenantId: params.tenantId }),
        getLabel: () => params.t("staff:select-positions.field"),
        visibility: {
            overview: ModelVisibilityDisabled,
            create: ModelVisibilityEdit,
            edit: ModelVisibilityEdit,
        },
        customData: null,
    },
    created_by_user: {
        type: new ModelDataTypeStringRendererCC(),
        getLabel: () => params.t("common:audit.created-by"),
        visibility: {
            overview: ModelVisibilityGridViewHidden,
            create: ModelVisibilityDisabled,
            edit: ModelVisibilityDisabled,
        },
        customData: null,
        filterable: true,
        sortable: true,
        columnWidth: [80, 320, 160],
    },
    created_at: {
        type: new ModelDataTypeDateNullableRendererCC(),
        getLabel: () => params.t("common:audit.created-at"),
        visibility: {
            overview: ModelVisibilityGridViewHidden,
            create: ModelVisibilityDisabled,
            edit: ModelVisibilityDisabled,
        },
        customData: null,
        filterable: true,
        sortable: true,
        columnWidth: [160, 160, 160],
    },
    updated_by_user: {
        type: new ModelDataTypeStringRendererCC(),
        getLabel: () => params.t("common:audit.updated-by"),
        visibility: {
            overview: ModelVisibilityGridViewHidden,
            create: ModelVisibilityDisabled,
            edit: ModelVisibilityDisabled,
        },
        customData: null,
        filterable: true,
        sortable: true,
        columnWidth: [80, 320, 160],
    },
    updated_at: {
        type: new ModelDataTypeDateNullableRendererCC(),
        getLabel: () => params.t("common:audit.updated-at"),
        visibility: {
            overview: ModelVisibilityGridViewHidden,
            create: ModelVisibilityDisabled,
            edit: ModelVisibilityDisabled,
        },
        customData: null,
        filterable: true,
        sortable: true,
        columnWidth: [160, 160, 160],
    },
    id: Object.assign(Object.assign({}, ID_FIELD_DEF(params.t)), { customData: null }),
}, new BackendConnector(params.teamResponsible
    ? `v4/tenants/${params.tenantId}/team_responsibles`
    : params.trainerDeviceModelId
        ? `v4/my/device_models/${params.trainerDeviceModelId}/trainers`
        : params.trainerInventoryId
            ? `v4/tenants/${params.tenantId}/inventories/${params.trainerInventoryId}/trainers`
            : `v4/tenants/${params.tenantId}/staffs`, "data", {
    additionalQueryParameters: Object.assign({}, params.extraParams),
    forceFieldFilter: params.trainerDeviceModelId && params.tenantId
        ? {
            tenant_id: {
                type: "equals",
                value1: params.tenantId,
                value2: "",
            },
        }
        : undefined,
}), [
    params.extraParams,
    params.tenantId,
    params.trainerInventoryId,
    params.trainerDeviceModelId,
    params.teamResponsible,
]);
export const getStaffLabel = (data, includeNo, includeEmail) => {
    let label = [data.last_name, data.first_name].filter((x) => !!x).join(", ");
    const paramsToInclude = [];
    if (includeNo && data.employee_no)
        paramsToInclude.push(data.employee_no);
    if (includeEmail && data.email)
        paramsToInclude.push(data.email);
    if (paramsToInclude.length > 0)
        label = label + ` (${paramsToInclude.join("; ")})`;
    return label;
};
export const StaffModelToSelectorData = (data) => {
    return {
        value: data.id,
        label: getStaffLabel(data, true, true),
        icon: data.avatar,
        ignore: !!data.left && new Date(data.left) < new Date(),
    };
};
const BriefingStatusInfo = (props) => {
    const { t } = useTranslation("staff");
    const { getFieldValue } = useFormContextLite();
    const briefingRequirements = props.getBriefingDevices(getFieldValue);
    const hasInstruction = (dataSource) => briefingRequirements &&
        briefingRequirements.length > 0 &&
        !briefingRequirements.find((deviceModelId) => !props.staff[dataSource].includes(deviceModelId));
    const hasVendorInstruction = hasInstruction("manufacturer_catalog_ids");
    const hasUserInstruction = hasInstruction("catalog_ids");
    let label = hasVendorInstruction
        ? t("briefing-status.has_vendor_training")
        : hasUserInstruction
            ? t("briefing-status.has_user_training")
            : null;
    if (label)
        label = " ⭐ " + label;
    return _jsx("span", { children: label });
};
export const StaffModelToSelectorDataForBriefing = (getBriefingDevices) => (data) => {
    const base = StaffModelToSelectorData(data);
    return Object.assign(Object.assign({}, base), { label: [
            base.label,
            _jsxs("span", { children: [base.label, _jsx(BriefingStatusInfo, { getBriefingDevices: getBriefingDevices, staff: data })] }),
        ] });
};
export const useAddStaffDialog = () => {
    const [pushDialog, popDialog] = useDialogContext();
    const { t } = useTranslation("staff");
    return useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const data = yield new Promise((resolve, reject) => pushDialog(_jsx(FormDialog, { dialogTitle: t("add-new-dialog.title"), maxWidth: "md", useCustomClasses: true, onClose: reject, children: _jsx(StaffCRUD, { disableRouting: true, initialView: "new", formProps: {
                        onSubmit: (data) => {
                            popDialog();
                            resolve(data);
                        },
                    } }) })));
            return StaffModelToSelectorData(data);
        }
        catch (_e) {
            return null;
        }
    }), [popDialog, pushDialog, t]);
};
export const StaffModelSelectorSort = [
    {
        field: "last_name",
        direction: 1,
    },
    {
        field: "first_name",
        direction: 1,
    },
    {
        field: "employee_no",
        direction: 1,
    },
    {
        field: "email",
        direction: 1,
    },
];
export const StaffModelMultiSelectedSort = (a, b) => {
    return getStringLabel(a).localeCompare(getStringLabel(b));
};
export const useStaffModelTranslations = () => useTranslation("staff");
export const useStaffModel = (params) => {
    const { t } = useStaffModelTranslations();
    const tenantId = useTenantIdWithContextOpt(params === null || params === void 0 ? void 0 : params.tenantId);
    const [pushDialog] = useDialogContext();
    if (!tenantId && !(params === null || params === void 0 ? void 0 : params.trainerDeviceModelId))
        throw new Error("No tenant id and no trainerDeviceModelId");
    return useMemo(() => StaffModel(Object.assign({ t,
        tenantId, postImageEdit: (image) => showImageEditDialog(pushDialog, image, t("staff:avatar-edit")) }, params)), [t, tenantId, params, pushDialog]);
};
export default StaffModel;

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { addGetParams } from "components-care";
import SystemController from "./controllers/SystemController";
import { openDB, } from "idb";
import * as Sentry from "@sentry/react";
import { ENABLE_OFFLINE_MODE } from "../../constants";
class OfflineDatabase {
    static init() {
        if (!ENABLE_OFFLINE_MODE)
            return;
        this.controllers.push(new SystemController());
        this.database = openDB("offline-db", 1, {
            upgrade(database, oldVersion, newVersion, transaction, event) {
                return Promise.all(OfflineDatabase.controllers.map((entry) => entry.upgradeStore(database, oldVersion, newVersion, transaction, event)));
            },
            blocked(currentVersion, blockedVersion, event) {
                Sentry.captureException(new Error(`OfflineDatabase.blocked(${currentVersion}, ${blockedVersion}, event)`), { extra: { event } });
            },
            terminated() {
                Sentry.captureException(new Error("OfflineDatabase.terminated()"));
            },
        });
    }
    static handleRequests(method, url, args, headers, body, _auth) {
        for (const controller of OfflineDatabase.controllers) {
            const result = controller.onRequest(method, new URL(addGetParams(url, args), window.location.origin), headers, body);
            if (result)
                return result;
        }
    }
    static cantSyncReason() {
        // TODO: Add checks for user preference
        var _a;
        // don't sync when saving data
        // @ts-expect-error not standard
        if ((_a = navigator.connection) === null || _a === void 0 ? void 0 : _a.saveData)
            return "saveData";
        return navigator.onLine ? null : "offline";
    }
    static canSync() {
        return this.cantSyncReason() == null;
    }
    static quota() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!("storage" in navigator) || !("estimate" in navigator.storage))
                return null;
            return navigator.storage.estimate();
        });
    }
}
OfflineDatabase.lastSync = null;
OfflineDatabase.controllers = [];
export default OfflineDatabase;

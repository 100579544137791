import getLocalizedString from "../../utils/getLocalizedString";
import i18n from "../../i18n";
export const validateMaintenanceTypeEntry = (entry) => {
    var _a;
    const label = getLocalizedString(null, (_a = entry.labels) !== null && _a !== void 0 ? _a : {}) || entry.label;
    if (!label) {
        return i18n.t("common:service_intervals.validation.label");
    }
    if (entry.value == null || entry.value <= 0) {
        return i18n.t("common:service_intervals.validation.interval", {
            NAME: label,
        });
    }
    return null;
};
/**
 * Type to handle Service intervals
 */
class TypeMaintenanceTypes {
    constructor(params) {
        this.params = params;
    }
    validate(value) {
        for (const entry of value) {
            const res = validateMaintenanceTypeEntry(entry);
            if (res != null)
                return res;
        }
        return null;
    }
    getFilterType() {
        return null;
    }
    getDefaultValue() {
        return [];
    }
    stringify(value) {
        return value
            .map((entry) => {
            var _a;
            return `${getLocalizedString(null, (_a = entry.labels) !== null && _a !== void 0 ? _a : {}) || entry.label} (${entry.category}): ${entry.value} ${entry.unit}`;
        })
            .join("; ");
    }
}
export default TypeMaintenanceTypes;

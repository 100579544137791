var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useMemo } from "react";
import SearchAndBrowse from "../index";
import { useTenantId } from "../../../../components/TenantContext";
import ContactModel, { CONTACT_CATEGORY_OPTIONS, ContactModelSelectorSort, useContactModelCCTranslations, useContactModelTranslations, } from "../../../../../components-care/models/ContactModel";
import ContactRenderer from "../Renderers/ContactRenderer";
import { multiSelectFilterEntryFromEnum } from "../FilterEntryMultiSelect";
const ContactSearch = (props) => {
    var _a;
    const { incident, issue, target, enterpriseClientId, extraParams } = props, other = __rest(props, ["incident", "issue", "target", "enterpriseClientId", "extraParams"]);
    const tenantId = useTenantId();
    const { t: contactModelT } = useContactModelTranslations();
    const { t: contactModelCCT } = useContactModelCCTranslations();
    const scope = (_a = extraParams === null || extraParams === void 0 ? void 0 : extraParams["filter[scope]"]) !== null && _a !== void 0 ? _a : "public_and_tenant";
    const enableScopeFilter = scope === "public_and_tenant";
    const loadRecords = useCallback((search, filters, offset) => __awaiter(void 0, void 0, void 0, function* () {
        var _b;
        const categories = filters.categories;
        const contactModel = ContactModel({
            t: contactModelT,
            ccT: contactModelCCT,
            tenantId,
            target,
            incident,
            issue,
            enterpriseClientId,
            extraParams: Object.assign(Object.assign({}, extraParams), { "filter[scope]": enableScopeFilter
                    ? filters.scope
                        ? "public_and_tenant"
                        : "tenant"
                    : extraParams === null || extraParams === void 0 ? void 0 : extraParams["filter[scope]"] }),
        });
        const [data, meta] = yield contactModel.index2({
            offset: offset,
            rows: 25,
            sort: ContactModelSelectorSort,
            quickFilter: search,
            fieldFilter: categories.length > 0
                ? {
                    categories: {
                        type: "inSet",
                        value1: categories.join(","),
                        value2: "",
                    },
                }
                : undefined,
        });
        return {
            data: data,
            total: (_b = meta.filteredRows) !== null && _b !== void 0 ? _b : meta.totalRows,
        };
    }), [
        contactModelT,
        contactModelCCT,
        tenantId,
        target,
        incident,
        issue,
        enterpriseClientId,
        extraParams,
        enableScopeFilter,
    ]);
    const isResponsibleSearch = !!(incident || issue);
    const filters = useMemo(() => ({
        scope: {
            variant: "switch",
            title: contactModelT("contact:scope.filter"),
            hidden: !enableScopeFilter,
            persistType: "user",
            persistKeys: ["tenant"],
        },
        categories: Object.assign(Object.assign({ variant: "multi-select", title: contactModelT("contact:categories.filter") }, multiSelectFilterEntryFromEnum(CONTACT_CATEGORY_OPTIONS(contactModelT))), { hidden: isResponsibleSearch }),
    }), [contactModelT, enableScopeFilter, isResponsibleSearch]);
    return (_jsx(SearchAndBrowse, Object.assign({}, other, { loadRecords: loadRecords, filters: filters, renderer: ContactRenderer, rendererContext: props, controlName: "ContactSearch" })));
};
export default React.memo(ContactSearch);

import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback } from "react";
import { useTenantInfo } from "../TenantContext";
import { useTranslation } from "react-i18next";
import { makeStyles } from "tss-react/mui";
import Breadcrumb from "./Breadcrumb";
import { useLocation, useNavigate, combineClassNames } from "components-care";
import { Hidden } from "@mui/material";
const useStyles = makeStyles()((theme) => ({
    root: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        position: "absolute",
        transform: "translateY(-50%)",
        maxWidth: "100%",
    },
    outerRoot: {
        position: "relative",
    },
    tenantSelected: {
        textOverflow: "ellipsis",
    },
    link: {
        cursor: "pointer",
        textDecoration: "underline",
        color: theme.palette.primary.main,
        display: "inline",
    },
}));
export const useGoToTenantSelection = () => {
    const navigate = useNavigate();
    return useCallback(() => {
        localStorage.removeItem("tenant"); // remove cached tenant selection
        navigate("/");
    }, [navigate]);
};
const TenantSelector = () => {
    var _a, _b;
    const { t } = useTranslation("portal");
    const location = useLocation();
    const { classes } = useStyles();
    const goToSelection = useGoToTenantSelection();
    const tenant = useTenantInfo();
    const is404 = !tenant && location.pathname !== "" && location.pathname !== "/";
    return (_jsx(_Fragment, { children: _jsx("div", { className: classes.outerRoot, children: _jsxs("div", { className: combineClassNames([
                    (is404 || tenant) && classes.root,
                    tenant && classes.tenantSelected,
                ]), children: [_jsx("div", { onClick: goToSelection, id: "tenant-name", className: combineClassNames([(is404 || tenant) && classes.link]), children: (_b = (_a = tenant === null || tenant === void 0 ? void 0 : tenant.short_name) !== null && _a !== void 0 ? _a : tenant === null || tenant === void 0 ? void 0 : tenant.full_name) !== null && _b !== void 0 ? _b : (is404
                            ? t("header.tenant.to-selection")
                            : t("header.tenant.not-selected")) }), _jsx(Hidden, { implementation: "js", lgUp: true, children: _jsx(Breadcrumb, {}) })] }) }) }));
};
export default React.memo(TenantSelector);

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import TagRenderer from "./TagRenderer";
import { useTranslation } from "react-i18next";
import { TrustLevelIcon } from "../../../../../components/icons";
import { getDeviceModelManufacturerLabel, OPERATOR_ORDINANCE_OPTIONS, } from "../../../../../components-care/models/DeviceModelsModel";
import { useRegulatoryDomain } from "../../../../../utils/useRegulatoryDomain";
import DenseLayout from "./DenseLayout";
import getLocalizedString from "../../../../../utils/getLocalizedString";
const DeviceModelRenderer = (props) => {
    var _a;
    const { data } = props, otherProps = __rest(props, ["data"]);
    const { t, i18n } = useTranslation(["global-device-catalog"]);
    const regDomain = useRegulatoryDomain();
    const manufacturer = getDeviceModelManufacturerLabel(data);
    const annex = regDomain !== "DE" || data.operator_ordinance === "none"
        ? null
        : (_a = OPERATOR_ORDINANCE_OPTIONS(t)
            .find((op) => op.value === data.operator_ordinance)) === null || _a === void 0 ? void 0 : _a.getLabel();
    const serviceTag = data.end_of_life ? (_jsx(TagRenderer, { color: "red", dense: true, denseLabel: true, children: t("global-device-catalog:tags.service.end_of_life") }, "service")) : data.end_of_service ? (_jsx(TagRenderer, { color: "yellow", dense: true, denseLabel: true, children: t("global-device-catalog:tags.service.end_of_service") }, "service")) : null;
    return (_jsx(DenseLayout, Object.assign({ id: data.id, icon: _jsx("img", { src: data.device_picture, alt: data.title }), title: [
            data.title,
            data.associated_version_ids.length > 0 && data.version,
        ]
            .filter((x) => !!x)
            .join(" • "), info: [
            [
                getLocalizedString(i18n.language, data.device_type_title_labels),
                annex,
            ]
                .filter((e) => !!e)
                .join(" • "),
            manufacturer,
        ].filter((e) => !!e), tags: [
            serviceTag,
            data.inventory_count > 0 && (_jsx(TagRenderer, { color: "yellow", info: t("global-device-catalog:tags.inventory_info"), icon: _jsx("img", { src: "/img/placeholders/device.png", alt: "" }), dense: true, children: t("global-device-catalog:tags.inventory") }, "inventory")),
            _jsx(TagRenderer, { color: "blue", info: t("global-device-catalog:tags.trust-level." +
                    data.trust_level +
                    "_info"), icon: _jsx(TrustLevelIcon, { level: data.trust_level }), dense: true, children: t("global-device-catalog:tags.trust-level." + data.trust_level) }, "trust_level"),
        ] }, otherProps)));
};
export default React.memo(DeviceModelRenderer);

import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useContext, useMemo } from "react";
import { MdmContext } from "../../../Admin/MdmTypes";
import { useTenantIdOpt } from "../../../../components/TenantContext";
import { useTranslation } from "react-i18next";
import SamedisCrudMultiImage from "../../../../../components-care/SamedisCrudMultiImage";
const DeviceModelViewerImages = (props) => {
    const { recordId, linkedImageId, enterpriseClientId } = props;
    const target = useContext(MdmContext);
    const tenantId = useTenantIdOpt();
    const { t } = useTranslation("device-models");
    const getEndpoint = useCallback((id) => target === "tenant"
        ? enterpriseClientId
            ? `v4/enterprise/tenants/${tenantId}/clients/${enterpriseClientId}/device_models/${id}/images`
            : tenantId
                ? `v4/tenants/${tenantId}/device_models/${id}/images`
                : `v4/my/device_models/${id}/images`
        : target === "user"
            ? `v4/tenants/${tenantId}/mdm/device_models/${id}/images`
            : `v4/device_models/${id}/images`, [tenantId, target, enterpriseClientId]);
    const extraParams = useMemo(() => (target === "admin" ? { tenant_id: tenantId } : undefined), [target, tenantId]);
    return (_jsx(SamedisCrudMultiImage, { field: "images", getEndpoint: getEndpoint, extraParams: extraParams, initialId: recordId, label: t("image"), primaryId: linkedImageId || "", placeholderImage: "/img/placeholders/device.png", readOnly: true }));
};
export default React.memo(DeviceModelViewerImages);

import { jsx as _jsx } from "react/jsx-runtime";
import React, { useMemo } from "react";
import ChatAttachmentUploadRenderer from "./ChatAttachmentUploadRenderer";
import SamedisCrudFileUpload from "../../../../../../components-care/SamedisCrudFileUpload";
import { useTenantIdOpt } from "../../../../../components/TenantContext";
import { useFormEnterpriseClientIdOpt } from "../../../../../Enterprise/utils/useFormEnterpriseClientId";
import { useIncidentsContext } from "../index";
import { throwError } from "components-care";
export const getMessageUploadsUrl = (enterpriseId, tenantId, incidentId, ignoreId, type) => (id) => {
    return incidentId
        ? ignoreId
            ? enterpriseId
                ? type === "dispatcher"
                    ? `v4/enterprise/tenants/${enterpriseId}/clients/${tenantId}/incidents/${incidentId}/uploads`
                    : type === "supporter"
                        ? `v4/enterprise/tenants/${enterpriseId}/clients/${tenantId}/incidents/${incidentId}/supporter_uploads`
                        : type === "reporter"
                            ? `v4/enterprise/tenants/${enterpriseId}/clients/${tenantId}/incidents/${incidentId}/uploads`
                            : throwError("invalid")
                : type === "dispatcher"
                    ? `v4/tenants/${tenantId}/incidents/${incidentId}/uploads`
                    : type === "supporter"
                        ? `v4/tenants/${tenantId}/incidents/${incidentId}/supporter_uploads`
                        : type === "reporter"
                            ? `v4/my/incidents/${incidentId}/uploads`
                            : throwError("invalid")
            : enterpriseId
                ? type === "dispatcher"
                    ? `v4/enterprise/tenants/${enterpriseId}/clients/${tenantId}/incidents/${incidentId}/messages/${id}/uploads`
                    : type === "supporter"
                        ? `v4/enterprise/tenants/${enterpriseId}/clients/${tenantId}/incidents/${incidentId}/messages/${id}/supporter_uploads`
                        : type === "reporter"
                            ? `v4/enterprise/tenants/${enterpriseId}/clients/${tenantId}/incidents/${incidentId}/messages/${id}/uploads`
                            : throwError("invalid")
                : type === "dispatcher"
                    ? `v4/tenants/${tenantId}/incidents/${incidentId}/messages/${id}/uploads`
                    : type === "supporter"
                        ? `v4/tenants/${tenantId}/incidents/${incidentId}/messages/${id}/supporter_uploads`
                        : type === "reporter"
                            ? `v4/my/incidents/${incidentId}/messages/${id}/uploads`
                            : throwError("invalid")
        : enterpriseId
            ? type === "dispatcher"
                ? `v4/enterprise/tenants/${enterpriseId}/clients/${tenantId}/incidents/${id}/uploads`
                : type === "supporter"
                    ? `v4/enterprise/tenants/${enterpriseId}/clients/${tenantId}/incidents/${id}/supporter_uploads`
                    : type === "reporter"
                        ? `v4/enterprise/tenants/${enterpriseId}/clients/${tenantId}/incidents/${id}/uploads`
                        : throwError("invalid")
            : type === "dispatcher"
                ? `v4/tenants/${tenantId}/incidents/${id}/uploads`
                : type === "supporter"
                    ? `v4/tenants/${tenantId}/incidents/${id}/supporter_uploads`
                    : type === "reporter"
                        ? `v4/my/incidents/${id}/uploads`
                        : throwError("invalid");
};
const ChatUploads = (props) => {
    const { messageId, incidentId, system } = props;
    const tenantId = useTenantIdOpt();
    const clientId = useFormEnterpriseClientIdOpt();
    const { type } = useIncidentsContext();
    const getEndpoint = useMemo(() => getMessageUploadsUrl(clientId ? tenantId : null, clientId !== null && clientId !== void 0 ? clientId : tenantId, incidentId, !messageId, type), [clientId, incidentId, messageId, tenantId, type]);
    return (_jsx(SamedisCrudFileUpload, { field: messageId
            ? "uploads_" + messageId + (system ? "_system" : "")
            : "uploads_first", previewSize: 24, previewImages: true, getEndpoint: getEndpoint, initialId: messageId !== null && messageId !== void 0 ? messageId : "first-record-special-case", variant: ChatAttachmentUploadRenderer, readOnly: true }));
};
export default React.memo(ChatUploads);

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from "react";
import { CRUD, DefaultErrorComponent as ErrorComponent, DataGridStorageManagerPersist, showInfoDialog, useDialogContext, useLocation, DataGridNoPersist, } from "components-care";
import { Grid, useTheme } from "@mui/material";
import Forbidden from "../pages/Forbidden";
import { useTenantContext } from "../pages/components/TenantContext";
import { useTranslation } from "react-i18next";
import * as Sentry from "@sentry/react";
const ImportUI = React.lazy(() => import("./ImportUI"));
export const useDefaultGridProps = () => {
    const theme = useTheme();
    const gridFilterBreakpoint = theme.breakpoints.down("lg");
    const location = useLocation();
    const { t } = useTranslation("common");
    const [pushDialog] = useDialogContext();
    return useMemo(() => {
        var _a;
        const urlQuery = new URLSearchParams(location.search);
        let columnFilter = undefined;
        if (urlQuery.has("column_filter")) {
            try {
                columnFilter = JSON.parse((_a = urlQuery.get("column_filter")) !== null && _a !== void 0 ? _a : "");
            }
            catch (e) {
                console.error("column filter parse error", e);
            }
        }
        return {
            enableFilterDialogMediaQuery: gridFilterBreakpoint,
            getAdditionalFilters: (data) => data,
            filterLimit: 1,
            sortLimit: 1,
            isFilterSupported: (dataType, filterType, field) => {
                if (field === "id") {
                    return filterType === "equals" || filterType === "notEqual";
                }
                if (dataType === "date") {
                    if (filterType === "lessThanOrEqual")
                        return false;
                    if (filterType === "greaterThanOrEqual")
                        return false;
                }
                else if (dataType === "combined-string") {
                    return filterType === "contains" || filterType === "notContains";
                }
                return true;
            },
            overrideFilter: columnFilter,
            overrideCustomData: location.search
                ? Object.fromEntries(urlQuery.entries())
                : undefined,
            customDeleteErrorHandler: (error) => {
                var _a, _b;
                if (error instanceof Error && error.name === "BackendError") {
                    const err = error;
                    const meta = err.meta;
                    if ((_b = (_a = meta === null || meta === void 0 ? void 0 : meta.meta) === null || _a === void 0 ? void 0 : _a.msg) === null || _b === void 0 ? void 0 : _b.error_details) {
                        return showInfoDialog(pushDialog, {
                            title: t("data-grid.delete.error.title"),
                            message: (_jsxs(Grid, { container: true, spacing: 1, children: [_jsx(Grid, { item: true, xs: 12, children: t("data-grid.delete.error.message", {
                                            ERROR: error.message,
                                        }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx("ul", { style: { paddingLeft: 16 }, children: Object.entries(meta.meta.msg.error_details).map(([id, info]) => (_jsxs("li", { children: [info.title, ": ", info.errors.join(", ")] }, id))) }) })] })),
                        });
                    }
                }
                showInfoDialog(pushDialog, {
                    title: t("data-grid.delete.error.title"),
                    message: t("data-grid.delete.error.message", {
                        ERROR: error.message,
                    }),
                });
            },
            onError: Sentry.captureException,
        };
    }, [gridFilterBreakpoint, location.search, pushDialog, t]);
};
const SamedisCrud = (props) => {
    const { isSyncReadOnly, disableStore } = props, crudProps = __rest(props, ["isSyncReadOnly", "disableStore"]);
    const { t } = useTranslation("common");
    const [tenantId] = useTenantContext();
    const defaultGridProps = useDefaultGridProps();
    const formPropsCached = useMemo(() => {
        var _a;
        return (Object.assign(Object.assign({ errorComponent: ErrorComponent }, props.formProps), { readOnlyReasons: Object.assign(Object.assign({}, (_a = props.formProps) === null || _a === void 0 ? void 0 : _a.readOnlyReasons), (isSyncReadOnly && { sync: t("sync-disabled.edit") })) }));
    }, [isSyncReadOnly, props.formProps, t]);
    const gridPropsCached = useMemo(() => (Object.assign(Object.assign({}, defaultGridProps), props.gridProps)), [defaultGridProps, props.gridProps]);
    const persistKeys = useMemo(() => {
        const keys = {
            model: props.model.modelId,
        };
        if (tenantId)
            keys.tenant = tenantId;
        return keys;
    }, [props.model.modelId, tenantId]);
    const crud = (_jsx(CRUD, Object.assign({}, crudProps, { newPermission: isSyncReadOnly ? false : props.newPermission, newPermissionHint: isSyncReadOnly ? t("sync-disabled.new") : undefined, editPermission: isSyncReadOnly ? false : props.editPermission, editPermissionHint: undefined, deletePermission: isSyncReadOnly ? false : props.deletePermission, deletePermissionHint: isSyncReadOnly ? t("sync-disabled.delete") : undefined, formProps: formPropsCached, gridProps: gridPropsCached, forbiddenPage: Forbidden, importUI: ImportUI })));
    return disableStore ? (_jsx(DataGridNoPersist, { children: crud })) : (_jsx(DataGridStorageManagerPersist, { storageKeys: persistKeys, children: crud }));
};
export default React.memo(SamedisCrud);

import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTenantId, useTenantInfo, } from "../../../../components/TenantContext";
import { useDeviceTypeCrudContext } from "../../DeviceType";
import { DeviceTypeModelToSelectorData, useDeviceTypeModel, } from "../../../../../components-care/models/DeviceTypeModel";
import { Grid } from "@mui/material";
import { BackendSingleSelect, useFormContext } from "components-care";
import DeviceTypeCatalogTreeView from "./DeviceTypeCatalogTreeView";
let NewDeviceTypeInfo = null;
const DeviceTypeCatalogView = () => {
    const tenantId = useTenantId();
    const tenantInfo = useTenantInfo();
    const ctx = useDeviceTypeCrudContext();
    const { id: recordId, getFieldValue, setFieldValue, values, customProps, } = useFormContext();
    const [catalogId, setCatalogId] = useState(() => {
        const parentIds = getFieldValue("parent_ids");
        if (Array.isArray(parentIds) && parentIds.length > 0) {
            return parentIds[0];
        }
        return (getFieldValue("id") ||
            (tenantInfo === null || tenantInfo === void 0 ? void 0 : tenantInfo.type_catalog_id) // when creating new device type, default to tenant default catalog
        );
    });
    // apply new device type info upon load
    useEffect(() => {
        if (!NewDeviceTypeInfo || recordId)
            return;
        setFieldValue("parent_id", NewDeviceTypeInfo.parentId);
        setCatalogId(NewDeviceTypeInfo.catalogId);
        NewDeviceTypeInfo = null;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recordId]);
    const catalogModel = useDeviceTypeModel(useMemo(() => ({
        tenantId,
        target: ctx.mode,
        onlyParent: true,
    }), [tenantId, ctx.mode]));
    const handleTreeClick = useCallback((evt) => {
        const clickId = evt.currentTarget.getAttribute("data-id");
        if (!clickId) {
            console.error("handleTreeClick click ID null", evt, evt.currentTarget);
            throw new Error("handleTreeClick click ID null");
        }
        const openNew = clickId.endsWith("-new");
        if (openNew) {
            if (!catalogId)
                throw new Error("Open new without catalogId");
            NewDeviceTypeInfo = {
                catalogId: catalogId,
                parentId: clickId.split("-")[0],
            };
        }
        customProps.open(openNew ? "new" : clickId);
    }, [customProps, catalogId]);
    return (_jsxs(_Fragment, { children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(BackendSingleSelect, { model: catalogModel, modelToSelectorData: DeviceTypeModelToSelectorData, selected: catalogId, onSelect: setCatalogId }) }), _jsx(Grid, { item: true, xs: 12, md: 6, children: catalogId && (_jsx(DeviceTypeCatalogTreeView, { mode: ctx.mode, catalogId: catalogId, activeId: recordId !== null && recordId !== void 0 ? recordId : (values.parent_id || "") + "-new", onClick: handleTreeClick, enableAddNew: true })) })] }));
};
export default React.memo(DeviceTypeCatalogView);

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Grid, Typography } from "@mui/material";
import { DeviceModelUploadsNonForm } from "../../../Admin/components/DeviceModels/DeviceModelUploads";
import { GroupBox } from "components-care";
import { useTranslation } from "react-i18next";
import DeviceModelViewerTabRegulatoryCountryList from "./DeviceModelViewerTabRegulatoryCountryList";
import VideoList from "./Videos/VideoList";
import { useActiveLanguageContext } from "../../../../../components/ActiveLanguageContext";
import { hiddenRegulatories } from "../../../../../components/DeviceModelRegulatoriesInput";
const DeviceModelViewerTabRegulatory = (props) => {
    const { device, enterpriseClientId } = props;
    const { t } = useTranslation("device-models");
    const [activeLanguage] = useActiveLanguageContext();
    return (_jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, md: 6, children: _jsx(GroupBox, { label: t("viewer.regulatory.references.title"), children: device.regulatories.length === 0 ? (_jsx(Typography, { children: t("viewer.regulatory.references.no-data") })) : (_jsx(Grid, { container: true, spacing: 2, children: device.regulatories
                            .filter((reg) => !hiddenRegulatories.includes(reg.label))
                            .map((reg, idx) => (_jsxs(Grid, { item: true, xs: 12, children: [_jsx(Typography, { variant: "caption2", color: "textSecondary", component: "p", children: t("regulatories.enum." + reg.label) }), _jsx(Typography, { variant: "body1", children: reg.value })] }, idx))) })) }) }), _jsx(Grid, { item: true, xs: 12, md: 6, children: _jsx(GroupBox, { label: t("viewer.regulatory.countries.title"), children: device.available_in_countries.length > 0 ? (_jsx(DeviceModelViewerTabRegulatoryCountryList, { countries: device.available_in_countries })) : (_jsx(Typography, { children: t("viewer.regulatory.countries.no-data") })) }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(DeviceModelUploadsNonForm, { recordId: device.id, category: "regulatory", enterpriseClientId: enterpriseClientId, readOnly: true }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(GroupBox, { label: t("videos.regulatory"), children: _jsx(VideoList, { recordId: device.id, category: "regulatory", language: activeLanguage, scope: "public" }) }) })] }));
};
export default React.memo(DeviceModelViewerTabRegulatory);

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { ComponentsCareI18n, ModelDataTypeEnumSelectRenderer, } from "components-care";
import currencies from "components-care/dist/assets/data/currencies-alive.json";
import currencyRelevance from "components-care/dist/assets/data/currency-relevance.json";
import TenantModel from "../../models/TenantModel";
import i18n from "../../../i18n";
import { useCCCurrencyTranslations } from "components-care/dist/utils/useCCTranslations";
// ordered currency list EUR, USD, all others
const currencyList = ["EUR", "USD"];
currencyList.push(...currencies
    .sort((a, b) => currencyRelevance[b] -
    currencyRelevance[a])
    .filter((currency) => !currencyList.includes(currency)));
const getEnumValues = (t) => currencyList.map((currency) => ({
    value: currency,
    getLabel: () => `${t("currencies:" + currency + ".displayName")} (${currency})`,
}));
const WithLoadedTranslations = (props) => {
    const { t } = useCCCurrencyTranslations();
    props.setValues(t);
    return props.children();
};
class RendererCurrencySelect extends ModelDataTypeEnumSelectRenderer {
    constructor(props) {
        super(getEnumValues(ComponentsCareI18n.t), props);
    }
    render(params) {
        return (_jsx(WithLoadedTranslations, { setValues: (t) => (this.values = getEnumValues(t)), children: () => super.render(params) }));
    }
}
export const getCurrencySelectFieldDefinition = (tenantId) => ({
    getDefaultValue: () => __awaiter(void 0, void 0, void 0, function* () {
        return (yield TenantModel({
            t: i18n.t.bind(i18n),
            ccT: ComponentsCareI18n.t.bind(ComponentsCareI18n),
        }).getCached(tenantId))[0].default_currency;
    }),
});
export default RendererCurrencySelect;

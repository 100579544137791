import { Model, ModelDataTypeEnumSelectRenderer, ModelDataTypeStringRendererCC, ModelVisibilityDisabledReadOnly, ModelVisibilityGridView, } from "components-care";
import BackendConnector from "../connectors/BackendConnector";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { ID_FIELD_DEF } from "../../constants";
import RendererObjectId from "../types/renderers/RendererObjectId";
export const ChangelogModel = (params) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12;
    return new Model(`changelogs`, {
        user_id: {
            type: new RendererObjectId(),
            getLabel: () => "",
            visibility: {
                overview: ModelVisibilityDisabledReadOnly,
                create: ModelVisibilityDisabledReadOnly,
                edit: ModelVisibilityDisabledReadOnly,
            },
            customData: null,
        },
        created_at: {
            type: new ModelDataTypeStringRendererCC(),
            getLabel: () => {
                var _a, _b, _c;
                return (_c = (_b = (_a = params.customSettings) === null || _a === void 0 ? void 0 : _a.created_at) === null || _b === void 0 ? void 0 : _b.label) !== null && _c !== void 0 ? _c : params.t(`changelog:fields.created_at`);
            },
            visibility: {
                overview: ((_c = (_b = (_a = params.customSettings) === null || _a === void 0 ? void 0 : _a.created_at) === null || _b === void 0 ? void 0 : _b.visible) !== null && _c !== void 0 ? _c : true)
                    ? ModelVisibilityGridView
                    : ModelVisibilityDisabledReadOnly,
                create: ModelVisibilityDisabledReadOnly,
                edit: ModelVisibilityDisabledReadOnly,
            },
            customData: null,
            sortable: true,
            filterable: true,
            columnWidth: (_f = (_e = (_d = params.customSettings) === null || _d === void 0 ? void 0 : _d.created_at) === null || _e === void 0 ? void 0 : _e.columnWidth) !== null && _f !== void 0 ? _f : [
                160, 160, 160,
            ],
        },
        user_name: {
            type: new ModelDataTypeStringRendererCC(),
            getLabel: () => {
                var _a, _b, _c;
                return (_c = (_b = (_a = params.customSettings) === null || _a === void 0 ? void 0 : _a.user_name) === null || _b === void 0 ? void 0 : _b.label) !== null && _c !== void 0 ? _c : params.t(`changelog:fields.user_name`);
            },
            visibility: {
                overview: ((_j = (_h = (_g = params.customSettings) === null || _g === void 0 ? void 0 : _g.user_name) === null || _h === void 0 ? void 0 : _h.visible) !== null && _j !== void 0 ? _j : true)
                    ? ModelVisibilityGridView
                    : ModelVisibilityDisabledReadOnly,
                create: ModelVisibilityDisabledReadOnly,
                edit: ModelVisibilityDisabledReadOnly,
            },
            customData: null,
            sortable: true,
            filterable: true,
            columnWidth: (_m = (_l = (_k = params.customSettings) === null || _k === void 0 ? void 0 : _k.user_name) === null || _l === void 0 ? void 0 : _l.columnWidth) !== null && _m !== void 0 ? _m : [
                60, 300, 140,
            ],
        },
        attr_name: {
            type: params.fieldList
                ? new ModelDataTypeEnumSelectRenderer(params.fieldList)
                : new ModelDataTypeStringRendererCC(),
            getLabel: () => {
                var _a, _b, _c;
                return (_c = (_b = (_a = params.customSettings) === null || _a === void 0 ? void 0 : _a.attr_name) === null || _b === void 0 ? void 0 : _b.label) !== null && _c !== void 0 ? _c : params.t(`changelog:fields.attr_name_human`);
            },
            visibility: {
                overview: !params.fieldList
                    ? ModelVisibilityDisabledReadOnly
                    : ((_q = (_p = (_o = params.customSettings) === null || _o === void 0 ? void 0 : _o.attr_name) === null || _p === void 0 ? void 0 : _p.visible) !== null && _q !== void 0 ? _q : true)
                        ? ModelVisibilityGridView
                        : ModelVisibilityDisabledReadOnly,
                create: ModelVisibilityDisabledReadOnly,
                edit: ModelVisibilityDisabledReadOnly,
            },
            customData: null,
            sortable: true,
            filterable: true,
            columnWidth: (_t = (_s = (_r = params.customSettings) === null || _r === void 0 ? void 0 : _r.attr_name) === null || _s === void 0 ? void 0 : _s.columnWidth) !== null && _t !== void 0 ? _t : [
                80, 400, 180,
            ],
        },
        attr_name_human: {
            type: new ModelDataTypeStringRendererCC(),
            getLabel: () => {
                var _a, _b, _c;
                return (_c = (_b = (_a = params.customSettings) === null || _a === void 0 ? void 0 : _a.attr_name) === null || _b === void 0 ? void 0 : _b.label) !== null && _c !== void 0 ? _c : params.t(`changelog:fields.attr_name_human`);
            },
            visibility: {
                overview: params.fieldList
                    ? ModelVisibilityDisabledReadOnly
                    : ((_w = (_v = (_u = params.customSettings) === null || _u === void 0 ? void 0 : _u.attr_name) === null || _v === void 0 ? void 0 : _v.visible) !== null && _w !== void 0 ? _w : true)
                        ? ModelVisibilityGridView
                        : ModelVisibilityDisabledReadOnly,
                create: ModelVisibilityDisabledReadOnly,
                edit: ModelVisibilityDisabledReadOnly,
            },
            customData: null,
            sortable: true,
            filterable: true,
            columnWidth: (_z = (_y = (_x = params.customSettings) === null || _x === void 0 ? void 0 : _x.attr_name) === null || _y === void 0 ? void 0 : _y.columnWidth) !== null && _z !== void 0 ? _z : [
                80, 400, 180,
            ],
        },
        value_before: {
            type: new ModelDataTypeStringRendererCC(),
            getLabel: () => {
                var _a, _b, _c;
                return (_c = (_b = (_a = params.customSettings) === null || _a === void 0 ? void 0 : _a.value_before) === null || _b === void 0 ? void 0 : _b.label) !== null && _c !== void 0 ? _c : params.t(`changelog:fields.value_before`);
            },
            visibility: {
                overview: ((_2 = (_1 = (_0 = params.customSettings) === null || _0 === void 0 ? void 0 : _0.value_before) === null || _1 === void 0 ? void 0 : _1.visible) !== null && _2 !== void 0 ? _2 : true)
                    ? ModelVisibilityGridView
                    : ModelVisibilityDisabledReadOnly,
                create: ModelVisibilityDisabledReadOnly,
                edit: ModelVisibilityDisabledReadOnly,
            },
            customData: null,
            sortable: true,
            filterable: true,
            columnWidth: (_5 = (_4 = (_3 = params.customSettings) === null || _3 === void 0 ? void 0 : _3.value_before) === null || _4 === void 0 ? void 0 : _4.columnWidth) !== null && _5 !== void 0 ? _5 : [
                80, 400, 180,
            ],
        },
        value_after: {
            type: new ModelDataTypeStringRendererCC(),
            getLabel: () => {
                var _a, _b, _c;
                return (_c = (_b = (_a = params.customSettings) === null || _a === void 0 ? void 0 : _a.value_after) === null || _b === void 0 ? void 0 : _b.label) !== null && _c !== void 0 ? _c : params.t(`changelog:fields.value_after`);
            },
            visibility: {
                overview: ((_8 = (_7 = (_6 = params.customSettings) === null || _6 === void 0 ? void 0 : _6.value_after) === null || _7 === void 0 ? void 0 : _7.visible) !== null && _8 !== void 0 ? _8 : true)
                    ? ModelVisibilityGridView
                    : ModelVisibilityDisabledReadOnly,
                create: ModelVisibilityDisabledReadOnly,
                edit: ModelVisibilityDisabledReadOnly,
            },
            customData: null,
            sortable: true,
            filterable: true,
            columnWidth: (_11 = (_10 = (_9 = params.customSettings) === null || _9 === void 0 ? void 0 : _9.value_after) === null || _10 === void 0 ? void 0 : _10.columnWidth) !== null && _11 !== void 0 ? _11 : [
                80, 400, 180,
            ],
        },
        changelog_id: {
            type: new RendererObjectId(),
            getLabel: () => "",
            visibility: {
                overview: ModelVisibilityDisabledReadOnly,
                create: ModelVisibilityDisabledReadOnly,
                edit: ModelVisibilityDisabledReadOnly,
            },
            customData: null,
        },
        record_id: {
            type: new RendererObjectId(),
            getLabel: () => "",
            visibility: {
                overview: ModelVisibilityDisabledReadOnly,
                create: ModelVisibilityDisabledReadOnly,
                edit: ModelVisibilityDisabledReadOnly,
            },
            customData: null,
        },
        record_type: {
            type: new ModelDataTypeStringRendererCC(),
            getLabel: () => "",
            visibility: {
                overview: ModelVisibilityDisabledReadOnly,
                create: ModelVisibilityDisabledReadOnly,
                edit: ModelVisibilityDisabledReadOnly,
            },
            customData: null,
        },
        tenant_locale: {
            type: new ModelDataTypeStringRendererCC(),
            getLabel: () => "",
            visibility: {
                overview: ModelVisibilityDisabledReadOnly,
                create: ModelVisibilityDisabledReadOnly,
                edit: ModelVisibilityDisabledReadOnly,
            },
            customData: null,
        },
        id: Object.assign(Object.assign({}, ID_FIELD_DEF(params.t)), { customData: null }),
    }, new BackendConnector((_12 = params.customEndpoint) !== null && _12 !== void 0 ? _12 : `${params.baseModel.connector
        .getApiBase()
        .substring(5 /* strip /api/ */)}/${params.id}/changelogs`, "data", {
        additionalQueryParameters: params.customQueryParams,
        forceFieldFilter: params.forceFilter,
    }), {
        endpoint: params.customEndpoint,
        base: JSON.stringify(params.baseModel.getReactQueryKey(params.id, false)),
    });
};
export const useChangelogTranslations = () => useTranslation("changelog");
export const useChangelogModel = (params) => {
    const { t } = useChangelogTranslations();
    return useMemo(() => ChangelogModel(Object.assign(Object.assign({}, params), { t })), [params, t]);
};

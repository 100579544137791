import { CountryFlags, Model, ModelDataTypeBooleanCheckboxRendererCC, ModelDataTypeDateNullableRendererCC, ModelDataTypeEnumSelectRenderer, ModelDataTypeIntegerRendererCC, ModelDataTypeStringRendererCC, ModelVisibilityDisabled, ModelVisibilityDisabledReadOnly, ModelVisibilityEdit, ModelVisibilityEditReadOnly, ModelVisibilityEditRequired, ModelVisibilityHidden, validateEmail, validateEmailRaw, validatePresence, } from "components-care";
import BackendConnector from "../connectors/BackendConnector";
import { useTranslation } from "react-i18next";
import countryList from "components-care/dist/assets/data/countries.json";
import { useTenantId } from "../../pages/components/TenantContext";
import { ID_FIELD_DEF, VAT_REGEX, VAT_REGEX_COUNTRIES } from "../../constants";
import { useCCCountryTranslations } from "components-care/dist/utils/useCCTranslations";
import { useMemo } from "react";
import RendererObjectId from "../types/renderers/RendererObjectId";
const TenantBillingModel = (params) => new Model("tenant-billing-model", {
    use_custom_billing_data: {
        type: new ModelDataTypeBooleanCheckboxRendererCC(),
        getLabel: () => params.t("subscription:tenant_billing.fields.use_custom_billing_data"),
        visibility: {
            overview: ModelVisibilityDisabled,
            edit: ModelVisibilityEdit,
            create: ModelVisibilityDisabled,
        },
        customData: null,
    },
    billing_name: {
        type: new ModelDataTypeStringRendererCC({
            placeholder: params.t("subscription:tenant_billing.placeholders.billing_name"),
        }),
        getLabel: () => params.t("subscription:tenant_billing.fields.billing_name"),
        visibility: {
            overview: ModelVisibilityDisabled,
            edit: ModelVisibilityEditRequired,
            create: ModelVisibilityDisabled,
        },
        customData: null,
        validate: validatePresence,
    },
    billing_email: {
        type: new ModelDataTypeStringRendererCC({
            placeholder: params.t("subscription:tenant_billing.placeholders.billing_email"),
        }),
        getLabel: () => params.t("subscription:tenant_billing.fields.billing_email"),
        visibility: {
            overview: ModelVisibilityDisabled,
            edit: ModelVisibilityEditRequired,
            create: ModelVisibilityDisabled,
        },
        customData: null,
        validate: validateEmail,
    },
    billing_phone: {
        type: new ModelDataTypeStringRendererCC({
            placeholder: params.t("subscription:tenant_billing.placeholders.billing_phone"),
        }),
        getLabel: () => params.t("subscription:tenant_billing.fields.billing_phone"),
        visibility: {
            overview: ModelVisibilityDisabled,
            edit: ModelVisibilityEdit,
            create: ModelVisibilityDisabled,
        },
        customData: null,
    },
    billing_address_city: {
        type: new ModelDataTypeStringRendererCC({
            placeholder: params.t("subscription:tenant_billing.placeholders.billing_address_city"),
        }),
        getLabel: () => params.t("subscription:tenant_billing.fields.billing_address_city"),
        visibility: {
            overview: ModelVisibilityDisabled,
            edit: ModelVisibilityEditRequired,
            create: ModelVisibilityDisabled,
        },
        customData: null,
        validate: validatePresence,
    },
    billing_address_country: {
        type: new ModelDataTypeEnumSelectRenderer(countryList.map((countryCode) => ({
            value: countryCode,
            getLabel: () => params.ccT("countries:" + countryCode),
            icon: CountryFlags[countryCode],
        })), {
            enableIcons: true,
            placeholder: params.t("subscription:tenant_billing.placeholders.billing_address_country"),
        }),
        getLabel: () => params.t("subscription:tenant_billing.fields.billing_address_country"),
        visibility: {
            overview: ModelVisibilityDisabled,
            edit: ModelVisibilityEditRequired,
            create: ModelVisibilityDisabled,
        },
        customData: null,
        validate: validatePresence,
    },
    billing_address_line1: {
        type: new ModelDataTypeStringRendererCC({
            placeholder: params.t("subscription:tenant_billing.placeholders.billing_address_line1"),
        }),
        getLabel: () => params.t("subscription:tenant_billing.fields.billing_address_line1"),
        visibility: {
            overview: ModelVisibilityDisabled,
            edit: ModelVisibilityEditRequired,
            create: ModelVisibilityDisabled,
        },
        customData: null,
        validate: validatePresence,
    },
    billing_address_line2: {
        type: new ModelDataTypeStringRendererCC({
            placeholder: params.t("subscription:tenant_billing.placeholders.billing_address_line2"),
        }),
        getLabel: () => params.t("subscription:tenant_billing.fields.billing_address_line2"),
        visibility: {
            overview: ModelVisibilityDisabled,
            edit: ModelVisibilityEdit,
            create: ModelVisibilityDisabled,
        },
        customData: null,
    },
    billing_address_postal_code: {
        type: new ModelDataTypeStringRendererCC({
            placeholder: params.t("subscription:tenant_billing.placeholders.billing_address_city"),
        }),
        getLabel: () => params.t("subscription:tenant_billing.fields.billing_address_postal_code"),
        visibility: {
            overview: ModelVisibilityDisabled,
            edit: ModelVisibilityEditRequired,
            create: ModelVisibilityDisabled,
        },
        customData: null,
        validate: validatePresence,
    },
    billing_address_state: {
        type: new ModelDataTypeStringRendererCC({
            placeholder: params.t("subscription:tenant_billing.placeholders.billing_address_state"),
        }),
        getLabel: () => params.t("subscription:tenant_billing.fields.billing_address_state"),
        visibility: {
            overview: ModelVisibilityDisabled,
            edit: ModelVisibilityEdit,
            create: ModelVisibilityDisabled,
        },
        customData: null,
    },
    vat_number: {
        type: new ModelDataTypeStringRendererCC({
            placeholder: params.t("subscription:tenant_billing.placeholders.vat_number"),
        }),
        getLabel: () => params.t("subscription:tenant_billing.fields.vat_number"),
        visibility: {
            overview: ModelVisibilityDisabled,
            edit: ModelVisibilityEdit,
            create: ModelVisibilityDisabled,
        },
        onChange: (value) => {
            return value.replace(/[^A-Z0-9]/gi, "").toUpperCase();
        },
        validate: (value, values) => {
            var _a, _b;
            const country = values["billing_address_country"];
            const vatOptional = country === "DE" || !VAT_REGEX_COUNTRIES.includes(country);
            if (!value && vatOptional)
                return null;
            if (!value)
                return params.t("subscription:tenant_billing.validations.vat_number.not_optional");
            if (value.slice(0, 2).toUpperCase() !== country) {
                return ((_a = params.t("subscription:tenant_billing.validations.vat_number.country_invalid")) !== null && _a !== void 0 ? _a : "VAT country invalid");
            }
            if (VAT_REGEX.test(value))
                return null;
            return ((_b = params.t("subscription:tenant_billing.validations.vat_number.format_invalid")) !== null && _b !== void 0 ? _b : "VAT invalid");
        },
        customData: null,
    },
    vat_number_verified: {
        type: new ModelDataTypeBooleanCheckboxRendererCC(),
        getLabel: () => params.t("subscription:tenant_billing.fields.vat_number_verified"),
        visibility: {
            overview: ModelVisibilityDisabled,
            edit: ModelVisibilityEditReadOnly,
            create: ModelVisibilityDisabled,
        },
        customData: null,
    },
    current_subscription_id: {
        type: new RendererObjectId(),
        getLabel: () => "",
        visibility: {
            overview: ModelVisibilityDisabledReadOnly,
            edit: ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityDisabledReadOnly,
        },
        customData: null,
    },
    upcoming_subscription_id: {
        type: new RendererObjectId(),
        getLabel: () => "",
        visibility: {
            overview: ModelVisibilityDisabledReadOnly,
            edit: ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityDisabledReadOnly,
        },
        customData: null,
    },
    current_plan_id: {
        type: new RendererObjectId(),
        getLabel: () => "",
        visibility: {
            overview: ModelVisibilityDisabledReadOnly,
            edit: ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityDisabledReadOnly,
        },
        customData: null,
    },
    "billing_contact.name": {
        type: new ModelDataTypeStringRendererCC(),
        getLabel: () => params.t("subscription:tenant_billing.fields.billing_name"),
        visibility: {
            overview: ModelVisibilityDisabled,
            edit: ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityDisabled,
        },
        customData: null,
    },
    "billing_contact.email": {
        type: new ModelDataTypeStringRendererCC(),
        getLabel: () => params.t("subscription:tenant_billing.fields.billing_email"),
        visibility: {
            overview: ModelVisibilityDisabled,
            edit: ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityDisabled,
        },
        customData: null,
    },
    "billing_contact.phone": {
        type: new ModelDataTypeStringRendererCC(),
        getLabel: () => params.t("subscription:tenant_billing.fields.billing_phone"),
        visibility: {
            overview: ModelVisibilityDisabled,
            edit: ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityDisabled,
        },
        customData: null,
    },
    "billing_contact.address.city": {
        type: new ModelDataTypeStringRendererCC(),
        getLabel: () => params.t("subscription:tenant_billing.fields.billing_address_city"),
        visibility: {
            overview: ModelVisibilityDisabled,
            edit: ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityDisabled,
        },
        customData: null,
    },
    "billing_contact.address.country": {
        type: new ModelDataTypeEnumSelectRenderer(countryList.map((countryCode) => ({
            value: countryCode,
            getLabel: () => params.ccT("countries:" + countryCode),
            icon: CountryFlags[countryCode],
        })), {
            enableIcons: true,
            placeholder: params.t("tenant:country.placeholder"),
        }),
        getLabel: () => params.t("subscription:tenant_billing.fields.billing_address_country"),
        visibility: {
            overview: ModelVisibilityDisabled,
            edit: ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityDisabled,
        },
        customData: null,
    },
    "billing_contact.address.line1": {
        type: new ModelDataTypeStringRendererCC(),
        getLabel: () => params.t("subscription:tenant_billing.fields.billing_address_line1"),
        visibility: {
            overview: ModelVisibilityDisabled,
            edit: ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityDisabled,
        },
        customData: null,
    },
    "billing_contact.address.line2": {
        type: new ModelDataTypeStringRendererCC(),
        getLabel: () => params.t("subscription:tenant_billing.fields.billing_address_line2"),
        visibility: {
            overview: ModelVisibilityDisabled,
            edit: ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityDisabled,
        },
        customData: null,
    },
    "billing_contact.address.postal_code": {
        type: new ModelDataTypeStringRendererCC(),
        getLabel: () => params.t("subscription:tenant_billing.fields.billing_address_postal_code"),
        visibility: {
            overview: ModelVisibilityDisabled,
            edit: ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityDisabled,
        },
        customData: null,
    },
    "billing_contact.address.state": {
        type: new ModelDataTypeStringRendererCC(),
        getLabel: () => params.t("subscription:tenant_billing.fields.billing_address_state"),
        visibility: {
            overview: ModelVisibilityDisabled,
            edit: ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityDisabled,
        },
        customData: null,
    },
    no_of_devices_allowed: {
        type: new ModelDataTypeIntegerRendererCC(),
        getLabel: () => "",
        visibility: {
            overview: ModelVisibilityDisabled,
            create: ModelVisibilityDisabledReadOnly,
            edit: ModelVisibilityDisabledReadOnly,
        },
        customData: null,
    },
    subscribed_until: {
        type: new ModelDataTypeStringRendererCC(),
        getLabel: () => "",
        visibility: {
            overview: ModelVisibilityDisabled,
            create: ModelVisibilityDisabledReadOnly,
            edit: ModelVisibilityDisabledReadOnly,
        },
        customData: null,
    },
    billing_anchor: {
        type: new ModelDataTypeDateNullableRendererCC(),
        getLabel: () => "",
        visibility: {
            overview: ModelVisibilityDisabled,
            create: ModelVisibilityDisabledReadOnly,
            edit: ModelVisibilityDisabledReadOnly,
        },
        customData: null,
    },
    current_date_time: {
        type: new ModelDataTypeDateNullableRendererCC(),
        getLabel: () => "",
        visibility: {
            overview: ModelVisibilityDisabled,
            create: ModelVisibilityDisabledReadOnly,
            edit: ModelVisibilityDisabledReadOnly,
        },
        customData: null,
    },
    cancelled: {
        type: new ModelDataTypeBooleanCheckboxRendererCC(),
        getLabel: () => "",
        visibility: {
            overview: ModelVisibilityDisabled,
            create: ModelVisibilityHidden,
            edit: ModelVisibilityHidden,
        },
        customData: null,
    },
    id: Object.assign(Object.assign({}, ID_FIELD_DEF(params.t)), { customData: null }),
}, new BackendConnector(params.admin ? `v4/admin/tenant_billings` : `v4/user/tenant_billing`, "data", {
    additionalQueryParameters: params.admin
        ? { tenant_id: params.tenantId }
        : undefined,
}), [
/* both controllers are the same, except different permission checking, so no need to define cache keys */
]);
export const validateBillingContact = (data) => {
    const { vat_number: vatNumber, billing_contact: billingContact } = data;
    if (!billingContact.name)
        return false;
    if (!billingContact.email)
        return false;
    if (!validateEmailRaw(billingContact.email))
        return false;
    const { address } = billingContact;
    if (!address.line1)
        return false;
    if (!address.postal_code)
        return false;
    if (!address.city)
        return false;
    if (!countryList.find((country) => country === address.country))
        return false;
    if (address.country !== "DE" &&
        VAT_REGEX_COUNTRIES.includes(address.country)) {
        if (!vatNumber)
            return false;
        if (!VAT_REGEX.test(vatNumber))
            return false;
        const vatCountry = vatNumber.slice(0, 2).toUpperCase();
        if ((vatCountry === "XI" && address.country !== "UK") || // north ireland protocol
            vatCountry !== address.country)
            return false;
    }
    return true;
};
export const useTenantBillingModel = (params) => {
    const { t } = useTranslation("subscription");
    const { t: ccT } = useCCCountryTranslations();
    const tenantId = useTenantId();
    return useMemo(() => TenantBillingModel(Object.assign(Object.assign({ admin: false }, params), { t, ccT, tenantId })), [t, ccT, tenantId, params]);
};
export default TenantBillingModel;

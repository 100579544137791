var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useMemo } from "react";
import { Grid } from "@mui/material";
import SamedisInfoBox from "../../../../../../components/SamedisInfoBox";
import { Publish as PublishIcon } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { ActionButton, useFormContext } from "components-care";
import { useActiveLanguageContext } from "../../../../../../components/ActiveLanguageContext";
const DeviceModelActionPublishLanguage = (props) => {
    const { close } = props;
    const { t } = useTranslation("device-models");
    const { values, getFieldValue, setFieldValue, submit } = useFormContext();
    const { published_languages } = values;
    const [activeLanguage] = useActiveLanguageContext();
    const langPublished = published_languages.includes(activeLanguage);
    const handlePublishLanguageVariant = useCallback(() => {
        close();
        void (() => __awaiter(void 0, void 0, void 0, function* () {
            setFieldValue("published_languages", [
                ...getFieldValue("published_languages"),
                activeLanguage,
            ]);
            setFieldValue("published", getFieldValue("published_languages").length > 0);
            try {
                yield submit();
            }
            catch (_e) {
                // error is displayed by form
                setFieldValue("published_languages", getFieldValue("published_languages").filter((lang) => lang !== activeLanguage));
                setFieldValue("published", getFieldValue("published_languages").length > 0);
            }
        }))();
    }, [activeLanguage, close, getFieldValue, setFieldValue, submit]);
    const handleUnpublishLanguageVariant = useCallback(() => {
        close();
        void (() => __awaiter(void 0, void 0, void 0, function* () {
            setFieldValue("published_languages", getFieldValue("published_languages").filter((lang) => lang !== activeLanguage));
            setFieldValue("published", getFieldValue("published_languages").length > 0);
            try {
                yield submit();
            }
            catch (_e) {
                // error is displayed by form
                setFieldValue("published_languages", [
                    ...getFieldValue("published_languages"),
                    activeLanguage,
                ]);
                setFieldValue("published", getFieldValue("published_languages").length > 0);
            }
        }))();
    }, [activeLanguage, close, getFieldValue, setFieldValue, submit]);
    return useMemo(() => (_jsx(_Fragment, { children: !langPublished && (_jsxs(_Fragment, { children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(SamedisInfoBox, { title: langPublished
                            ? t("actions.unpublish-language-variant.title")
                            : t("actions.publish-language-variant.title"), icon: PublishIcon, contentType: "paragraphs", content: [
                            langPublished
                                ? t("actions.unpublish-language-variant.content")
                                : t("actions.publish-language-variant.content"),
                        ] }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(ActionButton, { onClick: langPublished
                            ? handleUnpublishLanguageVariant
                            : handlePublishLanguageVariant, children: langPublished
                            ? t("actions.unpublish-language-variant.button")
                            : t("actions.publish-language-variant.button") }) })] })) })), [
        handlePublishLanguageVariant,
        handleUnpublishLanguageVariant,
        langPublished,
        t,
    ]);
};
export default React.memo(DeviceModelActionPublishLanguage);

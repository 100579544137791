var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { useTranslation } from "react-i18next";
import { throwError } from "components-care";
import { DeviceTeamImage, MixedTeamImage, StaffTeamImage, } from "../../../../../components-care/models/TeamModel";
import DenseLayout from "./DenseLayout";
const TeamRenderer = (props) => {
    const { data } = props, otherProps = __rest(props, ["data"]);
    const { t } = useTranslation("team");
    const image = data.type === "staff"
        ? StaffTeamImage
        : data.type === "device"
            ? DeviceTeamImage
            : data.type === "both"
                ? MixedTeamImage
                : throwError("unsupported type");
    return (_jsx(DenseLayout, Object.assign({ id: data.id, icon: _jsx("img", { src: image, alt: t(`grid.filter.types.${data.type}`) }), title: data.name, info: [data.manager, data.description].filter((e) => !!e) }, otherProps)));
};
export default React.memo(TeamRenderer);

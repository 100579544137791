import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext } from "react";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { DefaultFormPage, FormField, GroupBox, } from "components-care";
import { IsInFormDialogContext } from "components-care/dist/backend-components/Form/FormDialog";
import BoxGrid from "../../../../../components/BoxGrid";
import BasicPageLayout from "../../../../../components/BasicPageLayout";
import DeviceTypeCatalogView from "./DeviceTypeCatalogView";
const DeviceTypeForm = (formProps) => {
    const isEdit = !!formProps.id;
    const isInDialog = useContext(IsInFormDialogContext);
    const { t } = useTranslation("device-type");
    return (_jsx(DefaultFormPage, Object.assign({}, formProps, { children: _jsxs(BasicPageLayout, { title: !isInDialog && t("pagetitle"), children: [_jsx(BoxGrid, { item: true, xs: 12, md: isEdit && !isInDialog ? 9 : 12, children: _jsxs(Grid, { container: true, spacing: 2, children: [_jsx(DeviceTypeCatalogView, {}), _jsxs(Grid, { item: true, xs: 12, md: 6, container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "parent_id" }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "title_labels" }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "description_labels" }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "device_tag_ids" }) })] })] }) }), isEdit && !isInDialog && (_jsx(BoxGrid, { item: true, xs: 12, md: 3, children: _jsx(GroupBox, { label: t("information-box.label"), children: _jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "created_at" }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "created_by_user" }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "trust_level" }) })] }) }) }))] }) })));
};
export default React.memo(DeviceTypeForm);

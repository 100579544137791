import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import supportedLanguages from "./assets/data/supported-languages.json";
import resourcesToBackend from "i18next-resources-to-backend";
const isDev = process.env.NODE_ENV !== "production";
void i18n
    .use(resourcesToBackend((lang, namespace) => import(`./assets/locales/${lang.split("-")[0]}/${namespace}.json`)))
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
    fallbackLng: "en",
    supportedLngs: supportedLanguages,
    debug: isDev,
    ns: [
        "common",
        "portal",
        "tenant-select",
        "contact",
        "team",
        "department",
        "device-locations",
        "device-models",
        "briefed-employee-device",
        "device-type",
        "inventory",
        "event-issue",
        "home",
        "tenant",
        "menu",
        "team",
        "staff",
        "maintenance-type",
        "security-message",
        "overview-briefing",
        "briefing-devices",
        "employee-briefing",
        "service-intervals",
    ],
    defaultNS: "common",
    nonExplicitSupportedLngs: true,
    interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
    },
});
// cleanup local storage: we used to cache this stuff in localStorage, but now that we let
// webpack handle asset management the data is cached by the browser anyway
for (const key in localStorage) {
    if (key.startsWith("i18next_res_")) {
        delete localStorage[key];
    }
}
export default i18n;

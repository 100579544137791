import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { makeStyles } from "tss-react/mui";
import { combineClassNames, useFormContext } from "components-care";
import { useTranslation } from "react-i18next";
import { PageHeaderTypographyNew } from "../../../../../components/CustomTypography";
const useStyles = makeStyles()((theme) => ({
    root: {
        display: "inline-block",
        borderRadius: theme.shape.borderRadius,
        padding: `0 ${theme.spacing(2)}`,
    },
    smallLabel: {
        fontSize: "1.5rem",
    },
    status_has_account: {
        backgroundColor: theme.palette.success.main,
        color: theme.palette.success.contrastText,
    },
    status_no_account: {
        backgroundColor: theme.palette.getContrastText(theme.palette.background.paper),
        color: theme.palette.background.paper,
    },
}));
const ContactHasAccountStatus = (props) => {
    const { status, small } = props;
    const { classes } = useStyles();
    const { t } = useTranslation("contact");
    return (_jsx(PageHeaderTypographyNew, { component: "span", className: combineClassNames([
            classes.root,
            small && classes.smallLabel,
            classes[("status_" + status)],
        ]), children: t("login_status." + status) }));
};
export const ContactHasAccountStatusFromForm = (props) => {
    const { values } = useFormContext();
    // companies don't have accounts
    const isCompany = values.contact_type === "company";
    if (isCompany)
        return _jsx(React.Fragment, {});
    return (_jsx(ContactHasAccountStatus, Object.assign({ status: values.ident_user_id ? "has_account" : "no_account" }, props)));
};
export default React.memo(ContactHasAccountStatus);

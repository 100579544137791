var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Button, Dialog, DialogActions, DialogContent, Typography, } from "@mui/material";
import { Loader, useDialogContext } from "components-care";
import { useQuery } from "react-query";
import SamedisApiClient from "../../../components-care/connectors/SamedisApiClient";
import { marked } from "marked";
import { useTranslation } from "react-i18next";
import { ReactQueryRetryHandler } from "../../../constants";
import useAsyncMemo from "components-care/dist/utils/useAsyncMemo";
const getPolicyContent = (policy, lang) => {
    lang = lang.split("-")[0];
    const { content_translations } = policy.data.attributes;
    if (lang in content_translations)
        return content_translations[lang];
    return content_translations["en"];
};
export const TOS_DOCUMENT = "tos";
export const PRIVACY_DOCUMENT = "privacy";
export const acceptDocument = (document) => __awaiter(void 0, void 0, void 0, function* () {
    const policy = yield SamedisApiClient.get(`/api/v4/policy/${encodeURI(document)}`, null);
    yield SamedisApiClient.put(`/api/v4/policy/${policy.data.attributes.id}`, null, {});
});
const PolicyViewer = (props) => {
    const [, popDialog] = useDialogContext();
    const { i18n } = useTranslation();
    const { isLoading, data, error } = useQuery([props.document], () => SamedisApiClient.get(`/api/v4/policy/${encodeURI(props.document)}`, null), {
        retry: ReactQueryRetryHandler,
    });
    const content = useAsyncMemo(() => (!data ? null : marked(getPolicyContent(data, i18n.language))), [data, i18n.language]);
    return (_jsxs(Dialog, { open: true, onClose: popDialog, maxWidth: false, children: [_jsxs(DialogContent, { children: [isLoading && _jsx(Loader, {}), error && _jsx(Typography, { children: error.message }), content && _jsx("div", { dangerouslySetInnerHTML: { __html: content } })] }), _jsx(DialogActions, { children: _jsx(Button, { onClick: popDialog, children: "Close" }) })] }));
};
export default React.memo(PolicyViewer);

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useMemo } from "react";
import { IconButton, styled, Table, TableBody, TableCell, TableHead, TableRow, } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { RemoveCircleOutlined as RemoveIcon, AddCircleOutlined as AddIcon, } from "@mui/icons-material";
import { GroupBox, selectorLocalLoadHandler, TextFieldWithHelp, } from "components-care";
import { useTranslation } from "react-i18next";
import { SingleSelect } from "components-care/dist";
const useStyles = makeStyles()({
    addButton: { color: "rgba(0, 0, 0, 0.26)" },
    removeButton: { color: "rgba(0, 0, 0, 0.18)" },
    actionBtnColumn: { width: 64 },
});
const StyledTableHeaderCell = styled(TableCell)(({ theme }) => ({
    [theme.breakpoints.only("xs")]: {
        padding: 4,
    },
}));
const StyledTableHeaderCellName = styled(StyledTableHeaderCell)({
    width: "50%",
});
const StyledTableHeaderCellDesignation = styled(StyledTableHeaderCell)({
    width: "50%",
});
const StyledTableHeaderCellActions = styled(StyledTableHeaderCell)({
    minWidth: 80,
});
const StyledTableCell = styled(StyledTableHeaderCell)({
    borderBottom: "none",
});
export const hiddenRegulatories = ["emtec_code", "emtec_id"];
const defaultValues = [];
const DeviceModelRegulatoriesInput = (props) => {
    var _a, _b;
    const { label, onBlur, readOnly, name, isMdm } = props;
    const values = (_a = props.values) !== null && _a !== void 0 ? _a : defaultValues;
    const { t } = useTranslation("device-models");
    const { classes } = useStyles();
    const stubOnChange = useCallback(() => null, []);
    const onChange = (_b = props.onChange) !== null && _b !== void 0 ? _b : stubOnChange;
    const handleKeyChange = useCallback((id, value) => {
        onChange(values.map((entry, index) => {
            var _a;
            return index === id
                ? { label: (_a = value === null || value === void 0 ? void 0 : value.value) !== null && _a !== void 0 ? _a : "", value: entry.value }
                : entry;
        }));
    }, [onChange, values]);
    const handleValueChange = useCallback((evt) => {
        const value = evt.target.value;
        const id = parseInt(evt.target.name.split("-")[0]);
        onChange(values.map((entry, index) => index === id ? { label: entry.label, value } : entry));
    }, [onChange, values]);
    const deleteRow = useCallback((idx) => {
        onChange(values.filter((_, index) => index !== idx));
    }, [onChange, values]);
    const addRow = useCallback(() => {
        onChange([...values, { label: "", value: "" }]);
    }, [onChange, values]);
    const options = useMemo(() => [
        "ce",
        "udi_id",
        "eudamed_di",
        "eudamed_id",
        "eudamed_srn",
        "emdn_code",
        "emtec_code",
        "emtec_id",
    ].map((value) => ({
        value,
        label: t("regulatories.enum." + value),
        hidden: !isMdm && hiddenRegulatories.includes(value),
    })), [isMdm, t]);
    return (_jsx(GroupBox, { label: label, children: _jsxs(Table, { onBlur: onBlur, "data-name": name, children: [_jsx(TableHead, { children: _jsxs(TableRow, { children: [_jsx(StyledTableHeaderCellName, { children: t("regulatories.type.label") }), _jsx(StyledTableHeaderCellDesignation, { children: t("regulatories.type.value") }), !readOnly && (_jsx(StyledTableHeaderCellActions, { className: classes.actionBtnColumn }))] }) }), _jsxs(TableBody, { children: [values.map((entry, index) => {
                            var _a;
                            return (_jsxs(TableRow, { children: [_jsx(StyledTableCell, { children: _jsx(SingleSelect, { onLoad: selectorLocalLoadHandler(options), selected: !entry.label
                                                ? null
                                                : (_a = options.find((x) => x.value === entry.label)) !== null && _a !== void 0 ? _a : {
                                                    value: entry.label,
                                                    label: entry.label,
                                                }, disabled: readOnly, onSelect: (entry) => handleKeyChange(index, entry), error: !entry.label }) }), _jsx(StyledTableCell, { children: _jsx(TextFieldWithHelp, { value: entry.value, name: `${index}-value`, fullWidth: true, disabled: readOnly, onChange: handleValueChange, error: !entry.value }) }), !readOnly && (_jsx(StyledTableCell, { children: _jsx(IconButton, { onClick: () => deleteRow(index), className: classes.removeButton, size: "large", children: _jsx(RemoveIcon, {}) }) }))] }, index));
                        }), !readOnly && (_jsxs(TableRow, { children: [_jsx(StyledTableCell, {}), _jsx(StyledTableCell, {}), _jsx(StyledTableCell, { children: _jsx(IconButton, { onClick: addRow, className: classes.addButton, size: "large", children: _jsx(AddIcon, {}) }) })] }))] })] }) }));
};
export default React.memo(DeviceModelRegulatoriesInput);

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { marked } from "marked";
import { renderToStaticMarkup } from "react-dom/server";
import { Table, TableBody, TableCell, TableHead, TableRow, } from "@mui/material";
import "./Markdown.css";
import { getSubdomain } from "../utils";
import { VALID_SUBDOMAIN_PREFIXES } from "../constants";
// TODO: Using any Material-UI component seems to break the global styles for whatever reason.
//       I replaced everything except the Table components with CSS-only styles.
//       In case someone complains about broken fonts after viewing markdown content with tables
//       you have to replace the MUI table rendering with a CSS only version
const renderLinkCommon = (href, title, text) => {
    const isCurrentApp = href && href.startsWith(window.location.origin);
    const isApp = href &&
        [...VALID_SUBDOMAIN_PREFIXES, null].find((subdomain) => href.startsWith(`${window.location.protocol}//${getSubdomain(subdomain)}`)) !== undefined;
    return renderToStaticMarkup(href && isCurrentApp ? (_jsx("a", { href: href.substring(window.location.origin.length), title: title !== null && title !== void 0 ? title : undefined, className: "md-body", dangerouslySetInnerHTML: { __html: text } })) : (_jsx("a", { href: href !== null && href !== void 0 ? href : undefined, title: title !== null && title !== void 0 ? title : undefined, className: "md-body", dangerouslySetInnerHTML: { __html: text }, target: isApp ? undefined : "_blank", rel: "noreferrer" })));
};
const DefaultMarkedRenderer = {
    heading(_text, level, raw) {
        return renderToStaticMarkup(React.createElement(("h" + level), { className: "md-content-h" + level }, raw));
    },
    link(href, title, text) {
        return renderLinkCommon(href, title, text);
    },
    text(text) {
        return renderToStaticMarkup(_jsx("span", { className: "md-body", dangerouslySetInnerHTML: { __html: text } }));
    },
};
export class JournalRenderer extends marked.Renderer {
    link(href, title, text) {
        return renderLinkCommon(href, title, text);
    }
    strong(text) {
        return renderToStaticMarkup(_jsx("strong", { className: "md-body md-strong", dangerouslySetInnerHTML: { __html: text } }));
    }
    paragraph(text) {
        return renderToStaticMarkup(_jsx("p", { className: "md-body", dangerouslySetInnerHTML: { __html: text } }));
    }
    heading(_text, level, raw) {
        return renderToStaticMarkup(React.createElement(("h" + level), { className: "md-h" + level }, raw));
    }
    table(header, body) {
        return renderToStaticMarkup(_jsxs(Table, { children: [_jsx(TableHead, { dangerouslySetInnerHTML: { __html: header } }), _jsx(TableBody, { dangerouslySetInnerHTML: { __html: body } })] }));
    }
    tablerow(content) {
        return renderToStaticMarkup(_jsx(TableRow, { dangerouslySetInnerHTML: { __html: content } }));
    }
    tablecell(content, flags) {
        var _a;
        return renderToStaticMarkup(_jsx(TableCell, { variant: flags.header ? "head" : "body", dangerouslySetInnerHTML: { __html: content }, align: (_a = flags.align) !== null && _a !== void 0 ? _a : undefined }));
    }
    list(body, ordered, start) {
        return renderToStaticMarkup(ordered ? (_jsx("ol", { className: "md-list md-ol", start: start, dangerouslySetInnerHTML: { __html: body } })) : (_jsx("ul", { className: "md-list md-ul", dangerouslySetInnerHTML: { __html: body } })));
    }
    listitem(text, task, checked) {
        return renderToStaticMarkup(_jsxs("li", { className: "md-li", children: [task && (_jsx("div", { className: "md-li-task-box", children: checked && (_jsx("svg", { viewBox: "-3.5 -4.5 24 24", className: "md-li-task-box-checked", children: _jsx("polyline", { id: "check", fill: "transparent", stroke: "currentColor", strokeWidth: "3.5", points: "1 7 6 12 16.5 1.5" }) })) })), _jsx("span", { dangerouslySetInnerHTML: { __html: text } })] }));
    }
    image(href, title, text) {
        return renderToStaticMarkup(_jsx("img", { src: href !== null && href !== void 0 ? href : undefined, title: title !== null && title !== void 0 ? title : undefined, alt: text, className: "md-img" }));
    }
    blockquote(quote) {
        return renderToStaticMarkup(_jsx("blockquote", { dangerouslySetInnerHTML: { __html: quote }, className: "md-blockquote" }));
    }
    code(code, language) {
        return renderToStaticMarkup(_jsx("code", { className: "md-code md-code-block md-code-lang-" + language, children: code }));
    }
    codespan(code) {
        return renderToStaticMarkup(_jsx("code", { className: "md-code", children: code }));
    }
}
export default DefaultMarkedRenderer;

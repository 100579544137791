import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useContext } from "react";
import { ActionButton, BackActionButton, FormButtons, useFormContext, } from "components-care";
import { useTranslation } from "react-i18next";
import { IsInFormDialogContext } from "components-care/dist/backend-components/Form/FormDialog";
const EnterpriseRecordCreateTenantSelectFormPageButtons = (props) => {
    const { isSubmitting, customProps, handleNext } = props;
    const goBack = customProps === null || customProps === void 0 ? void 0 : customProps.goBack;
    const hasCustomCloseHandler = customProps === null || customProps === void 0 ? void 0 : customProps.hasCustomSubmitHandler;
    const isInDialog = useContext(IsInFormDialogContext);
    const { t } = useTranslation("common");
    const hasTenantId = !!useFormContext().values.tenant_id;
    const handleBack = useCallback(() => goBack && goBack(), [goBack]);
    return (_jsxs(FormButtons, { children: [_jsx(ActionButton, { disabled: isSubmitting || !hasTenantId, onClick: handleNext, children: t("buttons.next") }), goBack && !(isInDialog && hasCustomCloseHandler) && (_jsx(BackActionButton, { disabled: isSubmitting, onClick: handleBack, children: t("buttons.back") }))] }));
};
export default React.memo(EnterpriseRecordCreateTenantSelectFormPageButtons);

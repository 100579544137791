var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ComponentsCareI18n, Model, ModelDataTypeBackendMultiSelectRenderer, ModelDataTypeBooleanCheckboxRendererCC, ModelDataTypeDateNullableRendererCC, ModelDataTypeEnumSelectRenderer, ModelDataTypeStringRendererCC, ModelVisibilityDisabled, ModelVisibilityDisabledReadOnly, ModelVisibilityEdit, ModelVisibilityEditReadOnly, ModelVisibilityEditRequired, ModelVisibilityGridView, ModelVisibilityGridViewHidden, validatePresence, } from "components-care";
import BackendConnector from "../connectors/BackendConnector";
import { useTenantIdOpt } from "../../pages/components/TenantContext";
import { ID_FIELD_DEF } from "../../constants";
import DeviceModelsModel, { DeviceModelsModelSelectorSort, DeviceModelsModelToSelectorData, } from "./DeviceModelsModel";
import { getLruConfig, handleBackendMultiSelectLoadError } from "../../utils";
import PickDeviceModelsButton from "../../pages/TenantSpecific/components/Dialogs/PickDeviceModelsButton";
import FileRenderer from "./Types/Renderers/FileRenderer";
import { DeviceModelCommunityUploadCategories } from "../../pages/TenantSpecific/components/Dialogs/DeviceModelViewer/DeviceModelViewerCommunityUploads";
import { MdmContext } from "../../pages/TenantSpecific/Admin/MdmTypes";
import TypeFacilityContentResource from "../types/TypeFacilityContentResource";
export const FacilityContentContentTypeFieldType = (t, filterFn) => new ModelDataTypeEnumSelectRenderer(["text", "file", "link"].filter(filterFn).map((value) => ({
    value,
    getLabel: () => t("facility-content:content_type.enum." + value),
})), {
    placeholder: t("facility-content:content_type.placeholder"),
});
const FacilityContentModel = (params) => new Model("facility-content-model", {
    title: {
        type: new ModelDataTypeStringRendererCC({
            placeholder: params.t("facility-content:title.placeholder"),
        }),
        getLabel: () => params.t("facility-content:title.field"),
        visibility: {
            overview: ModelVisibilityGridView,
            create: ModelVisibilityEditRequired,
            edit: ModelVisibilityEditRequired,
        },
        customData: null,
        filterable: true,
        sortable: true,
        columnWidth: [80, 420, 260],
        validate: validatePresence,
    },
    content: {
        type: new ModelDataTypeStringRendererCC({
            multiline: true,
            rows: 5,
            placeholder: params.t("facility-content:content.placeholder"),
        }),
        getLabel: () => params.t("facility-content:content.field"),
        visibility: {
            overview: ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityEdit,
            edit: ModelVisibilityEdit,
        },
        customData: null,
        filterable: true,
        sortable: true,
        columnWidth: [80, 640, 320],
    },
    url: {
        type: new ModelDataTypeStringRendererCC({
            placeholder: params.t("facility-content:url.placeholder"),
        }),
        getLabel: () => params.t("facility-content:url.field"),
        visibility: {
            overview: ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityEdit,
            edit: ModelVisibilityEdit,
        },
        customData: null,
        filterable: true,
        sortable: true,
        columnWidth: [80, 260, 130],
    },
    name: {
        // file name (only for content_type file)
        type: new ModelDataTypeStringRendererCC({
            placeholder: params.t("facility-content:name.placeholder"),
        }),
        getLabel: () => params.t("facility-content:name.field"),
        visibility: {
            overview: ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityEdit,
            edit: ModelVisibilityDisabledReadOnly,
        },
        customData: null,
        filterable: true,
        sortable: true,
        columnWidth: [80, 260, 130],
    },
    content_type: {
        type: FacilityContentContentTypeFieldType(params.t, () => true),
        getLabel: () => params.t("facility-content:content_type.field"),
        visibility: {
            overview: ModelVisibilityGridView,
            create: ModelVisibilityEditRequired,
            edit: ModelVisibilityEditReadOnly,
        },
        customData: null,
        filterable: true,
        sortable: true,
        columnWidth: [80, 260, 130],
        validate: validatePresence,
    },
    resource: {
        type: new TypeFacilityContentResource(),
        getLabel: () => "",
        visibility: {
            overview: ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityDisabledReadOnly,
            edit: ModelVisibilityDisabledReadOnly,
        },
        customData: null,
    },
    thumbnail_cached: {
        type: new ModelDataTypeStringRendererCC(),
        getLabel: () => "",
        visibility: {
            overview: ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityDisabledReadOnly,
            edit: ModelVisibilityDisabledReadOnly,
        },
        customData: null,
    },
    is_public: {
        type: new ModelDataTypeBooleanCheckboxRendererCC(),
        getLabel: () => "",
        visibility: {
            overview: ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityDisabledReadOnly,
            edit: ModelVisibilityDisabledReadOnly,
        },
        customData: null,
    },
    category: {
        type: new ModelDataTypeEnumSelectRenderer(DeviceModelCommunityUploadCategories.map((value) => ({
            value,
            getLabel: () => params.t("device-models:form.tabs." + value),
        })), {
            placeholder: params.t("facility-content:category.placeholder"),
        }),
        getLabel: () => params.t("facility-content:category.field"),
        visibility: {
            overview: ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityEdit,
            edit: ModelVisibilityEdit,
        },
        onChange: (value, _model, setFieldValue, getFieldValue) => {
            const textContentAllowed = value === "training";
            if (!textContentAllowed && getFieldValue("content_type") === "text")
                setFieldValue("content_type", "");
            return value;
        },
        customData: null,
        filterable: true,
        sortable: true,
        columnWidth: [80, 260, 130],
    },
    device_model_ids: {
        type: new ModelDataTypeBackendMultiSelectRenderer({
            modelToSelectorData: DeviceModelsModelToSelectorData,
            placeholder: params.t("facility-content:device_model_ids.placeholder"),
            lru: getLruConfig(params.tenantId, "facility-content.device-model-ids"),
            sort: DeviceModelsModelSelectorSort(null),
            onLoadError: handleBackendMultiSelectLoadError,
            endAdornment: (_jsx(PickDeviceModelsButton, { target: "tenant", scope: "public_and_tenant", moduleName: "facility-content-device-selector", title: params.t("facility-content:device_model_ids.dialog-title") })),
            modelFetch: DeviceModelsModel({
                tenantId: params.tenantId,
                t: params.t,
                ccT: ComponentsCareI18n.t.bind(ComponentsCareI18n),
                target: "tenant",
                extraParams: {
                    "filter[scope]": "public_and_tenant",
                },
            }),
            enableIcons: true,
            iconSize: 24,
        }),
        getRelationModel: () => DeviceModelsModel({
            tenantId: params.tenantId,
            t: params.t,
            ccT: ComponentsCareI18n.t.bind(ComponentsCareI18n),
            target: "tenant",
            extraParams: {
                "filter[scope]": "public_and_tenant",
                "filter[published]": true,
            },
        }),
        getLabel: () => params.t("facility-content:device_model_ids.field"),
        visibility: {
            overview: ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityEdit,
            edit: ModelVisibilityEdit,
        },
        customData: null,
    },
    document: {
        type: new FileRenderer({
            previewImages: false,
            maxFiles: 1,
            allowDuplicates: false,
            previewSize: 24,
        }, "name"),
        getLabel: () => params.t("facility-content:document.field"),
        visibility: {
            overview: ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityEdit,
            edit: ModelVisibilityEdit,
        },
        customData: null,
    },
    created_by_user: {
        type: new ModelDataTypeStringRendererCC(),
        getLabel: () => params.t("common:audit.created-by"),
        visibility: {
            overview: ModelVisibilityGridViewHidden,
            create: ModelVisibilityDisabled,
            edit: ModelVisibilityDisabled,
        },
        customData: null,
        filterable: true,
        sortable: true,
        columnWidth: [80, 320, 160],
    },
    created_at: {
        type: new ModelDataTypeDateNullableRendererCC(),
        getLabel: () => params.t("common:audit.created-at"),
        visibility: {
            overview: ModelVisibilityGridViewHidden,
            create: ModelVisibilityDisabled,
            edit: ModelVisibilityDisabled,
        },
        customData: null,
        filterable: true,
        sortable: true,
        columnWidth: [160, 160, 160],
    },
    updated_by_user: {
        type: new ModelDataTypeStringRendererCC(),
        getLabel: () => params.t("common:audit.updated-by"),
        visibility: {
            overview: ModelVisibilityGridViewHidden,
            create: ModelVisibilityDisabled,
            edit: ModelVisibilityDisabled,
        },
        customData: null,
        filterable: true,
        sortable: true,
        columnWidth: [80, 320, 160],
    },
    updated_at: {
        type: new ModelDataTypeDateNullableRendererCC(),
        getLabel: () => params.t("common:audit.updated-at"),
        visibility: {
            overview: ModelVisibilityGridViewHidden,
            create: ModelVisibilityDisabled,
            edit: ModelVisibilityDisabled,
        },
        customData: null,
        filterable: true,
        sortable: true,
        columnWidth: [160, 160, 160],
    },
    id: Object.assign(Object.assign({}, ID_FIELD_DEF(params.t)), { customData: null }),
}, new BackendConnector(params.tenantId
    ? params.target === "tenant"
        ? `v4/tenants/${params.tenantId}/facility_contents`
        : `v4/tenants/${params.tenantId}/mdm/facility_contents`
    : "v4/my/facility_contents", "data", {
    deserializeOverride: (_a) => {
        var { document_url } = _a, record = __rest(_a, ["document_url"]);
        return (Object.assign(Object.assign({}, record), { document: document_url }));
    },
}), [], {
    cacheKeysIndex: [params.tenantId, params.target],
});
export const useFacilityContentTranslations = () => useTranslation(["facility-content", "device-models", "common"]);
export const useFacilityContentModel = (params) => {
    const tenantId = useTenantIdOpt();
    const { t } = useFacilityContentTranslations();
    const target = useContext(MdmContext);
    return useMemo(() => FacilityContentModel(Object.assign({ tenantId, t, target }, params)), [tenantId, t, target, params]);
};
export default FacilityContentModel;

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useMemo } from "react";
import DeviceModelRenderer from "../Renderers/DeviceModelRenderer";
import SearchAndBrowse from "../index";
import DeviceModelsModel, { DeviceModelsModelSelectorSort, useDeviceModelsModelCCTranslations, useDeviceModelsModelTranslations, } from "../../../../../components-care/models/DeviceModelsModel";
import { multiSelectFilterEntryFromModel } from "../FilterEntryMultiSelect";
import VendorModel, { VendorModelSelectorSort, VendorModelToSelectorData, } from "../../../../../components-care/models/VendorModel";
import { useTenantIdOpt } from "../../../../components/TenantContext";
import DepartmentModel, { DepartmentModelSelectorSort, DepartmentModelToSelectorData, useDepartmentModelTranslations, } from "../../../../../components-care/models/DepartmentModel";
import DeviceLocationModel, { DeviceLocationModelSelectorSort, DeviceLocationModelToSelectorData, useDeviceLocationModelTranslations, } from "../../../../../components-care/models/DeviceLocationModel";
import TeamModel, { TeamModelSelectorSort, TeamModelToSelectorData, } from "../../../../../components-care/models/TeamModel";
import ContactModel, { ContactModelSelectorSort, ContactModelToSelectorData, } from "../../../../../components-care/models/ContactModel";
import DeviceTagModel, { DeviceTagModelToSelectorData, getDeviceTagModelSort, useDeviceTagModelTranslations, } from "../../../../../components-care/models/DeviceTagModel";
import { useRegulatoryDomain } from "../../../../../utils/useRegulatoryDomain";
export const useDeviceModelFilters = (target, enterpriseClientId, scope) => {
    const tenantId = useTenantIdOpt();
    const { t: deviceModelsT } = useDeviceModelsModelTranslations();
    const { t: deviceTagT } = useDeviceTagModelTranslations();
    return useMemo(() => ({
        manufacturers: Object.assign({ variant: "multi-select", title: deviceModelsT("device-models:grid.filter.vendor_id.label") }, (tenantId
            ? multiSelectFilterEntryFromModel(ContactModel({
                tenantId,
                target,
                t: deviceModelsT,
                ccT: deviceModelsT,
                extraParams: {
                    "filter[contact_types]": ["company"],
                    "filter[scope]": "public_and_tenant",
                },
                ofCategory: "manufacturer",
                enterpriseClientId,
            }), ContactModelSelectorSort, ContactModelToSelectorData)
            : multiSelectFilterEntryFromModel(VendorModel(tenantId, scope === "public_and_tenant"
                ? { "filter[with_public]": true }
                : undefined, ["tenant", "public_and_tenant"].includes(scope !== null && scope !== void 0 ? scope : (target === "tenant" ? "tenant" : "public"))), VendorModelSelectorSort, VendorModelToSelectorData))),
        area_of_application: Object.assign({ variant: "multi-select", title: deviceModelsT("device-models:grid.filter.tag.area_of_application") }, multiSelectFilterEntryFromModel(DeviceTagModel({
            tenantId,
            target,
            t: deviceTagT,
            filterTagType: "area_of_application",
        }), getDeviceTagModelSort(), DeviceTagModelToSelectorData)),
        purpose: Object.assign({ variant: "multi-select", title: deviceModelsT("device-models:grid.filter.tag.purpose") }, multiSelectFilterEntryFromModel(DeviceTagModel({
            tenantId,
            target,
            t: deviceTagT,
            filterTagType: "purpose",
        }), getDeviceTagModelSort(), DeviceTagModelToSelectorData)),
        device_type: Object.assign({ variant: "multi-select", title: deviceModelsT("device-models:grid.filter.tag.device_type") }, multiSelectFilterEntryFromModel(DeviceTagModel({
            tenantId,
            target,
            t: deviceTagT,
            filterTagType: "device_type",
        }), getDeviceTagModelSort(), DeviceTagModelToSelectorData)),
        technical_property: Object.assign({ variant: "multi-select", title: deviceModelsT("device-models:grid.filter.tag.technical_property") }, multiSelectFilterEntryFromModel(DeviceTagModel({
            tenantId,
            target,
            t: deviceTagT,
            filterTagType: "technical_property",
        }), getDeviceTagModelSort(), DeviceTagModelToSelectorData)),
    }), [deviceModelsT, deviceTagT, target, scope, tenantId, enterpriseClientId]);
};
const DeviceModelSearch = (props) => {
    const { target, scope, disableInventory, enableTenantFilters, enterpriseClientId } = props, other = __rest(props, ["target", "scope", "disableInventory", "enableTenantFilters", "enterpriseClientId"]);
    const tenantId = useTenantIdOpt();
    const regDomain = useRegulatoryDomain();
    const { t: deviceModelsT, i18n } = useDeviceModelsModelTranslations();
    const { t: deviceModelsCCT } = useDeviceModelsModelCCTranslations();
    const { t: departmentModelT } = useDepartmentModelTranslations();
    const { t: deviceLocationModelT } = useDeviceLocationModelTranslations();
    const loadRecords = useCallback((search, filters, offset) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const deviceTagIds = [
            ...filters.area_of_application,
            ...filters.purpose,
            ...filters.device_type,
            ...filters.technical_property,
        ];
        const operatorOrdinance = filters.operator_ordinance;
        const deviceModelsModel = DeviceModelsModel({
            t: deviceModelsT,
            ccT: deviceModelsCCT,
            tenantId,
            target,
            enterpriseClientId,
            forceHasInventory: filters.has_inventory,
            extraParams: {
                "filter[scope]": scope,
                "filter[manufacturer_ids]": filters.manufacturers.join(","),
                "filter[department_ids]": filters.departments.join(","),
                "filter[device_location_ids]": filters.device_locations.join(","),
                "filter[team_ids]": filters.teams.join(","),
                "filter[tagged]": deviceTagIds.join(","),
                "filter[published]": true,
                "filter[annex]": operatorOrdinance || undefined,
            },
        });
        const [data, meta] = yield deviceModelsModel.index2({
            offset: offset,
            rows: 25,
            sort: DeviceModelsModelSelectorSort(i18n.language),
            quickFilter: search,
        });
        let dataCast = data;
        if (disableInventory) {
            // patch inventory_count to zero so inventory tag does not show
            dataCast = dataCast.map((data) => (Object.assign(Object.assign({}, data), { inventory_count: 0 })));
        }
        return {
            data: dataCast,
            total: (_a = meta.filteredRows) !== null && _a !== void 0 ? _a : meta.totalRows,
        };
    }), [
        deviceModelsT,
        deviceModelsCCT,
        tenantId,
        target,
        enterpriseClientId,
        scope,
        i18n.language,
        disableInventory,
    ]);
    const baseFilters = useDeviceModelFilters(target, enterpriseClientId, scope);
    const filters = useMemo(() => (Object.assign(Object.assign({ has_inventory: {
            variant: "switch",
            title: deviceModelsT("device-models:grid.filter.has_inventory.label"),
            hidden: disableInventory,
            persistType: "user",
            persistKeys: ["module"],
        }, operator_ordinance: {
            variant: "select",
            title: deviceModelsT("device-models:grid.filter.operator_ordinance.label"),
            options: ["", "annex_1", "annex_2", "annex_1_2"].map((value) => ({
                value,
                label: deviceModelsT("device-models:grid.filter.operator_ordinance.values." + value),
            })),
            hidden: regDomain !== "DE",
        } }, baseFilters), { departments: Object.assign(Object.assign({ variant: "multi-select", title: deviceModelsT("device-models:grid.filter.departments.label") }, multiSelectFilterEntryFromModel(DepartmentModel({
            tenantId,
            t: departmentModelT,
            enterpriseClientId,
        }), DepartmentModelSelectorSort, DepartmentModelToSelectorData)), { hidden: !enableTenantFilters }), device_locations: Object.assign(Object.assign({ variant: "multi-select", title: deviceModelsT("device-models:grid.filter.device_locations.label") }, multiSelectFilterEntryFromModel(DeviceLocationModel({
            tenantId,
            t: deviceLocationModelT,
            enterpriseClientId,
        }), DeviceLocationModelSelectorSort, DeviceLocationModelToSelectorData)), { hidden: !enableTenantFilters }), teams: Object.assign(Object.assign({ variant: "multi-select", title: deviceModelsT("device-models:grid.filter.teams.label") }, multiSelectFilterEntryFromModel(TeamModel({
            tenantId,
            t: deviceLocationModelT,
            enterpriseClientId,
            extraParams: {
                "filter[type]": "device",
            },
        }), TeamModelSelectorSort, TeamModelToSelectorData)), { hidden: !enableTenantFilters }) })), [
        baseFilters,
        departmentModelT,
        deviceLocationModelT,
        deviceModelsT,
        disableInventory,
        enableTenantFilters,
        enterpriseClientId,
        tenantId,
        regDomain,
    ]);
    return (_jsx(SearchAndBrowse, Object.assign({}, other, { loadRecords: loadRecords, filters: filters, renderer: DeviceModelRenderer, rendererContext: undefined, controlName: "DeviceModelSearch" })));
};
export default React.memo(DeviceModelSearch);

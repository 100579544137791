import { jsx as _jsx } from "react/jsx-runtime";
import React, { useContext } from "react";
import { useDeviceTypeModel } from "../../../components-care/models/DeviceTypeModel";
import SamedisCrud from "../../../components-care/SamedisCrud";
import { useTenantId } from "../../components/TenantContext";
import DeviceTypeForm from "./components/DeviceType/DeviceTypeForm";
export const DeviceTypeCrudContext = React.createContext(undefined);
export const useDeviceTypeCrudContext = () => {
    const ctx = useContext(DeviceTypeCrudContext);
    if (!ctx)
        throw new Error("ctx missing");
    return ctx;
};
const DeviceTypeCRUD = (props) => {
    const { parent } = props;
    const tenantId = useTenantId();
    const model = useDeviceTypeModel({
        tenantId,
        target: props.mode,
        extraParams: props.mode === "tenant" ? { "filter[scope]": "tenant" } : undefined,
    });
    return (_jsx(DeviceTypeCrudContext.Provider, { value: { parent: props.parent, mode: props.mode }, children: _jsx(SamedisCrud, Object.assign({ model: model, deletePermission: [
                "type-catalogs.deleter",
                "type-catalogs.tenant-deleter",
            ], readPermission: ["type-catalogs.reader", "type-catalogs.tenant-reader"], editPermission: ["type-catalogs.writer", "type-catalogs.tenant-writer"], newPermission: ["type-catalogs.writer", "type-catalogs.tenant-writer"], exportPermission: false, gridProps: {
                defaultSort: [{ field: "title_with_path", direction: 1 }],
            } }, props, { formProps: Object.assign(Object.assign({}, props.formProps), { initialRecord: parent
                    ? {
                        parent_id: parent[0],
                        parent_ids: parent[1],
                    }
                    : undefined }), children: DeviceTypeForm })) }));
};
export default React.memo(DeviceTypeCRUD);

import { useTenantInfo } from "../pages/components/TenantContext";
import EnterpriseContext from "../pages/Enterprise/components/EnterpriseContext";
import { useContext } from "react";
const useSyncConfig = () => {
    const tenant = useTenantInfo();
    if (!tenant)
        throw new Error("Tenant ID not set");
    const enterprise = useContext(EnterpriseContext);
    if (enterprise)
        throw new Error("No sync config in enterprise context");
    return tenant.sync_config;
};
export const useSyncConfigOpt = () => {
    const tenant = useTenantInfo();
    const enterprise = useContext(EnterpriseContext);
    if (enterprise)
        return null; // no sync config in enterprise context, need to handle in form itself
    return tenant === null || tenant === void 0 ? void 0 : tenant.sync_config;
};
export default useSyncConfig;

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useContext } from "react";
import { BaseSelectorContext, useDialogContext } from "components-care";
import { IconButton } from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import SearchAndSingleSelect from "../../../components/SearchAndBrowse/Instances/SearchAndSingleSelect";
import { useButtonEndAdornmentStyles } from "../../../components/Dialogs/PickDeviceModelButton";
import InventorySearch from "../../../components/SearchAndBrowse/Instances/InventorySearch";
import reactNodeToElement from "../../../../../utils/reactNodeToElement";
const BriefingPickInventoryButton = (props) => {
    const { onSelect, disabled, title } = props, other = __rest(props, ["onSelect", "disabled", "title"]);
    const { classes } = useButtonEndAdornmentStyles();
    const selectorContext = useContext(BaseSelectorContext);
    const [pushDialog, popDialog] = useDialogContext();
    const handleSelect = useCallback((selected) => {
        if (selected) {
            onSelect(selected);
            if (selectorContext)
                selectorContext.addToLru(selected);
            popDialog();
        }
    }, [onSelect, selectorContext, popDialog]);
    const openDialog = useCallback(() => pushDialog(_jsx(SearchAndSingleSelect, { instance: InventorySearch, onSelected: handleSelect, props: other, title: title })), [pushDialog, handleSelect, other, title]);
    return reactNodeToElement(!disabled && (_jsx(IconButton, { onClick: openDialog, className: classes.button, disabled: disabled, size: "large", color: "primary", children: _jsx(SearchIcon, {}) })));
};
export default React.memo(BriefingPickInventoryButton);

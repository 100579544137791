import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback } from "react";
import { Grid } from "@mui/material";
import { Form, DefaultErrorComponent as ErrorComponent, throwError, useNavigate, } from "components-care";
import { useTenantModel } from "../../../components-care/models/TenantModel";
import FacilityDataForm from "../../TenantSpecific/Facility/components/FacilityDataForm";
import { useAuthProviderReset } from "../../components/AuthProvider";
const FacilityCreate = () => {
    const navigate = useNavigate();
    const resetAuthContext = useAuthProviderReset();
    const model = useTenantModel();
    const goToSelection = useCallback(() => {
        navigate("/");
    }, [navigate]);
    const goToTenant = useCallback((id) => {
        resetAuthContext();
        navigate(`/tenant/${id}`);
    }, [navigate, resetAuthContext]);
    return (_jsx(Grid, { container: true, spacing: 2, children: _jsx(Grid, { item: true, xs: 12, children: _jsx(Form, { model: model, id: null, errorComponent: ErrorComponent, onSubmit: (data) => goToTenant(data.id), customProps: {
                    goBack: goToSelection,
                    open: () => throwError("open not implemented"),
                    hasCustomSubmitHandler: true,
                }, onlyValidateMounted: true, children: FacilityDataForm }) }) }));
};
export default React.memo(FacilityCreate);

import { ModelVisibilityGridViewHidden, ModelVisibilityHidden, } from "components-care";
import RendererObjectId from "./components-care/types/renderers/RendererObjectId";
export const ReactQueryRetryHandler = (count, err) => err.name === "NetworkError" && count < 3;
export const ToDateLocaleStringOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
};
export const SIG_CELL_WIDTH = 240;
export const SIG_CELL_HEIGHT = 140;
export const IS_DEV = process.env.NODE_ENV === "development";
export const ENABLE_OFFLINE_MODE = false;
export const StripePublishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
export const PlaceholderDeviceModelId = "63e399b904f218000e738670";
// ID to supply in filters if filter can be empty (empty value1) and thus ignored
export const INVALID_ID = "0123456789abcdef01234567";
export const GRID_FILTER_BREAKPOINTS_SINGLE_ENTRY = {
    xs: 12,
    sm: 12,
    md: 9,
    lg: 9,
    xl: 6,
};
export const GRID_FILTER_BREAKPOINTS_TWO_ENTRIES = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 6,
    xl: 6,
};
export const VAT_REGEX = /^((AT)U[0-9]{8}|(BE)0[0-9]{9}|(BG)[0-9]{9,10}|(CY)[0-9]{8}L|(CZ)[0-9]{8,10}|(DE)[0-9]{9}|(DK)[0-9]{8}|(EE)[0-9]{9}|(EL|GR)[0-9]{9}|(ES)[0-9A-Z][0-9]{7}[0-9A-Z]|(FI)[0-9]{8}|(FR)[0-9A-Z]{2}[0-9]{9}|(XI)([0-9]{9}([0-9]{3})|[A-Z]{2}[0-9]{3})|(HU)[0-9]{8}|(IE)[0-9]S[0-9]{5}L|(IT)[0-9]{11}|(LT)([0-9]{9}|[0-9]{12})|(LU)[0-9]{8}|(LV)[0-9]{11}|(MT)[0-9]{8}|(NL)[0-9]{9}B[0-9]{2}|(PL)[0-9]{10}|(PT)[0-9]{9}|(RO)[0-9]{2,10}|(SE)[0-9]{12}|(SI)[0-9]{8}|(SK)[0-9]{10})$/i;
export const VAT_REGEX_COUNTRIES = [
    "AT",
    "BE",
    "BG",
    "CY",
    "CZ",
    "DE",
    "DK",
    "EE",
    "EL",
    "ES",
    "FI",
    "FR",
    "HU",
    "IE",
    "IT",
    "LT",
    "LU",
    "LV",
    "MT",
    "NL",
    "PL",
    "PT",
    "RO",
    "SE",
    "SI",
    "SK",
];
export const CURRENCY_STORAGE_KEY = "currency";
export const MARKDOWN_PURIFY_CONFIG = {
    USE_PROFILES: { html: true, svg: true },
    FORCE_BODY: true,
    FORBID_TAGS: ["style", "form", "button", "input", "textarea"],
    FORBID_ATTR: ["style"],
};
// add all subdomains to VALID_SUBDOMAIN_PREFIXES
export const BI_APP_DOMAIN_PREFIX = "bi";
export const MDM_APP_DOMAIN_PREFIX = "mdm";
export const MY_APP_DOMAIN_PREFIX = "my";
export const ENTERPRISE_APP_DOMAIN_PREFIX = "enterprise";
export const SYNC_APP_DOMAIN_PREFIX = "sync";
export const CUBEAPI_DOMAIN_PREFIX = "cube";
export const GLOBAL_MODE_APPS = ["my"];
export const VALID_SUBDOMAIN_PREFIXES = [
    BI_APP_DOMAIN_PREFIX,
    MDM_APP_DOMAIN_PREFIX,
    MY_APP_DOMAIN_PREFIX,
    SYNC_APP_DOMAIN_PREFIX,
    ENTERPRISE_APP_DOMAIN_PREFIX,
    CUBEAPI_DOMAIN_PREFIX,
];
export const ID_FIELD_DEF = (t) => ({
    type: new RendererObjectId(),
    getLabel: () => t("common:id"),
    visibility: {
        overview: ModelVisibilityGridViewHidden,
        create: ModelVisibilityHidden,
        edit: ModelVisibilityHidden,
    },
    filterable: true,
    sortable: true,
    columnWidth: [220, 220, 220],
});

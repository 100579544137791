import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { Info as InfoIcon } from "@mui/icons-material";
import { customColors } from "../theme";
import { styled } from "@mui/material";
const useStyles = makeStyles()((theme) => ({
    icon: {
        color: theme.palette.background.paper,
        width: 56,
        height: 56,
    },
}));
const SamedisInfoBoxRoot = styled("div", {
    name: "SamedisInfoBox",
    slot: "root",
})(({ theme }) => ({
    padding: theme.spacing(2),
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
    background: `linear-gradient(270deg, ${customColors.turquoise} 0%, ${theme.palette.primary.main} 100%)`,
    height: "100%",
    width: "100%",
}));
const SamedisInfoBoxList = styled("ul", {
    name: "SamedisInfoBox",
    slot: "root",
})(({ theme }) => ({
    marginTop: theme.spacing(1),
    marginBottom: 0,
    paddingLeft: theme.spacing(2),
}));
const SamedisInfoBoxParagraph = styled(Typography, {
    name: "SamedisInfoBox",
    slot: "paragraph",
})({
    whiteSpace: "pre-wrap",
});
const SamedisInfoBox = (props) => {
    const { title, icon, content, contentType } = props;
    const { classes } = useStyles();
    const Icon = icon !== null && icon !== void 0 ? icon : InfoIcon;
    return (_jsx(SamedisInfoBoxRoot, { children: _jsxs(Grid, { container: true, spacing: 2, children: [_jsxs(Grid, { item: true, xs: true, children: [title && [
                            _jsx(SamedisInfoBoxParagraph, { component: "p", variant: "subtitle1", children: title }, "title"),
                            _jsx("br", {}, "title-br1"),
                        ], contentType === "list" && (_jsx(SamedisInfoBoxList, { children: content.map((label, idx) => (_jsx(Typography, { component: "li", variant: "subtitle1", children: label }, idx.toString(16)))) })), contentType === "paragraphs" &&
                            content.map((label, idx, arr) => [
                                _jsx(SamedisInfoBoxParagraph, { component: "p", variant: "subtitle1", children: label }, idx.toString(16) + "-text"),
                                idx !== arr.length - 1 && _jsx("br", {}, idx.toString(16) + "-br"),
                            ])] }), _jsx(Grid, { item: true, children: _jsx(Icon, { className: classes.icon }) })] }) }));
};
export default React.memo(SamedisInfoBox);

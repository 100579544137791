var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useContext } from "react";
import { BaseSelectorContext, useDialogContext, useFormFieldContext, } from "components-care";
import DeviceTagSearch from "../SearchAndBrowse/Instances/DeviceTagSearch";
import { useButtonEndAdornmentStyles } from "./PickDeviceModelButton";
import SearchAndMultiSelect from "../SearchAndBrowse/Instances/SearchAndMultiSelect";
import { IconButton } from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import reactNodeToElement from "../../../../utils/reactNodeToElement";
const PickDeviceTagsButton = (props) => {
    const { title } = props, otherProps = __rest(props, ["title"]);
    const { visibility, value, field, handleChange } = useFormFieldContext();
    const { classes } = useButtonEndAdornmentStyles();
    const selectorContext = useContext(BaseSelectorContext);
    const [pushDialog] = useDialogContext();
    const handleSelect = useCallback((selected) => {
        handleChange(field, selected);
        if (selectorContext)
            selectorContext.addToLru(...selected);
    }, [field, handleChange, selectorContext]);
    const openDialog = useCallback(() => pushDialog(_jsx(SearchAndMultiSelect, { instance: DeviceTagSearch, initialSelection: value, onSelected: handleSelect, props: otherProps, title: title })), [pushDialog, value, handleSelect, otherProps, title]);
    return reactNodeToElement(!visibility.readOnly && (_jsx(IconButton, { onClick: openDialog, className: classes.button, disabled: visibility.readOnly, size: "large", color: "primary", children: _jsx(SearchIcon, {}) })));
};
export default React.memo(PickDeviceTagsButton);

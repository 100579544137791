import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { DefaultFormPage, FormField, FullFormDialog, GroupBox, hasPermission, useDialogContext, useFormContext, usePermissionContext, Link, } from "components-care";
import { Divider, Grid, Link as MuiLink } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { IsInFormDialogContext } from "components-care/dist/backend-components/Form/FormDialog";
import BasicPageLayout from "../../../../../components/BasicPageLayout";
import BoxGrid from "../../../../../components/BoxGrid";
import InventoryDetails from "../EventIssue/InventoryDetails";
import ChatBox, { ChatBoxForm } from "./Chat/ChatBox";
import Chat from "./Chat";
import IncidentActionsButton from "./IncidentActionsButton";
import useScrollToEnd from "../../../../../utils/useScrollToEnd";
import { IncidentsContext, useIncidentsContext } from "./index";
import { useTenantIdWithContextOpt } from "../../../../components/TenantContext";
import MyTenantContext from "../../../../MySamedis/components/MyTenantContext";
import { useEnterpriseSyncConfigInForm } from "../../../../Enterprise/utils/useEnterpriseSyncConfig";
import EnterpriseRecordCreateTenantSelect from "../../../../Enterprise/components/EnterpriseRecordCreateTenantSelect";
import { useFormEnterpriseClientIdOpt } from "../../../../Enterprise/utils/useFormEnterpriseClientId";
const EventIssueCRUD = React.lazy(() => import("../../Issues"));
const useStyles = makeStyles()({
    issueList: {
        paddingLeft: "1.5rem",
    },
    chatWrapper: {
        height: "calc(100vh - 160px)",
    },
    chatWrapperInner: {
        height: "100%",
    },
    chat: {
        overflowY: "auto",
    },
    chatBox: {
        maxWidth: "100%",
    },
});
const IncidentsForm = (formProps) => {
    const { id } = formProps;
    const isInDialog = useContext(IsInFormDialogContext);
    const { t } = useTranslation("incident");
    const { values, relations } = useFormContext();
    const tenantId = useTenantIdWithContextOpt(values.tenant_id);
    const { classes } = useStyles();
    const incidentsContext = useIncidentsContext();
    const { type } = incidentsContext;
    const [pushDialog, popDialog] = useDialogContext();
    const [perms] = usePermissionContext();
    const { ref: chatScrollContainer, onScroll: onUserChatScroll } = useScrollToEnd();
    useEnterpriseSyncConfigInForm("incidents");
    const enterpriseClientId = useFormEnterpriseClientIdOpt();
    const handleOpenIssue = useCallback((evt, id, title) => {
        if (evt.ctrlKey || evt.metaKey || evt.shiftKey)
            return;
        evt.preventDefault();
        evt.stopPropagation();
        pushDialog(_jsx(FullFormDialog, { dialogTitle: t("issue-dialog.title", { TITLE: title }), useCustomClasses: true, children: _jsx(IncidentsContext.Provider, { value: incidentsContext, children: _jsx(EventIssueCRUD, { disableBackgroundGrid: true, disableRouting: true, initialView: id, formProps: { onSubmit: popDialog } }) }) }));
    }, [incidentsContext, popDialog, pushDialog, t]);
    const inventoryId = values.inventory_id;
    const subEntries = useMemo(() => [
        inventoryId && tenantId && (_jsx(Grid, { item: true, xs: 12, children: _jsx(MyTenantContext, { tenantId: tenantId, children: _jsx(InventoryDetails, { inventoryId: inventoryId, tenantId: tenantId, enterpriseClientId: enterpriseClientId, noImage: true }) }) }, "inventory_info")),
        id &&
            ["dispatcher", "supporter"].includes(type) &&
            hasPermission(perms, "issues.reader") && (_jsx(Grid, { item: true, xs: 12, children: _jsx(GroupBox, { label: t("ref-issues"), children: _jsx("ul", { className: classes.issueList, children: relations.issue_ids.map((issue) => (_jsx("li", { children: _jsx(MuiLink, { component: Link, to: `/tenant/${tenantId}/devices/event-issues/${issue.id}`, onClick: (evt) => handleOpenIssue(evt, issue.id, issue.title), children: issue.title }) }, issue.id))) }) }) }, "ref_issues")),
    ].filter((e) => e), [
        classes.issueList,
        handleOpenIssue,
        id,
        inventoryId,
        perms,
        relations.issue_ids,
        t,
        tenantId,
        type,
        enterpriseClientId,
    ]);
    const pageTitle = [t("pagetitle"), values.incident_number]
        .filter((e) => !!e)
        .join(" • ");
    return useMemo(() => (_jsx(DefaultFormPage, Object.assign({}, formProps, { children: _jsxs(BasicPageLayout, { title: !isInDialog && pageTitle, actions: id &&
                tenantId &&
                inventoryId && (_jsx(MyTenantContext, { tenantId: tenantId, children: _jsx(IncidentActionsButton, { incidentId: id, inventoryId: inventoryId }) })), children: [_jsxs(BoxGrid, { item: true, xs: 12, md: 4, container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "inventory_id" }) }), inventoryId && (_jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "responsible_id" }) })), inventoryId && (_jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "inventory_operation_status" }) })), _jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "status" }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "needs_transport" }) }), subEntries.length > 0 && (_jsx(Grid, { item: true, xs: 12, children: _jsx(Divider, {}) })), subEntries] }), _jsxs(BoxGrid, { item: true, xs: 12, md: 8, container: true, spacing: 2, direction: "column", wrap: "nowrap", className: classes.chatWrapper, classNameInner: classes.chatWrapperInner, children: [id && (_jsx(Grid, { item: true, xs: true, className: classes.chat, ref: chatScrollContainer, onScroll: onUserChatScroll, children: _jsx(Chat, {}) })), _jsx(Grid, { item: true, className: classes.chatBox, children: id ? (_jsx(ChatBox, { incidentId: id })) : (_jsx(ChatBoxForm, Object.assign({}, formProps, { customProps: null }))) })] })] }) }))), [
        chatScrollContainer,
        classes.chat,
        classes.chatBox,
        classes.chatWrapper,
        classes.chatWrapperInner,
        formProps,
        id,
        inventoryId,
        isInDialog,
        onUserChatScroll,
        pageTitle,
        subEntries,
        tenantId,
    ]);
};
const IncidentsFormWrapper = (formProps) => {
    const { t } = useTranslation("incident");
    return (_jsx(EnterpriseRecordCreateTenantSelect, Object.assign({}, formProps, { title: t("enterprise.create-title"), children: IncidentsForm })));
};
export default React.memo(IncidentsFormWrapper);

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { openProfile, useAuthProviderContext, } from "../../components/AuthProvider";
import { Avatar, Box, Grid, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles()((theme) => ({
    root: {
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
        cursor: "pointer",
        "&:hover": {
            backgroundColor: theme.palette.action.hover,
        },
    },
    pfp: {
        width: 48,
        height: 48,
        borderRadius: "50%",
        top: "50%",
        transform: "translateY(-50%)",
        "& > img": {
            top: "unset",
            transform: "unset",
        },
    },
}));
const UserInfo = () => {
    var _a, _b;
    const { t } = useTranslation("tenant-select");
    const authInfo = useAuthProviderContext();
    const { classes } = useStyles();
    return (_jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(Typography, { variant: "subtitle1", children: t("login") }) }), _jsx(Grid, { item: true, xs: 12, sm: 10, md: 6, children: _jsx(Box, { p: 1, className: classes.root, onClick: openProfile, children: _jsxs(Grid, { container: true, spacing: 2, wrap: "nowrap", children: [_jsx(Grid, { item: true, children: _jsx(Avatar, { className: classes.pfp, slotProps: { img: { className: classes.pfp } }, src: (_b = (_a = authInfo.current_user.image) === null || _a === void 0 ? void 0 : _a.small) !== null && _b !== void 0 ? _b : undefined }) }), _jsx(Grid, { item: true, xs: true, children: _jsx(Box, { py: 1, children: _jsxs(Grid, { container: true, spacing: 0, children: [_jsx(Grid, { item: true, xs: 12, children: _jsxs(Typography, { children: [authInfo.current_user.first_name, " ", authInfo.current_user.last_name] }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(Typography, { variant: "caption", children: authInfo.current_user.email }) })] }) }) })] }) }) })] }));
};
export default React.memo(UserInfo);

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { ComponentsCareI18n, Model, ModelDataTypeBackendSingleSelectRenderer, ModelDataTypeBooleanCheckboxRendererCC, ModelDataTypeDateNullableRendererCC, ModelDataTypeEnumSelectRenderer, ModelDataTypeIntegerRendererCC, ModelDataTypeStringRendererCC, ModelVisibilityDisabled, ModelVisibilityDisabledReadOnly, ModelVisibilityEdit, ModelVisibilityEditReadOnly, ModelVisibilityEditRequired, ModelVisibilityGridView, ModelVisibilityGridViewHidden, throwError, validatePresence, } from "components-care";
import BackendConnector from "../connectors/BackendConnector";
import { useMemo } from "react";
import { useTenantIdOpt } from "../../pages/components/TenantContext";
import InventoryModel, { INVENTORY_OPERATION_STATUS_ENUM, InventoryModelSelectorSort, InventoryModelToSelectorData, } from "./InventoryModel";
import i18n from "../../i18n";
import { getLruConfig } from "../../utils";
import handleBackendMultiSelectLoadError from "../../utils/handleBackendMultiSelectLoadError";
import PickInventoryButton from "../../pages/TenantSpecific/components/Dialogs/PickInventoryButton";
import EventIssueModel from "./EventIssueModel";
import { IncidentMessageContentRenderer } from "../../pages/TenantSpecific/Devices/components/Incidents/Chat/ChatBox";
import ContactModel, { ContactModelSelectorSort, ContactModelToSelectorData, } from "./ContactModel";
import PickContactButton from "../../pages/TenantSpecific/components/Dialogs/PickContactButton";
import RendererImageWithStatus from "../types/renderers/RendererImageWithStatus";
import RendererDeviceModelGrid from "../types/renderers/RendererDeviceModelGrid";
import RendererBackendObjectIdArray from "../types/renderers/RendererBackendObjectIdArray";
import { ID_FIELD_DEF } from "../../constants";
import RendererObjectId from "../types/renderers/RendererObjectId";
export const STATUS_OPTIONS = (t) => ["new", "pending", "in_progress", "done"].map((value) => ({
    value,
    getLabel: () => t("incident:status.enum." + value),
}));
const IncidentModel = (params) => new Model(params.tenantId
    ? params.enterpriseClients
        ? "enterprise-incident-model"
        : "incident-model"
    : "my-incident-model", {
    inventory_device_picture: {
        type: new RendererImageWithStatus("inventory_operation_status"),
        getLabel: () => params.t("incident:inventory_device_picture.field"),
        getColumnLabel: () => "",
        visibility: {
            overview: ModelVisibilityGridView,
            create: ModelVisibilityEdit,
            edit: ModelVisibilityEdit,
        },
        filterable: false,
        customData: null,
        columnWidth: [60, 60, 60],
    },
    device_model_combo_search: {
        type: new RendererDeviceModelGrid("device_model_title", "device_model_version", "device_type_title", "device_model_manufacturer_according_to_type_plate"),
        getLabel: () => params.t("incident:device_model_combo_search.field"),
        visibility: {
            overview: ModelVisibilityGridView,
            create: ModelVisibilityDisabled,
            edit: ModelVisibilityDisabled,
        },
        filterable: true,
        sortable: true,
        customData: null,
        columnWidth: [80, 400, 260],
    },
    device_type_title: {
        type: new ModelDataTypeStringRendererCC(),
        getLabel: () => params.t("incident:device_type_title.field"),
        visibility: {
            overview: ModelVisibilityGridViewHidden,
            edit: ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityDisabled,
        },
        customData: null,
        filterable: true,
        sortable: true,
    },
    device_model_title: {
        type: new ModelDataTypeStringRendererCC(),
        getLabel: () => params.t("incident:device_model_title.field"),
        visibility: {
            overview: ModelVisibilityGridViewHidden,
            edit: ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityDisabled,
        },
        customData: null,
        filterable: true,
        sortable: true,
    },
    device_model_version: {
        type: new ModelDataTypeStringRendererCC(),
        getLabel: () => params.t("incident:device_model_version.field"),
        visibility: {
            overview: ModelVisibilityGridViewHidden,
            create: ModelVisibilityDisabled,
            edit: ModelVisibilityDisabled,
        },
        customData: null,
        sortable: true,
        filterable: true,
        columnWidth: [80, 320, 160],
    },
    device_model_manufacturer_according_to_type_plate: {
        type: new ModelDataTypeStringRendererCC(),
        getLabel: () => params.t("incident:device_model_manufacturer_according_to_type_plate.field"),
        visibility: {
            overview: ModelVisibilityGridViewHidden,
            edit: ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityDisabled,
        },
        customData: null,
        filterable: true,
        sortable: true,
    },
    device_model_current_responsible_manufacturer: {
        type: new ModelDataTypeStringRendererCC(),
        getLabel: () => params.t("incident:device_model_current_responsible_manufacturer.field"),
        visibility: {
            overview: ModelVisibilityGridViewHidden,
            edit: ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityDisabled,
        },
        customData: null,
        filterable: true,
        sortable: true,
    },
    tenant_id: params.enterpriseClients
        ? {
            type: new ModelDataTypeEnumSelectRenderer(params.enterpriseClients.map((client) => ({
                getLabel: () => { var _a; return (_a = client.name) !== null && _a !== void 0 ? _a : client.name2; },
                value: client.id,
                isDisabled: client.sync_config.incidents === "to_samedis",
            })), {
                lru: Object.assign(Object.assign({}, getLruConfig(params.tenantId, "enterprise-tenant")), { forceQuery: false, loadData: (id) => {
                        var _a;
                        if (!params.enterpriseClients)
                            throw new Error("no clients");
                        const client = params.enterpriseClients.find((client) => client.id === id);
                        if (!client)
                            throw new Error("not found");
                        return {
                            label: (_a = client.name) !== null && _a !== void 0 ? _a : client.name2,
                            value: client.id,
                            isDisabled: client.sync_config.incidents === "to_samedis",
                        };
                    } }),
            }),
            getLabel: () => params.t("incident:tenant_id.field"),
            getDefaultValue: () => params.enterpriseClientId,
            visibility: {
                overview: ModelVisibilityGridView,
                edit: ModelVisibilityEditRequired,
                create: ModelVisibilityEditRequired,
            },
            customData: null,
            filterable: true,
            sortable: true,
            columnWidth: [80, 640, 240],
        }
        : {
            type: new ModelDataTypeStringRendererCC(),
            getLabel: () => params.t("incident:tenant_id.field"),
            getDefaultValue: () => { var _a; return (_a = params.enterpriseClientId) !== null && _a !== void 0 ? _a : params.tenantId; },
            visibility: {
                overview: ModelVisibilityDisabledReadOnly,
                edit: ModelVisibilityEditRequired,
                create: ModelVisibilityEditRequired,
            },
            customData: null,
        },
    inventory_device_number: {
        type: new ModelDataTypeStringRendererCC(),
        getLabel: () => params.t("incident:inventory_device_number.field"),
        visibility: {
            overview: ModelVisibilityGridView,
            edit: ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityDisabled,
        },
        customData: null,
        filterable: true,
        sortable: true,
        columnWidth: [80, 320, 160],
    },
    incident_number: {
        type: new ModelDataTypeIntegerRendererCC(),
        getLabel: () => params.t("incident:incident_number.field"),
        visibility: {
            overview: ModelVisibilityGridView,
            edit: ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityDisabled,
        },
        customData: null,
        filterable: true,
        sortable: true,
        columnWidth: [120, 120, 240],
    },
    inventory_id: {
        type: new ModelDataTypeBackendSingleSelectRenderer({
            modelToSelectorData: InventoryModelToSelectorData,
            placeholder: i18n.t("incident:inventory_id.placeholder"),
            lru: getLruConfig(params.tenantId, "incident-inventory"),
            sort: InventoryModelSelectorSort(null),
            onLoadError: handleBackendMultiSelectLoadError,
            endAdornment: (_jsx(PickInventoryButton, { title: params.t("incident:inventory_id.dialog-title") })),
            enableIcons: true,
            iconSize: 24,
        }),
        onChange: (value, _, setFieldValue, getFieldValue) => {
            if (!value)
                return value;
            (() => __awaiter(void 0, void 0, void 0, function* () {
                var _a, _b, _c, _d, _e;
                const model = InventoryModel({
                    t: params.t,
                    tenantId: (_b = (_a = params.tenantId) !== null && _a !== void 0 ? _a : getFieldValue("tenant_id")) !== null && _b !== void 0 ? _b : throwError("tenant id not set"),
                    enterpriseClientId: (_c = params.enterpriseClientId) !== null && _c !== void 0 ? _c : (params.enterpriseClients
                        ? (_d = getFieldValue("tenant_id")) !== null && _d !== void 0 ? _d : throwError("tenant id not set")
                        : null),
                });
                const [inventory] = yield model.getCached(value);
                setFieldValue("needs_transport", (_e = inventory.needs_transport) !== null && _e !== void 0 ? _e : false);
            }))();
            return value;
        },
        getRelationModel: (_id, values) => {
            var _a, _b, _c, _d;
            return InventoryModel({
                t: params.t,
                tenantId: (_b = (_a = params.tenantId) !== null && _a !== void 0 ? _a : values.tenant_id) !== null && _b !== void 0 ? _b : throwError("tenant id not set"),
                enterpriseClientId: (_c = params.enterpriseClientId) !== null && _c !== void 0 ? _c : (params.enterpriseClients
                    ? (_d = values.tenant_id) !== null && _d !== void 0 ? _d : throwError("tenant id not set")
                    : null),
            });
        },
        getRelationModelValues: ["tenant_id"],
        getLabel: () => params.t("incident:inventory_id.field"),
        visibility: {
            overview: ModelVisibilityDisabledReadOnly,
            edit: ModelVisibilityEditReadOnly,
            create: ModelVisibilityEditRequired,
        },
        customData: null,
        validate: validatePresence,
    },
    status: {
        type: new ModelDataTypeEnumSelectRenderer(STATUS_OPTIONS(params.t), {
            disableSearch: true,
            disableClearable: true,
        }),
        getLabel: () => params.t("incident:status.field"),
        visibility: {
            overview: ModelVisibilityGridView,
            create: ["dispatcher", "supporter"].includes(params.role)
                ? ModelVisibilityEdit
                : ModelVisibilityEditReadOnly,
            edit: ["dispatcher", "supporter"].includes(params.role)
                ? ModelVisibilityEdit
                : ModelVisibilityEditReadOnly,
        },
        getDefaultValue: () => "new",
        customData: null,
        filterable: true,
        sortable: true,
        validate: validatePresence,
        columnWidth: [120, 120, 240],
    },
    inventory_operation_status: {
        type: new ModelDataTypeEnumSelectRenderer(INVENTORY_OPERATION_STATUS_ENUM(params.t), {
            disableSearch: true,
            disableClearable: true,
        }),
        getLabel: () => params.t("inventory:operation_status.field"),
        getDefaultValue: () => "active",
        visibility: {
            overview: ModelVisibilityDisabledReadOnly,
            create: ["dispatcher", "supporter"].includes(params.role)
                ? (values) => values.inventory_operation_status === "retired"
                    ? ModelVisibilityEditReadOnly
                    : ModelVisibilityEdit
                : ModelVisibilityEditReadOnly,
            edit: ["dispatcher", "supporter"].includes(params.role)
                ? ModelVisibilityEdit
                : ModelVisibilityEditReadOnly,
        },
        customData: null,
    },
    needs_transport: {
        type: new ModelDataTypeBooleanCheckboxRendererCC(),
        getLabel: () => params.t("incident:needs_transport.field"),
        visibility: {
            overview: ModelVisibilityDisabledReadOnly,
            create: ["dispatcher", "supporter"].includes(params.role)
                ? ModelVisibilityEdit
                : ModelVisibilityEditReadOnly,
            edit: ["dispatcher", "supporter"].includes(params.role)
                ? ModelVisibilityEdit
                : ModelVisibilityEditReadOnly,
        },
        customData: null,
    },
    content: {
        type: new IncidentMessageContentRenderer({
            noMarkdown: true,
        }),
        getLabel: () => params.t("incident:chat.box.title"),
        visibility: {
            overview: ModelVisibilityDisabledReadOnly,
            edit: ModelVisibilityEditReadOnly,
            create: ModelVisibilityEditRequired,
        },
        customData: null,
    },
    last_activity_at: {
        type: new ModelDataTypeDateNullableRendererCC(),
        getLabel: () => params.t("incident:last_activity_at.field"),
        visibility: {
            overview: ModelVisibilityGridView,
            edit: ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityDisabled,
        },
        customData: null,
        filterable: true,
        sortable: true,
    },
    responsible_user_id: {
        // set by backend
        type: new RendererObjectId(),
        getLabel: () => "",
        visibility: {
            overview: ModelVisibilityDisabledReadOnly,
            edit: ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityDisabled,
        },
        customData: null,
    },
    responsible_id: {
        type: new ModelDataTypeBackendSingleSelectRenderer({
            modelToSelectorData: ContactModelToSelectorData,
            placeholder: params.t("incident:responsible_id.placeholder"),
            lru: getLruConfig(params.tenantId, "incident-responsible-contact"),
            sort: ContactModelSelectorSort,
            onLoadError: handleBackendMultiSelectLoadError,
            enableIcons: true,
            iconSize: 24,
            endAdornment: (_jsx(PickContactButton, { target: "tenant", incident: true, title: params.t("incident:responsible_id.dialog-title") })),
        }),
        getRelationModel: (_id, values) => {
            var _a, _b, _c, _d;
            return ContactModel({
                t: params.t,
                ccT: ComponentsCareI18n.t.bind(ComponentsCareI18n),
                tenantId: (_b = (_a = params.tenantId) !== null && _a !== void 0 ? _a : values.tenant_id) !== null && _b !== void 0 ? _b : throwError("tenant id not set"),
                incident: {
                    inventoryId: values.inventory_id,
                },
                enterpriseClientId: (_c = params.enterpriseClientId) !== null && _c !== void 0 ? _c : (params.enterpriseClients
                    ? (_d = values.tenant_id) !== null && _d !== void 0 ? _d : throwError("tenant id not set")
                    : null),
                target: "tenant",
            });
        },
        getRelationModelValues: ["tenant_id", "inventory_id"],
        getLabel: () => params.t("incident:responsible_id.field"),
        visibility: {
            overview: ModelVisibilityDisabled,
            edit: params.role === "dispatcher"
                ? ModelVisibilityEdit
                : ModelVisibilityEditReadOnly,
            create: params.enterpriseClients || params.enterpriseClientId
                ? ModelVisibilityEditRequired
                : params.role === "dispatcher"
                    ? ModelVisibilityEdit
                    : ModelVisibilityEditReadOnly,
        },
        customData: null,
        // required if enterprise
        validate: (params.enterpriseClients || params.enterpriseClientId) &&
            !params.reportOnly
            ? validatePresence
            : undefined,
        onChange: (responsibleId, _model, setFieldValue) => {
            setFieldValue("status", responsibleId ? "pending" : "new");
            return responsibleId;
        },
    },
    issue_ids: {
        type: new RendererBackendObjectIdArray(),
        getRelationModel: params.tenantId
            ? (_, values) => {
                var _a, _b;
                return EventIssueModel({
                    tenantId: params.tenantId,
                    t: params.t,
                    incidentsRole: params.role,
                    enterpriseClientId: (_a = params.enterpriseClientId) !== null && _a !== void 0 ? _a : (params.enterpriseClients
                        ? (_b = values.tenant_id) !== null && _b !== void 0 ? _b : throwError("tenant id not set")
                        : null),
                });
            }
            : undefined,
        getRelationModelValues: ["tenant_id"],
        getLabel: () => "",
        visibility: {
            overview: ModelVisibilityDisabledReadOnly,
            edit: ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityDisabledReadOnly,
        },
        customData: null,
    },
    created_by: {
        type: new ModelDataTypeStringRendererCC(),
        getLabel: () => "",
        visibility: {
            overview: ModelVisibilityDisabledReadOnly,
            edit: ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityDisabledReadOnly,
        },
        customData: null,
    },
    created_by_user: {
        type: new ModelDataTypeStringRendererCC(),
        getLabel: () => "",
        visibility: {
            overview: ModelVisibilityDisabledReadOnly,
            edit: ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityDisabledReadOnly,
        },
        customData: null,
    },
    created_at: {
        type: new ModelDataTypeDateNullableRendererCC(),
        getLabel: () => "",
        visibility: {
            overview: ModelVisibilityDisabledReadOnly,
            edit: ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityDisabled,
        },
        customData: null,
    },
    id: Object.assign(Object.assign({}, ID_FIELD_DEF(params.t)), { customData: null }),
}, new BackendConnector(params.tenantId
    ? params.enterpriseClientId
        ? params.role === "dispatcher"
            ? `v4/enterprise/tenants/${params.tenantId}/clients/${params.enterpriseClientId}/incidents`
            : params.role === "supporter"
                ? `v4/enterprise/tenants/${params.tenantId}/clients/${params.enterpriseClientId}/supporter_incidents`
                : throwError("invalid")
        : params.enterpriseClients
            ? params.role === "dispatcher"
                ? `v4/enterprise/tenants/${params.tenantId}/incidents`
                : params.role === "supporter"
                    ? `v4/enterprise/tenants/${params.tenantId}/supporter_incidents`
                    : throwError("invalid")
            : params.role === "dispatcher"
                ? `v4/tenants/${params.tenantId}/incidents`
                : params.role === "supporter"
                    ? `v4/tenants/${params.tenantId}/supporter_incidents`
                    : throwError("invalid config (tenant id)")
    : params.role === "reporter"
        ? `v4/my/incidents`
        : throwError("invalid config (no tenant id)"), "data", {
    includedRelations: params.tenantId
        ? {
            issue_ids: ["issue", "issues"],
        }
        : undefined,
    forceFieldFilter: params.filter,
    getEndpointOverrideCreate: params.enterpriseClients
        ? (record) => `/api/v4/enterprise/tenants/${params.tenantId}/clients/${record.tenant_id}/incidents`
        : undefined,
    getEndpointOverrideUpdate: params.enterpriseClients
        ? (record) => `/api/v4/enterprise/tenants/${params.tenantId}/clients/${record.tenant_id}/incidents/${record.id}`
        : undefined,
}), [params.tenantId, params.role], {
    cacheKeysIndex: [
        params.tenantId,
        params.role,
        !!params.enterpriseClients,
        params.enterpriseClientId,
        params.filter,
    ],
    hooks: {
        onCreateOrUpdate: ([data]) => {
            var _a, _b;
            if (!data.inventory_id)
                return;
            InventoryModel({
                t: params.t,
                tenantId: data.tenant_id,
                enterpriseClientId: ((_a = params.enterpriseClientId) !== null && _a !== void 0 ? _a : params.enterpriseClients)
                    ? (_b = data.tenant_id) !== null && _b !== void 0 ? _b : throwError("no tenant id set")
                    : null,
            }).invalidateCacheForId(data.inventory_id);
        },
    },
});
export const useIncidentModelTranslations = () => useTranslation(["incident", "inventory"]);
export const useIncidentModel = (params) => {
    const tenantId = useTenantIdOpt();
    const { t } = useIncidentModelTranslations();
    return useMemo(() => IncidentModel(Object.assign({ t, tenantId }, params)), [t, tenantId, params]);
};
export const IncidentModelToSelectorData = (data) => ({
    value: data.id,
    label: `${data.incident_number} ${data.content}`,
});
export default IncidentModel;

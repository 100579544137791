import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { ListItemButton, ListItemIcon, ListItemText, styled, } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { makeStyles } from "tss-react/mui";
const useStyles = makeStyles()((theme) => ({
    item: {
        borderBottomRightRadius: "30px",
        borderTopRightRadius: "30px",
        width: "calc(100% - 20px)",
        cursor: "pointer",
    },
    selectedItem: {
        color: theme.palette.getContrastText(theme.palette.primary.light),
        backgroundColor: theme.palette.primary.light,
    },
    icon: {
        color: theme.palette.getContrastText(theme.palette.primary.main),
    },
    text: {
        color: theme.palette.getContrastText(theme.palette.primary.main),
    },
}));
const SelectedListItem = styled(ListItemButton)(({ theme }) => ({
    "& .MuiListItemButton-button:hover": {
        backgroundColor: theme.palette.primary.light,
    },
}));
const UnselectedListItem = styled(ListItemButton)(({ theme }) => ({
    "& .MuiListItemButton-button:hover": {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.light,
    },
}));
const ExpandableListItem = styled(ListItemButton)(({ theme }) => ({
    "& .MuiListItemButton-button:hover": {
        backgroundColor: theme.palette.primary.light,
    },
}));
const typographyProps = { variant: "body1" };
const SamedisMenuItem = (props) => {
    const { classes } = useStyles();
    const Icon = props.icon;
    const MyListItem = props.expandable
        ? ExpandableListItem
        : props.active
            ? SelectedListItem
            : UnselectedListItem;
    return (_jsxs(MyListItem, { onClick: props.onClick, onAuxClick: props.onAuxClick, className: classes.item + (props.active ? " " + classes.selectedItem : ""), children: [_jsx(ListItemIcon, { children: Icon && _jsx(Icon, { className: classes.icon }) }), _jsx(ListItemText, { primary: props.title, primaryTypographyProps: typographyProps, className: classes.text }), props.expandable &&
                (props.expanded ? (_jsx(ExpandLess, { className: classes.icon })) : (_jsx(ExpandMore, { className: classes.icon })))] }));
};
export default React.memo(SamedisMenuItem);

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useMemo } from "react";
import SearchAndBrowse from "../index";
import { useTenantId } from "../../../../components/TenantContext";
import { multiSelectFilterEntryFromEnum } from "../FilterEntryMultiSelect";
import DeviceTagModel, { DEVICE_TAG_TYPES, getDeviceTagModelSort, useDeviceTagModelTranslations, } from "../../../../../components-care/models/DeviceTagModel";
import DeviceTagRenderer from "../Renderers/DeviceTagRenderer";
import { COMPACT_LAYOUT_HEIGHT } from "../Renderers/DenseLayout";
import { INVALID_ID } from "../../../../../constants";
const DeviceTagSearch = (props) => {
    const { target } = props, other = __rest(props, ["target"]);
    const tenantId = useTenantId();
    const { t: deviceTagModelT } = useDeviceTagModelTranslations();
    const loadRecords = useCallback((search, filters, offset) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const deviceTagModel = DeviceTagModel({
            t: deviceTagModelT,
            tenantId,
            target,
        });
        const [data, meta] = yield deviceTagModel.index2({
            offset: offset,
            rows: 25,
            sort: getDeviceTagModelSort(),
            quickFilter: search,
            fieldFilter: {
                type: {
                    type: "inSet",
                    value1: filters.type.join(",") || INVALID_ID,
                    value2: "",
                },
            },
        });
        return {
            data: data,
            total: (_a = meta.filteredRows) !== null && _a !== void 0 ? _a : meta.totalRows,
        };
    }), [deviceTagModelT, tenantId, target]);
    const filters = useMemo(() => ({
        type: Object.assign({ variant: "multi-select", title: deviceTagModelT("device-tag:type.field") }, multiSelectFilterEntryFromEnum(DEVICE_TAG_TYPES(deviceTagModelT))),
    }), [deviceTagModelT]);
    return (_jsx(SearchAndBrowse, Object.assign({}, other, { loadRecords: loadRecords, filters: filters, renderer: DeviceTagRenderer, rendererItemHeight: COMPACT_LAYOUT_HEIGHT, rendererContext: undefined, controlName: "DeviceTagSearch" })));
};
export default React.memo(DeviceTagSearch);

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import DenseLayout from "./DenseLayout";
import getLocalizedString from "../../../../../utils/getLocalizedString";
import { useTranslation } from "react-i18next";
const InventoryRenderer = (props) => {
    const { data } = props, otherProps = __rest(props, ["data"]);
    const { i18n } = useTranslation();
    return (_jsx(DenseLayout, Object.assign({ id: data.id, icon: _jsx("img", { src: data.device_model_image, alt: getLocalizedString(i18n.language, data.device_type_title_labels) }), title: [
            data.device_model_title,
            data.device_model_version,
            data.device_number,
        ]
            .filter((x) => !!x)
            .join(" • "), info: [
            getLocalizedString(i18n.language, data.device_type_title_labels),
            data.device_model_manufacturer_according_to_type_plate,
        ].filter((e) => !!e) }, otherProps)));
};
export default React.memo(InventoryRenderer);

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo, useRef, useState, } from "react";
import { CrudMultiImage, MultiImage, showConfirmDialogBool, useFormContext, DefaultErrorComponent as ErrorComponent, } from "components-care";
import { useDialogContext } from "components-care/dist";
import BackendConnector from "./connectors/BackendConnector";
import { useTranslation } from "react-i18next";
import uploadImage from "../assets/img/upload-image.png";
import useAsyncMemo from "components-care/dist/utils/useAsyncMemo";
import * as Sentry from "@sentry/react";
import useLazyCrudConnector, { extractLazyCrudConnectorParams, } from "components-care/dist/backend-components/Form/useLazyCrudConnector";
import DefaultConnector from "components-care/dist/backend-integration/Connector/DefaultConnector";
export const serializeImageData = (data, id) => __awaiter(void 0, void 0, void 0, function* () {
    return (Object.assign(Object.assign({}, data), { image: data.image.startsWith("data:") ? data.image : undefined, image_url: data.image.startsWith("data:") ? undefined : data.image, id: id !== null && id !== void 0 ? id : undefined }));
});
export const deserializeImageData = (data) => {
    var _a;
    return ({
        id: data.id,
        name: data.name,
        image: ((_a = data.image_url) !== null && _a !== void 0 ? _a : data.image),
    });
};
const SamedisCrudMultiImage = (props) => {
    const [connectorProps, crudMultiImageProps] = extractLazyCrudConnectorParams(props);
    const { primaryId: primaryImageId, onPrimaryChange, onChange: _, additionalImageSource, additionalImages, additionalImagesLoading } = crudMultiImageProps, otherProps = __rest(crudMultiImageProps, ["primaryId", "onPrimaryChange", "onChange", "additionalImageSource", "additionalImages", "additionalImagesLoading"]);
    const [pushDialog] = useDialogContext();
    const { t } = useTranslation("common");
    const [primaryId, setPrimaryId] = useState(primaryImageId);
    const primaryIdRef = useRef(primaryImageId);
    const onPrimaryChangeRef = useRef(onPrimaryChange);
    const parentRecordId = useRef(connectorProps.initialId);
    let formReadOnly;
    let setCustomFieldDirty;
    let connector = null;
    let onlySubmitMounted;
    try {
        // this is technically violating the rules of react hooks, but it doesn't matter
        // this would only cause issues if the form context would appear/disappear without this component remounting
        // which will never happen, it will always remount
        const formCtx = useFormContext();
        formReadOnly = formCtx.readOnly;
        setCustomFieldDirty = formCtx.setCustomFieldDirty;
        onlySubmitMounted = formCtx.onlySubmitMounted;
        // eslint-disable-next-line react-hooks/rules-of-hooks
        connector = useLazyCrudConnector(Object.assign(Object.assign({}, connectorProps), { onParentIdChange: (id) => {
                if (id === "null")
                    return;
                parentRecordId.current = id;
            } })).connector;
    }
    catch (_e) {
        formReadOnly = false;
        setCustomFieldDirty = null;
        onlySubmitMounted = false;
        // using without form context, connect directly with backend
        parentRecordId.current = connectorProps.initialId;
        if (connectorProps.initialId) {
            if (!DefaultConnector.getApi)
                throw new Error("Please configure setDefaultConnectorAPI");
            const backendConnector = DefaultConnector.getApi(connectorProps.getEndpoint(connectorProps.initialId), connectorProps.extraParams);
            if (connectorProps.configureConnector) {
                connectorProps.configureConnector(backendConnector);
            }
            connector = backendConnector;
        }
    }
    const readOnly = formReadOnly || props.readOnly;
    const handlePrimaryChange = useCallback((_name, id) => {
        id = id !== null && id !== void 0 ? id : "";
        primaryIdRef.current = id;
        setPrimaryId(id);
    }, []);
    useEffect(() => {
        handlePrimaryChange(undefined, primaryImageId);
    }, [handlePrimaryChange, primaryImageId]);
    useEffect(() => {
        onPrimaryChangeRef.current = onPrimaryChange;
    }, [onPrimaryChange]);
    const additionalImages2 = useAsyncMemo(() => __awaiter(void 0, void 0, void 0, function* () {
        if (!additionalImageSource)
            return [];
        try {
            const connector = new BackendConnector(additionalImageSource);
            if (connectorProps.configureConnector)
                connectorProps.configureConnector(connector);
            return (yield connector.index({ rows: 100 }))[0]
                .map(deserializeImageData)
                .map((img) => (Object.assign(Object.assign({}, img), { readOnly: true })));
        }
        catch (e) {
            console.error("Failed loading additional images.", e);
            if (!(e instanceof Error &&
                e.name === "BackendError" &&
                e.code === "record_not_found_error")) {
                Sentry.captureException(e);
            }
            return [];
        }
    }), [additionalImageSource]);
    useEffect(() => {
        if (!connector)
            return;
        const lazyConnector = connector;
        // type check
        if (typeof lazyConnector.workQueue === "undefined")
            return;
        // this may not be an anonymous function, we rely on it's name to check if we already hooked it
        function hook() {
            return __awaiter(this, void 0, void 0, function* () {
                let primaryId = primaryIdRef.current;
                yield origWorkQueue();
                if (primaryId && lazyConnector.mapId(primaryId) !== primaryId) {
                    primaryId = lazyConnector.mapId(primaryId);
                }
                if (!parentRecordId.current)
                    throw new Error("called workQueue, but parent id is null?");
                if (onPrimaryChangeRef.current)
                    onPrimaryChangeRef.current(parentRecordId.current, primaryId !== null && primaryId !== void 0 ? primaryId : "");
            });
        }
        if (lazyConnector.workQueue.name === hook.name)
            return;
        const origWorkQueue = lazyConnector.workQueue.bind(connector);
        lazyConnector.workQueue = hook;
    }, [connector]);
    // manage dirty state
    const connectorIsLazy = connector &&
        typeof connector
            .workQueue !== "undefined";
    const primaryIdMapped = primaryId && connectorIsLazy
        ? connector.mapId(primaryId)
        : primaryId;
    useEffect(() => {
        if (!setCustomFieldDirty || readOnly)
            return;
        const dirtyKey = connectorProps.field + "-primary";
        const dirty = primaryIdMapped !== primaryImageId;
        setCustomFieldDirty(dirtyKey, dirty);
        if (!onlySubmitMounted)
            return;
        return () => {
            if (!setCustomFieldDirty)
                return;
            setCustomFieldDirty(dirtyKey, false);
        };
    }, [
        connectorProps.field,
        primaryIdMapped,
        primaryImageId,
        setCustomFieldDirty,
        onlySubmitMounted,
        readOnly,
    ]);
    const handleDelete = useCallback(() => showConfirmDialogBool(pushDialog, {
        title: t("multi-image.confirm.title"),
        message: t("multi-image.confirm.message"),
        textButtonYes: t("multi-image.confirm.yes"),
        textButtonNo: t("multi-image.confirm.no"),
    }), [pushDialog, t]);
    const allAdditionalImages = useMemo(() => [...(additionalImages !== null && additionalImages !== void 0 ? additionalImages : []), ...(additionalImages2 !== null && additionalImages2 !== void 0 ? additionalImages2 : [])], [additionalImages, additionalImages2]);
    if (!connector) {
        return (_jsx(MultiImage, Object.assign({ previewSize: 256, primary: null, images: allAdditionalImages !== null && allAdditionalImages !== void 0 ? allAdditionalImages : [], uploadImage: uploadImage }, otherProps, { readOnly: true })));
    }
    return (_jsx(CrudMultiImage, Object.assign({ connector: connector, errorComponent: ErrorComponent, serialize: serializeImageData, deserialize: deserializeImageData, primary: (primaryId && "unmapId" in connector
            ? connector.unmapId(primaryId)
            : primaryId) || null // convert empty string to null to prevent dirty state issues if no image is set
        , onDelete: handleDelete, previewSize: 256, readOnly: readOnly, uploadImage: uploadImage, onPrimaryChange: handlePrimaryChange, additionalImages: allAdditionalImages, additionalImagesLoading: additionalImages2 === null || additionalImagesLoading }, otherProps)));
};
export default React.memo(SamedisCrudMultiImage);

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { CachedServerStorageProvider, } from "components-care";
import SamedisApiClient from "./connectors/SamedisApiClient";
export class BackendStorageProvider extends CachedServerStorageProvider {
    getAll() {
        return SamedisApiClient.get("/api/v4/user/settings", null);
    }
    clearFromServer() {
        return SamedisApiClient.delete("/api/v4/user/settings", null);
    }
    getItemFromServer(key) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const data = yield SamedisApiClient.get(`/api/v4/user/settings/${encodeURIComponent(key)}`, null);
                return data.data.attributes.value;
            }
            catch (error) {
                if (error instanceof Error &&
                    error.name === "BackendError" &&
                    error.code === "record_not_found_error") {
                    return null;
                }
                throw error;
            }
        });
    }
    setItemOnServer(key, value) {
        return __awaiter(this, void 0, void 0, function* () {
            if (value != null) {
                try {
                    yield SamedisApiClient.put(`/api/v4/user/settings/${encodeURIComponent(key)}`, null, { data: { value } });
                }
                catch (error) {
                    if (error instanceof Error &&
                        error.name === "BackendError" &&
                        error.code === "record_not_found_error") {
                        yield SamedisApiClient.post("/api/v4/user/settings", null, {
                            data: { key, value },
                        });
                    }
                    else {
                        throw error;
                    }
                }
            }
            else {
                try {
                    yield SamedisApiClient.delete(`/api/v4/user/settings/${encodeURIComponent(key)}`, null);
                }
                catch (error) {
                    if (error instanceof Error &&
                        error.name === "BackendError" &&
                        error.code === "record_not_found_error") {
                        return; // ignore
                    }
                    else {
                        throw error;
                    }
                }
            }
        });
    }
}
const BackendStorageProviderInstance = new BackendStorageProvider();
export default BackendStorageProviderInstance;

import { jsx as _jsx } from "react/jsx-runtime";
import RendererDeviceModelGrid, { DeviceModelGridRenderer, } from "./RendererDeviceModelGrid";
class RendererDeviceModelInventoryGrid extends RendererDeviceModelGrid {
    constructor(modelField, versionField, typeField, manufacturerField, deviceNumberField) {
        super(modelField, versionField, typeField, manufacturerField);
        this.settings = {};
        this.deviceNumberField = deviceNumberField;
        this.settings.updateHooks = [
            modelField,
            versionField,
            typeField,
            manufacturerField,
            deviceNumberField,
        ];
    }
    render(params) {
        const { visibility, values } = params;
        if (!visibility.grid)
            throw new Error("Unsupported");
        return (_jsx(DeviceModelGridRenderer, { model: [
                values[this.modelField],
                values[this.versionField],
                values[this.deviceNumberField],
            ]
                .filter((x) => x)
                .join(" • "), type: values[this.typeField], manufacturer: values[this.manufacturerField] }));
    }
    getFilterType() {
        return "combined-string";
    }
}
export default RendererDeviceModelInventoryGrid;

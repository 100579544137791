import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback } from "react";
import { FullFormDialog, useDialogContext, } from "components-care";
import { ImageEdit } from "./ImageEdit";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
const ImageEditDialog = (props) => {
    const { image, dialogTitle, onDone } = props;
    const { t } = useTranslation();
    const [, popDialog] = useDialogContext();
    const handleDone = useCallback((image) => {
        onDone(image);
        popDialog();
    }, [onDone, popDialog]);
    const closeDialog = useCallback(() => {
        onDone(null);
    }, [onDone]);
    return (_jsx(FullFormDialog, { useCustomClasses: true, onClose: closeDialog, dialogTitle: dialogTitle !== null && dialogTitle !== void 0 ? dialogTitle : t("image_edit.header"), children: _jsx(Box, { pt: 2, height: "100%", children: _jsx(ImageEdit, { image: image, onDone: handleDone }) }) }));
};
export const showImageEditDialog = (pushDialog, image, dialogTitle) => {
    return new Promise((resolve, reject) => {
        pushDialog(_jsx(ImageEditDialog, { image: image, dialogTitle: dialogTitle, onDone: (image) => image == null ? reject(new Error("User cancelled")) : resolve(image) }));
    });
};
export default React.memo(ImageEditDialog);

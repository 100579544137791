import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback } from "react";
import { List } from "@mui/material";
import SamedisMenuItem from "./MenuItem";
import { RoutedMenu, usePortalLayoutContext } from "components-care";
import { useTenantContext } from "./TenantContext";
import { useIsGlobalMode, useSamedisPortalStyles } from "./SamedisPortal";
import { useTenantRoutesContext, } from "./TenantRoutesProvider";
const convTenantRoute = (tenantId, entry, closeMenu) => {
    var _a, _b;
    return (Object.assign(Object.assign({}, entry), { onClick: entry.onClick
            ? (evt) => {
                if (entry.onClick)
                    entry.onClick(evt);
                closeMenu();
            }
            : ((_a = entry.children) === null || _a === void 0 ? void 0 : _a.length)
                ? undefined
                : closeMenu, children: (_b = entry.children) === null || _b === void 0 ? void 0 : _b.map((entry) => convTenantRoute(tenantId, entry, closeMenu)), route: entry.route
            ? tenantId
                ? `/tenant/${tenantId}${entry.route}`
                : entry.route
            : undefined }));
};
const SamedisMenu = () => {
    const { classes } = useSamedisPortalStyles();
    const tenantRoutes = useTenantRoutesContext();
    const [tenantId] = useTenantContext();
    const globalMode = useIsGlobalMode();
    const { setMenuOpen } = usePortalLayoutContext();
    const closeMenu = useCallback(() => {
        setMenuOpen(false);
    }, [setMenuOpen]);
    return tenantId || globalMode ? (_jsx(RoutedMenu, { className: classes.menu, definition: tenantRoutes.map((entry) => convTenantRoute(tenantId, entry, closeMenu)), wrapper: List, menuItem: SamedisMenuItem })) : (_jsx(_Fragment, {}));
};
export default React.memo(SamedisMenu);

import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { makeStyles } from "tss-react/mui";
import { combineClassNames } from "components-care";
import { useTranslation } from "react-i18next";
import { PageHeaderTypographyNew } from "../../../../../components/CustomTypography";
const useStyles = makeStyles()((theme) => ({
    root: {
        display: "inline-block",
        borderRadius: theme.shape.borderRadius,
        padding: `0 ${theme.spacing(2)}`,
    },
    smallLabel: {
        fontWeight: theme.typography.h4.fontWeight,
        fontSize: theme.typography.h4.fontSize,
        padding: 5,
    },
    status_active: {
        backgroundColor: theme.palette.success.main,
        color: theme.palette.success.contrastText,
    },
    status_limited_use: {
        backgroundColor: theme.palette.warning.main,
        color: theme.palette.warning.contrastText,
    },
    status_out_of_order: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText,
    },
    status_retired: {
        backgroundColor: theme.palette.getContrastText(theme.palette.background.paper),
        color: theme.palette.background.paper,
    },
}));
const OperationStatus = (props) => {
    const { status, small } = props;
    const { classes } = useStyles();
    const { t } = useTranslation("inventory");
    return (_jsx(PageHeaderTypographyNew, { component: "span", className: combineClassNames([
            classes.root,
            small && classes.smallLabel,
            classes[("status_" + status)],
        ]), children: t("operation_status.enum." + status) }));
};
export default React.memo(OperationStatus);

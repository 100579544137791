var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import OfflineController from "../OfflineController";
class SystemController extends OfflineController {
    constructor() {
        super("system");
        this.routes["GET"]["/api/v4/maintenance"] = (request) => this.tryForward(request, () => this.makeOfflineResponse({
            samedis: { current: null, planned: [] },
        }));
        this.routes["GET"]["/api/v4/get_current_user"] = (request) => __awaiter(this, void 0, void 0, function* () {
            const db = yield this.getDB();
            return this.tryForward(request, (e) => __awaiter(this, void 0, void 0, function* () {
                var _a, _b;
                const offlineUser = (yield db.get("system", "current_user"));
                if (!offlineUser)
                    throw e;
                offlineUser.data.current_user.image = this.rewriteImages(offlineUser.data.current_user.image);
                // add offline.mode cando to every tenant
                offlineUser.data.current_user.tenants.forEach((tenant) => tenant.candos.push("samedis-care/offline.mode"));
                return this.makeOfflineResponse(offlineUser, ((_b = (_a = offlineUser.meta) === null || _a === void 0 ? void 0 : _a.msg) === null || _b === void 0 ? void 0 : _b.success) ? 200 : 403);
            }), (resp) => __awaiter(this, void 0, void 0, function* () {
                var _c, _d;
                const currentUser = (yield resp.json());
                yield db.put("system", currentUser, "current_user");
                if ((_d = (_c = currentUser.meta) === null || _c === void 0 ? void 0 : _c.msg) === null || _d === void 0 ? void 0 : _d.success) {
                    yield this.cacheImages(currentUser.data.current_user.image);
                }
            }));
        });
    }
    upgradeStore(database, oldVersion, _newVersion, _transaction, _event) {
        if (oldVersion < 1) {
            database.createObjectStore("system");
        }
    }
}
export default SystemController;

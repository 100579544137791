import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Grid } from "@mui/material";
import { FormField } from "components-care";
import DeviceModelUploads from "./DeviceModelUploads";
import DeviceModelVideosEdit from "./DeviceModelVideosEdit";
const TabTechnical = () => {
    return (_jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, md: 6, children: _jsx(FormField, { name: "network_connections" }) }), _jsx(Grid, { item: true, xs: 12, md: 6, children: _jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "stores_patient_data" }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "software_operating_system" }) })] }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(DeviceModelUploads, { category: "technical" }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(DeviceModelVideosEdit, { category: "technical" }) })] }));
};
export default React.memo(TabTechnical);

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { Button, Grid } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { combineClassNames, combineColors, throwError } from "components-care";
const useStyles = makeStyles()((theme) => ({
    action: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        "&:hover": {
            backgroundColor: `rgba(${combineColors(theme.palette.primary.main, theme.palette.action.hover).join()})`,
        },
    },
    navigation: {
        backgroundColor: theme.palette.action.disabled,
        color: theme.palette.getContrastText(theme.palette.action.disabled),
        "&:hover": {
            backgroundColor: `rgba(${combineColors(theme.palette.action.disabled, theme.palette.action.hover).join()})`,
        },
    },
}));
const DialogButton = (props) => {
    const { styleType } = props, muiProps = __rest(props, ["styleType"]);
    const { classes } = useStyles();
    const styleClass = styleType === "action"
        ? classes.action
        : styleType === "navigation"
            ? classes.navigation
            : throwError("invalid");
    return (_jsx(Grid, { item: true, children: _jsx(Button, Object.assign({}, muiProps, { variant: "contained", className: combineClassNames([styleClass, muiProps.className]) })) }));
};
export default React.memo(DialogButton);

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { fileToData, ModelDataTypeFilesRenderer, } from "components-care";
class FileRenderer extends ModelDataTypeFilesRenderer {
    constructor(params, fileNameField) {
        super(params);
        // @ts-expect-error serializer output type changed
        this.serialize = (fileData) => __awaiter(this, void 0, void 0, function* () {
            var _a;
            if (fileData.length === 0)
                return null;
            const file = fileData.find((file) => file.canBeUploaded);
            return file
                ? (_a = file.preview) !== null && _a !== void 0 ? _a : (yield fileToData(file.file, true))
                : undefined;
        });
        this.deserialize = (value) => {
            if (!value)
                return [];
            if (Array.isArray(value))
                return value; // already deserialized => backend connector sets default value if field is undefined (completeAttributes)
            const url = new URL(value);
            return [
                {
                    file: {
                        name: url.pathname.substring(url.pathname.lastIndexOf("/") + 1),
                        downloadLink: value,
                    },
                },
            ];
        };
        this.fileNameField = fileNameField;
        this.settings = {
            updateHooks: this.fileNameField ? [fileNameField] : undefined,
        };
    }
    render(params) {
        const nameField = this.fileNameField;
        if (!nameField)
            return super.render(params);
        const handleChange = (field, value) => {
            params.handleChange(field, value);
            params.setFieldValue(nameField, value.length > 0 ? value[0].file.name : "");
        };
        return super.render(Object.assign(Object.assign({}, params), { handleChange, value: params.value.map((entry) => entry.canBeUploaded
                ? entry
                : Object.assign(Object.assign({}, entry), { file: Object.assign(Object.assign({}, entry.file), { name: params.values[nameField] || entry.file.name }) })) }));
    }
}
export default FileRenderer;

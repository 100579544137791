var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo } from "react";
import BoxGrid from "../../../../../components/BoxGrid";
import { FormControlLabel, Grid, Link } from "@mui/material";
import { Business as BusinessIcon } from "@mui/icons-material";
import { Trans, useTranslation } from "react-i18next";
import { Checkbox, FormField, useDialogContext, useFormContext, useFormState, } from "components-care";
import PolicyViewer, { acceptDocument, PRIVACY_DOCUMENT, TOS_DOCUMENT, } from "../../../../TenantSelection/components/PolicyViewer";
import SamedisInfoBox from "../../../../../components/SamedisInfoBox";
import TrustLevelCommunityIcon from "../../../../../components/icons/TrustLevelCommunityIcon";
import PersonIcon from "../../../../../components/icons/PersonIcon";
const FacilityCreateStep1 = () => {
    const { t } = useTranslation("tenant");
    const [pushDialog] = useDialogContext();
    const { setCustomValidationHandler, setPostSubmitHandler, removeCustomValidationHandler, removePostSubmitHandler, } = useFormContext();
    const openDocument = useCallback((doc) => {
        pushDialog(_jsx(PolicyViewer, { document: doc }));
    }, [pushDialog]);
    const openTosDocument = useCallback(() => openDocument(TOS_DOCUMENT), [openDocument]);
    const openPrivacyDocument = useCallback(() => openDocument(PRIVACY_DOCUMENT), [openDocument]);
    const [acceptTos, setAcceptTos] = useFormState("accept_tos", false);
    const [ackPrivacy, setAckPrivacy] = useFormState("ack_privacy", false);
    const handleAcceptTos = useCallback(() => setAcceptTos((prev) => !prev), [setAcceptTos]);
    const handleAckPrivacy = useCallback(() => setAckPrivacy((prev) => !prev), [setAckPrivacy]);
    useEffect(() => {
        const field = "acceptance";
        setCustomValidationHandler(field, () => {
            const errors = {};
            if (!ackPrivacy)
                errors["privacy"] = t("privacy-required");
            if (!acceptTos)
                errors["tos"] = t("tos-required");
            return errors;
        });
        setPostSubmitHandler(field, () => __awaiter(void 0, void 0, void 0, function* () {
            yield acceptDocument(TOS_DOCUMENT);
            yield acceptDocument(PRIVACY_DOCUMENT);
        }));
        /*return () => {
          removeCustomValidationHandler(field);
          removePostSubmitHandler(field);
        };*/
    }, [
        acceptTos,
        ackPrivacy,
        removeCustomValidationHandler,
        removePostSubmitHandler,
        setCustomValidationHandler,
        setPostSubmitHandler,
        t,
    ]);
    return useMemo(() => (_jsxs(BoxGrid, { item: true, xs: 12, container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "name" }) }), _jsxs(Grid, { item: true, xs: 12, container: true, spacing: 4, children: [_jsx(Grid, { item: true, xs: 12, md: 4, children: _jsx(SamedisInfoBox, { title: null, icon: BusinessIcon, contentType: "paragraphs", content: [t("create-info.step1")] }) }), _jsx(Grid, { item: true, xs: 12, md: 4, children: _jsx(SamedisInfoBox, { title: null, icon: TrustLevelCommunityIcon, contentType: "paragraphs", content: [t("create-info.trial")] }) }), _jsx(Grid, { item: true, xs: 12, md: 4, children: _jsx(SamedisInfoBox, { title: null, icon: PersonIcon, contentType: "paragraphs", content: [t("create-info.admin")] }) })] }), _jsx(Grid, { item: true, xs: 12, children: _jsx(FormControlLabel, { control: _jsx(Checkbox, { checked: ackPrivacy, onChange: handleAckPrivacy }), label: _jsx(Trans, { t: t, i18nKey: "ack-privacy", components: {
                            "1": _jsx(Link, { href: "#", onClick: openPrivacyDocument }),
                        } }) }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(FormControlLabel, { control: _jsx(Checkbox, { checked: acceptTos, onClick: handleAcceptTos }), label: _jsx(Trans, { t: t, i18nKey: "accept-tos", components: {
                            "1": _jsx(Link, { href: "#", onClick: openTosDocument }),
                        } }) }) })] })), [
        acceptTos,
        ackPrivacy,
        handleAcceptTos,
        handleAckPrivacy,
        openPrivacyDocument,
        openTosDocument,
        t,
    ]);
};
export default React.memo(FacilityCreateStep1);

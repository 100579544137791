import { Model, ModelDataTypeStringRendererCC, ModelVisibilityHidden, } from "components-care";
import BackendConnector from "../connectors/BackendConnector";
import { ID_FIELD_DEF } from "../../constants";
import i18n from "../../i18n";
import { useTenantId } from "../../pages/components/TenantContext";
import { useMemo } from "react";
import RendererObjectId from "../types/renderers/RendererObjectId";
const IdentAccountModel = (tenantId) => new Model("ident-account-model", {
    first_name: {
        type: new ModelDataTypeStringRendererCC(),
        getLabel: () => "",
        visibility: {
            overview: ModelVisibilityHidden,
            create: ModelVisibilityHidden,
            edit: ModelVisibilityHidden,
        },
        customData: null,
    },
    last_name: {
        type: new ModelDataTypeStringRendererCC(),
        getLabel: () => "",
        visibility: {
            overview: ModelVisibilityHidden,
            create: ModelVisibilityHidden,
            edit: ModelVisibilityHidden,
        },
        customData: null,
    },
    email: {
        type: new ModelDataTypeStringRendererCC(),
        getLabel: () => "",
        visibility: {
            overview: ModelVisibilityHidden,
            create: ModelVisibilityHidden,
            edit: ModelVisibilityHidden,
        },
        customData: null,
    },
    actor_id: {
        type: new RendererObjectId(),
        getLabel: () => "",
        visibility: {
            overview: ModelVisibilityHidden,
            create: ModelVisibilityHidden,
            edit: ModelVisibilityHidden,
        },
        customData: null,
    },
    personnel_number: {
        type: new ModelDataTypeStringRendererCC(),
        getLabel: () => "",
        visibility: {
            overview: ModelVisibilityHidden,
            create: ModelVisibilityHidden,
            edit: ModelVisibilityHidden,
        },
        customData: null,
    },
    title: {
        type: new ModelDataTypeStringRendererCC(),
        getLabel: () => "",
        visibility: {
            overview: ModelVisibilityHidden,
            create: ModelVisibilityHidden,
            edit: ModelVisibilityHidden,
        },
        customData: null,
    },
    short: {
        type: new ModelDataTypeStringRendererCC(),
        getLabel: () => "",
        visibility: {
            overview: ModelVisibilityHidden,
            create: ModelVisibilityHidden,
            edit: ModelVisibilityHidden,
        },
        customData: null,
    },
    gender: {
        type: new ModelDataTypeStringRendererCC(),
        getLabel: () => "",
        visibility: {
            overview: ModelVisibilityHidden,
            create: ModelVisibilityHidden,
            edit: ModelVisibilityHidden,
        },
        customData: null,
    },
    id: Object.assign(Object.assign({}, ID_FIELD_DEF(i18n.t.bind(i18n))), { customData: null }),
}, new BackendConnector(`v4/tenants/${tenantId}/ident_accounts`), [tenantId]);
export const IdentAccountModelSelectorSort = [
    {
        field: "first_name",
        direction: 1,
    },
    {
        field: "last_name",
        direction: 1,
    },
    {
        field: "email",
        direction: 1,
    },
];
export const useIdentAccountModel = () => {
    const tenantId = useTenantId();
    return useMemo(() => IdentAccountModel(tenantId), [tenantId]);
};
export default IdentAccountModel;

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo, useRef, useState, } from "react";
import { debouncePromise, GroupBox, Loader, TextFieldWithHelp, TreeView, } from "components-care";
import { useDeviceTypeModel, } from "../../../../../components-care/models/DeviceTypeModel";
import { useTenantId } from "../../../../components/TenantContext";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import { makeStyles } from "tss-react/mui";
import DeviceTypeCatalogTreeRenderer from "./DeviceTypeCatalogTreeRenderer";
import getLocalizedString from "../../../../../utils/getLocalizedString";
const useStyles = makeStyles()({
    fullHeight: {
        height: "100%",
    },
});
const deviceTypeToTreeData = (record) => ({
    id: record.id,
    label: getLocalizedString(null, record.title_labels),
    icon: null,
    expanded: false,
    hasChildren: record.has_children,
    parentId: record.parent_id || null,
    parentIds: record.parent_ids,
});
const deviceTypeParentToTreeData = (parent, nextParentIds) => ({
    id: parent.id,
    label: parent.title,
    icon: null,
    expanded: true,
    hasChildren: true,
    parentId: nextParentIds[0] || null,
    parentIds: nextParentIds,
});
const addNodeWithParents = (data, record) => {
    const parentIds = record.parent_ids;
    const parents = [...record.parents].sort((a, b) => parentIds.indexOf(b.id) - parentIds.indexOf(a.id));
    const nodes = [
        deviceTypeToTreeData(record),
        ...parents.map((parent, idx) => {
            return deviceTypeParentToTreeData(parent, parents.slice(idx + 1).map((parent) => parent.id));
        }),
    ];
    data = [...data];
    nodes.forEach((node) => {
        if (data.find((entry) => entry.id === node.id))
            return;
        data.push(node);
    });
    return data;
};
const DeviceTypeCatalogTreeView = (props) => {
    const { mode, catalogId, activeId, enableAddNew, onClick, onAuxClick } = props;
    const tenantId = useTenantId();
    const { classes } = useStyles();
    const { t } = useTranslation("device-type");
    const treeRef = useRef(null);
    const model = useDeviceTypeModel(useMemo(() => ({
        tenantId,
        target: mode,
        ofCatalog: catalogId,
        extraParams: {
            "filter[scope]": "public_and_tenant",
        },
    }), [mode, catalogId, tenantId]));
    const [search, setSearch] = useState("");
    const searchRef = useRef("");
    const handleSearchChange = useCallback((evt) => {
        searchRef.current = evt.target.value;
        setSearch(searchRef.current);
    }, []);
    const [rootNodeAvailable, setRootNodeAvailable] = useState(false);
    const rootNodeAvailableRef = useRef(false);
    const [data, setData] = useState([]);
    const handleSearch = useMemo(() => debouncePromise((search) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        if (!searchRef.current)
            return; // fix data race
        try {
            const [results, meta] = yield model.index({
                quickFilter: search,
                rows: 100,
            });
            let newData = [];
            results.forEach((node) => {
                newData = addNodeWithParents(newData, node);
            });
            if ((_a = meta.filteredRows) !== null && _a !== void 0 ? _a : meta.totalRows === 0) {
                newData.push({
                    id: "no-data",
                    label: t("tree-box.no-data"),
                    icon: null,
                    expanded: false,
                    hasChildren: false,
                    parentId: null,
                });
            }
            if ((_b = meta.filteredRows) !== null && _b !== void 0 ? _b : meta.totalRows > 100) {
                newData.push({
                    id: "more-data",
                    label: t("tree-box.more-data"),
                    icon: null,
                    expanded: false,
                    hasChildren: false,
                    parentId: catalogId,
                });
            }
            if (!searchRef.current)
                return; // fix data race
            setData(newData);
        }
        catch (e) {
            if (!searchRef.current)
                return; // fix data race
            setData([
                {
                    id: "error-root",
                    label: "Failed loading search result: " + e.message,
                    icon: null,
                    expanded: false,
                    hasChildren: false,
                    parentId: null,
                },
            ]);
        }
    }), 250), [catalogId, model, t]);
    useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            if (search) {
                handleSearch(search);
            }
            else {
                rootNodeAvailableRef.current = false;
                setRootNodeAvailable(false);
                try {
                    const [rootNode] = yield model.getCached(catalogId);
                    setData([deviceTypeToTreeData(rootNode)]);
                    rootNodeAvailableRef.current = true;
                    setRootNodeAvailable(true);
                }
                catch (e) {
                    setData([
                        {
                            id: "error-root",
                            label: "Failed loading root nodes: " + e.message,
                            icon: null,
                            expanded: false,
                            hasChildren: false,
                            parentId: null,
                        },
                    ]);
                }
            }
        }))();
    }, [model, catalogId, search, handleSearch]);
    const onLoadChildren = useCallback((parentId) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const [nodes] = yield model.fetchAll({
                fieldFilter: {
                    parent_id: {
                        type: "equals",
                        value1: parentId,
                        value2: "",
                    },
                },
            });
            if (nodes.length > 0) {
                setData((prev) => {
                    const newIds = nodes.map((entry) => entry.id);
                    return [
                        ...prev.filter((entry) => !newIds.includes(entry.id)),
                        ...nodes.map(deviceTypeToTreeData),
                    ];
                });
            }
            else {
                setData((prev) => prev.map((entry) => entry.id === parentId ? Object.assign(Object.assign({}, entry), { hasChildren: false }) : entry));
            }
        }
        catch (e) {
            setData((prev) => [
                ...prev,
                {
                    id: "error-root",
                    label: "Failed loading root nodes: " + e.message,
                    icon: null,
                    expanded: false,
                    hasChildren: false,
                    parentId: parentId,
                },
            ]);
        }
    }), [model]);
    useEffect(() => {
        if (search)
            return;
        if (!activeId)
            return;
        if (!rootNodeAvailableRef.current)
            return; // wait for root node to load
        const fetchId = activeId.endsWith("-new")
            ? activeId.split("-")[0]
            : activeId;
        const found = data.find((entry) => entry.id === fetchId);
        if (found) {
            // fetch children
            if (found.hasChildren)
                void onLoadChildren(found.id);
            return; // no need to refetch
        }
        const fetchActiveHandler = () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const [activeNode] = yield model.getCached(fetchId);
                if (activeNode.has_children)
                    yield onLoadChildren(fetchId);
                if (activeNode.parent_ids) {
                    const parentIds = activeNode.parent_ids;
                    if (parentIds.length > 0) {
                        setData((prev) => prev.map((entry) => parentIds.includes(entry.id)
                            ? Object.assign(Object.assign({}, entry), { hasChildren: true }) : entry));
                        yield Promise.all(parentIds.map(onLoadChildren));
                    }
                }
            }
            catch (e) {
                // non critical error, log and ignore
                console.error(e);
            }
        });
        void fetchActiveHandler();
        model.addEventHandler("mutate", fetchActiveHandler, fetchId);
        return () => {
            model.removeEventHandler("mutate", fetchActiveHandler, fetchId);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, rootNodeAvailable, activeId]);
    const onToggleExpanded = useCallback((id) => {
        setData((prev) => prev.map((record) => record.id === id ? Object.assign(Object.assign({}, record), { expanded: !record.expanded }) : record));
    }, []);
    const dataWithCallbacks = useMemo(() => data.map((entry) => (Object.assign(Object.assign({}, entry), { onClick, onAuxClick }))), [data, onClick, onAuxClick]);
    const dataWithCallbacksAndActive = useMemo(() => {
        if (!activeId)
            return dataWithCallbacks;
        const addNewNode = {
            id: activeId.endsWith("-new") ? activeId : activeId + "-new",
            label: t("tree-box.add"),
            icon: null,
            expanded: false,
            hasChildren: false,
            parentId: activeId.endsWith("-new") ? activeId.split("-")[0] : activeId,
            focus: activeId.endsWith("-new"),
            onClick,
            onAuxClick,
        };
        let expandId = activeId.endsWith("-new")
            ? activeId.split("-")[0]
            : activeId;
        let data = (enableAddNew ? [...dataWithCallbacks, addNewNode] : dataWithCallbacks).map((entry) => entry.id === expandId
            ? Object.assign(Object.assign({}, entry), { focus: expandId === activeId, expanded: true, expandLocked: true, hasChildren: true }) : entry);
        while (expandId) {
            let parentFound = false;
            // eslint-disable-next-line no-loop-func
            data = data.map((entry) => {
                if (entry.id !== expandId)
                    return entry;
                if (entry.parentId === expandId)
                    throw new Error("ParentID matches ExpandId: " + entry.id);
                expandId = entry.parentId;
                parentFound = true;
                if (!entry.hasChildren)
                    return entry;
                return Object.assign(Object.assign({}, entry), { expanded: true, expandLocked: true });
            });
            if (!parentFound)
                break;
        }
        return data;
    }, [activeId, dataWithCallbacks, t, onClick, onAuxClick, enableAddNew]);
    const scrolledToActive = useRef(false);
    useEffect(() => {
        scrolledToActive.current = !activeId;
    }, [activeId]);
    useEffect(() => {
        var _a;
        if (scrolledToActive.current)
            return;
        if (!activeId)
            return;
        if (!data.find((entry) => entry.id === activeId))
            return;
        (_a = treeRef.current) === null || _a === void 0 ? void 0 : _a.scrollTo(activeId);
        scrolledToActive.current = true;
    }, [activeId, data]);
    return (_jsx(GroupBox, { label: t("tree-box.label"), children: _jsxs(Grid, { container: true, spacing: 2, direction: "column", className: classes.fullHeight, wrap: "nowrap", children: [_jsx(Grid, { item: true, children: _jsx(TextFieldWithHelp, { placeholder: t("tree-box.search"), value: search, onChange: handleSearchChange, name: "search", fullWidth: true, InputProps: { startAdornment: _jsx(SearchIcon, {}) } }) }), _jsx(Grid, { item: true, xs: true, minHeight: 250, children: data.length === 0 ? (_jsx(Loader, {})) : (_jsx(TreeView, { ref: treeRef, data: dataWithCallbacksAndActive, onLoadChildren: onLoadChildren, onToggleExpanded: onToggleExpanded, renderer: DeviceTypeCatalogTreeRenderer })) })] }) }));
};
export default React.memo(DeviceTypeCatalogTreeView);

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Model, ModelDataTypeDateNullableRendererCC, ModelDataTypeEnumSelectRenderer, ModelDataTypeLocalizedStringRenderer, ModelDataTypeStringRendererCC, ModelVisibilityDisabled, ModelVisibilityEditReadOnly, ModelVisibilityEditRequired, ModelVisibilityGridView, ModelVisibilityGridViewHidden, validatePresence, } from "components-care";
import BackendConnector from "../connectors/BackendConnector";
import { useTenantId } from "../../pages/components/TenantContext";
import supportedLanguagesRaw from "../../assets/data/supported-languages.json";
import getLocalizedString from "../../utils/getLocalizedString";
import i18n from "../../i18n";
import { ID_FIELD_DEF } from "../../constants";
const supportedLanguages = supportedLanguagesRaw;
export const DEVICE_TAG_TYPES = (t) => ["area_of_application", "purpose", "device_type", "technical_property"].map((value) => ({
    value,
    getLabel: () => t("device-tag:type.enum." + value),
}));
const DeviceTagModel = (params) => new Model("device-tag-model", {
    name: {
        type: new ModelDataTypeStringRendererCC({
            placeholder: params.t("device-tag:name.placeholder"),
        }),
        getLabel: () => params.t("device-tag:name.field"),
        visibility: {
            overview: ModelVisibilityGridView,
            create: ModelVisibilityEditRequired,
            edit: ModelVisibilityEditRequired,
        },
        customData: null,
        filterable: true,
        sortable: true,
        columnWidth: [80, 420, 260],
        validate: (value, values, field) => __awaiter(void 0, void 0, void 0, function* () {
            const present = yield validatePresence(value, values, field);
            if (present)
                return present;
            if (!/^[a-z_]+$/.test(value))
                return params.t("device-tag:name.validation.regex");
            return null;
        }),
    },
    labels: {
        type: new ModelDataTypeLocalizedStringRenderer({
            enabledLanguages: supportedLanguages,
            placeholder: params.t("device-tag:labels.placeholder"),
        }),
        getLabel: () => params.t("device-tag:labels.field"),
        visibility: {
            overview: ModelVisibilityGridView,
            create: ModelVisibilityEditRequired,
            edit: ModelVisibilityEditRequired,
        },
        customData: null,
        filterable: true,
        sortable: true,
        columnWidth: [80, 420, 260],
    },
    type: {
        type: new ModelDataTypeEnumSelectRenderer(DEVICE_TAG_TYPES(params.t), {
            disableSearch: true,
            disableClearable: true,
            placeholder: params.t("device-tag:type.placeholder"),
        }),
        getLabel: () => params.t("device-tag:type.field"),
        visibility: {
            overview: ModelVisibilityGridView,
            create: ModelVisibilityEditRequired,
            edit: ModelVisibilityEditRequired,
        },
        customData: null,
        filterable: true,
        sortable: true,
        columnWidth: [80, 420, 260],
        validate: validatePresence,
    },
    created_by_user: {
        type: new ModelDataTypeStringRendererCC(),
        getLabel: () => params.t("common:audit.created-by"),
        visibility: {
            overview: ModelVisibilityGridViewHidden,
            create: ModelVisibilityDisabled,
            edit: ModelVisibilityEditReadOnly,
        },
        customData: null,
        filterable: true,
        sortable: true,
        columnWidth: [80, 320, 160],
    },
    created_at: {
        type: new ModelDataTypeDateNullableRendererCC(),
        getLabel: () => params.t("common:audit.created-at"),
        visibility: {
            overview: ModelVisibilityGridViewHidden,
            create: ModelVisibilityDisabled,
            edit: ModelVisibilityEditReadOnly,
        },
        customData: null,
        filterable: true,
        sortable: true,
        columnWidth: [160, 160, 160],
    },
    updated_by_user: {
        type: new ModelDataTypeStringRendererCC(),
        getLabel: () => params.t("common:audit.updated-by"),
        visibility: {
            overview: ModelVisibilityGridViewHidden,
            create: ModelVisibilityDisabled,
            edit: ModelVisibilityDisabled,
        },
        customData: null,
        filterable: true,
        sortable: true,
        columnWidth: [80, 320, 160],
    },
    updated_at: {
        type: new ModelDataTypeDateNullableRendererCC(),
        getLabel: () => params.t("common:audit.updated-at"),
        visibility: {
            overview: ModelVisibilityGridViewHidden,
            create: ModelVisibilityDisabled,
            edit: ModelVisibilityDisabled,
        },
        customData: null,
        filterable: true,
        sortable: true,
        columnWidth: [160, 160, 160],
    },
    id: Object.assign(Object.assign({}, ID_FIELD_DEF(params.t)), { customData: null }),
}, new BackendConnector(params.target === "tenant"
    ? params.tenantId
        ? `v4/tenants/${params.tenantId}/device_tags`
        : "v4/my/device_tags"
    : `v4/tenants/${params.tenantId}/mdm/device_tags`, "data", {
    additionalQueryParameters: params.filterTagType
        ? {
            "filter[types]": params.filterTagType,
        }
        : undefined,
}), [params.tenantId, params.target], {
    enableRequestBatching: true,
});
export const DeviceTagModelToSelectorData = (record) => ({
    value: record.id,
    label: getLocalizedString(i18n.language, record.labels),
});
export const getDeviceTagModelSort = () => [
    {
        field: "type",
        direction: 1,
    },
    {
        field: "labels." + i18n.language.split("-")[0],
        direction: 1,
    },
];
export const useDeviceTagModelTranslations = () => useTranslation(["device-tag", "common"]);
export const useDeviceTagModel = (params) => {
    const tenantId = useTenantId();
    const { t } = useDeviceTagModelTranslations();
    return useMemo(() => DeviceTagModel(Object.assign({ tenantId, t }, params)), [tenantId, t, params]);
};
export default DeviceTagModel;

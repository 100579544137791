import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useState } from "react";
import { useAuthProviderContext } from "../AuthProvider";
import ProfileMenu from "./ProfileMenu";
import { makeStyles } from "tss-react/mui";
import { ArrowDropDown as ArrowDropDownIcon, ArrowDropUp as ArrowDropUpIcon, Person as AccountIcon, } from "@mui/icons-material";
import { Hidden, IconButton } from "@mui/material";
const useStyles = makeStyles()((theme) => ({
    root: {
        whiteSpace: "nowrap",
    },
    name: {
        cursor: "pointer",
        textDecoration: "underline",
        color: theme.palette.primary.main,
    },
    arrowIcon: {
        width: 16,
        height: 16,
        verticalAlign: "middle",
    },
}));
const AccountActions = () => {
    const { classes } = useStyles();
    const authInfo = useAuthProviderContext();
    const [profileMenuAnchor, setProfileMenuAnchor] = useState(null);
    const openProfileMenu = useCallback((evt) => setProfileMenuAnchor(evt.currentTarget), [setProfileMenuAnchor]);
    const closeProfileMenu = useCallback(() => setProfileMenuAnchor(null), [setProfileMenuAnchor]);
    return (_jsxs(_Fragment, { children: [_jsx(Hidden, { mdDown: true, implementation: "js", children: _jsxs("div", { onClick: openProfileMenu, className: classes.root, children: [_jsxs("span", { className: classes.name, children: [authInfo.current_user.first_name, " ", authInfo.current_user.last_name] }), profileMenuAnchor ? (_jsx(ArrowDropUpIcon, { color: "primary", className: classes.arrowIcon })) : (_jsx(ArrowDropDownIcon, { color: "primary", className: classes.arrowIcon }))] }) }), _jsx(Hidden, { mdUp: true, implementation: "js", children: _jsx(IconButton, { onClick: openProfileMenu, size: "large", children: _jsx(AccountIcon, {}) }) }), _jsx(ProfileMenu, { anchorEl: profileMenuAnchor, handleClose: closeProfileMenu })] }));
};
export default React.memo(AccountActions);

import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { RawTenantRoutes, } from "../TenantRoutesProvider";
import { useLocation, doesRouteMatch } from "components-care";
import { useTranslation } from "react-i18next";
/**
 * Returns the internal name used by the menu to set the active state based off the given path
 * @param definitions The menu item definitions
 * @param path The current location.pathname
 * @param location The menu item id
 * @return The menu item "identifier" used to set the menu item active or undefined if no match has been found
 */
const resolveLocation = (definitions, path, location) => {
    // first recurse to find the deepest matching link
    for (const def of definitions) {
        if (def.children) {
            const nextLevel = resolveLocation(def.children, path, [
                ...location,
                def.title,
            ]);
            if (nextLevel.length > 0)
                return nextLevel;
        }
    }
    // then try this level
    const matchDef = [...definitions]
        .sort((a, b) => { var _a, _b, _c, _d; return ((_b = (_a = b.route) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0) - ((_d = (_c = a.route) === null || _c === void 0 ? void 0 : _c.length) !== null && _d !== void 0 ? _d : 0); })
        .find((def) => def.route &&
        doesRouteMatch("/tenant/:tenantId" + def.route, path, false));
    return matchDef ? [...location, matchDef.title] : [];
};
const Breadcrumb = () => {
    const { pathname: path } = useLocation();
    const loc = resolveLocation(RawTenantRoutes, path, []);
    const { t } = useTranslation("menu");
    return _jsx(_Fragment, { children: ["", ...loc.map((ident) => t(ident))].join(" > ") });
};
export default React.memo(Breadcrumb);

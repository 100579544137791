import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Model, ModelDataTypeBooleanCheckboxRendererCC, ModelDataTypeStringRendererCC, ModelVisibilityEdit, ModelVisibilityEditRequired, ModelVisibilityGridView, ModelVisibilityGridViewHidden, validatePresence, } from "components-care";
import BackendConnector from "../connectors/BackendConnector";
import { useTenantId } from "../../pages/components/TenantContext";
import { ID_FIELD_DEF } from "../../constants";
const PositionModel = (params) => new Model("position-model", {
    title: {
        type: new ModelDataTypeStringRendererCC({
            placeholder: params.t("position:title.placeholder"),
        }),
        getLabel: () => params.t("position:title.field"),
        visibility: {
            overview: ModelVisibilityGridView,
            create: ModelVisibilityEditRequired,
            edit: ModelVisibilityEditRequired,
        },
        customData: null,
        filterable: true,
        sortable: true,
        columnWidth: [80, 420, 260],
        validate: validatePresence,
    },
    description: {
        type: new ModelDataTypeStringRendererCC({
            placeholder: params.t("position:description.placeholder"),
        }),
        getLabel: () => params.t("position:description.field"),
        visibility: {
            overview: ModelVisibilityGridView,
            create: ModelVisibilityEdit,
            edit: ModelVisibilityEdit,
        },
        customData: null,
        filterable: true,
        sortable: true,
        columnWidth: [80, 640, 320],
    },
    show_in_directory: {
        type: new ModelDataTypeBooleanCheckboxRendererCC(),
        getLabel: () => params.t("position:show_in_directory.field"),
        visibility: {
            overview: ModelVisibilityGridViewHidden,
            create: ModelVisibilityEdit,
            edit: ModelVisibilityEdit,
        },
        sortable: true,
        filterable: true,
        customData: null,
        columnWidth: [80, 190, 190],
    },
    id: Object.assign(Object.assign({}, ID_FIELD_DEF(params.t)), { customData: null }),
}, new BackendConnector(`v4/tenants/${params.tenantId}/positions`, "data"), [params.tenantId]);
export const PositionModelToSelectorData = (data) => ({
    value: data.id,
    label: data.title,
});
export const PositionModelSelectorSort = [
    {
        field: "title",
        direction: 1,
    },
];
export const usePositionModelTranslations = () => useTranslation(["position"]);
export const usePositionModel = (params) => {
    const tenantId = useTenantId();
    const { t } = usePositionModelTranslations();
    return useMemo(() => PositionModel(Object.assign({ tenantId, t }, params)), [tenantId, t, params]);
};
export default PositionModel;

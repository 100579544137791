import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback } from "react";
import { FullFormDialog } from "components-care";
const SearchAndSingleSelect = (props) => {
    const { instance: Instance, props: instanceProps, onSelected, title } = props;
    const handleSelect = useCallback((selected) => {
        if (selected.length === 0)
            return;
        onSelected(selected[0]);
    }, [onSelected]);
    const handleClose = useCallback(() => {
        onSelected(null);
    }, [onSelected]);
    return (_jsx(FullFormDialog, { onClose: handleClose, useCustomClasses: true, maxWidth: "md", dialogTitle: title, children: React.createElement(Instance, Object.assign(Object.assign({}, instanceProps), { onSelected: handleSelect, multiSelect: false })) }));
};
export default React.memo(SearchAndSingleSelect);

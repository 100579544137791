var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useContext } from "react";
import { BaseSelectorContext, useDialogContext, useFormContextLite, useFormFieldContext, } from "components-care";
import { IconButton } from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import SearchAndSingleSelect from "../SearchAndBrowse/Instances/SearchAndSingleSelect";
import StaffSearch from "../SearchAndBrowse/Instances/StaffSearch";
import { useButtonEndAdornmentStyles } from "./PickDeviceModelButton";
import reactNodeToElement from "../../../../utils/reactNodeToElement";
const PickEmployeeButton = (props) => {
    const { getBriefingRequirements, getTenantIdMySamedis, team, title } = props;
    const { getFieldValue } = useFormContextLite();
    const { visibility, field, handleChange } = useFormFieldContext();
    const { classes } = useButtonEndAdornmentStyles();
    const selectorContext = useContext(BaseSelectorContext);
    const [pushDialog, popDialog] = useDialogContext();
    const handleSelect = useCallback((selected) => {
        if (selected) {
            handleChange(field, selected);
            if (selectorContext)
                selectorContext.addToLru(selected);
            popDialog();
        }
    }, [handleChange, field, selectorContext, popDialog]);
    const openDialog = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        return pushDialog(_jsx(SearchAndSingleSelect, { instance: StaffSearch, onSelected: handleSelect, props: {
                briefingRequirements: getBriefingRequirements &&
                    (yield getBriefingRequirements(getFieldValue)),
                tenantIdMySamedis: getTenantIdMySamedis && getTenantIdMySamedis(getFieldValue),
                team,
            }, title: title }));
    }), [
        pushDialog,
        handleSelect,
        getBriefingRequirements,
        getFieldValue,
        getTenantIdMySamedis,
        team,
        title,
    ]);
    return reactNodeToElement(!visibility.readOnly && (_jsx(IconButton, { onClick: openDialog, className: classes.button, disabled: visibility.readOnly, size: "large", color: "primary", children: _jsx(SearchIcon, {}) })));
};
export default React.memo(PickEmployeeButton);

import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { useDeviceModelsModel } from "../../../components-care/models/DeviceModelsModel";
import SamedisCrud from "../../../components-care/SamedisCrud";
import DeviceModelsForm from "./components/DeviceModels/DeviceModelsForm";
import { MdmContext } from "./MdmTypes";
const DeviceModelsCRUD = (props) => {
    const model = useDeviceModelsModel({
        target: props.mode,
    });
    return (_jsx(MdmContext.Provider, { value: props.mode, children: _jsx(SamedisCrud, Object.assign({ model: model, gridProps: {
                defaultSort: [
                    {
                        field: "title",
                        direction: 1,
                    },
                ],
            }, deletePermission: ["catalogs.deleter", "catalogs.tenant-deleter"], readPermission: ["catalogs.reader", "catalogs.tenant-reader"], editPermission: ["catalogs.writer", "catalogs.tenant-writer"], newPermission: ["catalogs.writer", "catalogs.tenant-writer"], exportPermission: ["catalogs.xlsx", "catalogs.tenant-xlsx"] }, props, { children: DeviceModelsForm })) }));
};
export default React.memo(DeviceModelsCRUD);

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import DenseLayout from "./DenseLayout";
const ContactRenderer = (props) => {
    var _a;
    const { data, context } = props, otherProps = __rest(props, ["data", "context"]);
    const { t } = useTranslation("contact");
    const responsibleSelection = !!(context.issue || context.incident);
    return (_jsx(DenseLayout, Object.assign({ id: data.id, icon: _jsx("img", { src: data.avatar, alt: t("contact_type.enum." + data.contact_type) }), title: data.name, info: [
            data.email,
            data.url,
            ((_a = data.categories) !== null && _a !== void 0 ? _a : [])
                .map((cat) => t("categories.enum." + cat))
                .join(", "),
        ].filter((e) => !!e), tags: responsibleSelection ? (_jsx(Typography, { color: "textSecondary", align: "right", children: [t("contact_type.enum." + data.contact_type), data.company_name]
                .filter((e) => !!e)
                .join(" • ") })) : undefined }, otherProps)));
};
export default React.memo(ContactRenderer);

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useQuery } from "react-query";
import SamedisApiClient from "../../components-care/connectors/SamedisApiClient";
import { useTenantId } from "../../pages/components/TenantContext";
import { ModelDataStore } from "components-care";
import { hasPermission, usePermissionContext } from "components-care/dist";
import IncidentModel, { useIncidentModelTranslations, } from "../../components-care/models/IncidentModel";
import { ReactQueryRetryHandler } from "../../constants";
export const invalidateDashboardData = (tenantId) => {
    ModelDataStore.removeQueries({
        queryKey: ["tenants/dashboard", { tenantId }],
    });
};
export const useDashboardData = () => {
    const tenantId = useTenantId();
    return useQuery(["tenants/dashboard", { tenantId }], () => __awaiter(void 0, void 0, void 0, function* () {
        return (yield SamedisApiClient.get(`/api/v4/tenants/${tenantId}/dashboard`, null)).data.attributes;
    }), { retry: ReactQueryRetryHandler, staleTime: 1000 });
};
export const useIncidentsDashboardData = () => {
    const tenantId = useTenantId();
    const [perms] = usePermissionContext();
    const isDispatcher = hasPermission(perms, "incidents.dispatcher");
    const isSupporter = hasPermission(perms, "incidents.supporter");
    const { t } = useIncidentModelTranslations();
    return useQuery([
        "tenants/dashboard/incidents",
        tenantId,
        isDispatcher ? "dispatcher" : isSupporter ? "supporter" : "reporter",
    ], () => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b, _c;
        if (!isDispatcher && !isSupporter)
            return { new: 0, pending: 0, inProgress: 0 };
        const model = IncidentModel({
            t,
            tenantId,
            role: isDispatcher ? "dispatcher" : "supporter",
        });
        const newStats = model.index({
            rows: 0,
            fieldFilter: { status: { type: "equals", value1: "new", value2: "" } },
        });
        const pendingStats = model.index({
            rows: 0,
            fieldFilter: {
                status: { type: "equals", value1: "pending", value2: "" },
            },
        });
        const inProgressStats = model.index({
            rows: 0,
            fieldFilter: {
                status: { type: "equals", value1: "in_progress", value2: "" },
            },
        });
        const [, newMeta] = yield newStats;
        const [, pendingMeta] = yield pendingStats;
        const [, inProgressMeta] = yield inProgressStats;
        return {
            new: (_a = newMeta.filteredRows) !== null && _a !== void 0 ? _a : newMeta.totalRows,
            pending: (_b = pendingMeta.filteredRows) !== null && _b !== void 0 ? _b : pendingMeta.totalRows,
            inProgress: (_c = inProgressMeta.filteredRows) !== null && _c !== void 0 ? _c : inProgressMeta.totalRows,
        };
    }), {
        retry: ReactQueryRetryHandler,
        staleTime: 1000,
    });
};

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from "react";
import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import UserInfo from "./components/UserInfo";
import TenantList from "./components/TenantList";
import { useAuthProviderContext, useAuthProviderReset, } from "../components/AuthProvider";
const TenantSelection = () => {
    const authCtx = useAuthProviderContext();
    const resetAuthCtx = useAuthProviderReset();
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.only("xs"));
    useEffect(() => {
        // if the last time auth context was refreshed was less then 10 sec ago, do nothing
        if (authCtx.updated_at > new Date(new Date().getTime() - 10000))
            return;
        // otherwise refresh the auth context
        resetAuthCtx();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (_jsx(Box, { p: isXs ? 2 : 0, children: _jsxs(Grid, { container: true, spacing: 3, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(UserInfo, {}) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(TenantList, {}) })] }) }));
};
export default React.memo(TenantSelection);

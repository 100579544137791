import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Grid } from "@mui/material";
import { FormField } from "components-care";
import DeviceModelUploads from "./DeviceModelUploads";
import DeviceModelLocalizedFormFieldProvider from "./DeviceModelLocalizedFormFieldProvider";
import DeviceModelVideosEdit from "./DeviceModelVideosEdit";
const TabModel = () => {
    return (_jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "device_type_id" }) }), _jsx(Grid, { item: true, xs: 12, md: 6 }), _jsx(Grid, { item: true, xs: 12, md: 6, children: _jsx(FormField, { name: "responsible_company_contact_id" }) }), _jsx(Grid, { item: true, xs: 12, md: 6, children: _jsx(FormField, { name: "title" }) }), _jsx(Grid, { item: true, xs: 12, md: 6, children: _jsx(FormField, { name: "manufacturer_company_contact_id" }) }), _jsx(Grid, { item: true, xs: 12, md: 6, children: _jsx(FormField, { name: "version" }) }), _jsx(Grid, { item: true, xs: 12, md: 6, children: _jsx(DeviceModelLocalizedFormFieldProvider, { children: _jsx(FormField, { name: "manufacturer_product_url_labels" }) }) }), _jsx(Grid, { item: true, xs: 12, md: 6 }), _jsx(Grid, { item: true, xs: 12, md: 6, children: _jsx(FormField, { name: "device_tag_ids" }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(DeviceModelUploads, { category: "model" }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(DeviceModelVideosEdit, { category: "model" }) })] }));
};
export default React.memo(TabModel);

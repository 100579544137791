var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMutation, useQuery } from "react-query";
import SamedisApiClient from "../../components-care/connectors/SamedisApiClient";
import { ModelDataStore } from "components-care";
import { ReactQueryRetryHandler } from "../../constants";
/**
 * Invites for the current user
 */
export const useOpenInvites = () => {
    return useQuery("v4/user/invitations", () => __awaiter(void 0, void 0, void 0, function* () {
        const data = (yield SamedisApiClient.get("/api/v4/user/invitations", null)).data;
        return data.map((record) => record.attributes);
    }), {
        retry: ReactQueryRetryHandler,
    });
};
export const useAcceptInvite = () => {
    return useMutation("PUT_v4/user/invitations", (token) => {
        return SamedisApiClient.put(`/api/v4/user/invitations/${encodeURIComponent(token)}`, null, {});
    }, {
        onSuccess: () => {
            return ModelDataStore.invalidateQueries("v4/user/invitations", {
                exact: true,
            });
        },
    });
};
export const useRejectInvite = () => {
    return useMutation("DELETE_v4/user/invitations", (token) => {
        return SamedisApiClient.delete(`/api/v4/user/invitations/${encodeURIComponent(token)}`, null);
    }, {
        onSuccess: () => {
            return ModelDataStore.invalidateQueries("v4/user/invitations", {
                exact: true,
            });
        },
    });
};

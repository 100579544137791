import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Grid } from "@mui/material";
import { PageHeaderTypographyNew } from "./CustomTypography";
import { makeStyles } from "tss-react/mui";
const useStyles = makeStyles()({
    titleItem: {
        ".CcFormDialog &": {
            display: "none",
        },
    },
    titleItemAction: {
        ".CcFormDialog &": {
            opacity: "0",
        },
    },
});
const BasicPageLayout = (props) => {
    const { title, children, spacing, actions } = props;
    const { classes } = useStyles();
    return (_jsxs(Grid, { container: true, spacing: spacing !== null && spacing !== void 0 ? spacing : 4, alignItems: "stretch", children: [title && (_jsxs(_Fragment, { children: [_jsx(Grid, { item: true, xs: 12, sm: actions ? 8 : undefined, md: actions ? 9 : undefined, className: actions ? classes.titleItemAction : classes.titleItem, children: _jsx(PageHeaderTypographyNew, { children: title }) }), actions && (_jsx(Grid, { item: true, xs: 12, sm: 4, md: 3, children: actions }))] })), children] }));
};
export default React.memo(BasicPageLayout);

import { isSubdomain } from "./index";
import { BI_APP_DOMAIN_PREFIX, ENTERPRISE_APP_DOMAIN_PREFIX, MDM_APP_DOMAIN_PREFIX, MY_APP_DOMAIN_PREFIX, } from "../constants";
const getCurrentApp = () => {
    if (isSubdomain(MDM_APP_DOMAIN_PREFIX))
        return "mdm";
    if (isSubdomain(ENTERPRISE_APP_DOMAIN_PREFIX))
        return "enterprise";
    if (isSubdomain(BI_APP_DOMAIN_PREFIX))
        return "bi";
    if (isSubdomain(MY_APP_DOMAIN_PREFIX))
        return "my";
    return "app";
};
export default getCurrentApp;

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { useTranslation } from "react-i18next";
import { OPERATOR_ORDINANCE_OPTIONS, RISK_LEVEL_OPTIONS, } from "../../../../../components-care/models/DeviceModelsModel";
import { Grid, Typography } from "@mui/material";
import { DeviceModelUploadsNonForm } from "../../../Admin/components/DeviceModels/DeviceModelUploads";
import { GroupBox } from "components-care";
import { useRegulatoryDomain } from "../../../../../utils/useRegulatoryDomain";
import DeviceModelViewerCommunityUploads from "./DeviceModelViewerCommunityUploads";
import VideoList from "./Videos/VideoList";
import { useActiveLanguageContext } from "../../../../../components/ActiveLanguageContext";
const DeviceModelViewerTabTraining = (props) => {
    var _a, _b;
    const { device, enterpriseClientId } = props;
    const { t } = useTranslation("device-models");
    const [activeLanguage] = useActiveLanguageContext();
    const regDomain = useRegulatoryDomain();
    const opAnnexOptions = OPERATOR_ORDINANCE_OPTIONS(t);
    const riskOptions = RISK_LEVEL_OPTIONS(t);
    return (_jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(GroupBox, { label: t("viewer.training.info.title"), children: _jsxs(Grid, { container: true, spacing: 2, children: [regDomain === "DE" && (_jsxs(Grid, { item: true, xs: 12, children: [_jsx(Typography, { variant: "caption2", component: "p", color: "textSecondary", children: t("operator-ordinance.field") }), _jsx(Typography, { variant: "body1", children: ((_a = opAnnexOptions
                                            .find((op) => op.value === device.operator_ordinance)) === null || _a === void 0 ? void 0 : _a.getLabel()) ||
                                            t("viewer.training.info.operator_ordinance-unknown") })] })), _jsxs(Grid, { item: true, xs: 12, children: [_jsx(Typography, { variant: "caption2", component: "p", color: "textSecondary", children: t("risk-level.field") }), _jsx(Typography, { variant: "body1", children: ((_b = riskOptions
                                            .find((risk) => risk.value === device.risk_level)) === null || _b === void 0 ? void 0 : _b.getLabel()) || t("viewer.training.info.risk_level-unknown") })] })] }) }) }), _jsx(Grid, { item: true, xs: 12, md: 6, children: _jsx(DeviceModelUploadsNonForm, { recordId: device.id, category: "training", enterpriseClientId: enterpriseClientId, readOnly: true }) }), _jsx(Grid, { item: true, xs: 12, md: 6, children: _jsx(DeviceModelViewerCommunityUploads, { category: "training", recordId: device.id, enterpriseClientId: enterpriseClientId }) }), _jsx(Grid, { item: true, xs: 12, md: 6, children: _jsx(GroupBox, { label: t("videos.training"), children: _jsx(VideoList, { recordId: device.id, category: "training", language: activeLanguage, scope: "public" }) }) }), _jsx(Grid, { item: true, xs: 12, md: 6, children: _jsx(GroupBox, { label: t("facility-videos.training"), children: _jsx(VideoList, { recordId: device.id, category: "training", language: activeLanguage, scope: "tenant" }) }) })] }));
};
export default React.memo(DeviceModelViewerTabTraining);

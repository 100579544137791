import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from "react";
import { Grid } from "@mui/material";
import { FormField, useFormContext } from "components-care";
import DeviceModelRiskLevelInfo from "./DeviceModelRiskLevelInfo";
import DeviceModelUploads from "./DeviceModelUploads";
import DeviceModelTrainingInherit from "./DeviceModelTrainingInherit";
import DeviceModelVideosEdit from "./DeviceModelVideosEdit";
const TabTraining = () => {
    const { id: recordId, values } = useFormContext();
    const { associated_version_ids } = values;
    const hasVersions = associated_version_ids.length > 0;
    return useMemo(() => (_jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, md: 6, children: _jsx(FormField, { name: "operator_ordinance" }) }), _jsxs(Grid, { item: true, xs: 12, md: 6, children: [_jsx(FormField, { name: "risk_level" }), _jsx(DeviceModelRiskLevelInfo, {})] }), recordId && hasVersions && (_jsx(Grid, { item: true, xs: 12, children: _jsx(DeviceModelTrainingInherit, {}) })), _jsx(Grid, { item: true, xs: 12, children: _jsx(DeviceModelUploads, { category: "training" }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(DeviceModelVideosEdit, { category: "training" }) })] })), [recordId, hasVersions]);
};
export default React.memo(TabTraining);

import i18n from "../i18n";
const fallbacks = [
    "en",
    "de",
    "fr",
    "ru",
    "uz",
];
const getLocalizedString = (locale, data) => {
    if (!data)
        return "";
    if (!locale)
        locale = i18n.language;
    if (locale in data && data[locale])
        return data[locale];
    const lang = locale.includes("-") ? locale.split("-")[0] : locale;
    if (lang in data && data[lang])
        return data[lang];
    const fallback = fallbacks.find((lang) => lang in data && data[lang]);
    if (!fallback)
        return ""; // no data
    return data[fallback];
};
export const getAvailableLanguage = (locale, languages) => {
    if (!locale)
        locale = i18n.language;
    const lang = (locale.includes("-") ? locale.split("-")[0] : locale);
    if (languages.includes(lang))
        return lang;
    const fallback = fallbacks.find((lang) => languages.includes(lang));
    return fallback !== null && fallback !== void 0 ? fallback : null;
};
export default getLocalizedString;

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FullFormDialog as FormDialog, Model, ModelDataTypeBackendMultiSelectRenderer, ModelDataTypeBackendSingleSelectRenderer, ModelDataTypeBooleanCheckboxRendererCC, ModelDataTypeDateNullableRendererCC, ModelDataTypeLocalizedStringRenderer, ModelDataTypeStringRendererCC, ModelVisibilityDisabled, ModelVisibilityDisabledReadOnly, ModelVisibilityEdit, ModelVisibilityEditReadOnly, ModelVisibilityEditRequired, ModelVisibilityGridView, ModelVisibilityGridViewHidden, ModelVisibilityHidden, useDialogContext, validatePresence, } from "components-care";
import BackendConnector from "../connectors/BackendConnector";
import DeviceTypeCRUD from "../../pages/TenantSpecific/Admin/DeviceType";
import { useAuthProviderContext } from "../../pages/components/AuthProvider";
import { useTenantId } from "../../pages/components/TenantContext";
import { getLruConfig } from "../../utils";
import handleBackendMultiSelectLoadError from "../../utils/handleBackendMultiSelectLoadError";
import supportedLanguagesRaw from "../../assets/data/supported-languages.json";
import DeviceTagModel, { DeviceTagModelToSelectorData, getDeviceTagModelSort, } from "./DeviceTagModel";
import DeviceTypeParentsRenderer from "./Types/Renderers/DeviceTypeParentsRenderer";
import PickDeviceTagsButton from "../../pages/TenantSpecific/components/Dialogs/PickDeviceTagsButton";
import RendererEnumSelectCustomReadonly from "../types/renderers/RendererEnumSelectCustomReadonly";
import getLocalizedString from "../../utils/getLocalizedString";
import RendererBackendObjectIdArray from "../types/renderers/RendererBackendObjectIdArray";
import { ID_FIELD_DEF } from "../../constants";
import RendererObjectId from "../types/renderers/RendererObjectId";
const supportedLanguages = supportedLanguagesRaw;
const DeviceTypeModel = (params) => new Model("device-type-model-" + (params.tenantId ? params.target : "my"), {
    title: {
        type: new ModelDataTypeStringRendererCC({
            placeholder: params.t("device-type:title.placeholder"),
        }),
        getLabel: () => params.t("device-type:title.field"),
        visibility: {
            overview: ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityDisabledReadOnly,
            edit: ModelVisibilityDisabledReadOnly,
        },
        customData: null,
        filterable: true,
        sortable: true,
        columnWidth: [160, 640, 320],
    },
    title_labels: {
        type: new ModelDataTypeLocalizedStringRenderer({
            enabledLanguages: supportedLanguages,
            getFallbackLabel: (_, values) => values.title,
            getFallbackLabelValues: ["title"],
            placeholder: params.t("device-type:title.placeholder"),
        }),
        getLabel: () => params.t("device-type:title.field"),
        visibility: {
            overview: ModelVisibilityGridView,
            create: ModelVisibilityEditRequired,
            edit: ModelVisibilityEditRequired,
        },
        customData: null,
        validate: validatePresence,
        filterable: true,
        sortable: true,
        columnWidth: [160, 640, 320],
    },
    title_with_path: {
        type: new ModelDataTypeStringRendererCC(),
        getLabel: () => params.t("device-type:title-with-path"),
        visibility: {
            overview: ModelVisibilityGridView,
            create: ModelVisibilityDisabledReadOnly,
            edit: ModelVisibilityDisabledReadOnly,
        },
        customData: null,
        filterable: true,
        sortable: true,
        columnWidth: [320, 1280, 510],
    },
    description_labels: {
        type: new ModelDataTypeLocalizedStringRenderer({
            enabledLanguages: supportedLanguages,
            placeholder: params.t("device-type:description.placeholder"),
        }),
        getLabel: () => params.t("device-type:description.field"),
        visibility: {
            overview: ModelVisibilityDisabled,
            create: ModelVisibilityEdit,
            edit: ModelVisibilityEdit,
        },
        customData: null,
    },
    device_tag_ids: {
        type: new ModelDataTypeBackendMultiSelectRenderer({
            sort: getDeviceTagModelSort(),
            modelToSelectorData: DeviceTagModelToSelectorData,
            placeholder: params.t("device-type:device_tag_ids.placeholder"),
            searchResultLimit: 50,
            onLoadError: handleBackendMultiSelectLoadError,
            endAdornment: (_jsx(PickDeviceTagsButton, { target: params.target, title: params.t("device-type:device_tag_ids.dialog-title") })),
            lru: getLruConfig(params.tenantId, "device-type.tags"),
        }),
        getLabel: () => params.t("device-type:device_tag_ids.label"),
        visibility: {
            overview: ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityEdit,
            edit: ModelVisibilityEdit,
        },
        getRelationModel: () => DeviceTagModel({
            tenantId: params.tenantId,
            t: params.t,
            target: params.target,
        }),
        customData: null,
    },
    trust_level: {
        type: new RendererEnumSelectCustomReadonly(["tenant", "supplier", "community", "manufacturer"].map((value) => ({
            value,
            getLabel: () => params.t("device-type:trust_level.enum." + value),
        }))),
        getLabel: () => params.t("device-type:trust_level.field"),
        visibility: {
            overview: ModelVisibilityGridView,
            create: ModelVisibilityDisabledReadOnly,
            edit: ModelVisibilityEditReadOnly,
        },
        customData: null,
        filterable: true,
        sortable: true,
        columnWidth: [80, 320, 160],
    },
    parent_id: {
        type: new ModelDataTypeBackendSingleSelectRenderer({
            modelToSelectorData: (data) => ({
                value: data.id,
                label: data.title_with_path,
            }),
            placeholder: params.t("device-type:select-device-type"),
            lru: getLruConfig(params.tenantId, "device-type-parent"),
            onLoadError: handleBackendMultiSelectLoadError,
            sort: [{ field: "title_with_path", direction: 1 }],
            searchResultLimit: 50,
        }),
        getRelationModel: () => DeviceTypeModel(Object.assign(Object.assign({}, params), { extraParams: {
                "filter[scope]": "tenant",
            } })),
        getDefaultValue: () => null,
        getLabel: () => params.t("device-type:select-device-type"),
        visibility: {
            overview: ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityEditRequired,
            edit: ModelVisibilityEditRequired,
        },
        customData: null,
        validate: validatePresence,
    },
    parent_ids: {
        type: new RendererBackendObjectIdArray(),
        getLabel: () => "",
        visibility: {
            overview: ModelVisibilityDisabledReadOnly,
            edit: ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityDisabled,
        },
        customData: null,
    },
    has_children: {
        type: new ModelDataTypeBooleanCheckboxRendererCC(),
        getLabel: () => "",
        visibility: {
            overview: ModelVisibilityDisabledReadOnly,
            edit: ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityDisabled,
        },
        customData: null,
    },
    parents: {
        type: new DeviceTypeParentsRenderer(),
        getLabel: () => "",
        visibility: {
            overview: ModelVisibilityDisabledReadOnly,
            edit: ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityDisabled,
        },
        customData: null,
    },
    tenant_id: {
        type: new RendererObjectId(),
        getLabel: () => "",
        getDefaultValue: () => params.tenantId,
        visibility: {
            overview: ModelVisibilityDisabled,
            create: ModelVisibilityHidden,
            edit: ModelVisibilityDisabled,
        },
        customData: null,
    },
    created_by_user: {
        type: new ModelDataTypeStringRendererCC(),
        getLabel: () => params.t("common:audit.created-by"),
        visibility: {
            overview: ModelVisibilityGridViewHidden,
            create: ModelVisibilityDisabled,
            edit: ModelVisibilityEditReadOnly,
        },
        customData: null,
        filterable: true,
        sortable: true,
        columnWidth: [80, 320, 160],
    },
    created_at: {
        type: new ModelDataTypeDateNullableRendererCC(),
        getLabel: () => params.t("common:audit.created-at"),
        visibility: {
            overview: ModelVisibilityGridViewHidden,
            create: ModelVisibilityDisabled,
            edit: ModelVisibilityEditReadOnly,
        },
        customData: null,
        filterable: true,
        sortable: true,
        columnWidth: [160, 160, 160],
    },
    updated_by_user: {
        type: new ModelDataTypeStringRendererCC(),
        getLabel: () => params.t("common:audit.updated-by"),
        visibility: {
            overview: ModelVisibilityGridViewHidden,
            create: ModelVisibilityDisabled,
            edit: ModelVisibilityEditReadOnly,
        },
        customData: null,
        filterable: true,
        sortable: true,
        columnWidth: [80, 320, 160],
    },
    updated_at: {
        type: new ModelDataTypeDateNullableRendererCC(),
        getLabel: () => params.t("common:audit.updated-at"),
        visibility: {
            overview: ModelVisibilityGridViewHidden,
            create: ModelVisibilityDisabled,
            edit: ModelVisibilityEditReadOnly,
        },
        customData: null,
        filterable: true,
        sortable: true,
        columnWidth: [160, 160, 160],
    },
    id: Object.assign(Object.assign({}, ID_FIELD_DEF(params.t)), { customData: null }),
}, new BackendConnector(!params.tenantId
    ? "v4/my/device_types"
    : params.target === "tenant"
        ? `v4/tenants/${params.tenantId}/device_types`
        : params.target === "admin"
            ? "v4/device_types"
            : `v4/tenants/${params.tenantId}/mdm/device_types`, "data", {
    additionalQueryParameters: Object.assign(Object.assign({}, params.extraParams), { tenant_id: params.target === "admin" ? params.tenantId : undefined }),
    forceFieldFilter: params.ofCatalog
        ? {
            parent_ids: {
                type: "equals",
                value1: params.ofCatalog,
                value2: "",
            },
        }
        : params.onlyParent
            ? {
                parent_id: {
                    type: "empty",
                    value1: "empty",
                    value2: "",
                },
            }
            : undefined,
}), [params.tenantId, params.target]);
export const DeviceTypeModelSelectorSort = [
    { field: "title", direction: 1 },
];
export const DeviceTypeModelToSelectorData = (data) => ({
    value: data.id,
    label: getLocalizedString(null, data.title_labels),
});
export const useAddDeviceTypeDialog = (target) => {
    const [pushDialog, popDialog] = useDialogContext();
    const { t } = useTranslation("device-models");
    const tenantId = useTenantId();
    const authInfo = useAuthProviderContext();
    const tenant = authInfo.current_user.tenants.find((tenant) => tenant.id === tenantId);
    return useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const data = yield new Promise((resolve, reject) => pushDialog(_jsx(FormDialog, { dialogTitle: t("device-type.add-new"), maxWidth: "lg", useCustomClasses: true, onClose: reject, children: _jsx(DeviceTypeCRUD, { disableRouting: true, initialView: "new", parent: [
                        tenant.type_catalog_id,
                        [tenant.type_catalog_id],
                    ], mode: target, formProps: {
                        onSubmit: (data) => {
                            popDialog();
                            resolve(data);
                        },
                    } }) })));
            return {
                value: data.id,
                label: data.title_with_path,
            };
        }
        catch (_e) {
            return null;
        }
    }), [popDialog, pushDialog, t, target, tenant]);
};
export const useDeviceTypeModelTranslations = () => useTranslation(["device-type", "common"]);
export const useDeviceTypeModel = (params) => {
    const { t } = useDeviceTypeModelTranslations();
    return useMemo(() => DeviceTypeModel(Object.assign(Object.assign({}, params), { t })), [params, t]);
};
export default DeviceTypeModel;

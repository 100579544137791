var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo, useRef, useState, } from "react";
import { Grid, InputAdornment, Typography, useTheme, IconButton, Tooltip, Box, } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { combineClassNames, debounce, FilterActiveIcon, FilterIcon, shallowCompare, TextFieldWithHelp, } from "components-care";
import { Search } from "@mui/icons-material";
import * as Sentry from "@sentry/react";
import AutoSizer from "react-virtualized-auto-sizer";
import InfiniteLoader from "react-window-infinite-loader";
import { FixedSizeList } from "react-window";
import BrowseEntry, { BrowseEntryContext, } from "./BrowseEntry";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles()((theme) => ({
    root: {
        flexBasis: 0,
        flexGrow: 1,
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.background.paper,
    },
    footer: {
        height: 42,
        borderTop: `1px solid ${theme.palette.divider}`,
    },
    footerText: {
        lineHeight: "42px",
        marginLeft: theme.spacing(2),
    },
    contentOuter: {
        position: "relative",
    },
    contentInner: {
        position: "absolute",
        width: "100%",
        height: "100%",
    },
    filterBtn: {
        padding: theme.spacing(1),
    },
    filterBtnIcon: {
        paddingRight: 4,
    },
    searchActive: {
        color: theme.palette.warning.main,
    },
}));
const BrowseContainer = (props) => {
    var _a, _b;
    const { loadRecords, filters, selected, onSelected, multiSelect, onOpenFilters, renderer: RecordRenderer, rendererContext, } = props;
    const { t } = useTranslation("global-device-catalog");
    const filtersActive = !!Object.values(filters).find((x) => Array.isArray(x) ? x.length > 0 : x);
    const theme = useTheme();
    const entryHeight = ((_a = props.rendererItemHeight) !== null && _a !== void 0 ? _a : 100) +
        2 * parseInt(theme.spacing(1) /* assets output in px */);
    const { classes } = useStyles();
    const infiniteLoader = useRef(null);
    const [resetPending, setResetPending] = useState(false);
    const infiniteLoaderReset = useMemo(() => debounce(() => {
        setRecords([]);
        setTotal(null);
        setResetPending(true);
    }, 500), []);
    useEffect(() => {
        var _a;
        if (!resetPending)
            return;
        (_a = infiniteLoader.current) === null || _a === void 0 ? void 0 : _a.resetloadMoreItemsCache(true);
        setResetPending(false);
    }, [resetPending]);
    const [search, setSearch] = useState("");
    // keep search and filters in refs so we can always access the current values in async loadMore function
    // this way we don't get stale data
    const searchRef = useRef(search);
    const filtersRef = useRef(filters);
    filtersRef.current = filters;
    const [lastError, setLastError] = useState(null);
    const [records, setRecords] = useState([]);
    const loadingCounter = useRef(0);
    const loadNextCall = useRef(null);
    const [total, setTotal] = useState(null);
    const handleSearchChange = useCallback((evt) => {
        searchRef.current = evt.target.value;
        setSearch(evt.target.value);
    }, []);
    const initialLoad = useRef(true);
    // react 18 remount stuff
    useEffect(() => {
        return () => {
            initialLoad.current = true;
        };
    }, []);
    useEffect(() => {
        if (initialLoad.current) {
            initialLoad.current = false;
            return;
        }
        infiniteLoaderReset();
    }, [infiniteLoaderReset, search, filters]);
    const loadMore = useCallback((startIndex) => __awaiter(void 0, void 0, void 0, function* () {
        if (loadingCounter.current > 0) {
            const nextLoadCall = loadNextCall.current;
            if (nextLoadCall != null) {
                //nextLoadCall.reject(new Error("Load cancelled")); // spams browser console
                nextLoadCall.resolve(); // seems to work just fine, no console spam
            }
            loadingCounter.current = 2;
            return new Promise((resolve, reject) => {
                loadNextCall.current = {
                    startIndex,
                    resolve,
                    reject,
                };
            });
        }
        try {
            const search = searchRef.current;
            const filters = filtersRef.current;
            loadingCounter.current++;
            const { data: moreRecords, total } = yield loadRecords(search, filters, startIndex);
            // check if filter or search changed
            if (search !== searchRef.current ||
                !shallowCompare(filters, filtersRef.current)) {
                // if they changed we don't want to set data to just keep the loading screen
                return;
            }
            setRecords((opts) => {
                const newOpts = [...opts];
                for (let i = 0; i < moreRecords.length; ++i) {
                    newOpts[startIndex + i] = moreRecords[i];
                }
                return newOpts;
            });
            setTotal(total);
            setLastError(null);
        }
        catch (e) {
            console.error(e);
            Sentry.captureException(e);
            setLastError(e);
        }
        finally {
            loadingCounter.current--;
            if (loadingCounter.current > 0) {
                loadingCounter.current = 0;
                const nextLoadCall = loadNextCall.current;
                if (!nextLoadCall) {
                    /* eslint-disable no-unsafe-finally */
                    // noinspection ThrowInsideFinallyBlockJS
                    throw new Error("Invalid state");
                    /* eslint-enable no-unsafe-finally */
                }
                loadNextCall.current = null;
                yield loadMore(nextLoadCall.startIndex)
                    .then(nextLoadCall.resolve)
                    .catch(nextLoadCall.reject);
            }
        }
    }), [loadRecords]);
    const handleSelect = useCallback((id, state) => {
        if (!onSelected)
            return;
        let newSelection = selected ? [...selected] : [];
        if (state)
            newSelection.push(id);
        else
            newSelection = newSelection.filter((selectedId) => selectedId !== id);
        onSelected(newSelection);
    }, [selected, onSelected]);
    const recordContext = useMemo(() => ({
        renderer: RecordRenderer,
        data: records
            .map((record) => record
            ? {
                data: record,
                selected: selected ? selected.includes(record.id) : false,
                selectable: !!onSelected,
                multiSelect: multiSelect,
                onSelect: handleSelect,
                context: rendererContext,
            }
            : null)
            .concat(lastError ? [lastError] : []),
    }), [
        RecordRenderer,
        records,
        lastError,
        selected,
        onSelected,
        multiSelect,
        handleSelect,
        rendererContext,
    ]);
    return (_jsxs(Grid, { container: true, direction: "column", className: classes.root, children: [_jsx(Grid, { item: true, children: _jsx(TextFieldWithHelp, { fullWidth: true, value: search, onChange: handleSearchChange, InputProps: {
                        startAdornment: (_jsxs(InputAdornment, { position: "start", children: [onOpenFilters && (_jsx(Tooltip, { title: (_b = t("filters.button")) !== null && _b !== void 0 ? _b : "", children: _jsx(IconButton, { onClick: onOpenFilters, color: "primary", className: combineClassNames([
                                            classes.filterBtn,
                                            filtersActive && classes.searchActive,
                                        ]), size: "large", children: filtersActive ? (_jsx(FilterActiveIcon, { className: classes.filterBtnIcon })) : (_jsx(FilterIcon, { className: classes.filterBtnIcon })) }) })), _jsx(Search, { className: combineClassNames([
                                        search && classes.searchActive,
                                    ]) })] })),
                    } }) }), _jsx(Grid, { item: true, xs: true, className: classes.contentOuter, children: _jsx("div", { className: classes.contentInner, children: total === 0 ? (_jsx(Box, { p: 2, children: _jsx(Typography, { children: t(filtersActive || search ? "no-data-filter" : "no-data") }) })) : (_jsx(AutoSizer, { children: ({ width, height }) => (_jsx(InfiniteLoader, { ref: infiniteLoader, isItemLoaded: (index) => index < records.length && !!records[index], loadMoreItems: loadMore, itemCount: total !== null && total !== void 0 ? total : 25, minimumBatchSize: 25, children: ({ ref, onItemsRendered }) => (_jsx(BrowseEntryContext.Provider, { value: recordContext, children: _jsx(FixedSizeList, { ref: ref, itemSize: entryHeight, height: height, itemCount: total !== null && total !== void 0 ? total : 25, width: width, onItemsRendered: onItemsRendered, children: BrowseEntry }) })) })) })) }) }), _jsx(Grid, { item: true, className: classes.footer, children: total != null && (_jsxs(Typography, { className: classes.footerText, children: [t("pagination.total", { COUNT: total }), " ", selected &&
                            selected.length > 0 &&
                            t("pagination.selected", { COUNT: selected.length })] })) })] }));
};
export default React.memo(BrowseContainer);

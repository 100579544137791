import i18n from "../../i18n";
/**
 * Type to handle Device Model Regulatories
 */
class TypeRegulatories {
    constructor(params) {
        this.params = params;
    }
    validate(value, field) {
        if (value.find((v) => { var _a, _b; return !((_a = v.label) === null || _a === void 0 ? void 0 : _a.trim()) || !((_b = v.value) === null || _b === void 0 ? void 0 : _b.trim()); }))
            return i18n.t("common:errors.regulatory-input.blank-records", {
                FIELD: field.getLabel(),
            });
        return null;
    }
    getFilterType() {
        return null;
    }
    getDefaultValue() {
        return [];
    }
    stringify(value) {
        return value.map((entry) => `${entry.label}: ${entry.value}`).join("; ");
    }
}
export default TypeRegulatories;

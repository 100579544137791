import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Popover, Grid } from "@mui/material";
import { ActionButton, useDialogContext, useFormContextLite, } from "components-care";
import { Menu as MenuIcon } from "@mui/icons-material";
import ContactInviteDialog from "./ContactInviteDialog";
import { useActionButtonStyles } from "../../../../../components/ActionButton";
const ContactActionsButton = (props) => {
    const { formProps } = props;
    const { t } = useTranslation("contact");
    const { classes } = useActionButtonStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [pushDialog] = useDialogContext();
    const { readOnly } = useFormContextLite();
    const handleClick = useCallback((event) => {
        setAnchorEl(event.currentTarget);
    }, []);
    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, []);
    const handleOpenInviteDialog = useCallback(() => {
        if (!formProps.id)
            throw new Error("id is null");
        pushDialog(_jsx(ContactInviteDialog, { contactIds: [formProps.id] }));
        handleClose();
    }, [formProps.id, handleClose, pushDialog]);
    return (_jsxs(_Fragment, { children: [_jsx(ActionButton, { onClick: handleClick, icon: _jsx(MenuIcon, {}), children: t("buttons.actions") }), _jsx(Popover, { open: !!anchorEl, anchorEl: anchorEl, onClose: handleClose, anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right",
                }, transformOrigin: {
                    vertical: "top",
                    horizontal: "right",
                }, className: classes.popover, children: _jsx("div", { className: classes.content, children: _jsx(Grid, { container: true, spacing: 2, children: _jsx(Grid, { item: true, xs: 12, children: _jsx(ActionButton, { onClick: handleOpenInviteDialog, disabled: !formProps.id || readOnly, children: t("buttons.invite") }) }) }) }) })] }));
};
export default React.memo(ContactActionsButton);

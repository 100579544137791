import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { GridSingleSelectFilter } from "components-care";
import { useTranslation } from "react-i18next";
import { GRID_FILTER_BREAKPOINTS_SINGLE_ENTRY } from "../../../../constants";
import { STATUS_OPTIONS } from "../../../../components-care/models/IncidentModel";
const IncidentFilterBar = (props) => {
    var _a;
    const { customData, setCustomData, inDialog } = props;
    const { t } = useTranslation("incident");
    return (_jsx(_Fragment, { children: _jsx(GridSingleSelectFilter, { label: t("status.field"), options: [
                {
                    value: "",
                    label: t("grid.filter.show-all"),
                },
                ...STATUS_OPTIONS(t).map((entry) => ({
                    value: entry.value,
                    label: entry.getLabel(),
                })),
            ], selected: (_a = customData["filter[status]"]) !== null && _a !== void 0 ? _a : "", defaultSelection: "", onSelect: (selected) => setCustomData((old) => (Object.assign(Object.assign({}, old), { "filter[status]": selected || null }))), dialog: inDialog, barBreakpoints: GRID_FILTER_BREAKPOINTS_SINGLE_ENTRY }) }));
};
export default React.memo(IncidentFilterBar);

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import getLocalizedString from "../../../../../utils/getLocalizedString";
import { useTranslation } from "react-i18next";
import DenseLayout from "./DenseLayout";
const DeviceLocationRenderer = (props) => {
    const { data } = props, otherProps = __rest(props, ["data"]);
    const { i18n, t } = useTranslation("device-tag");
    return (_jsx(DenseLayout, Object.assign({ id: data.id, title: t("type.enum." + data.type) +
            ": " +
            getLocalizedString(i18n.language, data.labels) }, otherProps)));
};
export default React.memo(DeviceLocationRenderer);

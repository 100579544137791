var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Model, ModelDataTypeStringRendererCC, ModelVisibilityDisabled, ModelVisibilityEdit, ModelVisibilityEditRequired, ModelVisibilityGridView, ModelVisibilityHidden, useDialogContext, validatePresence, FullFormDialog as FormDialog, ModelDataTypeDateNullableRendererCC, ModelVisibilityGridViewHidden, ModelDataTypeBooleanCheckboxRendererCC, ModelVisibilityDisabledReadOnly, } from "components-care";
import BackendConnector from "../connectors/BackendConnector";
import { useTenantId } from "../../pages/components/TenantContext";
import { ID_FIELD_DEF } from "../../constants";
import RendererObjectId from "../types/renderers/RendererObjectId";
const DepartmentCRUD = React.lazy(() => import("../../pages/TenantSpecific/Facility/Departments"));
const DepartmentModel = (params) => new Model("department-model", {
    tenant_id: {
        type: new RendererObjectId(),
        getLabel: () => "",
        visibility: {
            overview: ModelVisibilityDisabledReadOnly,
            create: ModelVisibilityDisabledReadOnly,
            edit: ModelVisibilityHidden,
        },
        customData: null,
    },
    title: {
        type: new ModelDataTypeStringRendererCC({
            placeholder: params.t("department:title.placeholder"),
        }),
        getLabel: () => params.t("department:title.field"),
        visibility: {
            overview: ModelVisibilityGridView,
            create: ModelVisibilityEditRequired,
            edit: ModelVisibilityEditRequired,
        },
        customData: null,
        filterable: true,
        sortable: true,
        columnWidth: [80, 420, 260],
        validate: validatePresence,
    },
    notes: {
        type: new ModelDataTypeStringRendererCC({
            multiline: true,
            rows: 5,
            placeholder: params.t("department:notes.placeholder"),
        }),
        getLabel: () => params.t("department:notes.field"),
        visibility: {
            overview: ModelVisibilityGridView,
            create: ModelVisibilityEdit,
            edit: ModelVisibilityEdit,
        },
        customData: null,
        filterable: true,
        sortable: true,
        columnWidth: [80, 640, 320],
    },
    cost_center_number: {
        type: new ModelDataTypeStringRendererCC({
            placeholder: params.t("department:cost-center-number.placeholder"),
        }),
        getLabel: () => params.t("department:cost-center-number.field"),
        visibility: {
            overview: ModelVisibilityGridView,
            create: ModelVisibilityEdit,
            edit: ModelVisibilityEdit,
        },
        customData: null,
        filterable: true,
        sortable: true,
        columnWidth: [80, 260, 130],
    },
    inventory_count: {
        type: new ModelDataTypeStringRendererCC(),
        getLabel: () => params.t("department:inventories.field"),
        visibility: {
            overview: ModelVisibilityGridView,
            create: ModelVisibilityDisabled,
            edit: ModelVisibilityDisabled,
        },
        customData: null,
        filterable: true,
        sortable: true,
        columnWidth: [80, 120, 120],
    },
    is_active: {
        type: new ModelDataTypeBooleanCheckboxRendererCC(),
        getLabel: () => params.t("department:is_active.field"),
        visibility: {
            overview: ModelVisibilityGridView,
            create: ModelVisibilityEdit,
            edit: ModelVisibilityEdit,
        },
        customData: null,
        getDefaultValue: () => true,
        filterable: true,
        sortable: true,
        columnWidth: [100, 100, 100],
    },
    created_by_user: {
        type: new ModelDataTypeStringRendererCC(),
        getLabel: () => params.t("common:audit.created-by"),
        visibility: {
            overview: ModelVisibilityGridViewHidden,
            create: ModelVisibilityDisabled,
            edit: ModelVisibilityDisabled,
        },
        customData: null,
        filterable: true,
        sortable: true,
        columnWidth: [80, 320, 160],
    },
    created_at: {
        type: new ModelDataTypeDateNullableRendererCC(),
        getLabel: () => params.t("common:audit.created-at"),
        visibility: {
            overview: ModelVisibilityGridViewHidden,
            create: ModelVisibilityDisabled,
            edit: ModelVisibilityDisabled,
        },
        customData: null,
        filterable: true,
        sortable: true,
        columnWidth: [160, 160, 160],
    },
    updated_by_user: {
        type: new ModelDataTypeStringRendererCC(),
        getLabel: () => params.t("common:audit.updated-by"),
        visibility: {
            overview: ModelVisibilityGridViewHidden,
            create: ModelVisibilityDisabled,
            edit: ModelVisibilityDisabled,
        },
        customData: null,
        filterable: true,
        sortable: true,
        columnWidth: [80, 320, 160],
    },
    updated_at: {
        type: new ModelDataTypeDateNullableRendererCC(),
        getLabel: () => params.t("common:audit.updated-at"),
        visibility: {
            overview: ModelVisibilityGridViewHidden,
            create: ModelVisibilityDisabled,
            edit: ModelVisibilityDisabled,
        },
        customData: null,
        filterable: true,
        sortable: true,
        columnWidth: [160, 160, 160],
    },
    id: Object.assign(Object.assign({}, ID_FIELD_DEF(params.t)), { customData: null }),
}, new BackendConnector(params.tenantId
    ? params.enterpriseClientId
        ? `v4/enterprise/tenants/${params.tenantId}/clients/${params.enterpriseClientId}/departments`
        : `v4/tenants/${params.tenantId}/departments`
    : "v4/my/departments", "data"), [params.tenantId, params.enterpriseClientId]);
export const useAddDepartmentDialog = () => {
    const [pushDialog, popDialog] = useDialogContext();
    const { t } = useTranslation("inventory");
    return useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const data = yield new Promise((resolve, reject) => pushDialog(_jsx(FormDialog, { dialogTitle: t("department-id.add-new"), maxWidth: "sm", useCustomClasses: true, onClose: reject, children: _jsx(DepartmentCRUD, { disableRouting: true, initialView: "new", formProps: {
                        onSubmit: (data) => {
                            popDialog();
                            resolve(data);
                        },
                    } }) })));
            return {
                value: data.id,
                label: data.title,
            };
        }
        catch (_e) {
            return null;
        }
    }), [popDialog, pushDialog, t]);
};
export const DepartmentModelToSelectorData = (data) => ({
    value: data.id,
    label: data.title,
});
export const DepartmentModelSelectorSort = [
    {
        field: "title",
        direction: 1,
    },
];
export const useDepartmentModelTranslations = () => useTranslation(["department", "common"]);
export const useDepartmentModel = (params) => {
    const tenantId = useTenantId();
    const { t } = useDepartmentModelTranslations();
    return useMemo(() => DepartmentModel(Object.assign({ tenantId, t }, params)), [tenantId, t, params]);
};
export default DepartmentModel;

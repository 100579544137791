var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useContext } from "react";
import { FormControlLabel, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { Skeleton } from "@mui/material";
import { Checkbox } from "components-care";
import { useTranslation } from "react-i18next";
export const FilterEntryMultiSelectOptionContext = React.createContext(undefined);
export const useFilterEntryMultiSelectOptionContext = () => {
    const ctx = useContext(FilterEntryMultiSelectOptionContext);
    if (!ctx)
        throw new Error("FilterEntryMultiSelectOptionContext not set");
    return ctx;
};
const useStyles = makeStyles()((theme) => ({
    root: {
        padding: `0 ${theme.spacing(1)}`,
    },
    entryRoot: {
        width: "calc(100% - 32px)",
    },
    entryLabel: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    entryCheckboxRoot: {
        padding: "2px 9px",
    },
    errorLabel: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        cursor: "pointer",
    },
}));
const FilterEntryMultiSelectOptionInner = (props) => {
    const { value, opt, selected, onSelectionChanged, onRetry } = props;
    const { classes } = useStyles();
    const { t } = useTranslation("global-device-catalog");
    const handleChange = useCallback((_evt, checked) => onSelectionChanged(value, checked), [onSelectionChanged, value]);
    if (!opt) {
        return _jsx(Skeleton, { variant: "rectangular" });
    }
    if (opt instanceof Error) {
        return (_jsx(Typography, { className: classes.errorLabel, color: "error", title: t("filters.retry-load"), onClick: () => onRetry(value), children: opt.message }));
    }
    return (_jsx(FormControlLabel, { control: _jsx(Checkbox, { checked: selected, classes: { root: classes.entryCheckboxRoot } }), title: opt.tooltip, label: opt.label, onChange: handleChange, value: opt.value, classes: {
            root: classes.entryRoot,
            label: classes.entryLabel,
        } }));
};
const FilterEntryMultiSelectOptionInnerMemo = React.memo(FilterEntryMultiSelectOptionInner);
const FilterEntryMultiSelectOption = (props) => {
    const { classes } = useStyles();
    const _a = useFilterEntryMultiSelectOptionContext(), { data } = _a, other = __rest(_a, ["data"]);
    const record = data[props.index];
    if (!record)
        return (_jsx(Skeleton, { style: props.style, className: classes.root, variant: "rectangular" }));
    if (record instanceof Error)
        return (_jsx(Typography, { color: "error", style: props.style, className: classes.root, children: record.message }));
    return (_jsx("div", { style: props.style, className: classes.root, children: _jsx(FilterEntryMultiSelectOptionInnerMemo, Object.assign({}, other, record)) }));
};
export default React.memo(FilterEntryMultiSelectOption);

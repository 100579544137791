var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { FileUploadGeneric } from "components-care";
import FacilityContentModel from "../../../../../components-care/models/FacilityContentModel";
import { useQuery } from "react-query";
import BackendConnector from "../../../../../components-care/connectors/BackendConnector";
import { useTranslation } from "react-i18next";
import { useTenantIdOpt } from "../../../../components/TenantContext";
import { ReactQueryRetryHandler } from "../../../../../constants";
export const DeviceModelCommunityUploadCategories = ["model", "service", "technical", "training"];
const DeviceModelViewerCommunityUploads = (props) => {
    const { recordId, category, inventoryId, enterpriseClientId } = props;
    const tenantId = useTenantIdOpt();
    const { t } = useTranslation("device-models");
    const { data: facilityContents } = useQuery(["device-viewer-facility-contents", tenantId, category, recordId], () => __awaiter(void 0, void 0, void 0, function* () {
        if (!tenantId)
            return [[], { totalRows: 0 }, null];
        const model = FacilityContentModel({
            tenantId,
            t,
            target: "tenant",
        });
        return model.fetchAll({
            additionalFilters: {
                "filter[video]": false,
            },
            fieldFilter: {
                content_type: {
                    type: "inSet",
                    value1: "file,link",
                    value2: "",
                },
                category: {
                    type: "equals",
                    value1: category,
                    value2: "",
                },
                device_model_ids: {
                    type: "equals",
                    value1: recordId,
                    value2: "",
                },
            },
        });
    }), { enabled: !!tenantId, retry: ReactQueryRetryHandler });
    const { data: inventoryDocuments } = useQuery([
        "device-viewer-inventory-uploads",
        tenantId,
        enterpriseClientId,
        inventoryId,
    ], () => {
        const endpoint = enterpriseClientId
            ? `v4/enterprise/tenants/${tenantId}/clients/${enterpriseClientId}/inventories/${inventoryId}/uploads`
            : `v4/tenants/${tenantId}/inventories/${inventoryId}/uploads`;
        return new BackendConnector(endpoint).index({ rows: 100 });
    }, { enabled: !!inventoryId, retry: ReactQueryRetryHandler });
    const files = [
        ...(facilityContents
            ? facilityContents[0].map((_entry) => {
                var _a, _b;
                const entry = _entry;
                return {
                    file: {
                        name: entry.title || entry.name,
                        downloadLink: ((_b = (_a = entry.document[0]) === null || _a === void 0 ? void 0 : _a.file) === null || _b === void 0 ? void 0 : _b.downloadLink) || entry.url,
                    },
                };
            })
            : []),
        ...(inventoryDocuments
            ? inventoryDocuments[0].map((entry) => ({
                file: {
                    name: entry.name,
                    downloadLink: entry.document,
                },
            }))
            : []),
    ];
    return (_jsx(FileUploadGeneric, { label: t("community-uploads." + category), readOnly: true, files: files, previewSize: 24, handleError: console.error, variant: "modern" }));
};
export default React.memo(DeviceModelViewerCommunityUploads);

import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { styled, Typography } from "@mui/material";
import { FormDialogDispatchContext } from "components-care/dist/backend-components/Form/FormDialog";
import { useContext, useEffect } from "react";
export const TitleTypography = React.forwardRef(function TitleTypography(props, ref) {
    const formDialogDispatch = useContext(FormDialogDispatchContext);
    useEffect(() => {
        formDialogDispatch === null || formDialogDispatch === void 0 ? void 0 : formDialogDispatch.setTitle(props.children);
    }, [formDialogDispatch, props.children]);
    if (formDialogDispatch) {
        return null;
    }
    return _jsx(Typography, Object.assign({ ref: ref }, props));
});
export const PageHeaderTypography = styled(TitleTypography)(({ theme }) => (Object.assign(Object.assign({}, theme.typography.h1), { marginBottom: 12, [theme.breakpoints.down("lg")]: Object.assign({ marginBottom: 0 }, theme.typography.h2), [theme.breakpoints.between("sm", "lg")]: {
        fontSize: "1.5rem",
    } })));
export const PageHeaderTypographyNew = styled(TitleTypography)(({ theme }) => (Object.assign(Object.assign({}, theme.typography.h1), { marginBottom: -4, [theme.breakpoints.down("lg")]: Object.assign({ marginBottom: -16 }, theme.typography.h2), [theme.breakpoints.between("sm", "lg")]: {
        fontSize: "1.5rem",
    } })));

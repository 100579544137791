import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useContext } from "react";
import { BaseSelectorContext, useDialogContext } from "components-care";
import { IconButton } from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import SearchAndMultiSelect from "../../../components/SearchAndBrowse/Instances/SearchAndMultiSelect";
import DeviceModelSearch from "../../../components/SearchAndBrowse/Instances/DeviceModelSearch";
import { useButtonEndAdornmentStyles } from "../../../components/Dialogs/PickDeviceModelButton";
import reactNodeToElement from "../../../../../utils/reactNodeToElement";
const BriefingPickDeviceModelsButton = (props) => {
    const { onSelect, disabled, target, scope, getInitialSelection, enableTenantFilters, moduleName, title, } = props;
    const { classes } = useButtonEndAdornmentStyles();
    const selectorContext = useContext(BaseSelectorContext);
    const [pushDialog] = useDialogContext();
    const handleSelect = useCallback((selected) => {
        onSelect(selected);
        if (selectorContext)
            selectorContext.addToLru(...selected);
    }, [onSelect, selectorContext]);
    const openDialog = useCallback(() => pushDialog(_jsx(SearchAndMultiSelect, { instance: DeviceModelSearch, initialSelection: getInitialSelection(), onSelected: handleSelect, props: { target, scope, enableTenantFilters, moduleName }, title: title })), [
        pushDialog,
        getInitialSelection,
        handleSelect,
        target,
        scope,
        enableTenantFilters,
        moduleName,
        title,
    ]);
    return reactNodeToElement(!disabled && (_jsx(IconButton, { onClick: openDialog, className: classes.button, disabled: disabled, size: "large", color: "primary", children: _jsx(SearchIcon, {}) })));
};
export default React.memo(BriefingPickDeviceModelsButton);

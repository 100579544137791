import { useMemo } from "react";
import { useTenantId } from "../pages/components/TenantContext";
export const getLruConfig = (tenantId, storageKey) => ({
    storageKey: (tenantId !== null && tenantId !== void 0 ? tenantId : "my") + ":" + storageKey,
    count: 10,
    forceQuery: true,
});
export const useLruConfig = (storageKey) => {
    const tenantId = useTenantId();
    return useMemo(() => getLruConfig(tenantId, storageKey), [tenantId, storageKey]);
};

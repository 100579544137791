var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback } from "react";
import SearchAndBrowse from "../index";
import { useTenantId } from "../../../../components/TenantContext";
import DepartmentModel, { DepartmentModelSelectorSort, useDepartmentModelTranslations, } from "../../../../../components-care/models/DepartmentModel";
import DepartmentRenderer from "../Renderers/DepartmentRenderer";
const FILTERS = {};
const DepartmentSearch = (props) => {
    const { enterpriseClientId } = props, other = __rest(props, ["enterpriseClientId"]);
    const tenantId = useTenantId();
    const { t: departmentModelT } = useDepartmentModelTranslations();
    const loadRecords = useCallback((search, _filters, offset) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const departmentModel = DepartmentModel({
            t: departmentModelT,
            tenantId,
            enterpriseClientId,
        });
        const [data, meta] = yield departmentModel.index2({
            offset: offset,
            rows: 25,
            sort: DepartmentModelSelectorSort,
            quickFilter: search,
        });
        return {
            data: data,
            total: (_a = meta.filteredRows) !== null && _a !== void 0 ? _a : meta.totalRows,
        };
    }), [departmentModelT, tenantId, enterpriseClientId]);
    return (_jsx(SearchAndBrowse, Object.assign({}, other, { loadRecords: loadRecords, filters: FILTERS, renderer: DepartmentRenderer, rendererContext: undefined, controlName: "DepartmentSearch" })));
};
export default React.memo(DepartmentSearch);

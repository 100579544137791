import { jsx as _jsx } from "react/jsx-runtime";
import React, { useMemo } from "react";
import SamedisCrudFileUpload, { FILE_UPLOAD_IMAGE_RESIZE, } from "../../../../../../components-care/SamedisCrudFileUpload";
import ChatAttachmentUploadRendererPreview from "./ChatAttachmentUploadRendererPreview";
import { useTenantIdOpt } from "../../../../../components/TenantContext";
import { useFormEnterpriseClientIdOpt } from "../../../../../Enterprise/utils/useFormEnterpriseClientId";
import { useIncidentsContext } from "../index";
import { getMessageUploadsUrl } from "./ChatUploads";
const ChatBoxUploads = React.forwardRef((props, ref) => {
    const { incidentId, formId, onChange } = props;
    const tenantId = useTenantIdOpt();
    const clientId = useFormEnterpriseClientIdOpt();
    const { type } = useIncidentsContext();
    const getEndpoint = useMemo(() => getMessageUploadsUrl(clientId ? tenantId : null, clientId !== null && clientId !== void 0 ? clientId : tenantId, incidentId, false, type), [clientId, incidentId, tenantId, type]);
    return (_jsx(SamedisCrudFileUpload, { ref: ref, field: "uploads", accept: ".pdf,audio/*,video/*,image/*", imageDownscaleOptions: FILE_UPLOAD_IMAGE_RESIZE, convertImagesTo: "image/png", previewImages: true, onChange: onChange, getEndpoint: getEndpoint, initialId: formId, variant: ChatAttachmentUploadRendererPreview, previewSize: 100 }));
});
export default React.memo(ChatBoxUploads);

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback } from "react";
import { Loader, showConfirmDialogBool, showErrorDialog, showInfoDialog, useDateHMS, useDialogContext, useModelGet, useModelMutation, } from "components-care";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography, } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import BackendConnector from "../../../../../components-care/connectors/BackendConnector";
import { ReactQueryRetryHandler } from "../../../../../constants";
import { useTenantModel } from "../../../../../components-care/models/TenantModel";
const useCouplingCode = (tenantId, enabled) => {
    const connector = new BackendConnector(`v4/tenants/${tenantId}/couplings`, "data");
    return useQuery(["coupling-code", tenantId], () => __awaiter(void 0, void 0, void 0, function* () {
        const server = (yield connector.create({}))[0];
        return Object.assign(Object.assign({}, server), { receivedAtClient: Date.now(), expiresAtClient: Date.now() + server.expire_in * 1000 });
    }), {
        refetchInterval: (resp) => (resp ? resp.expire_in * 1000 : 30000),
        retry: ReactQueryRetryHandler,
        enabled,
    });
};
const getExpireProgress = (code, now) => {
    const expiresMax = code.expiresAtClient - code.receivedAtClient;
    const expiresIn = code.expiresAtClient - now.getTime();
    return (expiresIn / expiresMax) * 100;
};
const FacilityLinkEnterpriseDialog = (props) => {
    const { tenantId } = props;
    const { t } = useTranslation("tenant");
    const [pushDialog, popDialog] = useDialogContext();
    const tenantModel = useTenantModel();
    const { isLoading: tenantLoading, data: tenantData, error: tenantError, } = useModelGet(tenantModel, tenantId);
    const tenant = tenantData && tenantData[0];
    const { mutateAsync: updateTenant } = useModelMutation(tenantModel);
    const canLink = tenant != null && tenant.enterprise_ids.length === 0;
    const { isLoading: couplingLoading, data: couplingData, error: couplingError, } = useCouplingCode(tenantId, canLink);
    const now = useDateHMS();
    const disconnect = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (!tenant)
            return;
        if (!(yield showConfirmDialogBool(pushDialog, {
            title: t("actions.link-enterprise.dialog.disconnect-confirm.title"),
            message: t("actions.link-enterprise.dialog.disconnect-confirm.message"),
            textButtonYes: t("actions.link-enterprise.dialog.disconnect-confirm.yes"),
            textButtonNo: t("actions.link-enterprise.dialog.disconnect-confirm.no"),
        }))) {
            return;
        }
        try {
            yield updateTenant({
                id: tenantId,
                decouple_enterprise_ids: tenant.enterprise_ids,
            });
            yield showInfoDialog(pushDialog, {
                title: t("actions.link-enterprise.dialog.disconnect-success.title"),
                message: t("actions.link-enterprise.dialog.disconnect-success.message"),
                buttons: [
                    {
                        text: t("actions.link-enterprise.dialog.disconnect-success.ok"),
                        autoFocus: true,
                    },
                ],
            });
        }
        catch (e) {
            yield showErrorDialog(pushDialog, e);
        }
        finally {
            popDialog();
        }
    }), [popDialog, pushDialog, t, tenant, tenantId, updateTenant]);
    return (_jsxs(Dialog, { open: true, children: [_jsx(DialogTitle, { children: t("actions.link-enterprise.dialog.title") }), _jsxs(DialogContent, { children: [tenantLoading && _jsx(Loader, {}), tenantError && (_jsx(Typography, { color: "error", children: tenantError.message })), tenant && (_jsx(Grid, { container: true, spacing: 2, justifyContent: "center", alignItems: "center", children: canLink ? (_jsxs(_Fragment, { children: [couplingLoading && _jsx(Loader, {}), couplingError && (_jsx(Grid, { item: true, children: _jsx(Typography, { color: "error", children: couplingError.message }) })), couplingData && (_jsxs(_Fragment, { children: [_jsx(Grid, { item: true, children: _jsx(CircularProgress, { variant: "determinate", value: getExpireProgress(couplingData, now) }) }), _jsx(Grid, { item: true, xs: true, children: _jsx(Typography, { children: couplingData.code }) })] }))] })) : (_jsx(_Fragment, { children: _jsx(Grid, { item: true, xs: true, children: _jsx(Typography, { children: t("actions.link-enterprise.dialog.linked-to", {
                                        NAME: Object.values(tenant.enterprise_names).join(", "),
                                    }) }) }) })) }))] }), _jsxs(DialogActions, { children: [!canLink && (_jsx(Button, { onClick: disconnect, color: "error", children: t("actions.link-enterprise.dialog.disconnect") })), _jsx(Button, { onClick: popDialog, children: t("actions.link-enterprise.dialog.close") })] })] }));
};
export default React.memo(FacilityLinkEnterpriseDialog);

import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useMemo, useState } from "react";
import { useTenantModel } from "../../../../../components-care/models/TenantModel";
import { useTenantId } from "../../../../components/TenantContext";
import ActiveLanguageContext, { AvailableLanguageContext, } from "../../../../../components/ActiveLanguageContext";
import { uniqueArray, useFormContext, useModelGet } from "components-care";
const DeviceModelLanguageContextProvider = (props) => {
    const { children } = props;
    const { values } = useFormContext();
    const publishedLanguages = values.published_languages;
    const tenantModel = useTenantModel();
    const tenantId = useTenantId();
    const { data: tenantData } = useModelGet(tenantModel, tenantId);
    const languageCodes = useMemo(() => {
        var _a;
        const [tenant] = tenantData !== null && tenantData !== void 0 ? tenantData : [];
        const tenantLanguageCodes = (_a = tenant === null || tenant === void 0 ? void 0 : tenant.content_languages) !== null && _a !== void 0 ? _a : [];
        return uniqueArray([...tenantLanguageCodes, ...publishedLanguages]);
    }, [tenantData, publishedLanguages]);
    const activeLanguageStatePack = useState(() => {
        var _a, _b;
        // first published language or first available language or en
        return (_b = (_a = publishedLanguages[0]) !== null && _a !== void 0 ? _a : languageCodes[0]) !== null && _b !== void 0 ? _b : "en";
    });
    const [activeLanguage, setActiveLanguage] = activeLanguageStatePack;
    // if active language is not available, update active language
    useEffect(() => {
        var _a, _b;
        if (!languageCodes.includes(activeLanguage)) {
            setActiveLanguage((_b = (_a = publishedLanguages[0]) !== null && _a !== void 0 ? _a : languageCodes[0]) !== null && _b !== void 0 ? _b : "en");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [languageCodes]);
    return (_jsx(AvailableLanguageContext.Provider, { value: languageCodes, children: _jsx(ActiveLanguageContext.Provider, { value: activeLanguageStatePack, children: children }) }));
};
export default React.memo(DeviceModelLanguageContextProvider);

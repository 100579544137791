var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { FullFormDialog as FormDialog, Model, ModelDataTypeBackendMultiSelectRenderer, ModelDataTypeBackendSingleSelectRenderer, ModelDataTypeDateNullableRendererCC, ModelDataTypeEnumSelectRenderer, ModelDataTypeStringRendererCC, ModelVisibilityDisabled, ModelVisibilityDisabledReadOnly, ModelVisibilityEdit, ModelVisibilityEditRequired, ModelVisibilityGridView, ModelVisibilityGridViewHidden, useDialogContext, validatePresence, } from "components-care";
import BackendConnector from "../connectors/BackendConnector";
import i18n from "../../i18n";
import StaffModel, { getStaffLabel, StaffModelMultiSelectedSort, StaffModelSelectorSort, StaffModelToSelectorData, useAddStaffDialog, } from "./StaffModel";
import DeviceModelsModel, { DeviceModelsModelSelectorSort, } from "./DeviceModelsModel";
import { getLruConfig, handleBackendMultiSelectLoadError } from "../../utils";
import { useTranslation } from "react-i18next";
import React, { useCallback, useMemo } from "react";
import PickDeviceModelsButton from "../../pages/TenantSpecific/components/Dialogs/PickDeviceModelsButton";
import PickEmployeesButton from "../../pages/TenantSpecific/components/Dialogs/PickEmployeesButton";
import { useTenantId } from "../../pages/components/TenantContext";
import ccI18n from "components-care/dist/i18n";
import RendererBackendObjectIdArray from "../types/renderers/RendererBackendObjectIdArray";
import { ID_FIELD_DEF } from "../../constants";
import { ContactModelSelectorSort } from "./ContactModel";
import PickEmployeeButton from "../../pages/TenantSpecific/components/Dialogs/PickEmployeeButton";
import { getCatalogNameFromRecord } from "../../pages/TenantSpecific/Training/components/Briefing/Step1/DeviceGroupSelector";
import RendererObjectId from "../types/renderers/RendererObjectId";
const TeamsCRUD = React.lazy(() => import("../../pages/TenantSpecific/Training/Teams"));
export const TeamTypeOptions = (t) => ["staff", "device", "both"].map((value) => ({
    value,
    getLabel: () => t("team:type.enum." + value),
}));
const TeamModel = (params) => {
    var _a, _b;
    return new Model("team-model", {
        type: {
            type: new ModelDataTypeEnumSelectRenderer(TeamTypeOptions(params.t), {
                disableSearch: true,
                disableClearable: true,
                placeholder: params.t("team:type.placeholder"),
            }),
            getDefaultValue: () => TeamTypeOptions(params.t)[2].value,
            getLabel: () => params.t("team:type.field"),
            visibility: {
                overview: ModelVisibilityGridView,
                create: ModelVisibilityEditRequired,
                edit: ModelVisibilityEditRequired,
            },
            filterable: true,
            sortable: true,
            customData: null,
            validate: validatePresence,
            columnWidth: [80, 320, 180],
        },
        name: {
            type: new ModelDataTypeStringRendererCC({
                placeholder: params.t("team:name.placeholder"),
            }),
            getLabel: () => params.t("team:name.field"),
            visibility: {
                overview: ModelVisibilityGridView,
                create: ModelVisibilityEditRequired,
                edit: ModelVisibilityEditRequired,
            },
            customData: null,
            filterable: true,
            sortable: true,
            columnWidth: [80, 320, 180],
            validate: validatePresence,
        },
        manager: {
            type: new ModelDataTypeStringRendererCC({
                placeholder: params.t("team:manager.placeholder"),
            }),
            getLabel: () => params.t("team:manager.field"),
            visibility: {
                overview: ModelVisibilityGridView,
                create: ModelVisibilityEdit,
                edit: ModelVisibilityEdit,
            },
            customData: null,
            filterable: true,
            sortable: true,
            columnWidth: [80, 320, 180],
        },
        description: {
            type: new ModelDataTypeStringRendererCC({
                placeholder: params.t("team:description.placeholder"),
            }),
            getLabel: () => params.t("team:description.field"),
            visibility: {
                overview: ModelVisibilityGridView,
                create: ModelVisibilityEdit,
                edit: ModelVisibilityEdit,
            },
            customData: null,
            filterable: true,
            sortable: true,
            columnWidth: [80, 640, 320],
        },
        responsible_id: {
            type: new ModelDataTypeBackendSingleSelectRenderer({
                modelToSelectorData: StaffModelToSelectorData,
                placeholder: params.t("team:responsible_id.placeholder"),
                lru: getLruConfig(params.tenantId, "team-responsible"),
                sort: ContactModelSelectorSort,
                onLoadError: handleBackendMultiSelectLoadError,
                enableIcons: true,
                iconSize: 24,
                onAddNew: ((_a = params.dialogParams) === null || _a === void 0 ? void 0 : _a.addStaffDialog) || undefined,
                endAdornment: (_jsx(PickEmployeeButton, { team: true, title: params.t("team:responsible_id.dialog-title") })),
            }),
            getRelationModel: () => StaffModel({
                t: params.t,
                tenantId: params.tenantId,
                teamResponsible: true,
            }),
            getLabel: () => params.t("team:responsible_id.field"),
            visibility: {
                overview: ModelVisibilityDisabled,
                edit: ModelVisibilityEdit,
                create: ModelVisibilityEdit,
            },
            customData: null,
            onChange: (value, _, setFieldValue, getFieldValue) => {
                if (getFieldValue("manager"))
                    return value;
                if (value) {
                    (() => __awaiter(void 0, void 0, void 0, function* () {
                        const model = StaffModel({
                            t: params.t,
                            tenantId: params.tenantId,
                            teamResponsible: true,
                        });
                        const [data] = yield model.getCached(value);
                        setFieldValue("manager", getStaffLabel(data, false, false));
                    }))();
                }
                else {
                    setFieldValue("manager", "");
                }
                return value;
            },
        },
        staff_ids: {
            type: params.tenantId
                ? new ModelDataTypeBackendMultiSelectRenderer({
                    modelToSelectorData: StaffModelToSelectorData,
                    placeholder: params.t("team:staff-selector.placeholder"),
                    lru: getLruConfig(params.tenantId, "staff-groups"),
                    sort: StaffModelSelectorSort,
                    selectedSort: StaffModelMultiSelectedSort,
                    onLoadError: handleBackendMultiSelectLoadError,
                    enableIcons: true,
                    iconSize: 24,
                    onAddNew: ((_b = params.dialogParams) === null || _b === void 0 ? void 0 : _b.addStaffDialog) || undefined,
                    endAdornment: (_jsx(PickEmployeesButton, { title: params.t("team:staff-selector.dialog-title") })),
                })
                : new RendererBackendObjectIdArray(),
            getRelationModel: params.tenantId
                ? () => StaffModel({
                    t: i18n.t.bind(i18n),
                    tenantId: params.tenantId,
                })
                : undefined,
            getLabel: () => params.t("team:staff-selector.label"),
            visibility: {
                overview: ModelVisibilityDisabled,
                create: params.tenantId
                    ? ModelVisibilityEdit
                    : ModelVisibilityDisabled,
                edit: params.tenantId ? ModelVisibilityEdit : ModelVisibilityDisabled,
            },
            customData: null,
        },
        catalog_ids: {
            type: new ModelDataTypeBackendMultiSelectRenderer({
                modelToSelectorData: (data) => ({
                    value: data.id,
                    label: getCatalogNameFromRecord(data),
                    onClick: params.openDeviceDialog
                        ? () => params.openDeviceDialog &&
                            params.openDeviceDialog(data.id)
                        : undefined,
                    icon: data.device_picture,
                }),
                placeholder: params.t("team:device-selector.placeholder"),
                lru: getLruConfig(params.tenantId, "device-groups"),
                sort: DeviceModelsModelSelectorSort(null),
                onLoadError: handleBackendMultiSelectLoadError,
                endAdornment: (_jsx(PickDeviceModelsButton, { target: "tenant", scope: "public_and_tenant", enableTenantFilters: true, moduleName: "team-device-selector", title: params.t("team:device-selector.dialog") })),
                enableIcons: true,
                iconSize: 24,
            }),
            getRelationModel: () => DeviceModelsModel({
                tenantId: params.tenantId,
                t: params.t,
                ccT: ccI18n.t,
                target: "tenant",
                extraParams: {
                    "filter[scope]": "public_and_tenant",
                    "filter[published]": true,
                },
            }),
            getLabel: () => params.t("team:device-selector.label"),
            visibility: {
                overview: ModelVisibilityDisabled,
                create: ModelVisibilityEdit,
                edit: ModelVisibilityEdit,
            },
            customData: null,
        },
        tenant_id: {
            type: new RendererObjectId(),
            getLabel: () => "",
            visibility: {
                overview: ModelVisibilityDisabledReadOnly,
                create: ModelVisibilityDisabled,
                edit: ModelVisibilityDisabled,
            },
            customData: null,
        },
        created_by_user: {
            type: new ModelDataTypeStringRendererCC(),
            getLabel: () => params.t("common:audit.created-by"),
            visibility: {
                overview: ModelVisibilityGridViewHidden,
                create: ModelVisibilityDisabled,
                edit: ModelVisibilityDisabled,
            },
            customData: null,
            filterable: true,
            sortable: true,
            columnWidth: [80, 160, 160],
        },
        created_at: {
            type: new ModelDataTypeDateNullableRendererCC(),
            getLabel: () => params.t("common:audit.created-at"),
            visibility: {
                overview: ModelVisibilityGridViewHidden,
                create: ModelVisibilityDisabled,
                edit: ModelVisibilityDisabled,
            },
            customData: null,
            filterable: true,
            sortable: true,
            columnWidth: [80, 160, 160],
        },
        updated_by_user: {
            type: new ModelDataTypeStringRendererCC(),
            getLabel: () => params.t("common:audit.updated-by"),
            visibility: {
                overview: ModelVisibilityGridViewHidden,
                create: ModelVisibilityDisabled,
                edit: ModelVisibilityDisabled,
            },
            customData: null,
            filterable: true,
            sortable: true,
            columnWidth: [80, 160, 160],
        },
        updated_at: {
            type: new ModelDataTypeDateNullableRendererCC(),
            getLabel: () => params.t("common:audit.updated-at"),
            visibility: {
                overview: ModelVisibilityGridViewHidden,
                create: ModelVisibilityDisabled,
                edit: ModelVisibilityDisabled,
            },
            customData: null,
            filterable: true,
            sortable: true,
            columnWidth: [80, 160, 160],
        },
        id: Object.assign(Object.assign({}, ID_FIELD_DEF(params.t)), { customData: null }),
    }, new BackendConnector(params.tenantId
        ? params.enterpriseClientId
            ? `v4/enterprise/tenants/${params.tenantId}/clients/${params.enterpriseClientId}/teams`
            : params.enterpriseClients
                ? `v4/enterprise/tenants/${params.tenantId}/teams`
                : `v4/tenants/${params.tenantId}/teams`
        : "v4/my/teams", "data", {
        includedRelations: params.include
            ? Object.fromEntries(params.include.map((item) => [
                item + "_ids",
                [item, item + "s"],
            ]))
            : undefined,
        additionalQueryParameters: Object.assign({}, params.extraParams),
        forceFieldFilter: params.tenantFilter
            ? {
                tenant_id: {
                    type: "equals",
                    value1: params.tenantFilter,
                    value2: "",
                },
            }
            : undefined,
    }), [
        params.tenantId,
        params.enterpriseClientId,
        params.enterpriseClients,
        params.include,
    ]);
};
export const TeamModelSelectorSort = [
    { field: "name", direction: 1 },
];
export const TeamModelToSelectorData = (data) => ({
    value: data.id,
    label: data.name,
});
export const useTeamModelTranslations = () => useTranslation(["common", "team"]);
export const useTeamModel = (params) => {
    const tenantId = useTenantId();
    const { t } = useTeamModelTranslations();
    const addStaff = useAddStaffDialog();
    return useMemo(() => TeamModel(Object.assign({ tenantId,
        t, dialogParams: { addStaffDialog: addStaff } }, params)), [tenantId, t, addStaff, params]);
};
export const useAddGroupDialog = () => {
    const [pushDialog, popDialog] = useDialogContext();
    const { t } = useTranslation("inventory");
    return useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const data = yield new Promise((resolve, reject) => pushDialog(_jsx(FormDialog, { dialogTitle: t("groups.add-new"), useCustomClasses: true, onClose: reject, children: _jsx(TeamsCRUD, { disableRouting: true, initialView: "new", formProps: {
                        onSubmit: (data) => {
                            popDialog();
                            resolve(data);
                        },
                    } }) })));
            return {
                value: data.id,
                label: data.name,
            };
        }
        catch (_e) {
            return null;
        }
    }), [popDialog, pushDialog, t]);
};
export const StaffTeamImage = "/img/placeholders/person.png";
export const DeviceTeamImage = "/img/placeholders/device.png";
export const MixedTeamImage = "/img/placeholders/device-and-person.svg";
export default TeamModel;

import { Model, ModelDataTypeBooleanCheckboxRendererCC, ModelDataTypeDateTimeNullableRendererCC, ModelDataTypeStringRendererCC, ModelVisibilityDisabled, ModelVisibilityDisabledReadOnly, ModelVisibilityHidden, ModelVisibilityView, } from "components-care";
import BackendConnector from "../connectors/BackendConnector";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ID_FIELD_DEF } from "../../constants";
export const UserNotificationModel = (params) => new Model("user-notification", {
    title: {
        type: new ModelDataTypeStringRendererCC(),
        getLabel: () => params.t("notification:fields.title"),
        visibility: {
            overview: ModelVisibilityDisabledReadOnly,
            edit: ModelVisibilityView,
            create: ModelVisibilityDisabled,
        },
        customData: null,
    },
    content: {
        type: new ModelDataTypeStringRendererCC(),
        getLabel: () => params.t("notification:fields.content"),
        visibility: {
            overview: ModelVisibilityDisabledReadOnly,
            edit: ModelVisibilityView,
            create: ModelVisibilityDisabled,
        },
        customData: null,
    },
    sender: {
        type: new ModelDataTypeStringRendererCC(),
        getLabel: () => params.t("notification:fields.sender"),
        visibility: {
            overview: ModelVisibilityDisabledReadOnly,
            edit: ModelVisibilityView,
            create: ModelVisibilityDisabled,
        },
        customData: null,
    },
    publish_at: {
        type: new ModelDataTypeDateTimeNullableRendererCC(),
        getLabel: () => params.t("notification:fields.publish_at"),
        visibility: {
            overview: ModelVisibilityDisabledReadOnly,
            edit: ModelVisibilityView,
            create: ModelVisibilityDisabled,
        },
        customData: null,
        filterable: true,
        sortable: true,
    },
    read_at: {
        type: new ModelDataTypeDateTimeNullableRendererCC(),
        getLabel: () => params.t("notification:fields.read_at"),
        visibility: {
            overview: ModelVisibilityDisabledReadOnly,
            edit: ModelVisibilityView,
            create: ModelVisibilityDisabled,
        },
        customData: null,
        filterable: true,
        sortable: true,
    },
    read: {
        type: new ModelDataTypeBooleanCheckboxRendererCC(),
        getLabel: () => params.t("notification:fields.read"),
        visibility: {
            overview: ModelVisibilityDisabledReadOnly,
            edit: ModelVisibilityHidden,
            create: ModelVisibilityHidden,
        },
        customData: null,
        getDefaultValue: () => false,
    },
    id: Object.assign(Object.assign({}, ID_FIELD_DEF(params.t)), { customData: null }),
}, new BackendConnector("v4/user/notifications", "data"));
export const useUserNotificationModelTranslations = () => useTranslation("notification");
export const useUserNotificationModel = (params) => {
    const { t } = useUserNotificationModelTranslations();
    return useMemo(() => UserNotificationModel(Object.assign({ t }, params)), [t, params]);
};
export default UserNotificationModel;

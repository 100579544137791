import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useContext } from "react";
import { BaseSelectorContext, useDialogContext, useFormFieldContext, } from "components-care";
import { IconButton } from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import { useButtonEndAdornmentStyles } from "./PickDeviceModelButton";
import SearchAndMultiSelect from "../SearchAndBrowse/Instances/SearchAndMultiSelect";
import TeamSearch from "../SearchAndBrowse/Instances/TeamSearch";
import reactNodeToElement from "../../../../utils/reactNodeToElement";
const PickTeamsButton = (props) => {
    const { type, title } = props;
    const { visibility, value, field, handleChange } = useFormFieldContext();
    const { classes } = useButtonEndAdornmentStyles();
    const selectorContext = useContext(BaseSelectorContext);
    const [pushDialog] = useDialogContext();
    const handleSelect = useCallback((selected) => {
        handleChange(field, selected);
        if (selectorContext)
            selectorContext.addToLru(...selected);
    }, [handleChange, field, selectorContext]);
    const openDialog = useCallback(() => pushDialog(_jsx(SearchAndMultiSelect, { instance: TeamSearch, initialSelection: value, onSelected: handleSelect, props: { type }, title: title })), [pushDialog, value, handleSelect, type, title]);
    return reactNodeToElement(!visibility.readOnly && (_jsx(IconButton, { onClick: openDialog, className: classes.button, disabled: visibility.readOnly, size: "large", color: "primary", children: _jsx(SearchIcon, {}) })));
};
export default React.memo(PickTeamsButton);

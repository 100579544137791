import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useMemo } from "react";
import { Checkbox, GroupBox, Loader, uniqueArray, useFormContext, } from "components-care";
import { FormControlLabel, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDeviceModelVersions } from "./DeviceModelVersionList";
const DeviceModelTrainingInheritEntry = React.memo((props) => {
    const { id, version, version_number, checked, current, disabled, onToggle, } = props;
    const { t } = useTranslation("device-models");
    const handleInheritTrainingChange = useCallback((_evt, checked) => {
        onToggle(id, checked);
    }, [onToggle, id]);
    return (_jsx(Grid, { item: true, xs: 12, children: _jsx(FormControlLabel, { name: "training_share-" + id, checked: checked || current, disabled: disabled || current, onChange: handleInheritTrainingChange, control: _jsx(Checkbox, {}), label: `${version || version_number}${current ? ` ${t("training_sharing.current")}` : ""}` }) }));
});
const DeviceModelTrainingInherit = () => {
    const { t } = useTranslation("device-models");
    const { id: recordId, values, setFieldValue, getFieldValue, } = useFormContext();
    const { version_number, training_shared_version_ids } = values;
    if (!recordId)
        throw new Error("Record ID not set");
    const { isLoading, data: versionData, error, } = useDeviceModelVersions(values);
    const handleInheritTrainingChange = useCallback((id, checked) => {
        if (!versionData)
            return;
        const training_shared_version_ids = getFieldValue("training_shared_version_ids");
        const version = versionData[0].find((ver) => ver.id === id);
        const ids = [id, ...version.training_shared_version_ids];
        setFieldValue("training_shared_version_ids", checked
            ? uniqueArray([...training_shared_version_ids, ...ids])
            : training_shared_version_ids.filter((x) => !ids.includes(x)));
    }, [versionData, getFieldValue, setFieldValue]);
    return useMemo(() => {
        if (error)
            return _jsx(Typography, { color: "error", children: error.message });
        if (isLoading || !versionData)
            return _jsx(Loader, {});
        const [versions] = versionData;
        return (_jsx(GroupBox, { label: t("training_sharing.label"), children: _jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(Typography, { children: t("training_sharing.explainer") }) }), _jsx(Grid, { item: true, xs: 12, container: true, children: versions.map((version) => (_jsx(DeviceModelTrainingInheritEntry, Object.assign({ checked: training_shared_version_ids.includes(version.id), disabled: version.version_number > version_number, current: version.id === recordId, onToggle: handleInheritTrainingChange }, version), version.id))) })] }) }));
    }, [
        error,
        isLoading,
        versionData,
        t,
        training_shared_version_ids,
        version_number,
        recordId,
        handleInheritTrainingChange,
    ]);
};
export default React.memo(DeviceModelTrainingInherit);

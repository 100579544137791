import { useCallback, useEffect, useRef, useState } from "react";
const useScrollToEnd = () => {
    // automatically scroll down in case of new content
    // automatically scroll down after attachments have been loaded
    const scrollContainerRef = useRef(null);
    const scrollTarget = useRef(0);
    const activeRef = useRef(true);
    const [active, setActive] = useState(true);
    const onScroll = useCallback((evt) => {
        // scroll(x, y) triggers scroll event, so we need to check for it here
        // resize to smaller also triggers scroll event, need to check for that too
        const div = evt.currentTarget;
        const maxScroll = div.scrollHeight - div.clientHeight;
        const scrolledToEnd = Math.abs(div.scrollTop - maxScroll) <= 1;
        // disable if user scrolled (check scrollTo(x,y) and resize triggered events)
        // re-engage if scrolled to end
        const newActive = (activeRef.current && div.scrollTop === scrollTarget.current) ||
            scrolledToEnd;
        /*console.log(
          "onScroll newActive=",
          newActive,
          "maxScroll=",
          maxScroll,
          "scrollTop=",
          div.scrollTop,
          "scrollTarget=",
          scrollTarget.current
        );*/
        if (activeRef.current !== newActive) {
            activeRef.current = newActive;
            setActive(newActive);
        }
    }, []);
    useEffect(() => {
        const div = scrollContainerRef.current;
        if (!div || !active)
            return;
        const scrollToEndInterval = window.setInterval(() => {
            //console.log("scrolling to end", activeRef.current);
            if (!activeRef.current) {
                return;
            }
            scrollTarget.current = div.scrollHeight - div.clientHeight;
            div.scroll(0, scrollTarget.current);
        }, 100);
        return () => {
            if (scrollToEndInterval != null) {
                window.clearInterval(scrollToEndInterval);
            }
        };
    }, [active]);
    return {
        ref: scrollContainerRef,
        onScroll,
    };
};
export default useScrollToEnd;

import { Model, ModelDataTypeStringRendererCC, ModelVisibilityDisabled, ModelVisibilityDisabledReadOnly, } from "components-care";
import BackendConnector from "../connectors/BackendConnector";
import { ID_FIELD_DEF } from "../../constants";
import i18n from "../../i18n";
const DisabledFieldVisibility = {
    overview: ModelVisibilityDisabledReadOnly,
    create: ModelVisibilityDisabled,
    edit: ModelVisibilityDisabledReadOnly,
};
const VendorModel = (tenantId, extraParams, useTenantVendors) => new Model(useTenantVendors ? "tenant-vendor-model" : "vendor-model", {
    last_name: {
        type: new ModelDataTypeStringRendererCC(),
        getLabel: () => "",
        visibility: DisabledFieldVisibility,
        customData: null,
        filterable: true,
        sortable: true,
    },
    id: Object.assign(Object.assign({}, ID_FIELD_DEF(i18n.t.bind(i18n))), { customData: null }),
}, new BackendConnector(tenantId
    ? useTenantVendors
        ? `v4/tenants/${tenantId}/manufacturers`
        : "v4/manufacturers"
    : "v4/my/manufacturers", "data", {
    additionalQueryParameters: Object.assign(Object.assign({}, extraParams), { tenant_id: useTenantVendors || !tenantId ? undefined : tenantId }),
}), [tenantId, useTenantVendors]);
export const VendorModelSelectorSort = [
    {
        field: "last_name",
        direction: 1,
    },
];
export const VendorModelToSelectorData = (data) => ({
    value: data.id,
    label: data.last_name,
});
export default VendorModel;

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useTenantId } from "../../../pages/components/TenantContext";
import { useQuery } from "react-query";
import SamedisApiClient from "../../../components-care/connectors/SamedisApiClient";
import { ReactQueryRetryHandler } from "../../../constants";
export const usePlans = (withCustom) => {
    const tenantId = useTenantId();
    return useQuery(["plans", { tenantId, withCustom }], () => __awaiter(void 0, void 0, void 0, function* () {
        const data = (yield SamedisApiClient.get(`/api/v4/tenants/${tenantId}/plans`, { "filter[with_custom]": withCustom })).data;
        return data.map((entry) => entry.attributes);
    }), {
        retry: ReactQueryRetryHandler,
    });
};
export const useBillingHistories = () => {
    const tenantId = useTenantId();
    return useQuery(["subscriptions", { tenantId }], () => __awaiter(void 0, void 0, void 0, function* () {
        const data = (yield SamedisApiClient.get(`/api/v4/tenants/${tenantId}/invoices`, null)).data;
        return data.map((entry) => entry.attributes);
    }), {
        retry: ReactQueryRetryHandler,
    });
};
export const useSubscriptionPreview = (planId, additionalDevices) => {
    const tenantId = useTenantId();
    return useQuery(["subscription-preview", { tenantId, planId, additionalDevices }], () => __awaiter(void 0, void 0, void 0, function* () {
        const resp = yield SamedisApiClient.post(`/api/v4/tenants/${tenantId}/subscription_previews`, null, {
            data: {
                plan_id: planId,
                additional_devices: additionalDevices,
            },
        });
        return resp.data.attributes;
    }), {
        retry: ReactQueryRetryHandler,
    });
};

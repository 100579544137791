var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback } from "react";
import { ListItemIcon, ListItemText, Menu, MenuItem, } from "@mui/material";
import { useTranslation } from "react-i18next";
import { destroySession, openProfile, redirectToLogin } from "../AuthProvider";
import { ExitToApp as LogoutIcon, Language as LangSwitchIcon, Person as ProfileIcon, } from "@mui/icons-material";
import { LocaleSelectorDialog, useDialogContext } from "components-care";
import supportedLanguages from "../../../assets/data/supported-languages.json";
const menuAnchorOrigin = {
    vertical: "top",
    horizontal: "right",
};
const menuTransformOrigin = {
    vertical: "top",
    horizontal: "right",
};
const ProfileMenu = (props) => {
    const { anchorEl, handleClose } = props;
    const [pushDialog] = useDialogContext();
    const { t } = useTranslation("portal");
    const handleOpenProfile = useCallback(() => {
        openProfile();
        handleClose();
    }, [handleClose]);
    const handleOpenLanguageDialog = useCallback(() => {
        pushDialog(_jsx(LocaleSelectorDialog, { supportedLocales: supportedLanguages }));
        handleClose();
    }, [pushDialog, handleClose]);
    const handleLogout = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        yield destroySession();
        redirectToLogin(false);
        handleClose();
    }), [handleClose]);
    return (_jsxs(Menu, { open: !!anchorEl, anchorEl: anchorEl, keepMounted: true, onClose: handleClose, anchorOrigin: menuAnchorOrigin, transformOrigin: menuTransformOrigin, children: [_jsxs(MenuItem, { onClick: handleOpenProfile, children: [_jsx(ListItemIcon, { children: _jsx(ProfileIcon, {}) }), _jsx(ListItemText, { primary: t("header.account.menu.profile") })] }), _jsxs(MenuItem, { onClick: handleOpenLanguageDialog, children: [_jsx(ListItemIcon, { children: _jsx(LangSwitchIcon, {}) }), _jsx(ListItemText, { primary: t("header.account.menu.language") })] }), _jsxs(MenuItem, { onClick: handleLogout, children: [_jsx(ListItemIcon, { children: _jsx(LogoutIcon, {}) }), _jsx(ListItemText, { primary: t("header.account.menu.logout") })] })] }));
};
export default React.memo(ProfileMenu);

import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useEffect, useRef } from "react";
import { Grid, Typography } from "@mui/material";
import PermissionGroup from "./PermissionGroup";
import { useAccessGroups } from "../../../../../api/ident-services/AccessManagement";
import { Skeleton } from "@mui/material";
const PermissionView = (props) => {
    const { selected, field, setSelected, tenant } = props;
    const { isLoading, data, error } = useAccessGroups(tenant);
    // we put selected data into a ref, so we don't need to update the callback every time
    const selectedRef = useRef(selected);
    useEffect(() => {
        selectedRef.current = selected;
    }, [selected]);
    const handleSelect = useCallback((id, itemSelected) => {
        if (itemSelected) {
            setSelected(field, [...selectedRef.current, id]);
        }
        else {
            const newSelection = selectedRef.current.filter((entry) => entry !== id);
            setSelected(field, newSelection);
        }
    }, [field, setSelected]);
    if (isLoading)
        return _jsx(Skeleton, { variant: "rectangular" });
    if (error || !data)
        return _jsx(Typography, { children: error.message });
    const dataEntries = Object.entries(data);
    return (_jsx(Grid, { container: true, spacing: 4, alignItems: "stretch", className: "permission-view", children: dataEntries.map(([title, blocks]) => (_jsx(Grid, { item: true, xs: 12, md: Math.max(6, 12 / dataEntries.length), lg: Math.max(4, 12 / dataEntries.length), xl: Math.max(3, 12 / dataEntries.length), container: true, direction: "column", children: _jsx(PermissionGroup, { title: title, blocks: blocks, 
                // only pass relevant selections and combine them to a string so React.memo does its job
                selected: selected
                    .filter((id) => blocks.find((block) => block.id === id))
                    .join(","), setSelected: handleSelect }) }, title))) }));
};
export default React.memo(PermissionView);

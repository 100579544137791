var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useMemo } from "react";
import SearchAndBrowse from "../index";
import { useTenantIdWithContext } from "../../../../components/TenantContext";
import StaffModel, { StaffModelSelectorSort, useStaffModelTranslations, } from "../../../../../components-care/models/StaffModel";
import { multiSelectFilterEntryFromModel } from "../FilterEntryMultiSelect";
import TeamModel, { TeamModelSelectorSort, TeamModelToSelectorData, useTeamModelTranslations, } from "../../../../../components-care/models/TeamModel";
import StaffRenderer from "../Renderers/StaffRenderer";
const StaffSearch = (props) => {
    const { briefingRequirements, tenantIdMySamedis, team } = props, other = __rest(props, ["briefingRequirements", "tenantIdMySamedis", "team"]);
    const tenantId = useTenantIdWithContext(tenantIdMySamedis);
    const { t: staffModelT } = useStaffModelTranslations();
    const { t: teamModelT } = useTeamModelTranslations();
    if (tenantIdMySamedis &&
        (!briefingRequirements || briefingRequirements.length !== 1))
        throw new Error("my-samedis briefing requirements invalid");
    const loadRecords = useCallback((search, filters, offset) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const staffModel = StaffModel({
            t: staffModelT,
            tenantId,
            teamResponsible: team,
            trainerDeviceModelId: tenantIdMySamedis
                ? briefingRequirements[0]
                : null,
            extraParams: {
                "filter[team_ids]": filters.teams.join(","),
                "filter[briefed_device_model_ids]": tenantIdMySamedis
                    ? undefined
                    : filters.with_training
                        ? briefingRequirements === null || briefingRequirements === void 0 ? void 0 : briefingRequirements.join(",")
                        : undefined,
                "filter[status]": filters.with_resigned
                    ? undefined
                    : "active",
            },
        });
        const [data, meta] = yield staffModel.index2({
            offset: offset,
            rows: 25,
            sort: StaffModelSelectorSort,
            quickFilter: search,
        });
        return {
            data: data,
            total: (_a = meta.filteredRows) !== null && _a !== void 0 ? _a : meta.totalRows,
        };
    }), [staffModelT, tenantId, team, tenantIdMySamedis, briefingRequirements]);
    const filters = useMemo(() => ({
        teams: Object.assign({ variant: "multi-select", title: staffModelT("staff:grid.filter.team_ids.label") }, multiSelectFilterEntryFromModel(TeamModel({
            t: teamModelT,
            tenantId: tenantIdMySamedis ? null : tenantId,
            tenantFilter: tenantIdMySamedis,
            extraParams: { "filter[type]": "staff" },
        }), TeamModelSelectorSort, TeamModelToSelectorData)),
        with_training: {
            variant: "switch",
            title: staffModelT("staff:grid.filter.with_training"),
            hidden: tenantIdMySamedis ||
                !briefingRequirements ||
                briefingRequirements.length === 0,
        },
        with_resigned: {
            variant: "switch",
            title: staffModelT("staff:grid.filter.switch-label"),
        },
    }), [
        staffModelT,
        teamModelT,
        tenantIdMySamedis,
        tenantId,
        briefingRequirements,
    ]);
    return (_jsx(SearchAndBrowse, Object.assign({}, other, { loadRecords: loadRecords, filters: filters, renderer: StaffRenderer, rendererContext: { briefingRequirements }, controlName: "StaffSearch" })));
};
export default React.memo(StaffSearch);

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useContext, useMemo } from "react";
import { Grid } from "@mui/material";
import { ActionButton, showErrorDialog, useDialogContext, useFormContext, useModelMutation, } from "components-care";
import { useTranslation } from "react-i18next";
import { useDeviceModelsModel } from "../../../../../../components-care/models/DeviceModelsModel";
import { MdmContext } from "../../../MdmTypes";
const DeviceModelActionNewVersion = (props) => {
    const { close, openView } = props;
    const { t } = useTranslation("device-models");
    const { id: recordId, getFieldValue, submit, addBusyReason, } = useFormContext();
    const target = useContext(MdmContext);
    const [pushDialog] = useDialogContext();
    if (!recordId)
        throw new Error("Record ID not set");
    const versionModel = useDeviceModelsModel(useMemo(() => ({
        target,
        versionId: recordId,
    }), [recordId, target]));
    const { mutateAsync: createVersion } = useModelMutation(versionModel);
    const handleNewVersion = useCallback(() => {
        close();
        const newVersionStr = (getFieldValue("version_number") + 1).toString();
        addBusyReason("new-version", (() => __awaiter(void 0, void 0, void 0, function* () {
            try {
                yield submit();
                try {
                    const [newVersionRecord] = yield createVersion({
                        version: newVersionStr,
                    });
                    openView(newVersionRecord.id, true);
                }
                catch (e) {
                    showErrorDialog(pushDialog, e);
                }
            }
            catch (_e) {
                // error displayed by form
            }
        }))());
    }, [
        addBusyReason,
        close,
        createVersion,
        getFieldValue,
        openView,
        pushDialog,
        submit,
    ]);
    return useMemo(() => {
        // tenant can't create versions
        if (target === "tenant")
            return _jsx(React.Fragment, {});
        return (_jsx(Grid, { item: true, xs: 12, children: _jsx(ActionButton, { onClick: handleNewVersion, children: t("actions.new-version") }) }));
    }, [handleNewVersion, t, target]);
};
export default React.memo(DeviceModelActionNewVersion);

import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { Domain as TenantIcon } from "@mui/icons-material";
import { useAuthProviderContext } from "../../components/AuthProvider";
import TenantListEntry from "./TenantListEntry";
import { useTranslation } from "react-i18next";
import { useOpenInvites } from "../../../api/samedis/Invites";
import PolicyLinks from "./PolicyLinks";
import CreateTenantExplainer from "./CreateTenantExplainer";
import NoTenantExplainer from "./NoTenantExplainer";
import { useNavigate, useLocation } from "components-care";
const TenantList = () => {
    const authInfo = useAuthProviderContext();
    const { t } = useTranslation("tenant-select");
    const navigate = useNavigate();
    const tenant_id = localStorage.getItem("tenant");
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const tenantRedirectURL = query.get("tenant_redirect");
    const { data: invites } = useOpenInvites();
    // use cached tenant to redirect directly upon page visit if it is valid
    // navigate must be used in useEffect
    useEffect(() => {
        if (tenant_id &&
            authInfo.current_user.tenants.find((tenant) => tenant.id === tenant_id)) {
            navigate(`/tenant/${tenant_id}${tenantRedirectURL !== null && tenantRedirectURL !== void 0 ? tenantRedirectURL : ""}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authInfo]);
    const goToTenantCreate = useCallback(() => {
        navigate("/new-tenant");
    }, [navigate]);
    return (_jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(Typography, { variant: "subtitle1", children: t("select") }) }), _jsx(Grid, { item: true, xs: 12, sm: 10, md: 6, children: _jsxs(Grid, { container: true, spacing: 2, children: [authInfo.current_user.tenants.length === 0 && (_jsx(Grid, { item: true, xs: 12, children: _jsx(NoTenantExplainer, {}) }, "no-tenant")), authInfo.current_user.tenants.map((tenant) => {
                            var _a;
                            return (_jsx(Grid, { item: true, xs: 12, children: _jsx(TenantListEntry, { id: tenant.id, image: (_a = tenant.image.small) !== null && _a !== void 0 ? _a : _jsx(TenantIcon, { color: "primary" }), text: tenant.short_name || tenant.full_name, subtext: tenant.town }) }, tenant.id));
                        }), invites === null || invites === void 0 ? void 0 : invites.map((invite) => {
                            var _a;
                            return (_jsx(Grid, { item: true, xs: 12, children: _jsx(TenantListEntry, { id: invite.tenant_id, image: (_a = invite.tenant.image) !== null && _a !== void 0 ? _a : _jsx(TenantIcon, { color: "primary" }), text: invite.tenant.name || invite.tenant.name2, invite: invite }) }, invite.tenant_id));
                        })] }) }), (authInfo.candos.includes("samedis-care/tenants.create" /* current */) ||
                authInfo.candos.includes("samedis-care/tenant.create" /* legacy */)) && (_jsxs(_Fragment, { children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(Typography, { variant: "subtitle1", children: t("create-title") }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(Grid, { container: true, spacing: 2, children: _jsxs(Grid, { item: true, xs: 12, sm: 10, md: 6, container: true, direction: "column", children: [_jsx(Grid, { item: true, children: _jsx(Box, { pb: 2, children: _jsx(CreateTenantExplainer, {}) }) }), _jsx(Grid, { item: true, children: _jsx(TenantListEntry, { id: "new", image: _jsx(TenantIcon, {}), text: t("create"), onClick: goToTenantCreate }) }), _jsx(Grid, { item: true, children: _jsx(Box, { pt: 2, children: _jsx(PolicyLinks, {}) }) })] }) }) })] }))] }));
};
export default React.memo(TenantList);

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { CountryFlags, FullFormDialog, Model, ModelDataTypeBackendSingleSelectRenderer, ModelDataTypeBooleanCheckboxRendererCC, ModelDataTypeDateNullableRendererCC, ModelDataTypeEnumSelectRenderer, ModelDataTypeStringRendererCC, ModelVisibilityDisabled, ModelVisibilityDisabledReadOnly, ModelVisibilityEdit, ModelVisibilityEditReadOnly, ModelVisibilityEditRequired, ModelVisibilityGridView, ModelVisibilityGridViewHidden, useDialogContext, validateEmail, validatePresence, } from "components-care";
import BackendConnector from "../connectors/BackendConnector";
import countryList from "components-care/dist/assets/data/countries.json";
import IdentAccountModel, { IdentAccountModelSelectorSort, } from "./IdentAccountModel";
import { getLruConfig } from "../../utils";
import handleBackendMultiSelectLoadError from "../../utils/handleBackendMultiSelectLoadError";
import { useTranslation } from "react-i18next";
import { useTenantId } from "../../pages/components/TenantContext";
import { useCallback, useMemo } from "react";
import { useCCCountryTranslations } from "components-care/dist/utils/useCCTranslations";
import PickContactButton from "../../pages/TenantSpecific/components/Dialogs/PickContactButton";
import RendererContactCategories from "../types/renderers/RendererContactCategories";
import RendererImageWithStatus from "../types/renderers/RendererImageWithStatus";
import ContactCRUD from "../../pages/TenantSpecific/ContactsAndEmployee/Contacts";
import { ID_FIELD_DEF, INVALID_ID } from "../../constants";
import { showImageEditDialog } from "../../components/ImageEdit/ImageEditDialog";
export const CONTACT_CATEGORY_OPTIONS = (t) => [
    "consultation",
    "waste_disposal",
    "specialized_planning",
    "commerce",
    "manufacturer",
    "inventorize",
    "personnel_service_provider",
    "inspection",
    "repair",
    "calibration",
].map((value) => ({
    value,
    getLabel: () => t("contact:categories.enum." + value),
}));
const ContactModel = (params) => {
    var _a, _b, _c;
    return new Model("contact-model", {
        avatar: {
            type: new RendererImageWithStatus(["ident_user_id", "contact_type"], {
                uploadLabel: params.t("common:uploads.choose-picture"),
                postEditCallback: params.postImageEdit,
            }, (values) => values.contact_type === "contact"
                ? values.ident_user_id
                    ? "has_account"
                    : "no_account"
                : null),
            getLabel: () => params.t("contact:avatar.field"),
            getColumnLabel: () => "",
            visibility: {
                overview: ModelVisibilityGridView,
                create: ModelVisibilityEdit,
                edit: ModelVisibilityEdit,
            },
            filterable: false,
            customData: null,
            columnWidth: [60, 60, 60],
        },
        name: {
            type: new ModelDataTypeStringRendererCC({
                placeholder: params.t("contact:name.placeholder"),
            }),
            getLabel: () => params.t("contact:name.field"),
            visibility: {
                overview: ModelVisibilityGridView,
                create: ModelVisibilityDisabled,
                edit: ModelVisibilityDisabledReadOnly,
            },
            filterable: true,
            sortable: true,
            customData: null,
            columnWidth: [80, 640, 320],
        },
        phone: {
            type: new ModelDataTypeStringRendererCC({
                placeholder: params.t("contact:phone.placeholder"),
            }),
            getLabel: () => params.t("contact:phone.field"),
            visibility: {
                overview: ModelVisibilityGridView,
                create: ModelVisibilityEdit,
                edit: ModelVisibilityEdit,
            },
            filterable: true,
            sortable: true,
            customData: null,
            columnWidth: [80, 320, 200],
        },
        email: {
            type: new ModelDataTypeStringRendererCC({
                placeholder: params.t("contact:email.placeholder"),
            }),
            getLabel: () => params.t("contact:email.field"),
            visibility: {
                overview: ModelVisibilityGridViewHidden,
                create: ModelVisibilityEdit,
                edit: ModelVisibilityEdit,
            },
            filterable: true,
            sortable: true,
            customData: null,
            columnWidth: [80, 320, 200],
            validate: (value, values, field) => {
                if (!value)
                    return null; // optional
                const validEmail = validateEmail(value, values, field);
                if (validEmail != null)
                    return validEmail;
                return null;
            },
        },
        mobile: {
            type: new ModelDataTypeStringRendererCC({
                placeholder: params.t("contact:mobile.placeholder"),
            }),
            getLabel: () => params.t("contact:mobile.field"),
            visibility: {
                overview: ModelVisibilityGridViewHidden,
                create: ModelVisibilityEdit,
                edit: ModelVisibilityEdit,
            },
            filterable: true,
            sortable: true,
            customData: null,
            columnWidth: [80, 320, 200],
        },
        fax: {
            type: new ModelDataTypeStringRendererCC({
                placeholder: params.t("contact:fax.placeholder"),
            }),
            getLabel: () => params.t("contact:fax.field"),
            visibility: {
                overview: ModelVisibilityGridViewHidden,
                create: ModelVisibilityEdit,
                edit: ModelVisibilityEdit,
            },
            filterable: true,
            sortable: true,
            customData: null,
            columnWidth: [80, 320, 200],
        },
        url: {
            type: new ModelDataTypeStringRendererCC({
                placeholder: params.t("contact:url.placeholder"),
            }),
            getLabel: () => params.t("contact:url.field"),
            visibility: {
                overview: ModelVisibilityGridViewHidden,
                create: ModelVisibilityEdit,
                edit: ModelVisibilityEdit,
            },
            filterable: true,
            sortable: true,
            customData: null,
            columnWidth: [80, 320, 250],
        },
        street: {
            type: new ModelDataTypeStringRendererCC({
                placeholder: params.t("contact:street.placeholder"),
            }),
            getLabel: () => params.t("contact:street.field"),
            visibility: {
                overview: ModelVisibilityGridViewHidden,
                create: ModelVisibilityEdit,
                edit: ModelVisibilityEdit,
            },
            filterable: true,
            sortable: true,
            customData: null,
            columnWidth: [80, 640, 320],
        },
        zip: {
            type: new ModelDataTypeStringRendererCC({
                placeholder: params.t("contact:zip.placeholder"),
            }),
            getLabel: () => params.t("contact:zip.field"),
            visibility: {
                overview: ModelVisibilityGridViewHidden,
                create: ModelVisibilityEdit,
                edit: ModelVisibilityEdit,
            },
            filterable: true,
            sortable: true,
            customData: null,
            columnWidth: [120, 120, 120],
        },
        town: {
            type: new ModelDataTypeStringRendererCC({
                placeholder: params.t("contact:town.placeholder"),
            }),
            getLabel: () => params.t("contact:town.field"),
            visibility: {
                overview: ModelVisibilityGridViewHidden,
                create: ModelVisibilityEdit,
                edit: ModelVisibilityEdit,
            },
            filterable: true,
            sortable: true,
            customData: null,
            columnWidth: [80, 640, 200],
        },
        country: {
            type: new ModelDataTypeEnumSelectRenderer(countryList.map((countryCode) => ({
                value: countryCode,
                getLabel: () => params.ccT("countries:" + countryCode),
                icon: CountryFlags[countryCode],
            })), {
                enableIcons: true,
                placeholder: params.t("contact:country.placeholder"),
            }),
            getLabel: () => params.t("contact:country.field"),
            visibility: {
                overview: ModelVisibilityGridViewHidden,
                create: ModelVisibilityEdit,
                edit: ModelVisibilityEdit,
            },
            filterable: true,
            sortable: true,
            customData: null,
            columnWidth: [80, 640, 200],
        },
        first_name: {
            type: new ModelDataTypeStringRendererCC({
                placeholder: params.t("contact:first_name.placeholder"),
            }),
            getLabel: () => params.t("contact:first_name.field"),
            visibility: {
                overview: ModelVisibilityDisabledReadOnly,
                create: ModelVisibilityEdit,
                edit: ModelVisibilityEdit,
            },
            filterable: true,
            sortable: true,
            customData: null,
        },
        last_name: {
            type: new ModelDataTypeStringRendererCC({
                placeholder: params.t("contact:last_name.placeholder"),
            }),
            getLabel: () => params.t("contact:last_name.field"),
            visibility: {
                overview: ModelVisibilityDisabledReadOnly,
                create: ModelVisibilityEditRequired,
                edit: ModelVisibilityEditRequired,
            },
            filterable: true,
            sortable: true,
            customData: null,
            validate: validatePresence,
        },
        salutation: {
            type: new ModelDataTypeStringRendererCC({
                placeholder: params.t("contact:salutation.placeholder"),
            }),
            getLabel: () => params.t("contact:salutation.field"),
            visibility: {
                overview: ModelVisibilityDisabled,
                create: ModelVisibilityEdit,
                edit: ModelVisibilityEdit,
            },
            filterable: true,
            sortable: true,
            customData: null,
        },
        company_id: {
            type: new ModelDataTypeBackendSingleSelectRenderer({
                modelToSelectorData: (data) => {
                    let label = `${data.last_name}`;
                    if (data.first_name)
                        label = label + `, ${data.first_name}`;
                    return {
                        value: data.id,
                        label: label,
                    };
                },
                placeholder: params.t("contact:company-of-contact.placeholder"),
                lru: getLruConfig(params.tenantId, "contact-company"),
                sort: ContactModelSelectorSort,
                onLoadError: handleBackendMultiSelectLoadError,
                endAdornment: (_jsx(PickContactButton, { target: params.target, extraParams: { "filter[contact_types]": ["company"] }, title: params.t("contact:company-of-contact.dialog-title") })),
            }),
            getRelationModel: () => ContactModel({
                tenantId: params.tenantId,
                target: params.target,
                t: params.t,
                ccT: params.ccT,
                extraParams: {
                    "filter[contact_types]": ["company"],
                },
            }),
            getLabel: () => params.t("contact:company-of-contact.label"),
            visibility: {
                overview: ModelVisibilityDisabled,
                create: ModelVisibilityEdit,
                edit: ModelVisibilityEdit,
            },
            filterable: false,
            sortable: false,
            customData: null,
        },
        contact_type: {
            type: new ModelDataTypeEnumSelectRenderer([
                {
                    value: "staff",
                    getLabel: () => params.t("contact:contact_type.enum.staff"),
                    invisible: true,
                },
                {
                    value: "contact",
                    getLabel: () => params.t("contact:contact_type.enum.contact"),
                },
                {
                    value: "company",
                    getLabel: () => params.t("contact:contact_type.enum.company"),
                },
            ], {
                disableSearch: true,
                disableClearable: false,
                placeholder: params.t("contact:contact_type.placeholder"),
            }),
            getDefaultValue: () => { var _a, _b; return (_b = (_a = params.forceFields) === null || _a === void 0 ? void 0 : _a.contact_type) !== null && _b !== void 0 ? _b : "contact"; },
            getLabel: () => params.t("contact:contact_type.field"),
            visibility: {
                overview: ModelVisibilityGridViewHidden,
                create: ((_a = params.forceFields) === null || _a === void 0 ? void 0 : _a.contact_type)
                    ? ModelVisibilityEditReadOnly
                    : ModelVisibilityEditRequired,
                edit: ModelVisibilityEditReadOnly,
            },
            filterable: true,
            sortable: true,
            customData: null,
            validate: validatePresence,
            columnWidth: [80, 320, 160],
        },
        ident_user_id: {
            type: new ModelDataTypeBackendSingleSelectRenderer({
                modelToSelectorData: (data) => ({
                    value: data.id,
                    label: `${data.first_name} ${data.last_name} (${data.email})`,
                }),
                placeholder: params.t("staff:select-account.placeholder"),
                sort: IdentAccountModelSelectorSort,
                onLoadError: handleBackendMultiSelectLoadError,
            }),
            getRelationModel: () => IdentAccountModel(params.tenantId),
            getLabel: () => params.t("staff:select-account.field"),
            visibility: {
                overview: ModelVisibilityDisabledReadOnly,
                create: ModelVisibilityEdit,
                edit: ModelVisibilityEdit,
            },
            customData: null,
        },
        status: {
            type: new ModelDataTypeEnumSelectRenderer([
                {
                    value: "public",
                    getLabel: () => params.t("contact:status.enum.public"),
                },
                {
                    value: "tenant",
                    getLabel: () => params.t("contact:status.enum.tenant"),
                },
            ], {
                placeholder: params.t("contact:status.placeholder"),
            }),
            getLabel: () => params.t("contact:status.field"),
            getDefaultValue: () => params.target === "tenant" ? "tenant" : "public",
            visibility: {
                overview: ModelVisibilityDisabledReadOnly,
                create: ModelVisibilityEditRequired,
                edit: ModelVisibilityEditRequired,
            },
            customData: null,
            columnWidth: [80, 320, 160],
        },
        is_public: {
            type: new ModelDataTypeBooleanCheckboxRendererCC(),
            getLabel: () => params.t("contact:is_public.field"),
            visibility: {
                overview: ModelVisibilityGridViewHidden,
                create: ModelVisibilityDisabled,
                edit: ModelVisibilityDisabled,
            },
            filterable: true,
            sortable: true,
            customData: null,
            columnWidth: [80, 160, 120],
        },
        categories: {
            type: new RendererContactCategories(CONTACT_CATEGORY_OPTIONS(params.t), {
                disableSearch: true,
                placeholder: params.t("contact:categories.placeholder"),
            }),
            getLabel: () => params.t("contact:categories.field"),
            visibility: {
                overview: ModelVisibilityGridViewHidden,
                create: ModelVisibilityEdit,
                edit: ModelVisibilityEdit,
            },
            filterable: true,
            sortable: true,
            customData: null,
            columnWidth: [80, 320, 160],
        },
        company_name: {
            type: new ModelDataTypeStringRendererCC(),
            getLabel: () => "",
            visibility: {
                overview: ModelVisibilityDisabledReadOnly,
                create: ModelVisibilityDisabledReadOnly,
                edit: ModelVisibilityDisabledReadOnly,
            },
            customData: null,
        },
        created_by_user: {
            type: new ModelDataTypeStringRendererCC(),
            getLabel: () => params.t("common:audit.created-by"),
            visibility: {
                overview: ModelVisibilityGridViewHidden,
                create: ModelVisibilityDisabled,
                edit: ModelVisibilityDisabled,
            },
            customData: null,
            filterable: true,
            sortable: true,
            columnWidth: [80, 160, 160],
        },
        created_at: {
            type: new ModelDataTypeDateNullableRendererCC(),
            getLabel: () => params.t("common:audit.created-at"),
            visibility: {
                overview: ModelVisibilityGridViewHidden,
                create: ModelVisibilityDisabled,
                edit: ModelVisibilityDisabled,
            },
            customData: null,
            filterable: true,
            sortable: true,
            columnWidth: [80, 160, 160],
        },
        updated_by_user: {
            type: new ModelDataTypeStringRendererCC(),
            getLabel: () => params.t("common:audit.updated-by"),
            visibility: {
                overview: ModelVisibilityGridViewHidden,
                create: ModelVisibilityDisabled,
                edit: ModelVisibilityDisabled,
            },
            customData: null,
            filterable: true,
            sortable: true,
            columnWidth: [80, 160, 160],
        },
        updated_at: {
            type: new ModelDataTypeDateNullableRendererCC(),
            getLabel: () => params.t("common:audit.updated-at"),
            visibility: {
                overview: ModelVisibilityGridViewHidden,
                create: ModelVisibilityDisabled,
                edit: ModelVisibilityDisabled,
            },
            customData: null,
            filterable: true,
            sortable: true,
            columnWidth: [80, 160, 160],
        },
        id: Object.assign(Object.assign({}, ID_FIELD_DEF(params.t)), { customData: null }),
    }, new BackendConnector(params.target === "admin"
        ? "v4/contacts"
        : params.target === "enterprise"
            ? `v4/enterprise/tenants/${params.tenantId}/contacts`
            : params.target === "tenant"
                ? params.incident
                    ? params.enterpriseClientId
                        ? `v4/enterprise/tenants/${params.tenantId}/clients/${params.enterpriseClientId}/inventories/${params.incident.inventoryId}/incident_supporter`
                        : `v4/tenants/${params.tenantId}/inventories/${params.incident.inventoryId}/incident_supporter`
                    : params.issue
                        ? params.enterpriseClientId
                            ? `v4/enterprise/tenants/${params.tenantId}/clients/${params.enterpriseClientId}/inventories/${params.issue.inventoryId}/issue_responsible`
                            : `v4/tenants/${params.tenantId}/inventories/${params.issue.inventoryId}/issue_responsible`
                        : params.enterpriseClientId
                            ? `v4/enterprise/tenants/${params.tenantId}/clients/${params.enterpriseClientId}/contacts`
                            : `v4/tenants/${params.tenantId}/contacts`
                : `v4/tenants/${params.tenantId}/mdm/contacts`, "data", {
        additionalQueryParameters: Object.assign(Object.assign({}, params.extraParams), { tenant_id: params.target === "admin" ? params.tenantId : undefined }),
        forceFieldFilter: (() => {
            const filters = {};
            if (params.hasEmail) {
                filters["email"] = {
                    type: "notEmpty",
                    value1: "notEmpty",
                    value2: "",
                };
            }
            if (params.ofCategory) {
                filters["categories"] = Array.isArray(params.ofCategory)
                    ? {
                        type: "inSet",
                        value1: params.ofCategory.join(",") || INVALID_ID,
                        value2: "",
                    }
                    : {
                        type: "equals",
                        value1: params.ofCategory,
                        value2: "",
                    };
            }
            return filters;
        })(),
    }), [
        params.target,
        params.tenantId,
        (_b = params.incident) === null || _b === void 0 ? void 0 : _b.inventoryId,
        (_c = params.issue) === null || _c === void 0 ? void 0 : _c.inventoryId,
        params.enterpriseClientId,
        params.forceFields,
    ]);
};
export const ContactModelToSelectorData = (data) => ({
    value: data.id,
    label: data.name,
    icon: data.avatar,
});
export const ContactModelSelectorSort = [
    {
        field: "last_name",
        direction: 1,
    },
    {
        field: "first_name",
        direction: 1,
    },
];
export const useAddContactDialog = (props) => {
    const { title, customDataConverter } = props, crudProps = __rest(props, ["title", "customDataConverter"]);
    const [pushDialog, popDialog] = useDialogContext();
    return useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const data = yield new Promise((resolve, reject) => pushDialog(_jsx(FullFormDialog, { dialogTitle: title, useCustomClasses: true, onClose: reject, children: _jsx(ContactCRUD, Object.assign({}, crudProps, { disableRouting: true, initialView: "new", mode: "tenant", formProps: {
                        onSubmit: (data) => {
                            popDialog();
                            resolve(data);
                        },
                    } })) })));
            return (customDataConverter !== null && customDataConverter !== void 0 ? customDataConverter : ContactModelToSelectorData)(data);
        }
        catch (_e) {
            return null;
        }
    }), [crudProps, customDataConverter, popDialog, pushDialog, title]);
};
export const ContactImage = "/img/placeholders/person.png";
export const CompanyImage = "/img/placeholders/company.png";
export const useContactModelTranslations = () => {
    return useTranslation(["contact", "staff", "common"]);
};
export const useContactModelCCTranslations = () => useCCCountryTranslations();
export const useContactModel = (params) => {
    const tenantId = useTenantId();
    const [pushDialog] = useDialogContext();
    const { t } = useContactModelTranslations();
    const { t: ccT } = useContactModelCCTranslations();
    return useMemo(() => ContactModel(Object.assign({ tenantId,
        t,
        ccT, postImageEdit: (image) => showImageEditDialog(pushDialog, image, t("contact:avatar.edit-title")) }, params)), [tenantId, t, ccT, pushDialog, params]);
};
export default ContactModel;

import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useContext } from "react";
import { BaseSelectorContext, useDialogContext, useFormFieldContext, } from "components-care";
import { IconButton } from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import { makeStyles } from "tss-react/mui";
import DeviceModelSearch from "../SearchAndBrowse/Instances/DeviceModelSearch";
import SearchAndSingleSelect from "../SearchAndBrowse/Instances/SearchAndSingleSelect";
import { useFormEnterpriseClientIdOpt } from "../../../Enterprise/utils/useFormEnterpriseClientId";
import reactNodeToElement from "../../../../utils/reactNodeToElement";
export const useButtonEndAdornmentStyles = makeStyles()({
    button: {
        padding: 2,
        marginRight: -2,
    },
});
const PickDeviceModelButton = (props) => {
    const { target, scope, disableInventory, moduleName, title } = props;
    const { visibility, field, handleChange } = useFormFieldContext();
    const enterpriseClientId = useFormEnterpriseClientIdOpt();
    const { classes } = useButtonEndAdornmentStyles();
    const selectorContext = useContext(BaseSelectorContext);
    const [pushDialog, popDialog] = useDialogContext();
    const handleSelect = useCallback((selected) => {
        if (selected) {
            handleChange(field, selected);
            if (selectorContext)
                selectorContext.addToLru(selected);
            popDialog();
        }
    }, [handleChange, field, selectorContext, popDialog]);
    const openDialog = useCallback(() => pushDialog(_jsx(SearchAndSingleSelect, { instance: DeviceModelSearch, onSelected: handleSelect, props: {
            target,
            scope,
            disableInventory,
            moduleName,
            enterpriseClientId,
        }, title: title })), [
        pushDialog,
        handleSelect,
        target,
        scope,
        disableInventory,
        moduleName,
        enterpriseClientId,
        title,
    ]);
    return reactNodeToElement(!visibility.readOnly && (_jsx(IconButton, { onClick: openDialog, className: classes.button, disabled: visibility.readOnly, size: "large", color: "primary", children: _jsx(SearchIcon, {}) })));
};
export default React.memo(PickDeviceModelButton);

import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { makeStyles } from "tss-react/mui";
import { combineClassNames } from "components-care";
import { useTranslation } from "react-i18next";
import { PageHeaderTypographyNew } from "../../../../../components/CustomTypography";
const useStyles = makeStyles()((theme) => ({
    root: {
        display: "inline-block",
        borderRadius: theme.shape.borderRadius,
        padding: `0 ${theme.spacing(2)}`,
    },
    smallLabel: {
        fontSize: "1.5rem",
    },
    status_published: {
        backgroundColor: theme.palette.success.main,
        color: theme.palette.success.contrastText,
    },
    status_draft: {
        backgroundColor: theme.palette.warning.main,
        color: theme.palette.warning.contrastText,
    },
}));
const PublishStatus = (props) => {
    const { status, small } = props;
    const { classes } = useStyles();
    const { t } = useTranslation("device-models");
    return (_jsx(PageHeaderTypographyNew, { component: "span", className: combineClassNames([
            classes.root,
            small && classes.smallLabel,
            classes[("status_" + status)],
        ]), children: t("publish-status." + status) }));
};
export default React.memo(PublishStatus);
